/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

//should inform Backend Team if any modifications done in this file

import * as yup from 'yup';
import {
  Bank,
  ControllingPersons,
  Groups,
  NomineeType,
  nonIndividualQuestionsFatca,
  ubo,
  Applicant,
  Broker,
} from '../redux-store/types/api-types';
import {
  accountNumberRegex,
  ifscRegex,
  stringRegex,
  addressRegex,
  phoneRegExp,
  nonIndividualPanRegex,
  emailRegex,
  individualPanRegex,
  numberRegex,
  amountRegex,
  alphaNumeric,
  indianPin,
  arnCodeRegex,
  linkRegex,
  cdslRegex,
  dpIdRegex,
  dpIdNumberRegex,
  defaultRegexWithSingleLine,
  pinCodeRegexOtherThanIndia,
} from './regex';
import {
  isMinor,
  currencyConversion,
  maxAge,
  getCountryCodes,
  checkForCorrespondenceAddress,
  futureAge,
  checkDateIsValid,
  checkUBOTypeIsTrust,
  checkValidationBasedOnDate,
  getdpIdField,
  getclIdField,
  isCDSL,
  getDematDropdown,
  checkFirstApplicant,
  otherNameOfBroker,
  shouldValidateUponSaveLater,
  isAccreditedInvestorApplicable,
  isFieldApplicable,
  isFieldVariable,
} from './utilityFunctions';
import { InternalOptions } from 'yup/lib/types';
import { Values } from '../components/investors/contactDetails';
import { Distributor, SubDistributor } from '../redux-store/actions';
import { Values as ContributorValues } from '../components/investors/contributorDetails';
import { Values as KycValues } from '../components/investors/additionalKYCDetails';
import {
  getApplicantMappedBank,
  Values as DocumentValues,
} from '../components/investors/documentDetails';
import { Values as NonIndividualDocValues } from '../components/NonIndividualInvestor/DocumentDetails';
import { getSubQuestionsIndex } from '../components/NonIndividualInvestor/fatca';
import {
  AdditionalRmDetailsValidationDate,
  addressTypesMasters,
  adminSupportMaster,
  applicantStatusMasters,
  APPLICATION_TYPE,
  BankAccountTypeMaster,
  CpUboCodesMaster,
  dateForDematDropdown,
  dematFieldValidationDate,
  DLCLMasters,
  dpIdFieldValidationDate,
  ENTITY_TYPE_MASTER,
  entityTypeValidationDate,
  genderMasters,
  grossAnnualMasters,
  investorTypeMasters,
  JointHolderDematValidationDate,
  nationalitiesMasters,
  FEE_TYPE_MASTER,
  NomineeRelations,
  NRIBankAccountTypeMaster,
  occupationDetailsMasters,
  PEPsMasters,
  Relations,
  UboTypeMaster,
  yesAndNoMasters,
  yesAndNoMasters1,
  YES_NO_MASTER,
} from './constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { Values as NonIndividualContactValues } from '../components/NonIndividualInvestor/ContactDetails';
import { Values as individualFatcaValues } from '../components/investors/fatca';
import { updatedBank } from '../components/investors/bankDetails';

export const contributorDetailsSchema = yup.object().shape({
  // folioType: yup.string().nullable().required('Folio Type is required'),
  applicants: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .nullable()
        .when('isActive', {
          is: (isActive: boolean) => isActive,
          then: yup
            .string()
            .nullable()
            .test('name', (value, context) => {
              const {
                options: { context: optionsContext },
                path,
                createError,
              } = context;
              if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return createError({
                  message: 'Name is required',
                  path,
                });
              }
              return true;
            }),
        }),
      gender: yup
        .string()
        .nullable()
        .when('isActive', {
          is: (isActive: boolean) => isActive,
          then: yup
            .string()
            .nullable()
            .oneOf(genderMasters, 'Invalid value for Gender')
            .test('gender', (value, context) => {
              const {
                options: { context: optionsContext },
                path,
                createError,
              } = context;
              if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return createError({
                  message: 'Please select gender',
                  path,
                });
              }
              return true;
            }),
        }),
      motherName: yup
        .string()
        .nullable()
        .when('isActive', {
          is: (isActive: boolean) => isActive,
          then: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed'),
        }),
      // leiExpiry: yup
      //   .string()
      //   .nullable()
      //   .test('leiExpiry', (value, context) => {
      //     const fieldValue = value === undefined ? '' : value;
      //     const { path, createError } = context;
      //     if (value && checkDateIsValid(fieldValue as string)) {
      //       return createError({
      //         message: 'Invalid Date',
      //         path,
      //       });
      //     }
      //     return true;
      //   }),
      fatherOrSpouseName: yup
        .string()
        .nullable()
        .when('isActive', {
          is: (isActive: boolean) => isActive,
          then: yup
            .string()
            .nullable()
            .test('fatherOrSpouseName', (value, context) => {
              const {
                options: { context: optionsContext },
                path,
                createError,
              } = context;
              if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return createError({
                  message: 'Father/Spouse Name is required',
                  path,
                });
              }
              return true;
            }),
        }),
      dateOfBirth: yup
        .string()
        .nullable()
        .test('dateOfBirth', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const {
            path,
            createError,
            parent,
            options: { context: optionsContext },
          } = context;
          if (parent.isActive) {
            if (!fieldValue && shouldValidateUponSaveLater(optionsContext?.saveType)) {
              return createError({
                message: 'Date of birth is required',
                path,
              });
            }
            if (value && checkDateIsValid(fieldValue as string)) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            if (futureAge(fieldValue || '')) {
              return createError({
                message: 'Future dates not allowed',
                path,
              });
            }
            if (isMinor(fieldValue || '')) {
              return createError({
                message: 'Age should be greater than 18',
                path,
              });
            }
          }
          return true;
        }),
      status: yup
        .string()
        .nullable()
        .when('isActive', {
          is: (isActive: boolean) => isActive,
          then: yup
            .string()
            .nullable()
            .oneOf(Object.keys(applicantStatusMasters), 'Invalid value for Status')
            .test('status', (value, context) => {
              const {
                options: { context: optionsContext },
                path,
                createError,
              } = context;
              if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return createError({
                  message: 'Status is required',
                  path,
                });
              }
              return true;
            }),
        }),
      passportExpiryDate: yup
        .string()
        .nullable()
        .when('status', {
          is: (status: string) => status === 'NRI',
          then: yup
            .string()
            .nullable()
            .test('passportExpiryDate', (value, context) => {
              const fieldValue = value === undefined ? '' : value;
              const { path, createError, parent } = context;
              if (value && checkDateIsValid(fieldValue as string)) {
                return createError({
                  message: 'Invalid Date',
                  path,
                });
              }
              return true;
            }),
        }),
      passportNumber: yup
        .string()
        .nullable()
        .when('status', {
          is: (status: string) => status === 'NRI',
          then: yup
            .string()
            .nullable()
            .matches(defaultRegexWithSingleLine, 'Special Character not allowed'),
        }),
      nationality: yup
        .string()
        .nullable()
        .test('nationality', (value, context) => {
          const fieldValue = value === null ? '' : value;
          // eslint-disable-next-line
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<ContributorValues>;
          const { nationalityDropdown = [] } = optionsContext || {};
          if (parent.isActive) {
            if (!fieldValue && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
              return createError({
                message: 'Nationality is required',
                path,
              });
            }
            if (fieldValue && !nationalityDropdown.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Nationality',
                path,
              });
            }
            // if (
            //   applicantStatusMasters[parent.status] === applicantStatusMasters.NRI &&
            //   value?.toUpperCase() !== 'INDIAN'
            // ) {
            //   return createError({
            //     message: 'Invalid value for Nationality',
            //     path,
            //   });
            // }
          }
          return true;
        }),
      jointApplicantRelation: yup
        .string()
        .nullable()
        .test('jointApplicationRelation', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { index } = options as InternalOptions<ContributorValues> & {
            index: number;
          };

          if (index !== 0 && parent.isActive) {
            if (!fieldValue && shouldValidateUponSaveLater(options.context?.saveType || '')) {
              return createError({
                message: 'Relationship with primary contributor is required',
                path,
              });
            }
            if (fieldValue && !Relations.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Relationship with primary contributor',
                path,
              });
            }
          }
          return true;
        }),
      relationShipWithFirstApplicant: yup
        .string()
        .nullable()
        .when(['jointApplicantRelation', 'isActive'], {
          is: (jointApplicantRelation: string | null, isActive: boolean) =>
            jointApplicantRelation === 'Others' && isActive,
          then: yup
            .string()
            .nullable()
            .test('relationShipWithFirstApplicant', (value, context) => {
              const {
                options: { context: optionsContext },
                path,
                createError,
              } = context;
              if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return createError({
                  message: 'Please Specify Relationship',
                  path,
                });
              }
              return true;
            }),
        }),
      weddingAnniversaryDate: yup
        .string()
        .nullable()
        .test('weddingAnniversaryDate', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (value && checkDateIsValid(fieldValue as string)) {
            return createError({
              message: 'Invalid Date',
              path,
            });
          }
          if (futureAge(fieldValue || '') && parent.isActive) {
            return createError({
              message: 'Future dates not allowed',
              path,
            });
          }
          return true;
        }),
      physicalCommunication: yup
        .string()
        .nullable()
        .oneOf(Object.keys(yesAndNoMasters1), 'Invalid value for Physical Communication')
        .test('relationShipWithFirstApplicant', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Physical Communication is required',
              path,
            });
          }
          return true;
        }),
    })
  ),
});

const validateOverseasAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? true : fieldValue !== '';
};

const validatePermanentAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  applicationType: string
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  const { permanentAddressSameAsCorresponding = false } =
    checkForCorrespondenceAddress(nationality, status) ||
    APPLICATION_TYPE.NON_INDIVIDUAL === applicationType
      ? currentApplicant.address.correspondence || {}
      : currentApplicant.address.overseas || {};
  return permanentAddressSameAsCorresponding ? true : fieldValue !== '';
};

const validatePincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  if (addressType === 'correspondence' && !checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (addressType === 'overseas' && checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (!fieldValue && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode should be 6 digit code',
      path,
    });
  }
  if (
    fieldValue &&
    parent.country?.toLowerCase() !== 'india' &&
    !pinCodeRegexOtherThanIndia.test(fieldValue)
  ) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  return true;
};

const validateCorrespondenceAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? fieldValue !== '' : true;
};

export const contactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      phoneNumberoffice: yup.string().nullable(),
      phoneNumberResidence: yup.string().nullable(),
      email: yup
        .string()
        .nullable()
        .matches(emailRegex, 'Invalid Email ID')
        .test('email', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Email is required',
              path,
            });
          }
          return true;
        }),
      mobile: yup
        .string()
        .nullable()
        .test('mobile', (value, context) => {
          const { createError, path, parent, options } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.countryNameAndCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
            return createError({
              message: 'Mobile is required',
              path,
            });
          }
          if (value && parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          if (value && !isValidPhoneNumber(value, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      countryNameAndCode: yup
        .string()
        .nullable()
        .test('countryNameAndCode', (value, context) => {
          const { createError, path, parent, options } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === value) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
            return createError({
              message: 'Country Code is required',
              path,
            });
          }
          if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const {
                options: { context: optionsContext },
              } = context;
              if (shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return validateCorrespondenceAddressFields(value, context);
              }
              return true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              if (!fetchedFromKRA && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                return validateCorrespondenceAddressFields(value, context);
              }
              return true;
            }),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validatePincodeField(value, context, 'correspondence')
            ),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const {
                options: { context: optionsContext },
              } = context;
              if (shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return validateCorrespondenceAddressFields(value, context);
              }
              return true;
            }),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) => {
              const {
                options: { context: optionsContext },
              } = context;
              if (shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return validateCorrespondenceAddressFields(value, context);
              }
              return true;
            }),
          // country: yup
          // .string()
          // .nullable()
          // .test('country', 'Country is required', (value, context) =>
          // validateCorrespondenceAddressFields(value, context)
          // ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [], applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;

              if (
                !value &&
                checkForCorrespondenceAddress(nationality, status) &&
                shouldValidateUponSaveLater(optionsContext?.saveType || '')
              ) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (
                value &&
                checkForCorrespondenceAddress(nationality, status) &&
                !countryDropdown.includes(value)
              ) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        overseas: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validateOverseasAddressFields(value, context);
              }
              return true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validateOverseasAddressFields(value, context);
              }
              return true;
            }),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => validatePincodeField(value, context, 'overseas')),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validateOverseasAddressFields(value, context);
              }
              return true;
            }),
          // state: yup
          //   .string()
          //   .nullable()
          //   .test('state', 'State is required', (value, context) =>
          //     validateOverseasAddressFields(value, context)
          //   ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              if (!value && checkForCorrespondenceAddress(nationality, status)) {
                return true;
              }
              if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (value && value.toUpperCase() === 'INDIA') {
                return createError({
                  message: "Country can't be choosen as INDIA",
                  path,
                });
              }
              if (value && !countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validateOverseasAddressFields(value, context)
          //   ),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options?.context?.saveType)) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .trim()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                return validatePincodeField(value, context, 'permanent');
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [] } = optionsContext || {};
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                // if (
                //   applicantStatusMasters[status || ''] === applicantStatusMasters.NRI &&
                //   value.toUpperCase() === 'INDIA'
                // ) {
                //   return createError({
                //     message:
                //       "If the Status is Individual (NRI) then Country can't be choosen as INDIA",
                //     path,
                //   });
                // }
                if (value && !countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validatePermanentAddressFields(value, context)
          //   ),
        }),
      }),
    })
  ),
});

export const KYCDetailsSchema = (brokers: Broker[], createdAt: string) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        occupationDetails: yup
          .string()
          .oneOf(occupationDetailsMasters, 'Invalid value for occupation details')
          .test('occupationDetails', (value, context) => {
            const {
              options: { context: optionsContext },
              path,
              createError,
            } = context;
            if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
              return createError({
                message: 'Occupation details is required',
                path,
              });
            }
            return true;
          }),
        politicallyExposedPersonStatus: yup
          .string()
          .oneOf(PEPsMasters, 'Invalid value for PEP Status')
          .test('politicallyExposedPersonStatus', (value, context) => {
            const {
              options: { context: optionsContext },
              path,
              createError,
            } = context;
            if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
              return createError({
                message: 'PEP status is required',
                path,
              });
            }
            return true;
          }),
        ckycNo: yup.number().test('ckycNo', (value, context) => {
          const {
            options: { context: optionsContext },
            createError,
            path,
          } = context;
          const { applicants } = optionsContext || {};
          if (!value) {
            return true;
          }
          if (value?.toString().length !== 14) {
            return createError({
              message: 'Invalid CKYC No',
              path,
            });
          }
          if (
            applicants.filter(
              (applicant: Applicant) => applicant.ckycNo?.toString() === value.toString()
            ).length !== 1
          ) {
            return createError({
              message:
                'There is already same CKYC Number for an contributor associated with this application',
              path,
            });
          }
          return true;
        }),
        dlclId: yup
          .string()
          .nullable()
          .test('dlclId', (value, context) => {
            const { path, createError, parent, options } = context;
            if (
              (!checkFirstApplicant(parent.applicant_type) &&
                checkValidationBasedOnDate(createdAt, JointHolderDematValidationDate)) ||
              !checkValidationBasedOnDate(createdAt, JointHolderDematValidationDate)
            ) {
              if (
                !value &&
                checkValidationBasedOnDate(createdAt, dateForDematDropdown) &&
                shouldValidateUponSaveLater(options?.context?.saveType)
              ) {
                return createError({
                  message: 'Demat Account Details (for allotment of units) is required',
                  path,
                });
              }
              if (value && !getDematDropdown(DLCLMasters, createdAt).includes(value)) {
                return createError({
                  message: 'Invalid value for Demat Account Details (for allotment of units)',
                  path,
                });
              }
            }
            return true;
          }),
        dpId: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getdpIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('dpId', (value, context) => {
                const {
                  path,
                  createError,
                  parent,
                  options: { context: optionsContext },
                } = context;
                if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                  return createError({
                    message: `${isCDSL(parent.dlclId) ? 'DPCL ID' : 'DP ID'} is required`,
                    path,
                  });
                }
                if (checkValidationBasedOnDate(createdAt, dematFieldValidationDate)) {
                  if (value && !isCDSL(parent.dlclId) && !dpIdRegex.test(value)) {
                    return createError({
                      message: `DP ID should consist of 8 digits and must start with 'IN'`,
                      path,
                    });
                  }

                  if (value && isCDSL(parent.dlclId)) {
                    if (
                      !dpIdNumberRegex.test(value) &&
                      checkValidationBasedOnDate(createdAt, dpIdFieldValidationDate)
                    ) {
                      return createError({
                        message: 'DPCL ID should consist of 16 numeric digits',
                        path,
                      });
                    }
                    if (
                      !checkValidationBasedOnDate(createdAt, dpIdFieldValidationDate) &&
                      !(cdslRegex.test(value) || dpIdNumberRegex.test(value))
                    ) {
                      return createError({
                        message: 'DPCL ID should consist of 8 or 16 numeric digits',
                        path,
                      });
                    }
                    return true;
                  }
                  return true;
                }
                return true;
              }),
          }),
        clId: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getclIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('clId', (value, context) => {
                const {
                  path,
                  createError,
                  options: { context: optionsContext },
                } = context;
                if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                  return createError({
                    message: 'CL ID is required',
                    path,
                  });
                }
                if (checkValidationBasedOnDate(createdAt, dematFieldValidationDate)) {
                  if (value && !cdslRegex.test(value)) {
                    return createError({
                      message: 'CL ID should consist of 8 numeric digits',
                      path,
                    });
                  }
                }
                return true;
              }),
          }),
        nameOfBroker: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getdpIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('nameOfBroker', (value, context) => {
                const { path, createError } = context;
                const brokerList = brokers.map((broker) => broker.key);
                if (!value) {
                  return true;
                }
                if (value && !brokerList.includes(value)) {
                  return createError({
                    message: 'Invalid value for Name of Broker/Depository Participant (DP)',
                    path,
                  });
                }
                return true;
              }),
          }),
        otherNameOfBroker: yup
          .string()
          .nullable()
          .when(['dlclId', 'nameOfBroker'], {
            is: (dlclId: string, nameOfBroker: string) =>
              getdpIdField(dlclId || '') && otherNameOfBroker(nameOfBroker),
            then: yup.string().nullable().required('Other Name of Broker is required'),
          }),
      })
    ),
  });

const createSchemaForNonIndianResident = (fieldName: string, validationMessage: string) => {
  return {
    is: (value: string) => value.toString() === 'true',
    then: yup.string().test(fieldName, (value, context) => {
      const { path, createError, options } = context;
      if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
        return createError({
          message: validationMessage,
          path,
        });
      }
      return true;
    }),
  };
};

export const FATCAValidationSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      typeOfAddressProvidedAtKRA: yup
        .string()
        .oneOf(addressTypesMasters, 'Invalid value for Type of Address Provided At KRA')
        .test('typeOfAddressProvidedAtKRA', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Please select type of address provided',
              path,
            });
          }
          return true;
        }),
      placeOfBirth: yup
        .string()
        .matches(stringRegex, 'Special characters and numbers not allowed')
        .test('placeOfBirth', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Please enter place of birth',
              path,
            });
          }
          return true;
        }),
      // countryOfBirth: yup
      //   .string()
      //   .required('Please enter country of birth')
      //   .matches(stringRegex, 'Please enter country of birth'),
      countryOfBirth: yup
        .string()
        .nullable()
        .test('countryOfBirth', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!fieldValue && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
            return createError({
              message: 'country of birth is required',
              path,
            });
          }
          if (fieldValue && !countryDropdown.map((list) => list.name).includes(fieldValue)) {
            return createError({
              message: 'Invalid value for country of birth',
              path,
            });
          }
          return true;
        }),
      countryOfNationality: yup
        .string()
        .nullable()
        .test('countryOfNationality', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!fieldValue && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
            return createError({
              message: 'country of nationality is required',
              path,
            });
          }
          if (fieldValue && !countryDropdown.map((list) => list.name).includes(fieldValue)) {
            return createError({
              message: 'Invalid value for country of nationality',
              path,
            });
          }
          return true;
        }),
      taxCountryName: yup
        .string()
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('taxCountryName', 'Please enter tax country name')
        ),
      taxID: yup
        .string()
        .max(20, 'Invalid Tax ID')
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('taxID', 'Please enter Tax ID')
        ),
      idType: yup
        .string()
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('idType', 'Please enter ID Type')
        ),
      // nameOfEntity: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter name of entity')
      //   ),
      // dateOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter date of incorporation')
      //   ),
      // cityOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter city of incorporation')
      //   ),
      // countryOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter country of incorporation')
      //   ),
      // entityExcemptionCode: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter entity exemption code')
      //   ),
    })
  ),
});

export const adminSupportValidation = yup.object().shape({
  supportType: yup.string().required('Support Type is required'),
  application_number: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        [
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
          adminSupportMaster.application_complete_for_topup,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().required('Application Number / Topup Number is required'),
    }),
  status: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        adminSupportMaster[supportType] === adminSupportMaster.change_expired_status,
      then: yup.string().required('Status is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        ![
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
          adminSupportMaster.process_signed_applications,
          adminSupportMaster.delete_topup_application,
          adminSupportMaster.application_complete_for_topup,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().test('phone', (value, context) => {
        const { createError, path, parent } = context;
        const codesList = getCountryCodes()
          .map((codes) => {
            if (codes.label === parent.countryCode) {
              return codes.countryCode;
            }
          })
          .filter((ele) => ele)
          .toString();
        if (!value) {
          return createError({
            message: 'Mobile number is required',
            path,
          });
        }
        if (parent.countryCode === 'India: +91' && !phoneRegExp.test(value)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        if (!isValidPhoneNumber(value, codesList as CountryCode)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        return true;
      }),
    }),
  // .required('Mobile Number is required')
  // .min(8, 'Invalid mobile number')
  // .max(16, 'Invalid mobile number')
  // .matches(phoneRegExp, 'Invalid mobile number'),
  // countryCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }
  //     return true;
  //   }),
});

export const nomineeDetailsSchema = (applicants?: Partial<Applicant>[]) =>
  yup.object().shape({
    nominees: yup.array().of(
      yup.object().shape({
        nomineeName: yup
          .string()
          .nullable()
          .when(['isActive'], {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .test('nomineeName', (value, context) => {
                const { path, createError, options } = context;
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'Nominee Name is required',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeRelationship: yup.string().when(['Relationship', 'isActive'], {
          is: (Relationship: string | null, isActive: boolean) =>
            isActive && Relationship === 'OTHERS',
          then: yup
            .string()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('nomineeRelationship', (value, context) => {
              const { path, createError, options } = context;
              if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                return createError({
                  message: 'Please Specify Relationship',
                  path,
                });
              }
              return true;
            }),
        }),
        Relationship: yup
          .string()
          .nullable()
          .when(['isActive'], {
            is: (isActive: boolean) => {
              return isActive;
            },
            then: yup
              .string()
              .nullable()
              .oneOf(NomineeRelations, 'Invalid value for Nominee Relationship')
              .test('Relationship', (value, context) => {
                const { path, createError, options } = context;
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'Please select relationship',
                    path,
                  });
                }
                return true;
              }),
          }),

        dateOfBirth: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup.string().nullable().required('Date of Birth is required'),
          })
          .test('dateOfBirth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, options } = context;
            if (
              value &&
              checkDateIsValid(fieldValue as string) &&
              shouldValidateUponSaveLater(options.context?.saveType)
            ) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            if (futureAge(fieldValue || '')) {
              return createError({
                message: 'Future dates not allowed',
                path,
              });
            }
            return true;
          }),
        nomineePercentage: yup.number().when(['isActive'], {
          is: (isActive: boolean) => isActive,
          then: yup
            .number()
            .typeError('Nominee % should be a number')
            .test(
              'nomineePercentage',
              'Total Nominee % should be equal to 100%',
              (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                } = context;
                if (
                  !value &&
                  value !== 0 &&
                  shouldValidateUponSaveLater(optionsContext?.saveType)
                ) {
                  return createError({
                    message: 'Nominee % is required',
                    path,
                  });
                }
                if (value === 0) {
                  return createError({
                    message: 'Nominee % should be greater then 0',
                    path,
                  });
                }
                const { nominees } = optionsContext || {};
                const total = nominees
                  .filter((_nominee: NomineeType) => _nominee.isActive)
                  .map((nominee: NomineeType) => Number(nominee.nomineePercentage))
                  .reduce((a: number, b: number) => a + b, 0);
                return nominees.length && total === 100;
              }
            ),
        }),
        guardianName: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .test('guardianName', (value, context) => {
                const { path, createError, options } = context;
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'Guardian Name is required',
                    path,
                  });
                }
                return true;
              }),
          }),
        // nomineePan: yup
        //   .string()
        //   .nullable()
        //   .matches(individualPanRegex, 'Only individual PANs are allowed')
        //   .test('nomineePan', (value, context) => {
        //     const {
        //       options: { context: optionsContext },
        //       createError,
        //       path,
        //       // eslint-disable-next-line
        //       parent,
        //     } = context;
        //     const { nominees = [] } = optionsContext || {};
        //     const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
        //     // if (!value && !isMinor(parent.dateOfBirth || '')) {
        //     //   return createError({
        //     //     message: 'Nominee PAN is required',
        //     //     path,
        //     //   });
        //     // }
        //     if (value && applicantsPan.length && applicantsPan?.includes(value)) {
        //       return createError({
        //         message: 'Applicant PAN not allowed to enter as nominee PAN',
        //         path,
        //       });
        //     }
        //     if (
        //       value &&
        //       nominees.filter((nominee: NomineeType) => nominee.nomineePan === value).length !== 1
        //     ) {
        //       return createError({
        //         message: 'There is already same pan for a nominee associated with this application',
        //         path,
        //       });
        //     }
        //     // if (value && parent.nomineeGuardianPan && value === parent.nomineeGuardianPan) {
        //     //   return createError({
        //     //     message: 'Nominee Pan should not be same as guardian PAN',
        //     //     path,
        //     //   });
        //     // }
        //     return true;
        //   }),
        // nomineeGuardianPan: yup
        //   .string()
        //   .nullable()
        //   .when('dateOfBirth', {
        //     is: (dateOfBirth: string | null) => isMinor(dateOfBirth || ''),
        //     then: yup
        //       .string()
        //       .nullable()
        //       .matches(individualPanRegex, 'Only individual PANs are allowed')
        //       .test('nomineeGuardianPan', (value, context) => {
        //         const {
        //           options: { context: optionsContext },
        //           createError,
        //           path,
        //           parent,
        //         } = context;
        //         // if (!value && isMinor(parent.dateOfBirth || '')) {
        //         //   return createError({
        //         //     message: 'Guardian Pan is required',
        //         //     path,
        //         //   });
        //         // }
        //         if (value && parent.nomineePan && value === parent.nomineePan) {
        //           return createError({
        //             message: 'Guardian Pan should not be same as nominee PAN',
        //             path,
        //           });
        //         }
        //         return true;
        //       }),
        //   }),
      })
    ),
  });

export const bankDetailsSchema = (applicants: Partial<Applicant>[] | undefined) =>
  yup.object().shape({
    banks: yup.array().of(
      yup.object().shape({
        ifscCode: yup
          .string()
          .nullable()
          .when('doNotWishToContributeBank', {
            is: (doNotWishToContributeBank: boolean) => !doNotWishToContributeBank,
            then: yup
              .string()
              .nullable()
              .required('IFSC Code is required')
              .matches(ifscRegex, 'Invalid IFSC Code'),
          }),
        bankAccountNumber: yup
          .string()
          .nullable()
          .when('doNotWishToContributeBank', {
            is: (doNotWishToContributeBank: boolean) => !doNotWishToContributeBank,
            then: yup
              .string()
              .nullable()
              .required('Bank Account Number is required')
              .matches(accountNumberRegex, 'Please enter valid Account Number'),
            // .test('bankAccountNumber', 'Account number already used', (value, context) => {
            //   const {
            //     options: { context: optionsContext },
            //   } = context;
            //   const { banks } = optionsContext || {};
            //   return (
            //     banks
            //       .filter((_bank: updatedBank) => !_bank.doNotWishToContributeBank)
            //       .filter((bank: Bank) => bank.bankAccountNumber === value).length === 1
            //   );
            // }),
          }),
        bankAccountType: yup
          .string()
          .nullable()
          .when('doNotWishToContributeBank', {
            is: (doNotWishToContributeBank: boolean) => !doNotWishToContributeBank,
            then: yup
              .string()
              .nullable()
              .test('bankAccountType', 'Bank Account Type is required', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;
                const fieldValue = value === null ? '' : value;
                const { applicationType, status, banks } = optionsContext || {};
                if (
                  applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
                  !['huf'].includes(applicants ? applicants[0]?.investorType || '' : '') &&
                  !fieldValue
                ) {
                  return true;
                }
                if (
                  applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
                  fieldValue &&
                  ![BankAccountTypeMaster.Current, BankAccountTypeMaster.Savings].includes(
                    fieldValue
                  )
                ) {
                  return false;
                } else if (
                  fieldValue &&
                  applicantStatusMasters[parent.applicantStatus] !== applicantStatusMasters.NRI &&
                  !Object.values(BankAccountTypeMaster).includes(fieldValue)
                ) {
                  return false;
                }
                if (
                  fieldValue &&
                  applicantStatusMasters[parent.applicantStatus] === applicantStatusMasters.NRI &&
                  !Object.values(NRIBankAccountTypeMaster).includes(fieldValue)
                ) {
                  return false;
                }
                // if (
                //   fieldValue &&
                //   applicantStatusMasters[parent.applicantStatus] === applicantStatusMasters.NRI &&
                //   banks.filter(
                //     (bank: Bank) =>
                //       Object.values(NRIBankAccountTypeMaster).includes(bank.bankAccountType) &&
                //       Object.values(NRIBankAccountTypeMaster).includes(fieldValue) &&
                //       bank.bankAccountType != fieldValue
                //   ).length === 1
                // ) {
                //   return createError({
                //     message: 'Both Bank Account Type should be same',
                //     path,
                //   });
                // }

                return !!fieldValue;
              }),
          }),

        bankName: yup
          .string()
          .nullable()
          .when('doNotWishToContributeBank', {
            is: (doNotWishToContributeBank: boolean) => !doNotWishToContributeBank,
            then: yup.string().nullable().required('Bank Name is required'),
          }),
        bankBranch: yup
          .string()
          .nullable()
          .when('doNotWishToContributeBank', {
            is: (doNotWishToContributeBank: boolean) => !doNotWishToContributeBank,
            then: yup.string().nullable().required('Bank Branch is required'),
          }),
        defaultBankAccount: yup.bool().when('doNotWishToContributeBank', {
          is: (doNotWishToContributeBank: boolean) => !doNotWishToContributeBank,
          then: yup
            .bool()
            .test(
              'defaultBankAccount',
              'Please check the default bank account',
              (value, context) => {
                const {
                  options: { context: optionsContext },
                } = context;
                const { banks } = optionsContext || {};
                const isDefaultBankSelected = banks.some((bank: Bank) => bank.defaultBankAccount);
                return isDefaultBankSelected;
              }
            ),
        }),
      })
    ),
  });

/**
 * investmentPaymentDetailsSchema
 * This schema is used to validate investment payment details form values.
 * It validates the following fields:
 * - schemeId
 * - planId
 * - commitmentAmountPrimaryApplicant
 * - setupFeePercentage
 * - contributionChequeAmount
 * - commitmentAmountJointHolder1
 * - commitmentAmountJointHolder2
 * It also checks for the following conditions:
 * - Commitment Amount should be greater than or equal to minimum commitment amount.
 * - Commitment Amount should be less than or equal to maximum commitment amount.
 * - Contribution Amount should be greater than or equal to minimum contribution percentage of commitment amount.
 * - Contribution Amount should not be greater than commitment amount.
 * - Setup Fee Percentage should be less than 100.
 * - TDS should be greater than or equal to 0.
 * - Total Commitment Amount should be greater than or equal to minimum commitment amount.
 * - Total Commitment Amount should not exceed maximum commitment amount.
 * @param {number} minCommitmentAmount - Minimum commitment amount.
 * @param {number} maxCommitmentAmount - Maximum commitment amount.
 * @param {number} minContributionPercentage - Minimum contribution percentage.
 * @param {number} primaryApplicantMinCommitmentAmount - Minimum commitment amount for primary applicant.
 * @param {number} Holder1MinCommitmentAmount - Minimum commitment amount for holder 1.
 * @param {number} Holder2MinCommitmentAmount - Minimum commitment amount for holder 2.
 * @param {number} applicantsCount - Number of applicants.
 * @returns {Object} - A Yup schema object.
 */
export const investmentPaymentDetailsSchema = (
  minCommitmentAmount: number,
  maxCommitmentAmount: number,
  minContributionPercentage: number,
  primaryApplicantMinCommitmentAmount: number,
  Holder1MinCommitmentAmount: number,
  Holder2MinCommitmentAmount: number,
  applicantsCount: any
) =>
  yup.object().shape({
    schemeId: yup.string().required('Scheme Name is required'),
    planId: yup.string().required('Class/Subclass is required'),
    commitmentAmountPrimaryApplicant: yup
      .number()
      .nullable()
      // .min(
      //   minCommitmentAmount,
      //   `Commitment Amount should be greater than or equal to ${currencyConversion(
      //     minCommitmentAmount
      //   )}`
      // )
      // .max(
      //   maxCommitmentAmount,
      //   `Commitment Amount should be between ${
      //     currencyConversion(minCommitmentAmount) || '1 Cr'
      //   } and ${currencyConversion(maxCommitmentAmount) || '2 Cr'}`
      // )
      .test('commitmentAmountPrimaryApplicant', (value, context) => {
        const { parent, createError, path } = context;
        const { commitmentAmount, planId } = parent;

        if (value !== 0 && !value) {
          return createError({
            message: `Commitment Amount is required`,
            path,
          });
        }
        if (Number(value) < Number(primaryApplicantMinCommitmentAmount)) {
          return createError({
            message: !['10', '9'].includes(planId || '')
              ? `Commitment Amount should be between ${
                  currencyConversion(primaryApplicantMinCommitmentAmount) || '1 Cr'
                } and ${currencyConversion(maxCommitmentAmount) || '2 Cr'}`
              : `Commitment Amount should greater than or equal to ${
                  currencyConversion(primaryApplicantMinCommitmentAmount) || '1 Cr'
                }`,
            path,
          });
        }
        if (Number(commitmentAmount) < Number(minCommitmentAmount)) {
          return createError({
            message: `${
              applicantsCount === 1 ? '' : 'Total '
            }Commitment Amount should be greater than or equal to ${currencyConversion(
              minCommitmentAmount
            )}`,
            path,
          });
        }
        if (Number(commitmentAmount) > Number(maxCommitmentAmount)) {
          return createError({
            message: `${
              applicantsCount === 1 ? '' : 'Total '
            }commitment amount exceeded maximum commitment amount (${
              currencyConversion(maxCommitmentAmount) || '2 Cr'
            })`,
            path,
          });
        }
        return true;
      }),
    setupFeePercentage: yup
      .number()
      .nullable()
      .required('Setup Fee Percentage is required')
      .lessThan(101, 'Setup Fee Percentage should be less then 100%'),
    contributionChequeAmount: yup
      .number()
      .nullable()
      .required('Contribution Cheque Amount is required')
      // .max(
      //   yup.ref('commitmentAmount'),
      //   'Contribution Amount should not be greater than commitment amount'
      // )
      .test(
        'contributionChequeAmount',
        `${
          Number(minContributionPercentage) === 100
            ? `Contribution Amount should be equal to ${
                applicantsCount === 1 ? '' : 'total '
              }commitment amount`
            : `Contribution Amount should be greater than or equal to ${minContributionPercentage}% of ${
                applicantsCount === 1 ? '' : 'total '
              }commitment amount`
        }`,
        (value, context) => {
          const { parent, createError, path } = context;
          const { commitmentAmount } = parent;
          if (Number(value) > Number(commitmentAmount)) {
            return createError({
              message: `Contribution Amount should not be greater than ${
                applicantsCount === 1 ? '' : 'total '
              }commitment amount`,
              path,
            });
          }
          return (
            (100 * Number(value)) / Number(commitmentAmount) >= Number(minContributionPercentage)
          );
        }
      ),
    setupFeeTDS: yup.number().nullable().min(0, `TDS should be greater than 0`),
    // .max(yup.ref('totalSetupFee'), 'TDS should not be greater than Total Setup Fee'),
    commitmentAmountJointHolder1: yup
      .number()
      .nullable()
      .test('commitmentAmountJointHolder1', (value, context) => {
        const { parent, createError, path } = context;
        const { commitmentAmount } = parent;

        if (Number(Holder1MinCommitmentAmount) && value !== 0 && !value) {
          return createError({
            message: `Commitment Amount is required`,
            path,
          });
        }
        if (Number(value) < Number(Holder1MinCommitmentAmount)) {
          return createError({
            message: `Commitment Amount should be between ${
              currencyConversion(Holder1MinCommitmentAmount) || '1 Cr'
            } and ${currencyConversion(maxCommitmentAmount) || '2 Cr'}`,
            path,
          });
        }
        if (Number(value) && Number(commitmentAmount) < Number(minCommitmentAmount)) {
          return createError({
            message: `Total Commitment Amount should be greater than or equal to ${currencyConversion(
              minCommitmentAmount
            )}`,
            path,
          });
        }
        if (Number(value) && Number(commitmentAmount) > Number(maxCommitmentAmount)) {
          return createError({
            message: `Total commitment amount exceeded maximum commitment amount (${
              currencyConversion(maxCommitmentAmount) || '2 Cr'
            })`,
            path,
          });
        }
        return true;
      }),
    commitmentAmountJointHolder2: yup
      .number()
      .nullable()
      .test('commitmentAmountJointHolder2', (value, context) => {
        const { parent, createError, path } = context;
        const { commitmentAmount } = parent;

        if (Number(Holder2MinCommitmentAmount) && value !== 0 && !value) {
          return createError({
            message: `Commitment Amount is required`,
            path,
          });
        }
        if (Number(value) < Number(Holder2MinCommitmentAmount)) {
          return createError({
            message: `Commitment Amount should be between ${
              currencyConversion(Holder2MinCommitmentAmount) || '1 Cr'
            } and ${currencyConversion(maxCommitmentAmount) || '2 Cr'}`,
            path,
          });
        }
        if (Number(value) && Number(commitmentAmount) < Number(minCommitmentAmount)) {
          return createError({
            message: `Total Commitment Amount should be greater than or equal to ${currencyConversion(
              minCommitmentAmount
            )}`,
            path,
          });
        }
        if (Number(value) && Number(commitmentAmount) > Number(maxCommitmentAmount)) {
          return createError({
            message: `Total commitment amount exceeded maximum commitment amount (${
              currencyConversion(maxCommitmentAmount) || '2 Cr'
            })`,
            path,
          });
        }
        return true;
      }),
  });

export const topUpInvestmentPaymentDetailsSchema = (
  minCommitmentAmount: number,
  maxCommitmentAmount: number,
  minContributionPercentage: number
) =>
  yup.object().shape({
    schemeId: yup.string().required('Scheme Name is required'),
    planId: yup.string().required('Class/Subclass is required'),
    topupContributionAmount: yup
      .number()
      .nullable()
      .required('Top Up Contribution Amount is required')
      .test(
        'topupContributionAmount',
        `${
          Number(minContributionPercentage) === 100
            ? `Top Up Contribution Amount should be equal to Top Up Commitment amount`
            : `Top Up Contribution Amount should be greater than or equal to ${minContributionPercentage}% of Top Up Commitment amount`
        }`,
        (value, context) => {
          const { parent, createError, path } = context;
          const { topupAmount } = parent;
          if (Number(value) > Number(topupAmount)) {
            return createError({
              message: `Top Up Contribution Amount should not be greater than Top Up Commitment amount`,
              path,
            });
          }
          return 100 * Number(value) >= Number(minContributionPercentage) * Number(topupAmount);
        }
      ),
    topupAmount: yup
      .number()
      .nullable()
      .required('Top Up Commitment Amount is required')
      .min(
        minCommitmentAmount,
        `Top Up Commitment Amount should be greater than or equal to ${
          currencyConversion(minCommitmentAmount) || 'zero'
        }`
      )
      .max(
        maxCommitmentAmount,
        `Top Up Commitment Amount should be less than or equal to ${
          currencyConversion(maxCommitmentAmount) || 'zero'
        }`
      ),
  });

export const documentDetailsSchema = yup.object().shape({
  applicants: yup.array().when('isTopup', {
    is: (value: boolean) => value === false,
    then: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentType,
                        options: optionsDropdown,
                        applicantId,
                      } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (
                          documentType === 'bankAccountProof' &&
                          !fieldValue &&
                          banks &&
                          !getApplicantMappedBank(banks, applicantId as string)?.pennydropVerified
                        ) {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                        if (documentType === 'bankAccountProof' && Number(indexes[0]) !== 0) {
                          return true;
                        }
                        if (
                          !fieldValue &&
                          required === 'true' &&
                          documentType !== 'bankAccountProof'
                        ) {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType, applicantId } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                        if (
                          !value &&
                          banks &&
                          !getApplicantMappedBank(banks, applicantId as string)
                            ?.pennydropVerified &&
                          documentType === 'bankAccountProof'
                        ) {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                        if (!value && required === 'true' && documentType !== 'bankAccountProof') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
  topUpApplicants: yup.array().when('isTopup', {
    is: (value: boolean) => value === true,
    then: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { topUpApplicants = [], banks } =
                        optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentType,
                        options: optionsDropdown,
                      } = topUpApplicants[indexes[0]].documents[indexes[1]].documentsList[
                        indexes[2]
                      ];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { topUpApplicants = [], banks } =
                        optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType } =
                        topUpApplicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                        if (!value && required === 'true') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
  nominees: yup.array().when('isTopup', {
    is: (value: boolean) => value === false,
    then: yup.array().of(
      yup.object().shape({
        nomineedocuments: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { nominees = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentName,
                        options: optionsDropdown,
                      } = nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[
                        indexes[2]
                      ];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { nominees = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName } =
                        nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (
                        !value &&
                        required === 'true' &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
  // applicants: yup.array().of(
  //   yup.object().shape({
  //     documents: yup.object().shape({
  //       // identityProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select identity proof'),
  //       //   documentId: yup.string().required('Please upload Identity Proof'),
  //       // }),
  //       // addressProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select Address Proof'),
  //       //   documentId: yup.string().required('Please upload Address Proof'),
  //       // }),
  //       bankAccountProof: yup.object().shape({
  //         documentName: yup
  //           .string()
  //           .test('documentName', 'Please select Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),

  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),
  //       }),
  //       poaNotarized: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload POA Notarized Document', (value, context) =>
  //             POADocumentValidation(value, context, 'poaNotarized')
  //           ),
  //       }),
  //       investorsConsent: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', "Please upload Investor's Consent Document", (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //       fatca: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload FATCA Document', (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //     }),
  //   })
  // ),
});

export const distributorsValidationSchema = (
  selectedDistributor: SubDistributor[],
  distributors: Distributor[]
) =>
  yup.object().shape({
    //role: useSelector((store: RootStateType) => store.auth),
    distributorId: yup
      .string()
      .nullable()
      .when('loginType', {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup.string().nullable().required('Please select a distributor code'),
      }),
    subdistributorId: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: () => 'distributor' && !!selectedDistributor.length,
        then: yup.string().nullable().required("Please select a distributor's rm name - code"),
      }),
    rmDetails: yup
      .array()
      .nullable()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('name', (value, context) => {
              const { path, createError } = context;
              // eslint-disable-next-line
              const [parent1, parent2, ...rest] = (context as any).from;
              if (
                !value &&
                checkValidationBasedOnDate(
                  parent2?.value?.createdAt,
                  AdditionalRmDetailsValidationDate
                )
              ) {
                return createError({
                  message: 'Name is required',
                  path,
                });
              }
              return true;
            }),
          email: yup
            .string()
            .nullable()
            .matches(emailRegex, 'Invalid email id')
            .test('email', (value, context) => {
              const { path, createError } = context;

              // eslint-disable-next-line
              const [parent1, parent2, ...rest] = (context as any).from;
              if (
                !value &&
                checkValidationBasedOnDate(
                  parent2?.value?.createdAt,
                  AdditionalRmDetailsValidationDate
                )
              ) {
                return createError({
                  message: 'Email is required',
                  path,
                });
              }
              return true;
            }),
        })
      ),
    //selectedDistributor.length
  });

export const userManagementRmSchema = yup.object().shape({
  name: yup.string().nullable().required('RM Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number')
    .required('Mobile Number is required'),

  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
});

export const DistributorSchema = yup.object().shape({
  name: yup.string().nullable().required('Distributor Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().required('Email is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('Mobile Number is required'),
    }),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value && parent.type=== 'individual') {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value&&parent.type=== 'individual') {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  arnCode: yup
    .string()
    .nullable()
    //.matches(arnCodeRegex, 'Invalid ARN Code Ex:- ARN-12345')
    .required('ARN Code is required'),
  rmId: yup
    .string()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('RM Name is required'),
    }),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const TrusteeAndFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  dateOfBirth: yup
    .string()
    .nullable()
    .test(
      'dateOfBirth',
      'Age should be greater than 18',
      (dateOfBirth) => !isMinor(dateOfBirth || '')
    ),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // .required('Date of Birth is required'),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const aifApproverSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});
export const amcApproverSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
});

export const addFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid mobile number')
    .max(16, 'Invalid mobile number')
    .matches(phoneRegExp, 'Invalid mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const AuthorisedSignatoryAndPOASchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number')
    .required('Mobile Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // distributorId: yup.string().required('Please Enter Distridutor Id'),
  // amcId: yup.string().required('Please Enter AmcId'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const DistributorRmSchema = yup.object().shape({
  name: yup.string().nullable().required("Distributor's RM name is required"),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number')
    .required('Mobile Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  //distributors_RM_Code: yup.string().required('Please Enter Distributor RM code'),
  rmId: yup.string().nullable().required("AMC's RM name is required"),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

// name,
// email,
// countryCode,
// phone,
// panNumber,
// distributors_RM_Code,
// rmId,
// distributorId,
// pincode,
// buildingNo,
// streetName,
// city,
// state,
// country,

// pan: yup
// .string()
// .nullable()
// .uppercase()
// .length(10, 'PAN must be exactly 10 characters')
// .matches(individualPanRegex, 'Only individual PANs are allowed')
// .test('pan', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesPanNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'PAN is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
//     return createError({
//       message:
//         'There is already same pan number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),

// mobile: yup
// .string()
// .nullable()
// .min(8, 'Invalid Mobile Number')
// .max(16, 'Invalid Mobile Number')
// .matches(phoneRegExp, 'Invalid Mobile Number')
// .test('mobile', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesMobileNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Mobile Number is required',
//       path,
//     });
//   }
//   if (
//     authorisedSignatoriesMobileNumbers &&
//     authorisedSignatoriesMobileNumbers.includes(value)
//   ) {
//     return createError({
//       message:
//         'There is already same mobile number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
// email: yup
// .string()
// .nullable()
// .matches(emailRegex, 'Invalid Email ID')
// .test('email', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesEmailID = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Email ID is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
//     return createError({
//       message:
//         'There is already same Email ID for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
export const amcAdminSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
});

export const NonIndividualContributorValidationSchema = (brokers: Broker[], createdAt: string) =>
  yup.object().shape({
    // folioType: yup.string().nullable().required('Folio Type is required'),
    applicants: yup.array().of(
      yup.object().shape({
        name: yup.string().nullable().required('Name Of Entity is required'),
        cityOfIncorporation: yup
          .string()
          .nullable()
          .required('Place of Registration/Incorporation is required'),
        leiExpiry: yup
          .string()
          .nullable()
          .test('leiExpiry', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError } = context;
            if (value && checkDateIsValid(fieldValue as string)) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            return true;
          }),
        dateOfBirth: yup
          .string()
          .nullable()
          .required('Date of Registration/Incorporation is required')
          .test('dateOfBirth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError } = context;
            if (value && checkDateIsValid(fieldValue as string)) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            if (futureAge(fieldValue || '')) {
              return createError({
                message: 'Future dates not allowed',
                path,
              });
            }
            return true;
          }),
        ckycNo: yup
          .string()
          .nullable()
          .test('ckycNo', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const {
              options: { context: optionsContext },
              createError,
              path,
            } = context;
            const { applicants } = optionsContext || {};
            if (!fieldValue) {
              return true;
            } else if (fieldValue.length !== 14) {
              return createError({
                message: 'Invalid CKYC No./CKYC Acknowledge No./KIN No',
                path,
              });
            } else if (!numberRegex.test(fieldValue)) {
              return createError({
                message: 'CKYC No./CKYC Acknowledge No./KIN No allows only digits',
                path,
              });
            } else if (
              applicants.filter((applicant: Applicant) => applicant.ckycNo === fieldValue).length >
              1
            ) {
              return createError({
                message:
                  'There is already same CKYC Number for an contributor associated with this application',
                path,
              });
            }
            return true;
          }),
        panNo: yup
          .string()
          .nullable()
          .uppercase()
          .length(10, 'PAN must be exactly 10 characters')
          .matches(nonIndividualPanRegex, 'Invalid PAN')
          .test('panNo', 'PAN is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
          }),
        investorType: yup
          .string()
          .nullable()
          .oneOf(Object.keys(investorTypeMasters), 'Invalid value for Investor Type')
          .test('investorType', 'Please Select Investor Type', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
          }),
        netWorth: yup
          .string()
          .nullable()
          .test('netWorth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, options } = context;
            if (!fieldValue && shouldValidateUponSaveLater(options?.context?.saveType)) {
              return createError({
                message: 'Net Worth is required',
                path,
              });
            }
            if (fieldValue && !amountRegex.test(fieldValue)) {
              return createError({
                message: 'Invalid amount',
                path,
              });
            }
            return true;
          }),
        netWorthDate: yup
          .string()
          .nullable()
          .required('Net worth as on is required')
          .test('dateOfBirth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError } = context;
            if (value && checkDateIsValid(fieldValue as string)) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            if (futureAge(fieldValue || '')) {
              return createError({
                message: 'Future dates not allowed',
                path,
              });
            }
            return true;
          }),
        grossAnnualIncome: yup
          .string()
          .nullable()
          .oneOf(grossAnnualMasters, 'Invalid value for Gross Annual Income')
          .required('Gross Annual Income is required'),
        dlclId: yup
          .string()
          .nullable()
          .test('dlclId', (value, context) => {
            const { path, createError, parent } = context;
            if (!value && checkValidationBasedOnDate(createdAt, dateForDematDropdown)) {
              return createError({
                message: 'Demat Account Details (for allotment of units) is required',
                path,
              });
            }
            if (value && !getDematDropdown(DLCLMasters, createdAt).includes(value)) {
              return createError({
                message: 'Invalid value for Demat Account Details (for allotment of units)',
                path,
              });
            }
            return true;
          }),
        dpId: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getdpIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('dpId', (value, context) => {
                const { path, createError, parent } = context;
                if (!value) {
                  return createError({
                    message: `${isCDSL(parent.dlclId) ? 'DPCL ID' : 'DP ID'} is required`,
                    path,
                  });
                }
                if (checkValidationBasedOnDate(createdAt, dematFieldValidationDate)) {
                  if (value && !isCDSL(parent.dlclId) && !dpIdRegex.test(value)) {
                    return createError({
                      message: `DP ID should consist of 8 digits and must start with 'IN'`,
                      path,
                    });
                  }

                  if (value && isCDSL(parent.dlclId)) {
                    if (
                      !dpIdNumberRegex.test(value) &&
                      checkValidationBasedOnDate(createdAt, dpIdFieldValidationDate)
                    ) {
                      return createError({
                        message: 'DPCL ID should consist of 16 numeric digits',
                        path,
                      });
                    }
                    if (
                      !checkValidationBasedOnDate(createdAt, dpIdFieldValidationDate) &&
                      !(cdslRegex.test(value) || dpIdNumberRegex.test(value))
                    ) {
                      return createError({
                        message: 'DPCL ID should consist of 8 or 16 numeric digits',
                        path,
                      });
                    }
                    return true;
                  }
                  return true;
                }
                return true;
              }),
          }),
        clId: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getclIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('clId', (value, context) => {
                const { path, createError, options } = context;
                if (!value) {
                  return createError({
                    message: 'CL ID is required',
                    path,
                  });
                }
                if (checkValidationBasedOnDate(createdAt, dematFieldValidationDate)) {
                  if (value && !cdslRegex.test(value)) {
                    return createError({
                      message: 'CL ID should consist of 8 numeric digits',
                      path,
                    });
                  }
                }
                return true;
              }),
          }),
        nameOfBroker: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getdpIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('nameOfBroker', (value, context) => {
                const { path, createError } = context;
                const brokerList = brokers.map((broker) => broker.key);
                if (!value) {
                  return true;
                }
                if (value && !brokerList.includes(value)) {
                  return createError({
                    message: 'Invalid value for Name of Broker/Depository Participant (DP)',
                    path,
                  });
                }
                return true;
              }),
          }),
        otherNameOfBroker: yup
          .string()
          .nullable()
          .when(['dlclId', 'nameOfBroker'], {
            is: (dlclId: string, nameOfBroker: string) =>
              getdpIdField(dlclId || '') && otherNameOfBroker(nameOfBroker),
            then: yup.string().nullable().required('Other Name of Broker is required'),
          }),
        physicalCommunication: yup
          .string()
          .nullable()
          .required('Physical Communication is required')
          .oneOf(Object.keys(yesAndNoMasters1), 'Invalid value for Physical Communication'),
      })
    ),
  });

const validateNonIndividualPincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext } = options as InternalOptions<NonIndividualContactValues> & {
    index: number;
  };
  if (
    !fieldValue &&
    (addressType === 'permanent' || addressType === 'correspondence') &&
    shouldValidateUponSaveLater(optionsContext?.saveType || '')
  ) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode code should be 6 digit code',
      path,
    });
  }
  if (
    fieldValue &&
    parent.country?.toLowerCase() !== 'india' &&
    !pinCodeRegexOtherThanIndia.test(fieldValue)
  ) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }
  return true;
};

export const nonIndividualContactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      contactperson: yup.object().shape({
        address1: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
          ),
        // .required('Address line 1 is required'),
        address2: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
          ),
        // .required('Address line 2 is required'),

        landLineNumber: yup
          .string()
          .nullable()
          .test('landLineNumber', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent, options } = context;
            if (
              !fieldValue &&
              parent.stdCode &&
              shouldValidateUponSaveLater(options.context?.saveType)
            ) {
              return createError({
                message: 'Please enter Landline number',
                path,
              });
            } else if (parent.landLineNumber && !numberRegex.test(parent.landLineNumber)) {
              return createError({
                message: 'Please enter valid Landline Number',
                path,
              });
            }
            return true;
          }),
        stdCode: yup
          .string()
          .nullable()
          .test('stdCode', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent, options } = context;
            if (
              !fieldValue &&
              parent.landLineNumber &&
              shouldValidateUponSaveLater(options.context?.saveType)
            ) {
              return createError({
                message: 'Please enter STD Code',
                path,
              });
            } else if (parent.stdCode && !numberRegex.test(parent.stdCode)) {
              return createError({
                message: 'Please enter valid STD Code',
                path,
              });
            }
            return true;
          }),

        city: yup.string().nullable(),
        // .required('City is required'),
        state: yup.string().nullable(),
        // .required('State is required'),
        country: yup
          .string()
          .nullable()
          .test('country', (value, context) => {
            const { options, createError, path } = context;
            const { context: optionsContext } =
              options as InternalOptions<NonIndividualContactValues>;
            const { countryDropdown = [] } = optionsContext || {};
            // if (!value) {
            //   return createError({
            //     message: 'Country is required',
            //     path,
            //   });
            // }
            if (value && !countryDropdown.includes(value)) {
              return createError({
                message: 'Invalid value for Country',
                path,
              });
            }
            return true;
          }),
        pincode: yup
          .string()
          .nullable()
          .test('pincode', (value, context) =>
            validateNonIndividualPincodeField(value, context, 'contactPerson')
          ),
        name: yup
          .string()
          .nullable()
          .test('name', 'Name is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
          }),
        email: yup
          .string()
          .nullable()
          .matches(emailRegex, 'Invalid Email ID of the Entity')
          .test('email', 'Email ID of the Entity is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
          }),
        mobile: yup
          .string()
          .nullable()
          .test('mobile', (value, context) => {
            const { createError, path, parent, options } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === parent.countryNameAndCode) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
              return createError({
                message: 'Mobile is required',
                path,
              });
            }
            if (value && parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }
            if (value && !isValidPhoneNumber(value, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
        countryNameAndCode: yup
          .string()
          .nullable()
          .test('countryNameAndCode', (value, context) => {
            const { createError, path, parent, options } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === value) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
              return createError({
                message: 'Country Code is required',
                path,
              });
            }
            if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
      }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              if (
                !fetchedFromKRA &&
                !value &&
                shouldValidateUponSaveLater(options.context?.saveType)
              ) {
                return false;
              }
              return true;
            }),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validateNonIndividualPincodeField(value, context, 'correspondence')
            ),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
            }),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
            }),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext } =
                options as InternalOptions<NonIndividualContactValues>;
              const { countryDropdown = [] } = optionsContext || {};
              if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (value && !countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType)
                ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
                : true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA && shouldValidateUponSaveLater(options.context?.saveType)) {
                return validatePermanentAddressFields(
                  value,
                  context,
                  APPLICATION_TYPE.NON_INDIVIDUAL
                );
              }
              return true;
            }),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                return validateNonIndividualPincodeField(value, context, 'permanent');
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType)
                ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
                : true;
            }),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType)
                ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
                : true;
            }),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } =
                options as InternalOptions<NonIndividualContactValues> & {
                  index: number;
                };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                if (value && !countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // .test('country', 'Country is required', (value, context) =>
          //   validatePermanentAddressFields(value, context)
          // ),
        }),
      }),
    })
  ),
});

export const nonIndividualDocumentDetailsSchema = yup.object().shape({
  applicants: yup.array().when('isTopup', {
    is: (value: boolean) => value === false,
    then: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { applicants = [], banks } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentType,
                        options: optionsDropdown,
                      } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (
                          documentType === 'bankAccountProof' &&
                          !fieldValue &&
                          banks &&
                          banks.filter(
                            (bank) =>
                              !bank.pennydropVerified && bank.defaultBankAccount && !bank?.isTopup
                          ).length !== 0
                        ) {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                        if (
                          !fieldValue &&
                          required === 'true' &&
                          documentType !== 'bankAccountProof'
                        ) {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { applicants = [], banks } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                        if (
                          !value &&
                          documentType === 'bankAccountProof' &&
                          banks &&
                          banks.filter(
                            (bank) =>
                              !bank.pennydropVerified && bank.defaultBankAccount && !bank?.isTopup
                          ).length !== 0
                        ) {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                        if (required === 'true' && documentType !== 'bankAccountProof') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
  topUpApplicants: yup.array().when('isTopup', {
    is: (value: boolean) => value === true,
    then: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { topUpApplicants = [], banks } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentType,
                        options: optionsDropdown,
                      } = topUpApplicants[indexes[0]].documents[indexes[1]].documentsList[
                        indexes[2]
                      ];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { topUpApplicants = [], banks } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType } =
                        topUpApplicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                        if (required === 'true') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
});

export const nonIndividualAuthorisedSignatories = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  pan: yup
    .string()
    .nullable()
    .uppercase()
    .length(10, 'PAN must be exactly 10 characters')
    .matches(individualPanRegex, 'Only individual PANs are allowed')
    .test('pan', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesPanNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
        .flat();
      if (!value) {
        return createError({
          message: 'PAN is required',
          path,
        });
      }
      if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same pan for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  mobile: yup
    .string()
    .nullable()
    .test('mobile', (value, context) => {
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesMobileNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
        .flat();
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (
        authorisedSignatoriesMobileNumbers &&
        authorisedSignatoriesMobileNumbers.includes(value)
      ) {
        return createError({
          message:
            'There is already same mobile number for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),

  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid Email ID')
    .test('email', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesEmailID = optionsContext
        ?.map((groups) =>
          groups.groupsignatories.map((signatory) => signatory.email?.toLowerCase())
        )
        .flat();
      if (!value) {
        return createError({
          message: 'Email ID is required',
          path,
        });
      }
      if (
        authorisedSignatoriesEmailID &&
        authorisedSignatoriesEmailID.includes(value?.toLowerCase())
      ) {
        return createError({
          message:
            'There is already same Email ID for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  designation: yup.string().nullable().required('Designation is required'),
});

const fieldValidation = (
  value: string | undefined | null | number,
  context: yup.TestContext<Record<string, ubo>>,
  mes: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { path, createError, parent } = context;
  if (!fieldValue && !parent.isFetchData) {
    return createError({
      message: `${mes} is required`,
      path,
    });
  }
  return true;
};

export const EntitySchemaForUBO = (createdAt: string) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        entityType: yup
          .string()
          .nullable()
          .test('entityType', (value, context) => {
            const { path, createError } = context;
            if (checkValidationBasedOnDate(createdAt, entityTypeValidationDate)) {
              if (!value) {
                return createError({
                  message: 'Entity type is required',
                  path,
                });
              }
              if (value && !Object.keys(ENTITY_TYPE_MASTER).includes(value)) {
                return createError({
                  message: 'Invalid value for entity type',
                  path,
                });
              }
            }
            return true;
          }),
      })
    ),
  });

export const nonIndividualUbo = (investorType: string | undefined | null) =>
  yup.object().shape({
    panNumber: yup
      .string()
      .nullable()
      .uppercase()
      .test('panNumber', (value, context) => {
        const { path, createError, options } = context;
        const { context: optionsContext } = options as InternalOptions<ubo[]>;

        const uboPanNumbers = optionsContext?.map((ubo) => ubo.panNumber);
        if (!value) {
          return createError({
            message: 'Taxpayer ID Number/PAN/Equivalent ID Number is required',
            path,
          });
        }
        if (uboPanNumbers && uboPanNumbers.includes(value)) {
          return createError({
            message:
              'There is already same Taxpayer ID Number/PAN/Equivalent ID Number for an Ultimate Beneficiary Ownership (UBO) associated with this application',
            path,
          });
        }

        return true;
      }),

    dob: yup
      .string()
      .nullable()
      .test('dob', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, parent } = context;
        if (!fieldValue) {
          return createError({
            message: 'Date of birth is required',
            path,
          });
        }
        if (
          parent.panNumber &&
          individualPanRegex.test(parent.panNumber) &&
          isMinor(fieldValue || '')
        ) {
          return createError({
            message: 'Age should be greater than 18',
            path,
          });
        }
        if (
          parent.panNumber &&
          individualPanRegex.test(parent.panNumber) &&
          maxAge(fieldValue || '')
        ) {
          return createError({
            message: 'Age should be less than 125',
            path,
          });
        }
        if (
          parent.panNumber &&
          individualPanRegex.test(parent.panNumber) &&
          futureAge(fieldValue || '')
        ) {
          return createError({
            message: 'Future age not allowed',
            path,
          });
        }
        if (value && checkDateIsValid(fieldValue as string)) {
          return createError({
            message: 'Invalid Date',
            path,
          });
        }
        return true;
      }),
    name: yup
      .string()
      .nullable()
      .test('name', (value, context) => {
        return fieldValidation(value, context, 'Name');
      }),
    identificationType: yup
      .string()
      .nullable()
      .test('identificationType', (value, context) => {
        return fieldValidation(value, context, 'Identification Type');
      }),
    typeOfUBO: yup
      .string()
      .nullable()
      .oneOf(Object.keys(UboTypeMaster), 'Invalid value for UBO Type'),
    percentageOfBeneficialInterest: yup
      .string()
      .nullable()
      .test('percentageOfBeneficialInterest', (value, context) => {
        const fieldValue = value;
        const { path, createError, parent } = context;
        if (
          (fieldValue === null || fieldValue === '' || fieldValue === undefined) &&
          !parent.isFetchData
        ) {
          return createError({
            message: `Percentage Of Beneficial Interest is required`,
            path,
          });
        }
        // if (fieldValue === 0 && !parent.isFetchData && !checkUBOTypeIsTrust(parent.typeOfUBO)) {
        //   return createError({
        //     message: `Percentage Of Beneficial Interest should be greater 0`,
        //     path,
        //   });
        // }
        // if (fieldValue) {
        //   if (fieldValue < 0 && !parent.isFetchData) {
        //     return createError({
        //       message: `Percentage Of Beneficial Interest should be greater than${
        //         checkUBOTypeIsTrust(parent.typeOfUBO) ? ' or equal to' : ''
        //       } 0`,
        //       path,
        //     });
        //   }
        //   if (fieldValue > 100 && !parent.isFetchData) {
        //     return createError({
        //       message: `Percentage Of Beneficial Interest must not exceed 100%`,
        //       path,
        //     });
        //   }
        // }
        return true;
      }),
    countryOfTaxResidency: yup
      .string()
      .nullable()
      .test('countryOfTaxResidency', (value, context) => {
        return fieldValidation(value, context, 'Country Of Tax Residency');
      }),
    cpUboCode: yup
      .string()
      .nullable()
      .oneOf(Object.keys(CpUboCodesMaster), 'Invalid value for CP/UBO Code')
      .test('cpUboCode', (value, context) => {
        return fieldValidation(value, context, 'Cp/UboCode');
      }),
    placeAndCountryOfBirth: yup
      .string()
      .nullable()
      .test('placeAndCountryOfBirth', (value, context) => {
        return fieldValidation(value, context, 'Place And Country Of Birth');
      }),
    occupation: yup
      .string()
      .nullable()
      .oneOf(occupationDetailsMasters, 'Invalid value for Occupation')
      .test('occupation', (value, context) => {
        return fieldValidation(value, context, 'Occupation');
      }),
    gender: yup
      .string()
      .nullable()
      .test('gender', (value, context) => {
        return fieldValidation(value, context, 'Gender');
      }),
    nationality: yup
      .string()
      .nullable()
      // .oneOf(nationalitiesMasters, 'Invalid value for Nationality')
      .test('nationality', (value, context) => {
        return fieldValidation(value, context, 'Nationality');
      }),
    fatherName: yup
      .string()
      .nullable()
      .test('fatherName', (value, context) => {
        return fieldValidation(value, context, 'FatherName');
      }),
    ckycNumber: yup
      .string()
      .nullable()
      .length(14, 'Invalid CKYC Number')
      .test('ckycNumber', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, options, parent } = context;
        const { context: optionsContext } = options as InternalOptions<ubo[]>;
        if (!fieldValue && !parent.isFetchData) {
          return true;
        } else if (!numberRegex.test(fieldValue as string) && !parent.isFetchData) {
          return createError({
            message: 'CKYC Number allows only digits',
            path,
          });
        }
        const uboCkycNumbers = optionsContext?.map((ubo) => Number(ubo.ckycNumber).toString());
        if (
          uboCkycNumbers &&
          uboCkycNumbers.includes(fieldValue as string) &&
          !parent.isFetchData
        ) {
          return createError({
            message:
              'There is already same CKYC Number for an Ultimate Beneficiary Ownership (UBO) associated with this application',
            path,
          });
        }
        return true;
      }),
    address2: yup
      .string()
      .nullable()
      .matches(
        addressRegex,
        "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
      )
      .test('address2', (value, context) => {
        return fieldValidation(value, context, 'Address Line 1');
      }),
    address3: yup
      .string()
      .nullable()
      .matches(
        addressRegex,
        "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
      )
      .test('address3', (value, context) => {
        return fieldValidation(value, context, 'Address Line 2');
      }),
    city: yup
      .string()
      .nullable()
      .test('city', (value, context) => {
        return fieldValidation(value, context, 'City');
      }),
    state: yup
      .string()
      .nullable()
      .test('state', (value, context) => {
        return fieldValidation(value, context, 'State');
      }),
    country: yup
      .string()
      .nullable()
      .test('country', (value, context) => {
        return fieldValidation(value, context, 'Country');
      }),
    pincode: yup
      .string()
      .nullable()
      .test('pincode', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, parent } = context;
        if (!fieldValue && !parent.isFetchData) {
          return createError({
            message: 'Pincode is required',
            path,
          });
        } else if (
          parent.country?.toLowerCase() === 'india' &&
          !indianPin.test(fieldValue as string) &&
          !parent.isFetchData
        ) {
          return createError({
            message: 'Pincode length should be 6 digits',
            path,
          });
        } else if (
          parent.country?.toLowerCase() !== 'india' &&
          !alphaNumeric.test(fieldValue as string) &&
          !parent.isFetchData
        ) {
          return createError({
            message: 'Invalid Pincode',
            path,
          });
        }
        return true;
      }),
  });

export const nonIndividualControllingPersons = yup.object().shape({
  name: yup.string().nullable().required('Name of controlling person is required'),
  correspondenceAddress: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid address format [special characters are not allowed except -/',&()#:.]"
    )
    .required('Correspondence address is required'),
  countryOfResidense: yup
    .string()
    .nullable()
    .required('Country of residence for tax purposes is required'),
  issuingCountry: yup.string().nullable().required('Issuing country is required'),
  controllingPersonType: yup.string().nullable().required('Controlling Person Type is required'),
  indentificationType: yup.string().nullable().required('Indentification Type is required'),
  indentificationNumber: yup
    .string()
    .nullable()
    .test('indentificationNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const indentificationNumberArr = optionsContext?.map(
        (person) => person.indentificationNumber
      );
      if (!value) {
        return createError({
          message: 'Indentification Number is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (indentificationNumberArr && indentificationNumberArr.includes(value)) {
        return createError({
          message:
            'There is already same Indentification Number for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
  occupationType: yup.string().nullable().required('Occupation Type is required'),
  occupation: yup
    .string()
    .nullable()
    .oneOf(occupationDetailsMasters, 'Invalid value for Occupation')
    .required('Occupation is required'),
  dateOfBirth: yup
    .string()
    .nullable()
    .test('dateOfBirth', (value, context) => {
      const { path, createError } = context;
      if (!value) {
        return createError({
          message: 'Date of Birth is required',
          path,
        });
      }
      if (isMinor(value)) {
        return createError({
          message: 'Age should be greater than 18',
          path,
        });
      }
      if (maxAge(value)) {
        return createError({
          message: 'Age should be less than 125',
          path,
        });
      }
      if (value && checkDateIsValid(value as string)) {
        return createError({
          message: 'Invalid Date',
          path,
        });
      }
      if (futureAge(value || '')) {
        return createError({
          message: 'Future dates not allowed',
          path,
        });
      }
      return true;
    }),
  countryOfBirth: yup.string().nullable().required('Country Of Birth is required'),
  nationality: yup.string().nullable().required('Nationality is required'),
  tin: yup
    .string()
    .nullable()
    .test('tin', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const controllingPersons = optionsContext?.map((person) => person.tin);
      if (!value) {
        return createError({
          message: 'TIN is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (controllingPersons && controllingPersons.includes(value)) {
        return createError({
          message:
            'There is already same Tin for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
});

type errorObj = { fieldPath: string; fieldName: string };

const subQuestionsValidation = (
  fatca: nonIndividualQuestionsFatca,
  path: string
): errorObj | errorObj[] | undefined => {
  if (
    !fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.isMandatory === 'true' &&
    fatca.question_type !== 'Controlling_person_details'
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName:
        fatca.question_type === 'single_choice_radio'
          ? 'Field is required'
          : fatca.question_text
          ? fatca.question_text + ' is required'
          : (fatca.placeholder || 'Field') + ' is required',
    };
  }
  if (
    fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.validation === 'alphaNumeric' &&
    !alphaNumeric.test(fatca.answer)
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName: 'Special Characters are Not allowed',
    };
  }
  if (fatca.question_type === 'group' && fatca.isVisible && fatca.sub_questions?.length) {
    return fatca.sub_questions
      ?.map((qun) => subQuestionsValidation(qun, path) as errorObj)
      .filter((ele: errorObj) => ele)
      .flat();
  }
};

export const nonIndividualFatcaSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      fatcadetail: yup.array().of(
        yup.object().shape({
          answer: yup
            .string()
            .nullable()
            .test('answer', (value, context) => {
              const { path, createError, parent, options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                if (
                  !value &&
                  parent.isVisible &&
                  parent.isMandatory === 'true' &&
                  parent.backend_key &&
                  parent.question_type !== 'single_choice_radio'
                ) {
                  return createError({
                    message:
                      parent.question_type === 'open_text_field'
                        ? (parent.placeholder || 'Field') + ' is required'
                        : parent.question_text
                        ? parent.question_text + ' is required'
                        : (parent.placeholder || 'Field') + ' is required',
                    path,
                  });
                }
                if (
                  value &&
                  parent.isVisible &&
                  parent.backend_key &&
                  parent.validation === 'alphaNumeric' &&
                  !alphaNumeric.test(value)
                ) {
                  return createError({
                    message: 'Special Characters are Not allowed',
                    path,
                  });
                }
                if (parent.question_type === 'group' && parent.isVisible && parent.sub_questions) {
                  const val = parent.sub_questions
                    ?.map((qun: nonIndividualQuestionsFatca) => {
                      const r = subQuestionsValidation(qun, path?.split('.')[0]) as errorObj;
                      return r;
                    })
                    .filter((ele: errorObj) => ele)
                    .flat();
                  if (val.length) {
                    const error = val.map((ele: errorObj) => {
                      return new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`);
                    });
                    if (error.length) {
                      return new yup.ValidationError(error);
                    }
                  }
                  return true;
                }
              }
              return true;
            }),
        })
      ),
    })
  ),
});

const topUpPlanValidation = yup.object().shape({
  topupMinCommitmentAmount: yup
    .number()
    .nullable()
    .when('isTopupApplicable', {
      is: true,
      then: yup
        .number()
        .nullable()
        .moreThan(0, `Total Minimum Commitment Amount should be greater than 0`)
        .required('Total Minimum Commitment Amount is required'),
    }),
  topupMaxCommitmentAmount: yup
    .number()
    .nullable()
    .when('isTopupApplicable', {
      is: true,
      then: yup
        .number()
        .nullable()
        .min(
          yup.ref('topupMinCommitmentAmount'),
          'Maximum Top Up Commitment Amount should be greater than or equal to Minimum Top Up Commitment Amount'
        )
        .required('Maximum Top Up Commitment Amount is required'),
    }),
  // topupMinPrimaryCommitmentAmount: yup
  //   .number()
  //   .nullable()
  //   .when('isTopupApplicable', {
  //     is: true,
  //     then: yup
  //       .number()
  //       .nullable()
  //       .min(0, `Primary Applicant Minimum Commitment Amount should be greater than or equal to 0`)
  //       .required('Primary Applicant Minimum Commitment Amount is required'),
  //   }),
  // topupMinJointHolder1CommitmentAmount: yup
  //   .number()
  //   .nullable()
  //   .when('isTopupApplicable', {
  //     is: true,
  //     then: yup
  //       .number()
  //       .nullable()
  //       .min(0, `Joint Holder1 Minimum Commitment Amount should be greater than or equal to 0`)
  //       .required('Joint Holder1 Minimum Commitment Amount is required'),
  //   }),
  // topupMinJointHolder2CommitmentAmount: yup
  //   .number()
  //   .nullable()
  //   .when('isTopupApplicable', {
  //     is: true,
  //     then: yup
  //       .number()
  //       .nullable()
  //       .min(0, `Joint Holder2 Minimum Commitment Amount should be greater than or equal to 0`)
  //       .required('Joint Holder2 Minimum Commitment Amount is required'),
  //   }),
});

export const addPlanValidation = yup
  .object()
  .shape({
    planCode: yup.string().nullable().required('Plan Code is required'),
    planDescription: yup.string().nullable().required('Plan Description is required'),
    schemeNature: yup.string().nullable().required('Scheme Nature is required'),
    // registrationNumber: yup.string().nullable().required('Registration Number is required'),
    fundmanagerIds: yup
      .array()
      .nullable()
      .min(1, 'Investment Manager(s) required')
      .required('Investment Manager Name is required'),
    trusteeIds: yup
      .array()
      .nullable()
      .min(1, 'Trustee(s) required')
      .required('Trustee Name is required'),
    requiredFundManagers: yup
      .number()
      .nullable()
      .test('requiredFundManagers', (value, context) => {
        const { createError, path, parent } = context;
        if (!value) {
          return createError({
            message: 'No. of Investment Manager(s) to esign is required',
            path,
          });
        }
        if (value && parent?.fundmanagerIds?.length < value) {
          return createError({
            message: 'Invalid No. of Investment Manager(s) to esign',
            path,
          });
        }
        return true;
      }),
    requiredTrustees: yup
      .number()
      .nullable()
      .test('requiredTrustees', (value, context) => {
        const { createError, path, parent } = context;
        if (!value) {
          return createError({
            message: 'No. of Trustee(s) to esign is required',
            path,
          });
        }
        if (value && parent?.trusteeIds?.length < value) {
          return createError({
            message: 'Invalid No. of Trustee(s) to esign',
            path,
          });
        }
        return true;
      }),
    schemeCategory: yup.string().nullable().required('Scheme Category is required'),
    minCommitmentAmount: yup
      .number()
      .nullable()
      .moreThan(0, `Total Minimum Commitment Amount should be greater than 0`)
      .required('Total Minimum Commitment Amount is required'),
    maxCommitmentAmount: yup
      .number()
      .nullable()
      .min(
        yup.ref('minCommitmentAmount'),
        'Maximum Top Up Commitment Amount should be greater than or equal to Minimum Top Up Commitment Amount'
      )
      .required('Maximum Commitment Amount is required'),
    tcLink: yup
      .string()
      .nullable()
      .matches(linkRegex, 'Enter Valid PPM Link')
      .required('PPM Link is required'),
    schemeStartDate: yup
      .string()
      .nullable()
      .test('schemeStartDate', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError } = context;
        if (value && checkDateIsValid(fieldValue as string)) {
          return createError({
            message: 'Invalid Date',
            path,
          });
        }
        if (futureAge(fieldValue || '')) {
          return createError({
            message: 'Future dates not allowed',
            path,
          });
        }
        return true;
      }),
    minPrimaryCommitmentAmount: yup
      .number()
      .nullable()
      .min(0, `Primary Applicant Minimum Commitment Amount should be greater than or equal to 0`)
      .required('Primary Applicant Minimum Commitment Amount is required'),
    minJointHolder1CommitmentAmount: yup
      .number()
      .nullable()
      .min(0, `Joint Holder1 Minimum Commitment Amount should be greater than or equal to 0`)
      .required('Joint Holder1 Minimum Commitment Amount is required'),
    minJointHolder2CommitmentAmount: yup
      .number()
      .nullable()
      .min(0, `Joint Holder2 Minimum Commitment Amount should be greater than or equal to 0`)
      .required('Joint Holder2 Minimum Commitment Amount is required'),
  })
  .concat(topUpPlanValidation);

export const editFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
  schemeStartDate: yup
    .string()
    .nullable()
    .test('schemeStartDate', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError } = context;
      if (value && checkDateIsValid(fieldValue as string)) {
        return createError({
          message: 'Invalid Date',
          path,
        });
      }
      if (futureAge(fieldValue || '')) {
        return createError({
          message: 'Future dates not allowed',
          path,
        });
      }
      return true;
    }),
  LEEGALITY_STAMP_SERIES: yup
    .string()
    .nullable()
    .required('Stamp Paper Series is required')
    .matches(numberRegex, 'Invalid value for stamp paper series'),
  LEEGALITY_STAMP_SERIES_GROUP: yup
    .string()
    .nullable()
    .required('Stamp Paper Group is required')
    .matches(alphaNumeric, 'Special characters are not allowed'),
  LEEGALITY_STAMP_VALUE: yup.number().nullable().required('Stamp Paper Value is required'),
  // LEEGALITY_USE_STAMP_SERIES: yup.boolean().nullable().required('Field is required'),
});

export const addFundValidation = editFundValidation.concat(addPlanValidation);

export const updateImAndTrusteeFundValidation = (IMId?: number, idForTrustee?: number) =>
  yup.object().shape({
    fundmanagerIds: yup
      .array()
      .nullable()
      .test('fundmanagerIds', (value, context) => {
        const { createError, path } = context;
        if (IMId && value?.length === 0) {
          return createError({
            message: 'Investment Manager(s) required',
            path,
          });
        }
        return true;
      }),
    trusteeIds: yup
      .array()
      .nullable()
      .test('trusteeIds', (value, context) => {
        const { createError, path } = context;
        if (idForTrustee && value?.length === 0) {
          return createError({
            message: 'Trustee(s) required',
            path,
          });
        }
        return true;
      }),
    requiredTrustees: yup
      .number()
      .nullable()
      .test('requiredTrustees', (value, context) => {
        const { createError, path, parent } = context;
        if (idForTrustee && !value) {
          return createError({
            message: 'No. of Trustee(s) to esign is required',
            path,
          });
        }
        if (idForTrustee && value && parent?.trusteeIds?.length < value) {
          return createError({
            message: 'Invalid No. of Trustee(s) to esign',
            path,
          });
        }
        return true;
      }),
    requiredFundManagers: yup
      .number()
      .nullable()
      .test('requiredFundManagers', (value, context) => {
        const { createError, path, parent } = context;
        if (IMId && !value) {
          return createError({
            message: 'No. of Investment Manager(s) to esign is required',
            path,
          });
        }
        if (IMId && value && parent?.fundmanagerIds?.length < value) {
          return createError({
            message: 'Invalid No. of Investment Manager(s) to esign',
            path,
          });
        }
        return true;
      }),
  });
