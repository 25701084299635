//should inform Backend Team if any modifications done in this file

export const salutationsMasters: string[] = ['Mr.', 'Ms.', 'M/S.', 'Mrs.'];
export const applicantStatusMasters: { [key: string]: string } = {
  Individual: 'Individual (Resident)',
  NRI: 'Individual (NRI)',
};
export const nationalitiesMasters: string[] = ['Indian', 'Others'];
export const occupationDetailsMasters: string[] = [
  'PVT.SECTOR SERVICE',
  'PUBLIC SECTOR',
  'GOVT.SERVICE',
  'HOUSEWIFE',
  'DEFENCE',
  'PROFESSIONAL',
  'RETIRED',
  'BUSINESS',
  'AGRICULTURE',
  'STUDENT',
  'FOREX DEALER',
  'OTHERS',
];

export const mandatorytext = '*Fields are Mandatory';

export const grossAnnualMasters: string[] = [
  'BELOW 1 LAC',
  '1-5 LAC',
  '5-10 LACS',
  '10-25 LACS',
  '>25 LACS - 1 CRORE',
  '> 1 CRORE',
];

export const PEPsMasters: string[] = ['I am PEP', 'I am Related to PEP', 'Not Applicable'];

export const addressTypesMasters: string[] = [
  'Resident/Business',
  'Resident',
  'Business',
  'Registered Office',
  'Unspecified',
];

export const genderMasters: string[] = ['Male', 'Female', 'Others'];

export const IdentityProofsMasters: string[] = [
  'Photo PAN Card',
  'Passport',
  "Voter's ID",
  'Driving License',
  'Aadhar Card',
];

export const AddressProofsMasters: string[] = [
  'Passport',
  "Voter's ID",
  'Ration Card',
  'Electricity Bill',
  'Landline/Telephone Bill',
  'Gas Bill',
  'Latest Bank Statement/Passbook',
  'Latest Depository Account Statement',
];

export const BankAccountProofsMasters: string[] = [
  'Bank Statement with IFSC printed on it',
  'Cancelled cheque leaf verified',
  'Letter from the bank giving account details',
  'Passbook (showing bank account details)',
];

export const DrawDownMasters: number[] = [1, 2, 3, 4, 5];

//ENABLE_RTA_APPROVER if enabled it will add RTA Approver AS AIF Approver in flow after AMC approver and In API calls need to call RTA related APIs, If disabled AMC Approver becomes AIF approver in lable changes and In API calls need to call AMC related APIs and RTA approver will be hide
export const ENABLE_RTA_APPROVER = false;

//SAVE_LATER_VALIDATION_CHECK if false then Mandatory fields for Document Section will be Verified only on Clicking Preview
export const SAVE_LATER_VALIDATION_CHECK = false;

export const filterApplicationTypes = [
  { key: 'individual', value: 'Individual (Resident)' },
  { key: 'non_individual', value: 'Non-Individual' },
  { key: 'poa', value: 'Individual (POA)' },
  { key: 'nri', value: 'Individual (NRI)' },
  { key: 'nri_poa', value: 'Individual (NRI-POA)' },
  { key: 'non_individual_poa', value: 'Non-Individual (POA)' },
];

export const ModeOfHoldingTypes = [
  { key: 'single', value: 'Single' },
  { key: 'either or survivor,joint', value: 'Joint' },
];

export const IMAGE_UPLOAD_FORMATS = ['image/jpeg', 'application/pdf', 'image/png', 'image/jpg'];

export const USER_EXCEL_UPLOAD_FORMATS = [
  'xlsx',
  'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const INVESTOR_IMAGE_UPLOAD_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

export const APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to 1st Contributor',
  approved_by_applicant1: 'Approved by 1st Contributor',
  approved_by_applicant2: 'Approved by 2nd Contributor',
  approved_by_applicant3: 'Approved by 3rd Contributor',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};

export const SUPPORT_STATUS: { [key: string]: string } = {
  sent_to_applicant1: 'Sent to 1st Contributor',
  approved_by_applicant1: 'Approved by 1st Contributor',
  approved_by_applicant2: 'Approved by 2nd Contributor',
  approved_by_applicant3: 'Approved by 3rd Contributor',
  approved_by_fundmanager: 'Approved by investment manager',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
};

export const POA_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: ENABLE_RTA_APPROVER ? 'Sent to AMC approver' : 'Sent to AIF approver',
  sent_to_rta_approver: 'Sent to AIF approver',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const ESIGN_STATUS: { [key: string]: string } = {
  NOT_GENERATED: 'notgenerated',
  PENDING_SIGNATURE: 'pendingsignature',
  SIGNED: 'signed',
  EXPIRED: 'expired',
};

export enum USER_ROLES {
  SUPER_ADMIN = 'superadmin',
  AMC_ADMIN = 'amcadmin',
  RM = 'rm',
  DISTRIBUTOR = 'distributor',
  INVESTOR = 'investor',
  FUND_MANAGER = 'fundmanager',
  TRUSTEE = 'trustee',
  WITNESS = 'witness',
  APPLICATION = 'application',
  SUBDISTRIBUTOR = 'subdistributor',
  POAAPPROVER = 'poaapprover',
  AMCAPPROVER = 'amcapprover',
  AUTHORISEDSIGNATORY = 'authorisedsignatory',
  GROUPSIGNATORY = 'groupsignatory',
  RTAAPPROVER = 'rtaapprover',
}

export const ApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } = {
  [USER_ROLES.POAAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_poa_approver: 'Pending',
  },
  [USER_ROLES.AMCAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_amc_approver: 'Pending',
  },
  [USER_ROLES.RTAAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_rta_approver: 'Pending',
  },
};

export const Relations = [
  'Mother',
  'Father',
  'Daughter',
  'Son',
  'Spouse',
  'Brother',
  'Sister',
  'Others',
];

export const NomineeRelations = [
  'MOTHER',
  'FATHER',
  'DAUGHTER',
  'SON',
  'SPOUSE',
  'BROTHER',
  'SISTER',
  'OTHERS',
];

export const bulkFundType: { [key: string]: string } = {
  download: '--- select Fund or Plan for download template ---',
  fund: 'Funds',
  plan: 'Plans',
};
export const bulkApplicationType: { [key: string]: string } = {
  download: '--- select Application for download template ---',
  physicalApplication: 'Physical Application',
};

export const bulkUsersType: { [key: string]: string } = {
  download: '--- select user for download template ---',
  rm: 'Relationship Managers',
  individual_distributor: 'Individual Distributors',
  company_distributor: 'Company Distributors',
  distributorRm: "Distributor's RM",
  authorisedSignatory: 'Authorised Signatory',
  poaApprover: 'POA Approver',
  trustee: 'Trustees',
  investmentManager: 'Investment Managers',
  ...(ENABLE_RTA_APPROVER ? { amcApprover: 'AMC Approvers' } : {}),
  rtaApprover: 'AIF Approvers',
  amcAdmins: 'AMC Admins',
};

export const adminSupportMaster: { [key: string]: string } = {
  unlock_user: 'Unlock User',
  check_whitelisted: 'Check Whitelisted',
  whitelist_user: 'Whitelist User',
  unwhitelist_user: 'Unwhitelist User',
  change_expired_status: 'Change Expired Status',
  change_status_to_draft: 'Change Status to draft',
  process_signed_applications: 'Process Signing Applications',
  application_complete_for_topup: 'Application Complete for Topup',
  delete_topup_application: 'Delete All Topup Applications',
};

export const ENABLE_PROCESS_SIGNING_APPLICATIONS = true;

export const ENABLE_TOPUP_APPLICATION_ADMIN_SUPPORT = false; // ONLY ENABLE WHEN BE RUNNING IN YOUR LOCAL MACHINE OR NGROKS

export const IS_DOB_APPLICABLE_FOR_AMC = true;

export const REJECTED_ROLES: { [key: string]: string } = {
  poaapprover: 'POA Approver',
  amcapprover: ENABLE_RTA_APPROVER ? 'AMC Approver' : 'AIF Approver',
  ...(ENABLE_RTA_APPROVER ? { rtaapprover: 'AIF Approver' } : {}),
};

export const investorTypeMasters: { [key: string]: string } = {
  corporate: 'Corporate',
  trust: 'Trust',
  partnership_firm: 'Partnership Firm',
  huf: 'HUF',
  govt_bodies: 'Army/ Government Bodies',
  registered_society: 'Registered Society',
  fii_fpi: 'FII/FPI',
  bank: 'Bank',
  institutional_investor: 'Institutional Investor',
  unincorporated_or_body_of_individuals: 'Unincorporated association or a body of individuals',
  foreign_entity: 'Foreign Entity',
  llp: 'LLP',
};
export const IS_PENNYDROP_APPLICABLE_FOR_AMC = true;

export const getGroupNameMaster = ['Group-A', 'Group-B', 'Group-C'];

export const NON_INDIVIDUAL_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: ENABLE_RTA_APPROVER ? 'Sent to AMC approver' : 'Sent to AIF approver',
  sent_to_rta_approver: 'Sent to AIF approver',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const CpUboCodesMaster: { [key: string]: string } = {
  C01: '- CP of legal person-ownership',
  C02: '- CP of legal person-other means',
  C03: '- CP of legal person-senior managing official',
  C04: '- CP of legal arrangement-trust-settlor',
  C05: '- CP of legal arrangement-trust-trustee',
  C06: '- CP of legal arrangement-trust-protector',
  C07: '- CP of legal arrangement-trust-beneficiary',
  C08: '- CP of legal arrangement-trust-other',
  C09: '- CP of legal arrangement-trust-other-settlor-equivalent',
  C10: '- CP of legal arrangement-trust-other-trustee-equivalent',
  C11: '- CP of legal arrangement-trust-other-protector-equivalent',
  C12: '- CP of legal arrangement-trust-other-beneficiary-equivalent',
  C13: '- CP of legal arrangement-trust-other-other-equivalent',
  C14: '- Unknown',
};
export const controllingPersonsNationalitiesMasters: string[] = ['Indian', 'Others'];

//Application types
export enum APPLICATION_TYPE {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
}

export const AMC_APPROVER_CHECK_FOR_INDIVIDUAL = true; // this parameter is used to check application should be sent to approver first before investor

export const ENABLE_EMAIL_OTP = true;

export const APPLICATION_STATUS_AMCAPPROVER_individual: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: ENABLE_RTA_APPROVER ? 'Sent to AMC approver' : 'Sent to AIF approver',
  sent_to_rta_approver: 'Sent to AIF approver',
  sent_to_applicant1: 'Sent to 1st Contributor',
  approved_by_applicant1: 'Approved by 1st Contributor',
  approved_by_applicant2: 'Approved by 2nd Contributor',
  approved_by_applicant3: 'Approved by 3rd Contributor',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};
export const IndividualApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } =
  {
    [USER_ROLES.AMCAPPROVER]: {
      ...APPLICATION_STATUS_AMCAPPROVER_individual,
      sent_to_amc_approver: 'Pending',
    },
    [USER_ROLES.RTAAPPROVER]: {
      ...APPLICATION_STATUS_AMCAPPROVER_individual,
      sent_to_rta_approver: 'Pending',
    },
  };

export const APPLICATION_LISTING_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to 1st Contributor',
  approved_by_applicant1: 'Approved by 1st Contributor',
  approved_by_applicant2: 'Approved by 2nd Contributor',
  approved_by_applicant3: 'Approved by 3rd Contributor',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: ENABLE_RTA_APPROVER ? 'Sent to AMC approver' : 'Sent to AIF approver',
  sent_to_rta_approver: 'Sent to AIF approver',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
};

export enum APPLICATION_TYPE_FOR_DOCUMENTS {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
  INDIVIDUAL_POA = 'individual_poa',
  NRI = 'nri',
  NRI_POA = 'nri_poa',
  NOMINEE_DOC = 'nominee',
  NOMINEE_GUARDIAN = 'nomineeGuardian',
}

export const folioTypeMaster: { [key: string]: string } = {
  normalFolio: 'Normal Folio',
  restrictedFolio: 'Restricted Folio',
};

export const BankAccountTypeMaster: { [key: string]: string } = {
  Current: 'CURRENT',
  Savings: 'SAVING',
};
export const NRIBankAccountTypeMaster: { [key: string]: string } = {
  NRE: 'NRE',
  NRO: 'NRO',
};
export const DistributorTypesForUserManageMent: { [key: string]: string } = {
  Individual: 'individual',
  Company: 'company',
};

export const ENABLE_KRA_DOCUMENT_FETCH = true;

export const SALT = 'F2lNZWFxUH';
export const SALT2 = 'WTVPJel6dTGVocF';

export const ONBOARDING_OPTIONS: { [key: string]: string } = {
  otp: 'OTP',
  email_consent: 'Email Consent',
};

export const EmailConsentStatusMaster: { [key: string]: string } = {
  pending: 'Pending',
  rejected: 'Rejected',
  approved: 'Approved',
  revoked: 'Revoked',
};

export const ENABLE_Email_Consent = false;

export const BloodRelations = ['Father', 'Mother', 'Son', 'Daughter', 'Spouse'];

export const FilledByMaster: string[] = [
  `P S Krishnan`,
  `Ramesh Hariharan`,
  `Chitra Kalyanaraman`,
  `N Jayadev`,
  `Gurmeet Nihal Singh`,
  `Shaji T A`,
  `Mehul Marfatia`,
  `ROBEN DASS`,
].sort((a, b) => (a > b ? 1 : -1));

export const RmFilledByMaster = [];

// For changing Juliusbaer RM name change the "name" Not "key"

export const FilledByRMJuliusbaerMaster = [
  { key: 'Vrinda Mahadevia', name: 'Vrinda Mahadevia', email: 'vrinda.mahadevia@juliusbaer.com' },
  { key: 'Anand Khatau', name: 'Anand Khatau', email: 'anand.khatau@juliusbaer.com' },
  { key: 'Mehul Marfatia', name: 'Mehul Marfatia', email: 'mehul.marfatia@juliusbaer.com' },
  { key: 'Michelle Baptist', name: 'Michelle Baptist', email: 'michelle.baptist@juliusbaer.com' },
  {
    key: 'Subhashini Perinkulam Ramachandran',
    name: 'Subhashini Perinkulam Ramachandran',
    email: 'subhashini.pr@juliusbaer.com',
  },
  { key: 'Sarita Irani', name: 'Sarita Irani', email: 'sarita.irani@juliusbaer.com' },
  { key: 'Dia Sen', name: 'Dia Sen', email: 'dia.sen@juliusbaer.com' },
  { key: 'Manjari Verma', name: 'Manjari Verma', email: 'manjari.verma@juliusbaer.com' },
  {
    key: 'Atmanya Mrudul Jhaveri',
    name: 'Atmanya Mrudul Jhaveri',
    email: 'atmanya.jhaveri@juliusbaer.com',
  },
  { key: 'Rakesh Sharma', name: 'Rakesh Sharma', email: 'rakesh.sharma@juliusbaer.com' },
  { key: 'Pradeep Bihani', name: 'Pradeep Bihani', email: 'pradeep.bihani@juliusbaer.com' },
  { key: 'Mukesh Ahuja', name: 'Mukesh Ahuja', email: 'mukesh.ahuja@juliusbaer.com' },
  { key: 'Seema Kapadia', name: 'Seema Kapadia', email: 'seema.kapadia@juliusbaer.com' },
  { key: 'Kaushik Shanghvi', name: 'Kaushik Shanghvi', email: 'kaushik.shanghvi@juliusbaer.com' },
  {
    key: 'Harish Maheshwari',
    name: 'Harish Maheshwari',
    email: 'harish.maheshwari@juliusbaer.com',
  },
  { key: 'Namrata Ankeet Shah', name: 'Namrata Ankeet Shah', email: 'namrata.shah@juliusbaer.com' },
  {
    key: 'Abhishek Gumashta',
    name: 'Abhishek Gumashta',
    email: 'abhishek.gumashta@juliusbaer.com',
  },
  { key: 'Milan Doshi', name: 'Milan Doshi', email: 'milan.doshi@juliusbaer.com' },
  { key: 'Neha Shah', name: 'Neha Shah', email: 'neha.shah@juliusbaer.com' },
  { key: 'Kapil Katyal', name: 'Kapil Katyal', email: 'kapil.katyal@juliusbaer.com' },
  {
    key: 'Pavit Ravinder Chadha',
    name: 'Pavit Ravinder Chadha',
    email: 'pavit.chadha@juliusbaer.com',
  },
  { key: 'Mohit Malik', name: 'Mohit Malik', email: 'mohit.malik@juliusbaer.com' },
  { key: 'Mayank Agarwal', name: 'Mayank Agarwal', email: 'mayank.agarwal@juliusbaer.com' },
  { key: 'Kapil Bhushan', name: 'Kapil Bhushan', email: 'kapil.bhushan@juliusbaer.com' },
  { key: 'Girish Narang', name: 'Girish Narang', email: 'girish.narang@juliusbaer.com' },
  { key: 'Shashank Shah', name: 'Shashank Shah', email: 'shashank.shah@juliusbaer.com' },
  { key: 'Parag Dalvi', name: 'Parag Dalvi', email: 'parag.dalvi@juliusbaer.com' },
  { key: 'Mandar Shendye', name: 'Mandar Shendye', email: 'mandar.shendye@juliusbaer.com' },
  { key: 'Santosh Dubey', name: 'Santosh Dubey', email: 'santosh.dubey@juliusbaer.com' },
  {
    key: 'Sharad Phophaliya',
    name: 'Sharad Phophaliya',
    email: 'sharad.phophaliya@juliusbaer.com',
  },
  {
    key: 'Reetika Srinivasan',
    name: 'Reetika Srinivasan',
    email: 'reetika.srinivasan@juliusbaer.com',
  },
  { key: 'Ramit Kapoor', name: 'Ramit Kapoor', email: 'ramit.kapoor@juliusbaer.com' },
  { key: 'Vivek Syal', name: 'Vivek Syal', email: 'vivek.syal@juliusbaer.com' },
  { key: 'Suchita Shah', name: 'Suchita Shah', email: 'suchita.shah@juliusbaer.com' },
  { key: 'Ashima Chatterji', name: 'Ashima Chatterji', email: 'ashima.chatterji@juliusbaer.com' },
  { key: 'Tejas Kulkarni', name: 'Tejas Kulkarni', email: 'tejas.kulkarni@juliusbaer.com' },
  { key: 'Amit Chadha', name: 'Amit Chadha', email: 'amit.chadha@juliusbaer.com' },
  { key: 'Nelson D’Souza', name: 'Nelson D’Souza', email: 'nelson.dsouza@juliusbaer.com' },
  {
    key: 'Vikas Harshvardhan',
    name: 'Vikas Harshvardhan',
    email: 'vikas.harshvardhan@juliusbaer.com',
  },
  { key: 'Sumit Seth', name: 'Sumit Seth', email: 'sumit.seth@juliusbaer.com' },
  { key: 'Abhinav Kumar', name: 'Abhinav Kumar', email: 'abhinav.kumar@juliusbaer.com' },
  { key: 'Manish Khaitan', name: 'Manish Khaitan', email: 'manish.khaitan@juliusbaer.com' },
  { key: 'Varun Gupta', name: 'Varun Gupta', email: 'varun.gupta@juliusbaer.com' },
  { key: 'Geetesh Arora', name: 'Geetesh Arora', email: 'geetesh.arora@juliusbaer.com' },
  { key: 'Poornima Shirsat', name: 'Poornima Shirsat', email: 'poornima.shirsat@juliusbaer.com' },
  { key: 'Richard Selvey', name: 'Richard Selvey', email: 'richard.selvey@juliusbaer.com' },
  { key: 'Anuj Singla', name: 'Anuj Singla', email: 'anuj.singla@juliusbaer.com' },
  { key: 'Shweta Shah', name: 'Shweta Shah', email: 'shweta.shah@juliusbaer.com' },
  { key: 'Ashok R', name: 'Ashok R', email: 'ashok.r@juliusbaer.com' },
  { key: 'Sourav Saggar', name: 'Sourav Saggar', email: 'sourav.saggar@juliusbaer.com' },
  { key: 'Ritik Pahwa', name: 'Ritik Pahwa', email: 'ritik.pahwa@juliusbaer.com' },
  { key: 'Manish Bansal', name: 'Manish Bansal', email: 'manish.bansal@juliusbaer.com' },
  { key: 'Vinit Goyal', name: 'Vinit Goyal', email: 'vinit.goyal@juliusbaer.com' },
  { key: 'Akul Juneja', name: 'Akul Juneja', email: 'akul.juneja@juliusbaer.com' },
  {
    key: 'Dhanmesh Tripathi',
    name: 'Dhanmesh Tripathi',
    email: 'dhanmesh.tripathi@juliusbaer.com',
  },
  { key: 'Abhishek Chopra', name: 'Abhishek Chopra', email: 'abhishek.chopra@juliusbaer.com' },
  { key: 'Munish Kumar', name: 'Munish Kumar', email: 'munish.kumar@juliusbaer.com' },
  { key: 'Sourabh Aggarwal', name: 'Sourabh Aggarwal', email: 'sourabh.aggarwal@juliusbaer.com' },
  { key: 'Rashmi Sharma', name: 'Rashmi Sharma', email: 'rashmi.sharma@juliusbaer.com' },
  { key: 'Paras Shah', name: 'Paras Shah', email: 'paras.shah@juliusbaer.com' },
  { key: 'Shalini Mital', name: 'Shalini Mital', email: 'shalini.mital@juliusbaer.com' },
  { key: 'Vinay Sharma', name: 'Vinay Sharma', email: 'vinay.sharma@juliusbaer.com' },
].sort((a, b) => a.name.localeCompare(b.name));

export const FilledByRMNuvamaMaster = [
  { key: 'N/A', name: 'N/A', email: null },
  { key: 'Ashish Goel', name: 'Ashish Goel', email: 'Ashish.Goel@nuvama.com' },
  { key: 'Gaurav Chauhan', name: 'Gaurav Chauhan', email: 'GauravP.Chauhan@nuvama.com' },
  { key: 'Sharma Neha', name: 'Sharma Neha', email: 'sharma.neha@nuvama.com' },
  { key: 'Dushyant Barara', name: 'Dushyant Barara', email: 'Dushyant.Barara@nuvama.com' },
  { key: 'Ashish KumarB', name: 'Ashish KumarB', email: 'Ashish.KumarB@nuvama.com' },
  { key: 'Alka Goel', name: 'Alka Goel', email: 'Alka.Goel@nuvama.com' },
  { key: 'Sumit Wadhwa', name: 'Sumit Wadhwa', email: 'SumitS.Wadhwa@nuvama.com' },
  { key: 'Sundeep Ravinder', name: 'Sundeep Ravinder', email: 'sundeep.ravinder@nuvama.com' },
  { key: 'Amber Bhaskar', name: 'Amber Bhaskar', email: 'Amber.Bhaskar@nuvama.com' },
  { key: 'Harish Khanna', name: 'Harish Khanna', email: 'Harish.Khanna@nuvama.com' },
  { key: 'Amit Kumar', name: 'Amit Kumar', email: 'AmitS.Kumar@nuvama.com' },
  { key: 'Sumit Verma', name: 'Sumit Verma', email: 'SumitMP.Verma@nuvama.com' },
  { key: 'Gaurav Sehgal', name: 'Gaurav Sehgal', email: 'GauravS.Sehgal@nuvama.com' },
  { key: 'Gaurav Mittal', name: 'Gaurav Mittal', email: 'GauravJ.Mittal@nuvama.com' },
  { key: 'Rakesh Bharadwaj', name: 'Rakesh Bharadwaj', email: 'Rakesh.Bharadwaj@nuvama.com' },
  { key: 'Kapil Manchanda', name: 'Kapil Manchanda', email: 'kapil.manchanda@nuvama.com' },
  { key: 'Shakti Mahajan', name: 'Shakti Mahajan', email: 'Shakti.Mahajan@nuvama.com' },
  { key: 'Faraaz Shah', name: 'Faraaz Shah', email: 'Faraaz.Shah@nuvama.com' },
  { key: 'Damanjit Mayal', name: 'Damanjit Mayal', email: 'damanjit.mayal@nuvama.com' },
  { key: 'Neelima Karthik', name: 'Neelima Karthik', email: 'Neelima.Karthik@nuvama.com' },
  { key: 'Prashant Shastri', name: 'Prashant Shastri', email: 'Prashant.Shastri@nuvama.com' },
  { key: 'Arvind Mangai', name: 'Arvind Mangai', email: 'arvind.mangai@nuvama.com' },
  { key: 'Ram tv', name: 'Ram tv', email: 'ram.tv@nuvama.com' },
  { key: 'Nikhil Shah', name: 'Nikhil Shah', email: 'NikhilS.Shah@nuvama.com' },
  { key: 'Rohit Bhatia', name: 'Rohit Bhatia', email: 'Rohit.Bhatia@nuvama.com' },
  { key: 'Dushyant Sharma', name: 'Dushyant Sharma', email: 'Dushyant.Sharma@nuvama.com' },
  { key: 'Ashok Ahuja', name: 'Ashok Ahuja', email: 'Ashok.Ahuja@nuvama.com' },
  { key: 'Karthik Nair', name: 'Karthik Nair', email: 'Karthik.Nair@nuvama.com' },
  { key: 'Vishal Raheja', name: 'Vishal Raheja', email: 'Vishal.Raheja@nuvama.com' },
  { key: 'Hrishikesh Singh', name: 'Hrishikesh Singh', email: 'Hrishikesh.Singh@nuvama.com' },
  { key: 'Priyanshu Gaurav', name: 'Priyanshu Gaurav', email: 'priyanshu.gaurav@nuvama.com' },
  { key: 'Vivek Jain', name: 'Vivek Jain', email: 'VivekV.Jain@nuvama.com' },
  { key: 'Vicky Harjani', name: 'Vicky Harjani', email: 'vicky.harjani@nuvama.com' },
  { key: 'Pawan Kumar', name: 'Pawan Kumar', email: 'PawanR.Kumar@nuvama.com' },
  { key: 'Anil Mirani', name: 'Anil Mirani', email: 'Anil.Mirani@nuvama.com' },
  { key: 'Suhel Shaikh', name: 'Suhel Shaikh', email: 'Suhel.Shaikh@nuvama.com' },
  { key: 'Bhavin Parikh', name: 'Bhavin Parikh', email: 'Bhavin.Parikh@nuvama.com' },
  { key: 'Kuldeep Sikarwar', name: 'Kuldeep Sikarwar', email: 'Kuldeep.Sikarwar@nuvama.com' },
  { key: 'Sarfaraz Darvesh', name: 'Sarfaraz Darvesh', email: 'Sarfaraz.Darvesh@nuvama.com' },
  { key: 'Vikram Gupta', name: 'Vikram Gupta', email: 'Vikram.Gupta@nuvama.com' },
  { key: 'Pankaj Kotwani', name: 'Pankaj Kotwani', email: 'Pankaj.Kotwani@nuvama.com' },
  { key: 'Sameer Madia', name: 'Sameer Madia', email: 'Sameer.Madia@nuvama.com' },
  { key: 'Yash Shah', name: 'Yash Shah', email: 'YashB.Shah@nuvama.com' },
  { key: 'Anuj Sodhani', name: 'Anuj Sodhani', email: 'anuj.sodhani@nuvama.com' },
  { key: 'Vishwa Vora', name: 'Vishwa Vora', email: 'Vishwa.Vora@nuvama.com' },
  { key: 'Dhaval Mehta', name: 'Dhaval Mehta', email: 'Dhaval.Mehta@nuvama.com' },
  { key: 'Vimal Khajuria', name: 'Vimal Khajuria', email: 'Vimal.Khajuria@nuvama.com' },
  { key: 'Ruchika Kapur', name: 'Ruchika Kapur', email: 'Ruchika.Kapur@nuvama.com' },
  { key: 'Shagun Khosla', name: 'Shagun Khosla', email: 'khosla.shagun@nuvama.com' },
  { key: 'Jay Ahuja', name: 'Jay Ahuja', email: 'jay.ahuja@nuvama.com' },
  { key: 'Subham Agarwal', name: 'Subham Agarwal', email: 'subham.agarwal@nuvama.com' },
  { key: 'Aashish Kapoor', name: 'Aashish Kapoor', email: 'Aashish.Kapoor@nuvama.com' },
  { key: 'Maitri Shah', name: 'Maitri Shah', email: 'Maitri.Shah@nuvama.com' },
  { key: 'Hardik Barot', name: 'Hardik Barot', email: 'Hardik.Barot@nuvama.com' },
  { key: 'Swarnim Maheshwari', name: 'Swarnim Maheshwari', email: 'swarnim.maheshwari@nuvama.com' },
  { key: 'Sreejoy Dey', name: 'Sreejoy Dey', email: 'Sreejoy.Dey@nuvama.com' },
  { key: 'Tashu Sareen', name: 'Tashu Sareen', email: 'tashu.sareen@nuvama.com' },
  { key: 'Salil Thanawala', name: 'Salil Thanawala', email: 'Salil.Thanawala@nuvama.com' },
  { key: 'Purvashree Juvekar', name: 'Purvashree Juvekar', email: 'Purvashree.Juvekar@nuvama.com' },
  { key: 'Abhishek Rathi', name: 'Abhishek Rathi', email: 'Abhishek.Rathi@nuvama.com' },
  { key: 'Romila Nijhawan', name: 'Romila Nijhawan', email: 'romila.nijhawan@nuvama.com' },
  { key: 'Premal Gandhi', name: 'Premal Gandhi', email: 'Premal.Gandhi@nuvama.com' },
  { key: 'Vanusha Dhas', name: 'Vanusha Dhas', email: 'Vanusha.Dhas@nuvama.com' },
  { key: 'Akash Bansal', name: 'Akash Bansal', email: 'akash.bansal@nuvama.com' },
  {
    key: 'Krishnadas Damodaran',
    name: 'Krishnadas Damodaran',
    email: 'krishnadas.damodaran@nuvama.com',
  },
  { key: 'Abhishek Bhushan', name: 'Abhishek Bhushan', email: 'Abhishek.Bhushan@nuvama.com' },
  { key: 'Kunal Gupta', name: 'Kunal Gupta', email: 'KunalS.Gupta@nuvama.com' },
  { key: 'Tapan Desai', name: 'Tapan Desai', email: 'desai.tapan@nuvama.com' },
  { key: 'Shraddha Joshi', name: 'Shraddha Joshi', email: 'shraddha.joshi@nuvama.com' },
  { key: 'Khyati Bhuva', name: 'Khyati Bhuva', email: 'khyati.bhuva@nuvama.com' },
  { key: 'Abhinav Bhargava', name: 'Abhinav Bhargava', email: 'Abhinav.Bhargava@nuvama.com' },
  { key: 'Anupam Sharma', name: 'Anupam Sharma', email: 'sharma.anupam@nuvama.com' },
  { key: 'Anand Saraf', name: 'Anand Saraf', email: 'Anand.Saraf@nuvama.com' },
  {
    key: 'Sandeep Chakraborti',
    name: 'Sandeep Chakraborti',
    email: 'Sandeep.Chakraborti@nuvama.com',
  },
  { key: 'Bhuvan Billore', name: 'Bhuvan Billore', email: 'bhuvan.billore@nuvama.com' },
  { key: 'Manjeema Banerjee', name: 'Manjeema Banerjee', email: 'Manjeema.Banerjee@nuvama.com' },
  { key: 'Amit Saxena', name: 'Amit Saxena', email: 'Amit.Saxena@nuvama.com' },
].sort((a, b) => {
  if (a.key === 'N/A') {
    return -1;
  } else if (b.key === 'N/A') {
    return 1;
  } else {
    return a.name.localeCompare(b.name);
  }
});

export const FilledByAvendusMaster = [
  {
    key: 'Abhishek Tandon',
    name: 'Abhishek Tandon',
    email: 'abhishek.tandon@avendus.com',
  },
  {
    key: 'Adarsh Safir',
    name: 'Adarsh Safir',
    email: 'adarsh.safir@avendus.com',
  },
  {
    key: 'Alok Agarwal',
    name: 'Alok Agarwal',
    email: 'alok.agrawal@avendus.com',
  },
  {
    key: 'Anubhaw Chandak',
    name: 'Anubhaw Chandak',
    email: 'anubhaw.chandak@avendus.com',
  },
  {
    key: 'Anuj Lodha',
    name: 'Anuj Lodha',
    email: 'anuj.lodha@avendus.com',
  },
  {
    key: 'Bikram Mansahia',
    name: 'Bikram Mansahia',
    email: 'bikram.mansahia@avendus.com',
  },
  {
    key: 'Deepika Bhatia',
    name: 'Deepika Bhatia',
    email: 'deepika.bhatia@avendus.com',
  },
  {
    key: 'Dhaval Kothari ',
    name: 'Dhaval Kothari ',
    email: 'dhaval.kothari@avendus.com',
  },
  {
    key: 'Dhruv Sharma',
    name: 'Dhruv Sharma',
    email: 'dhruv.sharma@avendus.com',
  },
  {
    key: 'Gaurav Ahuja',
    name: 'Gaurav Ahuja',
    email: 'gaurav.ahuja@avendus.com',
  },
  {
    key: 'Gaurav Kaushik ',
    name: 'Gaurav Kaushik ',
    email: 'gaurav.kaushik@avendus.com',
  },
  {
    key: 'Gautam Bhandari',
    name: 'Gautam Bhandari',
    email: 'gautam.bhandari@avendus.com',
  },
  {
    key: 'Harmeet Sahney',
    name: 'Harmeet Sahney',
    email: 'harmeet.sahney@avendus.com',
  },
  {
    key: 'Heeral Parikh',
    name: 'Heeral Parikh',
    email: 'heeral.parikh@avendus.com',
  },
  {
    key: 'Jaspreet Dang',
    name: 'Jaspreet Dang',
    email: 'jaspreet.dang@avendus.com',
  },
  {
    key: 'Kanika Mittal ',
    name: 'Kanika Mittal ',
    email: 'kanika.mittal@avendus.com',
  },
  {
    key: 'Kshipra Dharmik',
    name: 'Kshipra Dharmik',
    email: 'kshipra.dharmik@avendus.com',
  },
  {
    key: 'Madhu Dasari',
    name: 'Madhu Dasari',
    email: 'madhu.dasari@avendus.com',
  },
  {
    key: 'Manoj Vaswani',
    name: 'Manoj Vaswani',
    email: 'manoj.vaswani@avendus.com',
  },
  {
    key: 'Mohan Landa ',
    name: 'Mohan Landa ',
    email: 'mohan.landa@avendus.com',
  },
  {
    key: 'Mohit Bagla',
    name: 'Mohit Bagla',
    email: 'mohit.bagla@avendus.com',
  },
  {
    key: 'Mrinal Malhotra',
    name: 'Mrinal Malhotra',
    email: 'mrinal.malhotra@avendus.com',
  },
  {
    key: 'Navneet Dutta',
    name: 'Navneet Dutta',
    email: 'navneet.dutta@avendus.com',
  },
  {
    key: 'Nehha Singhania',
    name: 'Nehha Singhania',
    email: 'nehha.singhania@avendus.com',
  },
  {
    key: 'Nikhil Vidyarthi',
    name: 'Nikhil Vidyarthi',
    email: 'nikhil.vidyarthi@avendus.com',
  },
  {
    key: 'Nishant Bhatia',
    name: 'Nishant Bhatia',
    email: 'nishant.bhatia@avendus.com',
  },
  {
    key: 'Nitin Mehndiratta',
    name: 'Nitin Mehndiratta',
    email: 'nitin.mehndiratta@avendus.com',
  },
  {
    key: 'Pallavi Srivastava',
    name: 'Pallavi Srivastava',
    email: 'pallavi.srivastava@avendus.com',
  },
  {
    key: 'Payal Bhatia',
    name: 'Payal Bhatia',
    email: 'payal.bhatia@avendus.com',
  },
  {
    key: 'Piyush Bhagla',
    name: 'Piyush Bhagla',
    email: 'piyush.bagla@avendus.com',
  },
  {
    key: 'Pravin Somani ',
    name: 'Pravin Somani ',
    email: 'pravin.somani@avendus.com',
  },
  {
    key: 'Priyam Chaudhry',
    name: 'Priyam Chaudhry',
    email: 'priyam.choudhary@avendus.com',
  },
  {
    key: 'Rahul Sharma',
    name: 'Rahul Sharma',
    email: 'rahul.sharma@avendus.com',
  },
  {
    key: 'Rajeev Choudhary ',
    name: 'Rajeev Choudhary ',
    email: 'rajeev.choudhary@avendus.com',
  },
  {
    key: 'Rajeev Mundhada ',
    name: 'Rajeev Mundhada ',
    email: 'rajeev.mundhada@avendus.com',
  },
  {
    key: 'Rashmi Choudhary',
    name: 'Rashmi Choudhary',
    email: 'rashmi.choudhary@avendus.com',
  },
  {
    key: 'Raveena Yagnik ',
    name: 'Raveena Yagnik ',
    email: 'raveena.yagnik@avendus.com',
  },
  {
    key: 'Rohit Suri',
    name: 'Rohit Suri',
    email: 'rohit.suri@avendus.com',
  },
  {
    key: 'Ruchi Rathi',
    name: 'Ruchi Rathi',
    email: 'ruchi.rathi@avendus.com',
  },
  {
    key: 'Saiyam Joshi',
    name: 'Saiyam Joshi',
    email: 'saiyam.joshi@avendus.com',
  },
  {
    key: 'Saurabh Banga',
    name: 'Saurabh Banga',
    email: 'saurabh.banga@avendus.com',
  },
  {
    key: 'Shunali Nagarkatti ',
    name: 'Shunali Nagarkatti ',
    email: 'shunali.nagarkatti@avendus.com',
  },
  {
    key: 'Sunil Khandelwal',
    name: 'Sunil Khandelwal',
    email: 'sunil.khandelwal@avendus.com',
  },
  {
    key: 'T Mohan Rahul',
    name: 'T Mohan Rahul',
    email: 'mohanrahul.tulluri@avendus.com',
  },
  {
    key: 'Vedant Chandak',
    name: 'Vedant Chandak',
    email: 'vedant.chandak@avendus.com',
  },
  {
    key: 'Vedanta Kanoria',
    name: 'Vedanta Kanoria',
    email: 'vedanta.kanoria@avendus.com',
  },
  {
    key: 'Vikky Srivastava',
    name: 'Vikky Srivastava',
    email: 'vikky.srivastava@avendus.com',
  },
  {
    key: 'Vinu Mammen ',
    name: 'Vinu Mammen ',
    email: 'vinu.mammen@avendus.com',
  },
  {
    key: 'Zarksis Gotla',
    name: 'Zarksis Gotla',
    email: 'zarksis.gotla@avendus.com',
  },
].sort((a, b) => a.name.localeCompare(b.name));

export const yesAndNoMasters: { [key: string]: string } = {
  Yes: 'true',
  No: 'false',
};
export const yesAndNoMasters1: { [key: string]: string } = {
  yes: 'Yes',
  no: 'No',
};

export const UboTypeMaster: { [key: string]: string } = {
  applicablePercentage: 'Applicable',
  notApplicable: 'Not Applicable',
  indeterminateTrust: 'Indeterminate Trust',
};

export const CvlUrlMaster: { [key: string]: string } = {
  UAT: 'https://krapancheck.cvlindia.com',
  PROD: 'https://pancheck.www.kracvl.com',
};

export const COMMENT_ROLES: { [key: string]: string } = {
  rm: 'RM',
  distributor: 'Distributor',
  subdistributor: 'Sub Distributor',
  amcapprover: ENABLE_RTA_APPROVER ? 'AMC Approver' : 'AIF Approver',
  poaapprover: 'POA Approver',
  rtaapprover: 'AIF Approver',
};
export const AdditionalRmDetailsValidationDate = '2024-02-01'; //Applicable date for validating Additional RM Details as per regex in RM/Distributor details page
export const CVLVerificationEnabled = false;

export const RiskProfileEnabled = false; //Configurable key for checkbox in risk profile in application

export const WishToUploadRiskProfileEnabled = false; //Configurable key for checkbox in risk profile (Wish to upload Risk Form) (Note: This is only be Enabled when RiskProfileEnabled is enabled)

export const IS_STAMP_PAPER_SERIES_MASTER: { [key: string]: string } = {
  true: 'Yes',
  false: 'No',
};
export const DLCLMasters: { [key: string]: string } = {
  cdsl: 'CDSL',
  nsdl: 'NSDL',
  none: 'None',
};

export const ENTITY_TYPE_MASTER: { [key: string]: string } = {
  sponsor: 'Sponsor',
  manager: 'Manager',
  directors_partners_employees_of_sponsor: 'Directors/ Partners/ Employees of Sponsor',
  directors_partners_employees_of_manager: 'Directors/ Partners/ Employees of Manager',
  employee_benefit_trust_of_manager: 'Employee Benefit Trust of Manager',
  banks: 'Banks',
  nbfcs: 'NBFCs',
  insurance_companies: 'Insurance Companies',
  pension_funds: 'Pension Funds',
  provident_funds: 'Provident Funds',
  aifs: 'AIFs',
  other_corporates: 'Other Corporates',
  non_corporate_other_than_trusts: 'Non-Corporate (other than Trusts)',
  trusts: 'Trusts',
  fpis: 'FPIs',
  fvcis: 'FVCIs',
  others: 'Others',
  domestic_development_agencies_government_agencies:
    'Domestic Developmental Agencies / Government Agencies',
};

export const dematFieldValidationDate = '2023-09-29'; //Applicable date for validating demat fields as per regex in KYC details
export const dateForDematDropdown = '2024-02-01'; //Removed None option from dropdown and making DPCL ID Mandatory for individual and non-individual
export const dpIdFieldValidationDate = '2024-02-17'; //Applicable date for validating dpId fields in kyc details and contributor details for individual and non-individual respectively

export const JointHolderDematValidationDate = '2024-02-27'; // Joint holder demat validation start date

export const entityTypeValidationDate = '2024-02-27'; // Entity type validation start date

export const DOCUMENT_ALLOW_PASTE = false;

export const RTAApproverSendBackRoles: { [key: string]: string } = {
  maker: 'Maker',
  amcapprover: 'AMC Approver',
};

export const AllApplicationStatusAsPerUserInterface: { [key: string]: string } = {
  ...APPLICATION_LISTING_STATUS,
  draft_send_back: 'Draft(Send Back)',
};

export const POA_Authorized_signatory_can_Esign_Enable = false;

export const POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE = false;

export const RESPONSE_TYPES: { [key: string]: string } = {
  kra: 'KRA',
  aml: 'AML',
  mf: 'MF',
  panAadhaar: 'PAN_AADHAAR',
  ifsc: 'IFSC',
  pennydrop: 'PENNYDROP',
};

export const YES_NO_MASTER: { [key: string]: string } = {
  yes: 'Yes',
  no: 'No',
};

export const YES_NO_BOOLEAN_MASTER: { [key: string]: string } = {
  true: 'Yes',
  false: 'No',
};

export const FEE_TYPE_MASTER: { [key: string]: string } = {
  fixed: 'Fixed',
  variable: 'Variable',
};

export const timeZoneFromServer = 'Asia/Kolkata';

export const Enable_Email_Login = false;
export const Enable_Mobile_Login = true;

export const updateKycDetails = [
  {
    tabText: 'Personal And Contact',
    header: 'Personal And Contact Details',
    endPoint: 'personal-details',
  },
  {
    tabText: 'Address Details',
    header: 'Address Details',
    endPoint: 'address-details',
  },
  {
    tabText: 'Document Details',
    header: 'Document Details',
    endPoint: 'document-details',
  },
];

export const KYC_APPLICATION_STATUS: { [key: string]: string } = {
  created: 'CREATED',
  aadhar_xml_fetched: 'AADHAR XML FETCHED',
  sent_to_applicant: 'SENT TO APPLICANT',
  signed: 'SIGNED',
  completed: 'COMPLETED',
  failed: 'FAILED',
  underprocess: 'UNDER PROCESS',
  registered: 'REGISTERED',
  hold: 'HOLD',
  rejected: 'REJECTED',
  notavailable: 'NOT AVAILABLE',
  deactivated: 'DEACTIVATED',
  notcheckedwithrespectivekra: 'NOT CHECKED WITH RESPECTIVE KRA',
  validated: 'VALIDATED',
  invitationexpired: 'INVITATION EXPIRED',
};

export const handleTopUp = true;

export const AddTopUpBankAccount = false;

export const AllowMultipleBank = false;

export const ENABLE_DIGILOCKER_FLOW = false;

export const ENABLE_BLUK_UPLOAD_USERS_AND_FUND = false;

export const ENABLE_ERROR_LOGS = false;

export const ENABLE_DOWNLOAD_USERS_DATA = false;

export const poaValidationDate = '2050-12-31';

export const ENABLE_TOPUP_ONBOARDING_REPORT = false;
export const ENABLE_TOPUP_COMPLETED_ONBOARDING_REPORT = false;
