import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Distributor,
  getDistributors,
  getDistributorsResponse,
  SubDistributor,
} from '../../redux-store/actions';
import React, { useEffect, useState } from 'react';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  mandatorytext,
  RmFilledByMaster,
  USER_ROLES,
  POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE,
  POA_Authorized_signatory_can_Esign_Enable,
  poaValidationDate,
} from '../../utils/constant';
import { InfoDialog, Notes, SubHeading } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { distributorsValidationSchema } from '../../utils/schema';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  applicationComparison,
  applyRoleBasedStatus,
  getFilledByForDistributor,
  getEmailByKey,
  checkIfApplicationIsIndividualPOA,
  checkValidationBasedOnDate,
  getOlderDate,
} from '../../utils/utilityFunctions';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { ConfirmationDialog } from '../commonComponents';
import {
  ApplicationProps,
  POAAuthorisedSignatories,
  rmDetailType,
} from '../../redux-store/types/api-types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { POAAuthorisedSignatoriesFragment } from '../NonIndividualInvestor/Authorised';
import { showError } from '../../redux-store/actions/auth';

const rmDetail = { sno: 1, name: '', email: '' };

type DistributorDetailsProps = {
  distributorName: string | null;
  distributorId: number | null;
  loginType: string;
  firstName: string;
  empCode: string;
  email: string;
  subdistributorId: number | null;
  subdistributorName: string | null;
  subdistributorEmail: string | null;
  filledBy: string | null;
  filledByRm: string | null;
  filledByEmail: string | null;
  rmDetails: rmDetailType[];
  createdAt: string;
};

type POAApplication = {
  authorisedSignatories: POAAuthorisedSignatories[];
  thrashold: number;
};

function GridItem({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}

const AdditionalRMDetails = ({
  values,
  setValues,
  application,
}: {
  values: DistributorDetailsProps;
  setValues: FormikHelpers<DistributorDetailsProps>['setValues'];
  application: ApplicationProps | null;
}): JSX.Element => {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [removeRM, SetRemoveRM] = useState<null | number>(null);
  const AddRM = async () => {
    try {
      setValues({
        ...values,
        rmDetails: values.rmDetails?.concat(rmDetail)?.map((item, i) => {
          return { ...item, sno: i + 1 };
        }),
      });
      return;
    } catch (e) {
      console.error((e as Error).message);
    }
  };
  const RemoveRM = (sno: number) => {
    setValues({
      ...values,
      rmDetails: values.rmDetails
        ?.filter((item) => item.sno !== sno)
        ?.map((item, i) => {
          return { ...item, sno: i + 1 };
        }),
    });
    return;
  };

  return (
    <>
      <SubHeading
        sx={{
          color: 'common.black',
          letterSpacing: 0.5 + 'px',
          padding: { xs: '10px 10px', sm: '10px 30px' },
        }}>
        Additional RM Details
      </SubHeading>
      <Notes
        displayContent={
          'The RM details provided herein will be registered in this application and will be used for all Investor Communication.'
        }
      />
      {values?.rmDetails?.map((item, i) => (
        <React.Fragment key={i}>
          <GridItem>
            <MFTextField
              name={`rmDetails.${i}.name`}
              label={`Additional RM Name ${item.sno}*`}
              placeholder="Enter Additional RM Name"
              disabled={
                [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) ||
                !!application?.topupInitiated
              }
            />
          </GridItem>
          <Grid item xs={12} sm={6} display={'flex'} direction={'row'}>
            <MFTextField
              name={`rmDetails.${i}.email`}
              label={`Additional RM Email ${item.sno}*`}
              placeholder="Enter Additional RM Email"
              disabled={
                [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) ||
                !!application?.topupInitiated
              }
            />
            {values?.rmDetails?.length !== 0 && !application?.topupInitiated && (
              <>
                {i + 1 === values?.rmDetails?.length && (
                  <AddIcon
                    onClick={AddRM}
                    sx={{ mt: 7, color: 'rgba(0,0,0,0.7)', ':hover': { cursor: 'pointer' } }}
                  />
                )}

                <RemoveIcon
                  onClick={() => SetRemoveRM(item.sno)}
                  sx={{ mt: 7, color: 'rgba(0,0,0,0.7)', ':hover': { cursor: 'pointer' } }}
                />
              </>
            )}
          </Grid>
        </React.Fragment>
      ))}
      {values?.rmDetails?.length < 1 && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{
              color: 'primary.main',
              fontWeight: 500,
              fontSize: 14,
              mt: 2,
            }}
            onClick={AddRM}
            disabled={!!application?.topupInitiated}>
            Add RM
          </Button>
        </Grid>
      )}
      <ConfirmationDialog
        message={`Are you sure you want to delete?`}
        open={removeRM !== null}
        setOpen={() => SetRemoveRM(null)}
        onSave={async () => {
          SetRemoveRM(null);
          RemoveRM(removeRM as number);
        }}
        onCancel={() => SetRemoveRM(null)}
      />
    </>
  );
};

const RMDetail = ({ application }: { application: ApplicationProps | null }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <GridItem>
        <MFTextField
          name="firstName"
          label="3PIM Name"
          placeholder="Enter 3PIM name"
          disabled={true}
        />
      </GridItem>
      <GridItem>
        <MFTextField
          name="email"
          label="3PIM Email ID"
          placeholder="Enter 3PIM Email ID"
          disabled={true}
        />
      </GridItem>
      <GridItem>
        <SearchableSelect
          name={`filledByRm`}
          label={'Filled by'}
          items={RmFilledByMaster.map((item) => ({
            key: item,
            value: item,
          }))}
          searchFieldPlaceholder={'Search filled by'}
          disabled={!!application?.topupInitiated}
        />
        <Typography
          component="span"
          sx={{ position: 'relative', left: 64, bottom: { xs: 85, md: 91 } }}>
          <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
        </Typography>
        <InfoDialog onClose={handleClose} open={open} content={'Please add the Name of the RM.'} />
      </GridItem>
      {/* <Box sx={{ width: '100%', mb: -1 }}></Box> */}

      {/* <GridItem>
        <MFTextField
          name="empCode"
          label="Employee Code"
          placeholder="Enter Employee Code"
          disabled={true}
        />
      </GridItem> */}
    </>
  );
};

const DistributorDetail = ({
  distributors,
  values,
  selectedDistributor,
  setSelectedDistributor,
  application,
  authorisedSignatoriesPOA,
  setAuthorisedSignatoriesPOA,
}: {
  distributors: Distributor[];
  values: DistributorDetailsProps;
  selectedDistributor: SubDistributor[];
  setSelectedDistributor: (distributor: SubDistributor[]) => void;
  application: ApplicationProps | null;
  authorisedSignatoriesPOA: POAApplication;
  setAuthorisedSignatoriesPOA: (e: POAApplication) => void;
}): JSX.Element => {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const { setValues } = useFormikContext();
  const [open, setOpen] = useState(false);
  const [openDialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setDialog(true);
  };
  const handleDialogClose = () => {
    setDialog(false);
  };

  return (
    <>
      {distributors.length > 0 ? (
        <>
          {[USER_ROLES.RM].includes(role) && values.distributorId && (
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 14,
                  py: 0.5,
                  px: 3,
                }}
                onClick={handleDialogOpen}
                disabled={application?.topupInitiated || false}>
                clear
              </Button>
            </Grid>
          )}
          <GridItem>
            <SearchableSelect
              name="distributorId"
              label="Distributor Code *"
              items={distributors
                .sort((distributor1, distributor2) =>
                  (distributor1['name'] || '')
                    .toString()
                    .localeCompare((distributor2['name'] || '').toString())
                )
                .map((item) => ({
                  key: (item.name || 'NA') + ' - ' + (item.arnCode || 'NA'),
                  value: item.id,
                }))}
              onChange={({ target: { value } }) => {
                setLoading(true);
                const distributorCode = distributors.find((item) => item.id === value);
                const { id = null, name } = distributorCode || {};
                setValues({
                  ...values,
                  distributorName: name,
                  distributorId: id,
                  subdistributorId: null,
                  filledBy: '',
                  filledByEmail: '',
                  subdistributorName: null,
                  subdistributorEmail: null,
                });
                setSelectedDistributor(
                  distributors.find((distributor) => distributor.id === value)?.subdistributors ||
                    []
                );
                setLoading(false);
              }}
              disabled={
                [
                  USER_ROLES.DISTRIBUTOR,
                  USER_ROLES.INVESTOR,
                  USER_ROLES.POAAPPROVER,
                  USER_ROLES.SUBDISTRIBUTOR,
                  USER_ROLES.AMCAPPROVER,
                  USER_ROLES.RTAAPPROVER,
                  USER_ROLES.AMC_ADMIN,
                ].includes(role) || !!application?.topupInitiated
              }
              applyRenderValue={true}
              searchFieldPlaceholder={'Search Distributor'}
            />
            {loading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={30} />
              </Box>
            )}
          </GridItem>
          <GridItem>
            <MFTextField
              name="distributorName"
              label="Distributor Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem>
          {!!selectedDistributor?.length && (
            <>
              <GridItem>
                <SearchableSelect
                  name="subdistributorId"
                  label="Distributor's RM Name - Code *"
                  items={selectedDistributor
                    .sort((subDistributor1, subDistributor2) =>
                      (subDistributor1.user['firstName'] || '')
                        .toString()
                        .localeCompare((subDistributor2.user['firstName'] || '').toString())
                    )
                    .map((item) => ({
                      key: item.user.firstName + ' - ' + (item.distributors_RM_Code || 'NA'),
                      value: item.id,
                    }))}
                  onChange={({ target: { value } }) => {
                    const distributorCode = selectedDistributor.find((item) => item.id === value);
                    const { id = null, user } = distributorCode || {};
                    const { email } = user || {};
                    setValues({
                      ...values,
                      subdistributorId: id,
                      subdistributorEmail: email,
                    });
                  }}
                  disabled={
                    [
                      USER_ROLES.DISTRIBUTOR,
                      USER_ROLES.INVESTOR,
                      USER_ROLES.POAAPPROVER,
                      USER_ROLES.SUBDISTRIBUTOR,
                      USER_ROLES.AMCAPPROVER,
                      USER_ROLES.RTAAPPROVER,
                      USER_ROLES.AMC_ADMIN,
                    ].includes(role) || !!application?.topupInitiated
                  }
                  searchFieldPlaceholder={"Search Distributor's RM"}
                />
              </GridItem>
              {/* <GridItem>
            <MFTextField
              name="subdistributorName"
              label="Distributor's RM Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem> */}
              <GridItem>
                <MFTextField
                  name="subdistributorEmail"
                  label="Distributor's RM Email *"
                  placeholder="Enter Distributor email"
                  disabled={true}
                />
              </GridItem>
            </>
          )}
          <GridItem>
            <SearchableSelect
              name={`filledBy`}
              label={'Filled by'}
              items={getFilledByForDistributor(values.distributorId as number).map((item) => ({
                key: item.key,
                value: item.name,
              }))}
              onChange={({ target: { value } }) => {
                setValues({
                  ...values,
                  filledBy: value,
                  filledByEmail: getEmailByKey(
                    value as string,
                    getFilledByForDistributor(values.distributorId as number)
                  ),
                });
              }}
              disabled={
                !getFilledByForDistributor(values.distributorId as number).length ||
                !!application?.topupInitiated
              }
              searchFieldPlaceholder={'Search filled by'}
            />
            {/* <MFTextField name="filledBy" label="Filled by" placeholder="Enter filled by" /> */}
            <Typography
              component="span"
              sx={{ position: 'relative', left: 64, bottom: { xs: 85, md: 91 } }}>
              <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
            </Typography>
          </GridItem>
          <InfoDialog
            onClose={handleClose}
            open={open}
            content={'Please add the Name of the Subdistributor RM.'}
          />

          {application &&
            application?.poaauthorisedsignatories?.length >= 0 &&
            checkValidationBasedOnDate(
              getOlderDate(application?.createdAt || '', application?.applicationCreatedAt || ''),
              poaValidationDate
            ) &&
            checkIfApplicationIsIndividualPOA(application) &&
            POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE && (
              <>
                <SubHeading>Authorised Signatories</SubHeading>
                <POAAuthorisedSignatoriesFragment
                  onChange={(e) => setAuthorisedSignatoriesPOA(e)}
                  values={authorisedSignatoriesPOA}
                  application={application}
                />
              </>
            )}
        </>
      ) : (
        <Grid
          xs={12}
          sm={12}
          sx={{
            fontSize: 18,
            fontWeight: 500,
            color: 'rgb(97, 209, 214)',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            mt: { lg: 9, xs: 9 },
            mb: 2.9,
            ml: { xs: 3 },
          }}>
          No distributor mapped under this RM
        </Grid>
      )}
      <ConfirmationDialog
        message={`Are you sure you want to clear Distributor details ?`}
        open={openDialog}
        setOpen={handleDialogClose}
        onSave={() => {
          handleDialogClose();
          setValues({
            ...values,
            distributorName: '',
            distributorId: null,
            subdistributorId: null,
            subdistributorName: '',
            subdistributorEmail: '',
            filledBy: '',
            filledByEmail: '',
          });
          setSelectedDistributor([]);
        }}
        onCancel={handleDialogClose}
      />
    </>
  );
};

export default function DistributorDetails(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [selectedDistributor, setSelectedDistributor] = useState<SubDistributor[]>([]);

  const {
    distributor,
    rm,
    subdistributorId = null,
    subDistributor,
    filledBy = '',
    filledByRm = '',
    filledByEmail = '',
    rmDetails = [],
    createdAt = '',
    applicationCreatedAt = '',
  } = application || {};

  const { name, id } = distributor || {
    arnCode: '',
    name: '',
    id: null,
  };

  const {
    empCode,
    user: { email: RMEmail = '', firstName: RMFirstName = '' },
  } = rm || { empCode: '', user: { email: '' } };

  const {
    user: { firstName: subdistributorName, email: subdistributorEmail },
  } = subDistributor || { user: { firstName: '', email: '' } };

  const initialValues: DistributorDetailsProps = {
    distributorId: id,
    distributorName: name,
    loginType: [USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role)
      ? USER_ROLES.DISTRIBUTOR
      : USER_ROLES.RM,
    empCode,
    email: RMEmail,
    firstName: RMFirstName,
    subdistributorId: subdistributorId === null ? subdistributorId : Number(subdistributorId),
    subdistributorName,
    subdistributorEmail,
    filledBy,
    filledByEmail,
    filledByRm,
    rmDetails: rmDetails ? rmDetails : [],
    createdAt: getOlderDate(createdAt, applicationCreatedAt),
  };
  const [authorisedSignatoriesPOA, setAuthorisedSignatoriesPOA] = useState<POAApplication>({
    authorisedSignatories: application?.poaauthorisedsignatories || [],
    thrashold: application?.requiredAuthorisedSignatory || 0,
  });

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        if (Object.keys(application || {}).length && role === USER_ROLES.RM) {
          const res = (await dispatch(
            getDistributors({ isActive: true })
          )) as unknown as getDistributorsResponse;
          const { distributors = [] } = res || {};
          const updatedDistributor =
            application?.distributor_id && !application.distributor.isActive
              ? [
                  ...distributors,
                  {
                    ...application.distributor,
                    subdistributors: [application.subDistributor].filter((ele) => ele),
                  } as Distributor,
                ]
              : distributors;
          if (isComponentActive) {
            const updateSubdistributor = (
              application?.distributor_id &&
              application?.distributor.isActive &&
              application?.subdistributorId &&
              !application.subDistributor?.isActive
                ? [
                    ...(updatedDistributor.find((distributor) => distributor.id === id)
                      ?.subdistributors || []),
                    { ...application.subDistributor },
                  ]
                : updatedDistributor.find((distributor) => distributor.id === id)
                    ?.subdistributors || []
            ).filter((ele) => ele);
            setSelectedDistributor(updateSubdistributor as unknown as SubDistributor[]);
            setDistributors(updatedDistributor as unknown as Distributor[]);
          }
        } else {
          const { distributor, subDistributor } = application || {};
          setDistributors(distributor ? [distributor as Distributor] : []);
          setSelectedDistributor(
            subDistributor
              ? [{ ...subDistributor, id: Number(subDistributor.id) } as SubDistributor]
              : []
          );
        }
        setAuthorisedSignatoriesPOA({
          authorisedSignatories: application?.poaauthorisedsignatories || [],
          thrashold: application?.requiredAuthorisedSignatory || 0,
        });
      } catch (e: unknown) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [Object.keys(application || {}).length]);

  const onSubmit = async (values: DistributorDetailsProps) => {
    try {
      const { distributorId, subdistributorId, filledBy, filledByRm, filledByEmail, rmDetails } =
        values;
      const {
        id,
        applicant1ReferenceId,
        currentStep = null,
        status,
        hasPOA,
        isTopup,
      } = application || {};
      if (
        application &&
        checkValidationBasedOnDate(
          getOlderDate(application?.createdAt || '', application?.applicationCreatedAt || ''),
          poaValidationDate
        ) &&
        checkIfApplicationIsIndividualPOA(application) &&
        POA_Authorized_signatory_can_Esign_Enable &&
        POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE
      ) {
        const getCanEsignCount = authorisedSignatoriesPOA.authorisedSignatories?.filter(
          (item) => item.canEsign
        );
        if (!authorisedSignatoriesPOA.authorisedSignatories?.length) {
          throw 'Please add Authorised Signatories';
        }
        if (!getCanEsignCount.length) {
          throw 'Please Select a member for E-Sign';
        }
        if (getCanEsignCount.length < authorisedSignatoriesPOA.thrashold) {
          throw 'Authorised Signatories that can esign can not be less than the Total No. of required Authorised Signatories';
        }
      }

      const Poa_can_esign =
        POA_Authorized_signatory_can_Esign_Enable &&
        POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE
          ? {
              poaauthorisedsignatories:
                application && checkIfApplicationIsIndividualPOA(application)
                  ? authorisedSignatoriesPOA.authorisedSignatories
                  : application?.poaauthorisedsignatories,
              requiredAuthorisedSignatory:
                application && checkIfApplicationIsIndividualPOA(application)
                  ? authorisedSignatoriesPOA.thrashold
                  : application?.requiredAuthorisedSignatory,
            }
          : {};

      const updatedApplication = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        ...Poa_can_esign,
        distributor_id: distributorId,
        subdistributorId: subdistributorId ? subdistributorId.toString() : subdistributorId,
        filledBy: filledBy,
        filledByRm: filledByRm,
        filledByEmail: filledByEmail,
        currentStep: currentStep === null ? 1 : currentStep,
        rmDetails,
      };

      const checkApplication = applicationComparison(application, {
        ...updatedApplication,
        currentStep: currentStep === null ? 1 : currentStep,
      });
      if (id && !checkApplication) {
        await dispatch(
          updateApplication({
            body: {
              ...updatedApplication,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 1,
              //currentStep === null ? 1 : currentStep
              filledBy,
              filledByRm,
              filledByEmail,
              rmDetails,
            },
            applicationId: id,
          })
        );
        history.push(isTopup ? 'investment-payment-details' : 'contributor-details', {
          id,
          applicant1ReferenceId,
          topUpApplication: application?.topupInitiated,
        });
      } else if (checkApplication) {
        history.push(isTopup ? 'investment-payment-details' : 'contributor-details', {
          id,
          applicant1ReferenceId,
          topUpApplication: application?.topupInitiated,
        });
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={distributorsValidationSchema(selectedDistributor, distributors)}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues, errors }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          {![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role) && (
            <Notes
              displayContent={'This section specifies whether the application is from an 3PIM/DRM.'}
              displayContent1={mandatorytext}
            />
          )}
          <Grid container rowSpacing={2} columnSpacing={5}>
            {/* Only maker can have  the edit access for fields */}
            {([USER_ROLES.RM].includes(role) ||
              (application?.hasPOA &&
                ![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role))) && (
              <Grid item xs={12}>
                <UseRadioGroup
                  formLabel=""
                  name={`loginType`}
                  items={[
                    { label: '3PIM', value: USER_ROLES.RM },
                    { label: 'Distributor', value: USER_ROLES.DISTRIBUTOR },
                  ]}
                />
              </Grid>
            )}
            {![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.POAAPPROVER].includes(
              role
            ) && values.loginType === USER_ROLES.RM ? (
              <RMDetail application={application} />
            ) : (
              <DistributorDetail
                distributors={distributors}
                values={values}
                selectedDistributor={selectedDistributor}
                setSelectedDistributor={(subdistributors) =>
                  setSelectedDistributor(subdistributors)
                }
                application={application}
                authorisedSignatoriesPOA={authorisedSignatoriesPOA}
                setAuthorisedSignatoriesPOA={(e) => setAuthorisedSignatoriesPOA(e)}
              />
            )}
            <AdditionalRMDetails
              setValues={(val) => setValues(val)}
              values={values}
              application={application}
            />
          </Grid>
          <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 5 }}>
            <MFSubmitButton label="Next" />
          </Box>
        </Box>
      )}
    </Formik>
  );
}
