import { Typography, Grid, CardMedia, Button, IconButton, SelectChangeEvent } from '@mui/material';
import { Formik, FormikHelpers, useField, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateApplication } from '../../redux-store/actions/application';
import {
  individuals_Poa_nonIndividuals_Documents,
  FileType,
  Bank,
  KraDocumentType,
  ApplicationProps,
} from '../../redux-store/types/api-types';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { RootStateType } from '../../redux-store/reducers';
import { Box } from '@mui/system';
import {
  ENABLE_KRA_DOCUMENT_FETCH,
  IMAGE_UPLOAD_FORMATS,
  mandatorytext,
  RiskProfileEnabled,
  SAVE_LATER_VALIDATION_CHECK,
  DOCUMENT_ALLOW_PASTE,
  USER_ROLES,
} from '../../utils/constant';
import { Notes, ProceedSaveLater, SubHeading2 } from '../investors/components';
import { useHistory } from 'react-router';
import {
  applicationComparison,
  checkIfApplicationIsNonIndividualPOA,
  filteredMandatoryNonMandatoryArray,
  getDocNumberForMultipleDocs,
  getStep,
  nonIndividualDocuments,
  nonIndividualTopupDocuments,
  saveForLater,
} from '../../utils/utilityFunctions';
import { cleardpIdField } from '../../utils/utilityFunctions';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { nonIndividualDocumentDetailsSchema } from '../../utils/schema';
import { logout, showError } from '../../redux-store/actions/auth';
import MFSelectField from '../../lib/formik/SelectField';
import { useSnackbar } from 'notistack';
import { KraDocument } from '../investors/kraDocument';
import { FileUploadDialog } from '../commonComponents';
import en from '../../lang/en-us';
import { useMdmsDocumentData, useMdmsTopUpDocumentData } from '../../utils/useDataMdms';
import FieldValidationNote from '../investors/FieldValidationNote';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

type documentDetails = {
  documentType: string;
  documentName: string;
  documentId: string;
  isActive: boolean;
  isVisible: boolean;
  file?: FileType;
  required?: string;
  uniqueKey?: string;
  options?: string[];
  [key: string]: string | number | boolean | FileType | undefined | string[] | null;
};

export type documentProps = {
  documents: {
    documentType: string;
    documentName: string;
    documentsList: documentDetails[];
    multipleFiles: string;
    required: string;
    options?: string[];
  }[];
  kraDocument?: KraDocumentType[];
};
export interface Values {
  topUpApplicants: documentProps[];
  applicants: documentProps[];
  saveType: string;
  banks: Bank[];
  isTopup: boolean;
  [key: string]: documentProps[] | string | undefined | string[] | Bank[] | boolean;
}

export const FileUpload = ({
  onFileChange,
  name,
  documentData,
  disabled = false,
}: {
  onFileChange: (value: string, documentId: string, removeFile?: boolean) => void;
  name: string;
  documentData: documentDetails;
  disabled?: boolean;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [files, setFiles] = useState<any>([]);
  const authToken = useSelector((store: RootStateType) => store.auth.token);
  const dispatch = useDispatch();
  const [open, setOpen] = useState({ show: false, message: '', code: '' });

  // eslint-disable-next-line
  const handleOpen = (result: any) => {
    setOpen({ show: true, message: result.message, code: result.code });
  };
  const handleClose = () => {
    setOpen({ show: false, message: '', code: '' });
  };

  const handleFileChange = (name: string, documentId: string) => {
    try {
      onFileChange(name, documentId);
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      {!documentData.file ? (
        <Box
          sx={{
            '.filepond--credits': { display: 'none' },
            '.filepond--label-action': {
              textDecoration: 'none',
              fontSize: '14px',
            },
            '.filepond--drop-label': {
              cursor: disabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'all',
            },
            '.filepond--file': {
              color: '#000',
              flexWrap: 'wrap',
              fontWeight: 500,
            },
          }}>
          <FilePond
            files={files}
            server={{
              process: {
                url: '/api/files/files',
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
                onload: (response): string => {
                  const result = JSON.parse(response);
                  result?.name && result?.id && handleFileChange(result.name, result.id);
                  return response;
                },
                onerror: (response) => {
                  if (response) {
                    const result = JSON.parse(response);
                    if (result && result.message) {
                      handleOpen(result);
                    }
                  } else {
                    handleOpen({ code: '', message: en.networkText.unableToProcess });
                  }
                  return response;
                },
              },
            }}
            onupdatefiles={(fileItems) => {
              setFiles(fileItems);
            }}
            // onremovefile={() => {
            //   onFileChange('', '', true);
            // }}
            // eslint-disable-next-line
            onprocessfile={(error, file) => {
              if (error) {
                if (error.code === 401) {
                  dispatch(logout());
                }
              }
            }}
            onprocessfilerevert={() => {
              onFileChange('', '', true);
            }}
            name="file"
            maxFileSize={'3MB'}
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={IMAGE_UPLOAD_FORMATS}
            labelIdle='<span class="filepond--label-action">Upload File <div>Max Size: 3MB</div>Supports: jpeg,pdf,png,jpg</span>'
            labelMaxFileSize={'Max file size is 3 MB'}
            labelFileTypeNotAllowed={'Invalid file type'}
            fileValidateTypeLabelExpectedTypesMap={{
              'image/jpeg': '.jpeg',
              'image/jpg': '.jpg',
              'image/png': '.png',
              'application/pdf': '.pdf',
            }}
            imagePreviewHeight={100}
            allowPaste={DOCUMENT_ALLOW_PASTE}
            disabled={disabled}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px dashed #AAB2BA',
            borderRadius: '6px',
            mb: 2,
            minHeight: '65px',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              src={documentData.file.thumbnailUrl || '/images/photo-placeholder.jpg'}
              alt="aadhar"
              width="50"
              height="50"
              sx={{ display: 'flex', width: 'unset', mx: 1 }}
            />
          </Box>
          <Typography
            component="span"
            sx={{ whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }}>
            {documentData.file.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              '.MuiButton-root': { fontWeight: 500, fontSize: 14, p: 0, mr: 1 },
              flexWrap: 'wrap',
            }}>
            <Button
              sx={{
                color: 'primary.main',
                border: '1px dashed #2057A6',
                mb: 1,
              }}
              onClick={() => window.open(documentData.file?.viewUrl)}>
              View
            </Button>
            {!disabled && (
              <Button
                variant="contained"
                onClick={() => {
                  onFileChange('', '', true);
                }}
                sx={{ mb: 1 }}>
                Replace
              </Button>
            )}
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
                mb: 1,
              }}
              onClick={() => window.open(documentData.file?.url, '_blank')}>
              <CardMedia
                component="img"
                src={'/images/download-icon.png'}
                alt="Download"
                sx={{ width: '25px' }}
              />
            </IconButton>
          </Box>
        </Box>
      )}
      <FileUploadDialog
        handleClose={handleClose}
        show={open.show}
        message={open.message}
        code={open.code}
      />
      {!!errorText && (
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
          }}>
          {errorText}
        </Typography>
      )}
    </>
  );
};

export const document_object = {
  documentId: '',
  isActive: true,
};

export const ApplicantTopUpDocuments = ({
  values,
  getLabel,
  setFieldValue,
}: {
  values: Values;
  getLabel: (
    documentName: string,
    documentType: string,
    required: string,
    values: Values
  ) => string;
  setFieldValue: FormikHelpers<Values>['setFieldValue'];
}): JSX.Element => {
  const { application } = useSelector((store: RootStateType) => store.application);
  const dispatch = useDispatch();
  return application?.isTopup ? (
    <>
      <SubHeading2>Top Up Documents</SubHeading2>
      {values.topUpApplicants.map((applicant, ind) => {
        const { documents } = applicant;
        return (
          <>
            {documents.map((document, doc_index) => {
              const {
                documentType,
                documentName,
                documentsList = [],
                multipleFiles,
                options,
                required,
              } = document;
              return (
                <>
                  <React.Fragment key={ind}>
                    <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
                      <Typography
                        component="span"
                        sx={{ color: '#2057A6', fontSize: '20px', paddingRight: '15px' }}>
                        {doc_index + 1}
                      </Typography>
                      {!options && (
                        <Typography
                          component="span"
                          sx={{ fontSize: 14, fontWeight: 500, color: 'rgba(0,0,0,0.7)' }}>
                          {documentName} {required === 'true' ? '*' : ''}
                        </Typography>
                      )}
                      {options &&
                        documentsList
                          .filter(
                            (document, index) =>
                              documentsList.findIndex(
                                (obj) => document.documentType === obj.documentType
                              ) === index
                          )
                          .map((doc, index) => (
                            <React.Fragment key={index}>
                              <Grid xs={12} sm={12} sx={{ position: 'relative', bottom: '15px' }}>
                                <MFSelectField
                                  name={`topUpApplicants.${ind}.documents.${doc_index}.documentsList.${index}.documentName`}
                                  label={getLabel(documentName, documentType, required, values)}
                                  items={options.map((item: string) => ({
                                    key: item,
                                    value: item,
                                  }))}
                                  applyLabelStyles={true}
                                  onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                                    const updatedDocumentList = values.topUpApplicants[
                                      ind
                                    ].documents[doc_index].documentsList.map((docList) => {
                                      if (docList.documentType === doc.documentType) {
                                        return {
                                          ...docList,
                                          documentName: value,
                                        };
                                      }
                                      return docList;
                                    });
                                    setFieldValue(
                                      `topUpApplicants.${ind}.documents.${doc_index}.documentsList`,
                                      updatedDocumentList
                                    );
                                  }}
                                />
                              </Grid>
                            </React.Fragment>
                          ))}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display="flex"
                      flexDirection="column"
                      mt={options ? { xs: 1, sm: 3 } : 0}>
                      {documentsList
                        .filter((item) => item.isVisible)
                        .map((doc, index) => (
                          <React.Fragment key={doc.uniqueKey}>
                            <FileUpload
                              onFileChange={(value, documentId, removeFile) => {
                                const { file, ...rest } =
                                  values.topUpApplicants[ind].documents[doc_index].documentsList[
                                    Number(doc?.uniqueKey?.split('-')[2])
                                  ];
                                setFieldValue(
                                  `topUpApplicants.${ind}.documents.${doc_index}.documentsList.${
                                    doc?.uniqueKey?.split('-')[2]
                                  }`,
                                  {
                                    ...rest,
                                    documentId,
                                    ...(!removeFile && { file }),
                                  }
                                );
                              }}
                              name={`topUpApplicants.${ind}.documents.${doc_index}.documentsList.${
                                doc?.uniqueKey?.split('-')[2]
                              }.documentId`}
                              documentData={doc}
                            />
                            {index > 0 && doc.isVisible && !doc.documentId && (
                              <DeleteOutlineIcon
                                onClick={() => {
                                  const getDocs = values.topUpApplicants[ind].documents[
                                    doc_index
                                  ].documentsList.map((_item, i) => ({
                                    ..._item,
                                    isVisible:
                                      i !== Number(doc?.uniqueKey?.split('-')[2])
                                        ? _item.isVisible
                                        : false,
                                  }));
                                  // .filter((_, i) => i !== index);
                                  setFieldValue(
                                    `topUpApplicants.${ind}.documents.${doc_index}.documentsList`,
                                    getDocs
                                  );
                                }}
                                color="error"
                                sx={{
                                  alignSelf: 'end',
                                  position: 'relative',
                                  bottom: '90px',
                                  left: '22px',
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      {multipleFiles === 'true' &&
                        documentsList.filter((item) => item.isVisible).length <
                          (documentType === 'additional_document_topup' ? 3 : 2) && (
                          <Typography
                            onClick={() => {
                              const currentDocTypeListLength =
                                values.topUpApplicants[ind].documents[doc_index].documentsList
                                  .length;
                              const {
                                required = true,
                                documentType,
                                documentName,
                                // eslint-disable-next-line
                                documentId,
                              } = values.topUpApplicants[ind].documents[doc_index].documentsList[
                                currentDocTypeListLength - 1
                              ];
                              try {
                                documentsList
                                  .filter((item) => item.isVisible)
                                  .forEach((item, _i) => {
                                    if (!item.documentId) {
                                      throw Error(
                                        `Please upload the ${getDocNumberForMultipleDocs(
                                          _i + 1
                                        )}document to add more documents`
                                      );
                                    }
                                  });
                                setFieldValue(
                                  `topUpApplicants.${ind}.documents.${doc_index}.documentsList`,
                                  [
                                    ...values.topUpApplicants[ind].documents[doc_index]
                                      .documentsList,
                                    {
                                      ...document_object,
                                      uniqueKey:
                                        ind.toString() +
                                        '-' +
                                        doc_index.toString() +
                                        '-' +
                                        currentDocTypeListLength.toString(),
                                      isVisible: true,
                                      documentType,
                                      documentName,
                                      required,
                                    },
                                  ]
                                );
                              } catch (e) {
                                dispatch(showError((e as Error).message));
                              }
                            }}
                            sx={{
                              color: '#417850',
                              fontSize: '12px',
                              fontWeight: 500,
                              textDecoration: 'underline',
                              alignSelf: 'end',
                              cursor: 'pointer',
                            }}>
                            {'Add another document'}
                          </Typography>
                        )}
                    </Grid>
                  </React.Fragment>
                </>
              );
            })}
          </>
        );
      })}
      <SubHeading2>Existing Application Documents</SubHeading2>
    </>
  ) : (
    <></>
  );
};

export default function DocumentDetails(): JSX.Element {
  const [documentsData, setDocumentsData] = useState<individuals_Poa_nonIndividuals_Documents[]>(
    []
  );
  const [documentsDataTopUp, setDocumentsDataTopUp] = useState<
    individuals_Poa_nonIndividuals_Documents[]
  >([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();
  const { Document } = useMdmsDocumentData();
  const { TopUpDocument } = useMdmsTopUpDocumentData();

  useEffect(() => {
    (async function () {
      try {
        const { applicants = [] } = application || {};
        const getInvestorType = applicants[0]?.investorType?.toString();
        if (getInvestorType) {
          if (getInvestorType && Object.keys(Document).includes(getInvestorType)) {
            if (checkIfApplicationIsNonIndividualPOA(application as ApplicationProps)) {
              setDocumentsData(
                Document[`${getInvestorType}_poa`] as individuals_Poa_nonIndividuals_Documents[]
              );
              setDocumentsDataTopUp(
                TopUpDocument[
                  `${getInvestorType}_poa`
                ] as individuals_Poa_nonIndividuals_Documents[]
              );
            } else {
              setDocumentsData(
                Document[getInvestorType] as individuals_Poa_nonIndividuals_Documents[]
              );
              setDocumentsDataTopUp(
                TopUpDocument[getInvestorType] as individuals_Poa_nonIndividuals_Documents[]
              );
            }
          }
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application, Document, TopUpDocument]);

  const { banks = [] } = application || {};

  const initialValues: Values = {
    topUpApplicants: nonIndividualTopupDocuments(application, documentsDataTopUp),
    applicants: nonIndividualDocuments(application, role, documentsData),
    saveType: 'save and proceed',
    banks: banks,
    isTopup: !!application?.isTopup,
  };

  const handleSubmit = async (values: Values) => {
    const { applicants, saveType, topUpApplicants } = values;
    const {
      applicants: exisitingApplicants = [],
      id,
      applicant1ReferenceId = '',
      currentStep,
      applicationNumber,
      topupApplicationNumber,
      isTopup = false,
    } = application || {};
    const updatedApplicants = exisitingApplicants.map((applicant, index) => {
      const { documents = [] } = applicants[index] || {};
      const { documents: topUpDocuments = [] } = topUpApplicants[index] || {};
      const updatedDoc = documents.map((doc) => {
        return {
          ...doc,
          documentsList: doc.documentsList.map((_doc) => {
            return { ..._doc, ...(isTopup ? { isTopup: false } : {}) };
          }),
        };
      });
      const finalDocs = (
        isTopup
          ? updatedDoc.concat(
              topUpDocuments.map((doc) => {
                return {
                  ...doc,
                  documentsList: doc.documentsList.map((_doc) => {
                    return { ..._doc, isTopup: true };
                  }),
                };
              })
            )
          : updatedDoc
      ).map((doc) => {
        return doc.documentsList.filter((d) => d.documentId);
      });
      return {
        ...applicant,
        documents: finalDocs.flat().map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, isVisible, ...rest } = doc;
          return rest;
        }),
      };
    });
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants
          ?.map((applicant) => {
            return {
              ...applicant,
              documents: applicant.documents
                // eslint-disable-next-line
                ?.map((document: any) => {
                  const {
                    // eslint-disable-next-line
                    documentData,
                    // id,
                    // applicantId,
                    // eslint-disable-next-line
                    filename,
                    // eslint-disable-next-line
                    updatedAt,
                    // eslint-disable-next-line
                    createdAt,
                    // eslint-disable-next-line
                    updatedBy,
                    // eslint-disable-next-line
                    createdBy,
                    ...rest
                  } = document;
                  return { ...rest };
                })
                .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
            };
          })
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        ...application,
        applicants: updatedApplicants
          .map((applicant) => {
            return {
              ...applicant,
              documents: applicant.documents
                // eslint-disable-next-line
                ?.map((document: any) => {
                  // eslint-disable-next-line
                  const { documentData, options, ...rest } = document;
                  return { ...rest };
                })
                .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
            };
          })
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        currentStep:
          !!currentStep && currentStep > (application?.isTopup ? 3 : RiskProfileEnabled ? 6 : 5)
            ? currentStep
            : Number(currentStep) + 1,
      }
    );
    try {
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              currentStep: application?.isTopup
                ? 4
                : RiskProfileEnabled
                ? getStep(7, isSaveLater)
                : getStep(6, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
        saveType === 'save and proceed'
          ? application?.isTopup
            ? history.push(
                [USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER].includes(role)
                  ? `/application-details/${id}`
                  : `/non-individual-application-preview/${id}`,
                { topUpApplication: application?.topupInitiated }
              )
            : history.push('authorised-signatory', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      } else if (checkApplication) {
        if (saveType !== 'save and proceed') {
          enqueueSnackbar(
            `Application ${
              application?.topupInitiated ? topupApplicationNumber : applicationNumber
            } - ` + ' Saved successfully',
            {
              variant: 'success',
              autoHideDuration: 3000,
            }
          );
        }
        saveType === 'save and proceed'
          ? application?.isTopup
            ? history.push(
                [USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER].includes(role)
                  ? `/application-details/${id}`
                  : `/non-individual-application-preview/${id}`,
                { topUpApplication: application?.topupInitiated }
              )
            : history.push('authorised-signatory', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const getLabel = (
    documentName: string,
    documentType: string,
    required: string,
    values: Values
  ): string => {
    return `${documentName}  ${
      ['bankAccountProof'].includes(documentType)
        ? values.banks.filter(
            (bank) => !bank.pennydropVerified && bank.defaultBankAccount && !bank?.isTopup
          ).length !== 0
          ? '*'
          : ''
        : ['bankAccountProofTopUp'].includes(documentType)
        ? values.banks.filter(
            (bank) => !bank.pennydropVerified && bank.defaultBankAccount && !!bank?.isTopup
          ).length !== 0
          ? '*'
          : ''
        : required === 'true'
        ? '*'
        : ''
    } `;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, nonIndividualDocumentDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues, setFieldValue }) => (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          display="flex"
          alignItems="flex-start">
          <Grid sx={{ ml: '40px', width: '100%' }}>
            <Notes
              displayContent={'Please upload latest documents not more than 2 months old'}
              displayContent1={"Please don't upload any password protected files"}
              displayContent2={mandatorytext}
            />
          </Grid>
          <ApplicantTopUpDocuments
            getLabel={getLabel}
            setFieldValue={setFieldValue}
            values={values}
          />
          {values.applicants.map((applicant, ind) => {
            const { documents } = applicant;
            let num = 0;
            return (
              <>
                {ENABLE_KRA_DOCUMENT_FETCH && (
                  <KraDocument KraDocument={applicant.kraDocument} num={num + 1} />
                )}
                {documents.map((document, doc_index) => {
                  num = doc_index + 1;
                  const {
                    documentType,
                    documentName,
                    documentsList = [],
                    multipleFiles,
                    options,
                    required,
                  } = document;
                  return (
                    <>
                      <React.Fragment key={ind}>
                        <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
                          <Typography
                            component="span"
                            sx={{ color: '#2057A6', fontSize: '20px', paddingRight: '15px' }}>
                            {doc_index + 2}
                          </Typography>
                          {!options && (
                            <Typography
                              component="span"
                              sx={{ fontSize: 14, fontWeight: 500, color: 'rgba(0,0,0,0.7)' }}>
                              {documentName} {required === 'true' ? '*' : ''}
                            </Typography>
                          )}
                          {options &&
                            documentsList
                              .filter(
                                (document, index) =>
                                  documentsList.findIndex(
                                    (obj) => document.documentType === obj.documentType
                                  ) === index
                              )
                              .map((doc, index) => (
                                <React.Fragment key={index}>
                                  <Grid
                                    xs={12}
                                    sm={12}
                                    sx={{ position: 'relative', bottom: '15px' }}>
                                    <MFSelectField
                                      name={`applicants.${ind}.documents.${doc_index}.documentsList.${index}.documentName`}
                                      label={getLabel(documentName, documentType, required, values)}
                                      items={options.map((item: string) => ({
                                        key: item,
                                        value: item,
                                      }))}
                                      applyLabelStyles={true}
                                      disabled={!!application?.topupInitiated || false}
                                      onChange={({
                                        target: { value },
                                      }: SelectChangeEvent<unknown>) => {
                                        const updatedDocumentList = values.applicants[
                                          ind
                                        ].documents[doc_index].documentsList.map((docList) => {
                                          if (docList.documentType === doc.documentType) {
                                            return {
                                              ...docList,
                                              documentName: value,
                                            };
                                          }
                                          return docList;
                                        });
                                        setFieldValue(
                                          `applicants.${ind}.documents.${doc_index}.documentsList`,
                                          updatedDocumentList
                                        );
                                      }}
                                    />
                                  </Grid>
                                </React.Fragment>
                              ))}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          display="flex"
                          flexDirection="column"
                          mt={options ? { xs: 1, sm: 3 } : 0}>
                          {documentsList
                            .filter((item) => item.isVisible)
                            .map((doc, index) => (
                              <React.Fragment key={doc.uniqueKey}>
                                <FileUpload
                                  onFileChange={(value, documentId, removeFile) => {
                                    const { file, ...rest } =
                                      values.applicants[ind].documents[doc_index].documentsList[
                                        Number(doc?.uniqueKey?.split('-')[2])
                                      ];
                                    setFieldValue(
                                      `applicants.${ind}.documents.${doc_index}.documentsList.${
                                        doc?.uniqueKey?.split('-')[2]
                                      }`,
                                      {
                                        ...rest,
                                        documentId,
                                        ...(!removeFile && { file }),
                                      }
                                    );
                                  }}
                                  name={`applicants.${ind}.documents.${doc_index}.documentsList.${
                                    doc?.uniqueKey?.split('-')[2]
                                  }.documentId`}
                                  documentData={doc}
                                  disabled={!!application?.topupInitiated || false}
                                />
                                {index > 0 && doc.isVisible && !doc.documentId && (
                                  <DeleteOutlineIcon
                                    onClick={() => {
                                      const getDocs = values.applicants[ind].documents[
                                        doc_index
                                      ].documentsList.map((_item, i) => ({
                                        ..._item,
                                        isVisible:
                                          i !== Number(doc?.uniqueKey?.split('-')[2])
                                            ? _item.isVisible
                                            : false,
                                      }));
                                      // .filter((_, i) => i !== index);
                                      setFieldValue(
                                        `applicants.${ind}.documents.${doc_index}.documentsList`,
                                        getDocs
                                      );
                                    }}
                                    color="error"
                                    sx={{
                                      alignSelf: 'end',
                                      position: 'relative',
                                      bottom: '90px',
                                      left: '22px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            ))}
                          {multipleFiles === 'true' &&
                            !application?.topupInitiated &&
                            documentsList.filter((item) => item.isVisible).length <
                              (documentType === 'additional' ? 3 : 2) && (
                              <Typography
                                onClick={() => {
                                  const currentDocTypeListLength =
                                    values.applicants[ind].documents[doc_index].documentsList
                                      .length;
                                  const {
                                    required = true,
                                    documentType,
                                    documentName,
                                    // eslint-disable-next-line
                                    documentId,
                                  } = values.applicants[ind].documents[doc_index].documentsList[
                                    currentDocTypeListLength - 1
                                  ];
                                  try {
                                    documentsList
                                      .filter((item) => item.isVisible)
                                      .forEach((item, _i) => {
                                        if (!item.documentId) {
                                          throw Error(
                                            `Please upload the ${getDocNumberForMultipleDocs(
                                              _i + 1
                                            )}document to add more documents`
                                          );
                                        }
                                      });
                                    setFieldValue(
                                      `applicants.${ind}.documents.${doc_index}.documentsList`,
                                      [
                                        ...values.applicants[ind].documents[doc_index]
                                          .documentsList,
                                        {
                                          ...document_object,
                                          uniqueKey:
                                            ind.toString() +
                                            '-' +
                                            doc_index.toString() +
                                            '-' +
                                            currentDocTypeListLength.toString(),
                                          isVisible: true,
                                          documentType,
                                          documentName,
                                          required,
                                        },
                                      ]
                                    );
                                  } catch (e) {
                                    dispatch(showError((e as Error).message));
                                  }
                                }}
                                sx={{
                                  color: '#417850',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  textDecoration: 'underline',
                                  alignSelf: 'end',
                                  cursor: 'pointer',
                                }}>
                                {'Add another document'}
                              </Typography>
                            )}
                        </Grid>
                      </React.Fragment>
                    </>
                  );
                })}
              </>
            );
          })}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
