import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles, Item } from './index';
import { formatShortDate } from '../../utils/date';
import {
  currencyConversion,
  DoNotWishToValidateCommitmentAmountRoles,
} from '../../utils/utilityFunctions';
import { USER_ROLES } from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import { useSelector } from 'react-redux';
import { styles } from '../../Styles/styles';

export default function InvestAndPaymentDetails({
  application,
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  const { applicants } = application;
  const getApplicants = applicants?.map((applicant) => applicant.applicant_type?.toString()) || [];
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                {!application?.isTopup && DoNotWishToValidateCommitmentAmountRoles(role) && (
                  <KeyValue
                    title={'Wish To Lower the Commitment Amount'}
                    description={application?.doNotWishToValidateCommitmentAmount ? 'Yes' : 'No'}
                  />
                )}
                <KeyValue title={'Scheme Name'} description={application.scheme?.schemeName} />
                {application?.isTopup && (
                  <KeyValue
                    title={'Existing Commitment Amount'}
                    description={application?.commitmentAmount}
                    amount={currencyConversion(application?.commitmentAmount)}
                  />
                )}
                <KeyValue
                  title={'Class/Subclass'}
                  description={application.plan?.planDescription}
                />
                {!application?.isTopup && application?.isPhysicalApplication && (
                  <KeyValue
                    title="Commitment Amount"
                    description={application?.commitmentAmount}
                    amount={currencyConversion(application?.commitmentAmount)}
                  />
                )}
                {!application?.isTopup &&
                  !application?.isPhysicalApplication &&
                  getApplicants.includes('1') && (
                    <KeyValue
                      title={`Primary/Solo Contributor Commitment Amount`}
                      description={
                        application.commitmentAmountPrimaryApplicant === 0
                          ? '0'
                          : application.commitmentAmountPrimaryApplicant
                      }
                      amount={currencyConversion(application.commitmentAmountPrimaryApplicant)}
                    />
                  )}
                {!application?.isTopup &&
                  !application?.isPhysicalApplication &&
                  getApplicants.includes('2') && (
                    <KeyValue
                      title={'Joint Contributor 1 Commitment Amount'}
                      description={
                        application.commitmentAmountJointHolder1 === 0
                          ? '0'
                          : application.commitmentAmountJointHolder1
                      }
                      amount={currencyConversion(application.commitmentAmountJointHolder1)}
                    />
                  )}
                {!application?.isTopup &&
                  !application?.isPhysicalApplication &&
                  getApplicants.includes('3') && (
                    <KeyValue
                      title={'Joint Contributor 2 Commitment Amount'}
                      description={
                        application.commitmentAmountJointHolder2 === 0
                          ? '0'
                          : application.commitmentAmountJointHolder2
                      }
                      amount={currencyConversion(application.commitmentAmountJointHolder2)}
                    />
                  )}
                {application?.isTopup && (
                  <KeyValue
                    title={'Top Up Commitment Amount'}
                    description={application?.topupAmount}
                    amount={currencyConversion(application?.topupAmount)}
                  />
                )}
                {((!application?.isTopup && !application?.isPhysicalApplication) ||
                  application?.isTopup) && (
                  <KeyValue
                    title={'Management Fees Rate'}
                    description={
                      application.managementFeesRate === 0 ? '0' : application.managementFeesRate
                    }
                  />
                )}
                {/* <KeyValue title={'Tax Rate'} description={application.taxRate} /> */}
                {/* <KeyValue title={'DrawDown Number'} description={application.drawDownNumber} /> */}
                {((!application?.isTopup && !application?.isPhysicalApplication) ||
                  application?.isTopup) && (
                  <KeyValue
                    title={
                      !application?.isTopup ? 'Contribution Amount' : 'Top Up Contribution Amount'
                    }
                    description={
                      !application?.isTopup
                        ? application.contributionChequeAmount === 0
                          ? '0'
                          : application.contributionChequeAmount
                        : application.topupContributionAmount
                    }
                    amount={currencyConversion(
                      !application?.isTopup
                        ? application.contributionChequeAmount
                        : application.topupContributionAmount
                    )}
                  />
                )}
                {/* <KeyValue
                  title={'Setup Fee Percentage'}
                  description={application.setupFeePercentage}
                />
                <KeyValue
                  title={'Setup Fee Amount'}
                  description={application.setupFeeAmount}
                  amount={currencyConversion(application.setupFeeAmount)}
                />
                <KeyValue
                  title={'GST (GST On Setup Fee Amount)'}
                  description={application.setupFeeGST}
                  amount={currencyConversion(application.setupFeeGST)}
                />
                <KeyValue
                  title={'Total Setup Fee'}
                  description={application.totalSetupFee}
                  amount={currencyConversion(application.totalSetupFee)}
                /> */}
                {/* <KeyValue
                  title={'TDS'}
                  description={application.setupFeeTDS}
                  amount={currencyConversion(application.setupFeeTDS)}
                /> */}
                {/* <KeyValue
                  title={'Setup Fee Payable'}
                  description={application.netSetupFeeLessTDS}
                  amount={currencyConversion(application.netSetupFeeLessTDS)}
                /> */}
                {((!application?.isTopup && !application?.isPhysicalApplication) ||
                  application?.isTopup) && (
                  <KeyValue
                    title={'Total Amount Payable'}
                    description={
                      application.totalAmountPayable === 0 ? '0' : application.totalAmountPayable
                    }
                    amount={currencyConversion(application.totalAmountPayable)}
                  />
                )}
                {application?.isTopup && (
                  <KeyValue
                    title={'Aggregate Commitment Amount'}
                    description={application.aggregateAmount}
                    amount={currencyConversion(application.aggregateAmount)}
                  />
                )}
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          {/* <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Payment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Payment Bank Name'} description={application.paymentBankame} />
                <KeyValue
                  title={'Payment Bank Account Number'}
                  description={application.paymentBankAccountNumber}
                />
                <KeyValue
                  title={'Payment Bank Account Type'}
                  description={application.paymentBankAccontType}
                />
                <KeyValue title={'Mode Of Payment'} description={application.modeOfPayment} />
                <KeyValue
                  title={'Payment/Cheque Date'}
                  description={formatShortDate(application.chequeDate)}
                />
                <KeyValue title={'Payment Ref No'} description={application.paymentRefNo} />
              </Grid>
            </Gridstyles>
          </Grid> */}
        </Box>
      </Box>
    </>
  );
}
