import {
  Box,
  Button,
  CardMedia,
  Grid,
  IconButton,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Notes, ProceedSaveLater, SubHeading, SubHeading2 } from './components';
import { Formik, FormikHelpers, useField, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import React, { useEffect, useState } from 'react';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  APPLICATION_TYPE_FOR_DOCUMENTS,
  DOCUMENT_ALLOW_PASTE,
  ENABLE_KRA_DOCUMENT_FETCH,
  IMAGE_UPLOAD_FORMATS,
  INVESTOR_IMAGE_UPLOAD_FORMATS,
  JointHolderDematValidationDate,
  mandatorytext,
  RiskProfileEnabled,
  USER_ROLES,
  SAVE_LATER_VALIDATION_CHECK,
} from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
  Applicant,
  Bank,
  Broker,
  BrokerList,
  Document,
  FileType,
  KraDocumentType,
  NomineeType,
} from '../../redux-store/types/api-types';
import { isFormValidForSubmission, updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { documentDetailsSchema } from '../../utils/schema';
import { logout, showError } from '../../redux-store/actions/auth';
import {
  filteredMandatoryNonMandatoryArray,
  cleardpIdField,
  getApplicantName,
  isMinor,
  checkFirstApplicant,
  isNriApplicant,
  isNationalityIndian,
  checkValidationBasedOnDate,
  getDocNumberForMultipleDocs,
  getStep,
  individualDocuments,
  individualTopupDocuments,
  nomineesDocuments,
  shouldValidateUponSaveLater,
  applyRoleBasedStatus,
} from '../../utils/utilityFunctions';
import { getFieldTitle } from '../ApplicationDetails/DocumentDetails';
import { applicationComparison } from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { KraDocument } from './kraDocument';
import { FileUploadDialog } from '../commonComponents';
import en from '../../lang/en-us';
import {
  useMdmsBrokerListData,
  useMdmsCountryData,
  useMdmsDocumentData,
  useMdmsRiskProfileData,
  useMdmsTopUpDocumentData,
} from '../../utils/useDataMdms';
import FieldValidationNote from './FieldValidationNote';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

export const FileUpload = ({
  onFileChange,
  name,
  documentData,
  disabled,
  onFileUpload,
  onFileUploaded,
}: {
  onFileChange: (value: string, documentId: string, removeFile?: boolean) => void;
  name: string;
  documentData: documentDetails;
  disabled: boolean;
  onFileUpload: () => void;
  onFileUploaded: () => void;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [files, setFiles] = useState<any>([]);
  const authToken = useSelector((store: RootStateType) => store.auth.token);
  const { token: kycToken = '' } = useSelector((store: RootStateType) => store.kycInvestor);
  const dispatch = useDispatch();
  const [open, setOpen] = useState({ show: false, message: '', code: '' });

  // eslint-disable-next-line
  const handleOpen = (result: any) => {
    setOpen({ show: true, message: result.message, code: result.code });
  };
  const handleClose = () => {
    setOpen({ show: false, message: '', code: '' });
  };

  const handleFileChange = (name: string, documentId: string) => {
    try {
      onFileChange(name, documentId);
    } catch (e) {
      onFileUploaded();
      console.error((e as Error).message);
    }
  };
  return (
    <>
      {!documentData.file ? (
        <Box
          sx={{
            '.filepond--credits': { display: 'none' },
            '.filepond--label-action': {
              textDecoration: 'none',
              fontSize: '14px',
            },
            '.filepond--drop-label': {
              cursor: disabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'all',
            },
            '.filepond--file': {
              color: '#000',
              flexWrap: 'wrap',
              fontWeight: 500,
            },
            pt: 4,
          }}>
          <FilePond
            files={files}
            fileSizeBase={1024}
            server={{
              process: {
                url: '/api/files/files',
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${authToken || kycToken}`,
                },
                onload: (response): string => {
                  const result = JSON.parse(response);
                  result?.name && result?.id && handleFileChange(result.name, result.id);
                  onFileUploaded();
                  return response;
                },
                onerror: (response) => {
                  if (response) {
                    const result = JSON.parse(response);
                    if (result && result.message) {
                      handleOpen(result);
                    }
                  } else {
                    handleOpen({ code: '', message: en.networkText.unableToProcess });
                  }
                  return response;
                },
              },
            }}
            onaddfilestart={() => {
              onFileUpload();
            }}
            // eslint-disable-next-line
            onaddfile={(error, file) => {
              if (error) {
                onFileUploaded();
              }
            }}
            // eslint-disable-next-line
            onprocessfile={(error, file) => {
              if (error) {
                if (error.code === 401) {
                  dispatch(logout());
                } else {
                  onFileUploaded();
                }
              }
            }}
            onupdatefiles={(fileItems) => {
              setFiles(fileItems);
            }}
            onprocessfilerevert={() => {
              onFileChange('', '', true);
            }}
            name="file"
            maxFileSize={documentData.documentType === 'poaNotarized' ? '5MB' : '2MB'}
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={
              ['investorSignature', 'investorPhotograph'].includes(documentData.documentType)
                ? INVESTOR_IMAGE_UPLOAD_FORMATS
                : IMAGE_UPLOAD_FORMATS
            }
            labelIdle={`<span class="filepond--label-action">Upload File <div>Max Size: ${
              documentData.documentType === 'poaNotarized' ? '5' : '2'
            }MB</div>Supports: ${
              ['investorSignature', 'investorPhotograph'].includes(documentData.documentType)
                ? 'png,jpg,jpeg'
                : 'jpeg,pdf,png,jpg'
            }</span>`}
            labelFileTypeNotAllowed={'Invalid file type'}
            labelMaxFileSize={`Max file size is ${
              documentData.documentType === 'poaNotarized' ? '5' : '2'
            } MB`}
            fileValidateTypeLabelExpectedTypesMap={{
              'image/jpeg': '.jpeg',
              'image/jpg': '.jpg',
              'image/png': '.png',
              'application/pdf': '.pdf',
            }}
            imagePreviewHeight={100}
            disabled={disabled}
            allowPaste={DOCUMENT_ALLOW_PASTE}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px dashed #AAB2BA',
            borderRadius: '6px',
            mt: '30px',
            minHeight: '65px',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              src={documentData.file.thumbnailUrl || '/images/photo-placeholder.jpg'}
              alt="aadhar"
              width="50"
              height="50"
              sx={{ display: 'flex', width: 'unset', mx: 1 }}
            />
          </Box>
          <Typography
            component="span"
            sx={{ whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }}>
            {documentData.file.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              '.MuiButton-root': { fontWeight: 500, fontSize: 14, p: 0, mr: 1 },
              flexWrap: 'wrap',
            }}>
            <Button
              sx={{
                color: 'primary.main',
                border: '1px dashed #2057A6',
                mb: 1,
              }}
              onClick={() => window.open(documentData.file?.viewUrl)}>
              View
            </Button>
            {!disabled && (
              <Button
                variant="contained"
                onClick={() => {
                  onFileChange('', '', true);
                }}
                sx={{ mb: 1 }}>
                Replace
              </Button>
            )}
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
                mb: 1,
              }}
              onClick={() => window.open(documentData.file?.url, '_blank')}>
              <CardMedia
                component="img"
                src={'/images/download-icon.png'}
                alt="Download"
                sx={{ width: '25px' }}
              />
            </IconButton>
          </Box>
        </Box>
      )}
      <FileUploadDialog
        handleClose={handleClose}
        show={open.show}
        message={open.message}
        code={open.code}
      />
      {!!errorText && (
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
          }}>
          {errorText}
        </Typography>
      )}
    </>
  );
};

export const getApplicantMappedBank = (banks: Bank[], applicantId: string) => {
  return banks?.find((bank) => Number(bank.applicantId) === Number(applicantId));
};

export type documentDetails = {
  documentType: string;
  documentName: string;
  documentId: string;
  isActive: boolean;
  file?: FileType;
  required: string;
  isVisible: boolean;
  uniqueKey: string;
  options?: string[];
  id?: string | number;
  [key: string]: string | number | boolean | FileType | undefined | string[] | null;
};

export type documentProps = {
  documents: {
    documentType: string;
    documentName: string;
    documentsList: documentDetails[];
    multipleFiles: string;
    required: string;
    options?: string[];
    applicantId?: string;
    topupApplicantId?: string;
  }[];
  kraDocument?: KraDocumentType[];
};

export type nomineeDocumentProps = {
  nomineedocuments: {
    documentType: string;
    documentName: string;
    documentsList: documentDetails[];
    multipleFiles: string;
    required: string;
    options?: string[];
  }[];
};
export interface Values {
  applicants: documentProps[];
  topUpApplicants: documentProps[];
  nominees: nomineeDocumentProps[];
  saveType: string;
  banks: Bank[];
  hasPOA: boolean;
  isTopup: boolean;
  [key: string]:
    | documentProps[]
    | string
    | undefined
    | string[]
    | Bank[]
    | boolean
    | nomineeDocumentProps[];
}
export const document_object = {
  documentId: '',
  isActive: true,
};

/**
 * ApplicantTopUpDocuments component
 * @param {Values} values - applicant details
 * @param {Function} getLabel - function to get label based on document type and required field
 * @param {boolean} isFieldDisabled - flag to disable upload field
 * @param {Function} setFieldValue - function to set formik field value
 * @param {Function} handleUpload - function to handle file upload
 * @param {Function} handleUploaded - function to handle file uploaded
 * @returns {JSX.Element} component element
 */
export const ApplicantTopUpDocuments = ({
  values,
  getLabel,
  isFieldDisabled = false,
  setFieldValue,
  handleUpload,
  handleUploaded,
}: {
  values: Values;
  getLabel: (
    documentName: string,
    documentType: string,
    required: string,
    values: Values,
    ind: number,
    applicantId?: string,
    wishToContribute?: boolean
  ) => string;
  isFieldDisabled: boolean;
  setFieldValue: FormikHelpers<Values>['setFieldValue'];
  handleUpload: () => void;
  handleUploaded: () => void;
}): JSX.Element => {
  const { application } = useSelector((store: RootStateType) => store.application);
  const dispatch = useDispatch();
  return application?.isTopup ? (
    <>
      <SubHeading2>Top Up Documents</SubHeading2>
      {values?.topUpApplicants?.map((applicant, ind) => {
        const { documents } = applicant;
        return (
          <>
            {' '}
            <SubHeading>
              {getApplicantName(ind + 1)} Contributor
              {ind !== 0 && ' ' + ind} Top Up Document Details
            </SubHeading>
            {documents.map((document, doc_index) => {
              const {
                documentType,
                documentName,
                documentsList = [],
                // eslint-disable-next-line
                multipleFiles,
                options,
                required,
                applicantId,
              } = document;
              return (
                <React.Fragment key={ind}>
                  <Grid item xs={12} sm={6}>
                    {options &&
                      documentsList
                        .filter(
                          (document, index) =>
                            documentsList.findIndex(
                              (obj) => document.documentType === obj.documentType
                            ) === index
                        )
                        .map((doc, index) => (
                          <React.Fragment key={index}>
                            <Grid xs={12} sm={12}>
                              <MFSelectField
                                name={`topUpApplicants.${ind}.documents.${doc_index}.documentsList.${index}.documentName`}
                                label={getLabel(
                                  documentName,
                                  documentType,
                                  required,
                                  values,
                                  ind,
                                  applicantId,
                                  application?.applicants[ind].wishToContribute
                                )}
                                items={options.map((item: string) => ({
                                  key: item,
                                  value: item,
                                }))}
                                disabled={isFieldDisabled || false}
                                applyLabelStyles={true}
                                onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                                  const updatedDocumentList = values.topUpApplicants[ind].documents[
                                    doc_index
                                  ].documentsList.map((docList) => {
                                    if (docList.documentType === doc.documentType) {
                                      return {
                                        ...docList,
                                        documentName: value,
                                      };
                                    }
                                    return docList;
                                  });
                                  setFieldValue(
                                    `topUpApplicants.${ind}.documents.${doc_index}.documentsList`,
                                    updatedDocumentList
                                  );
                                }}
                              />
                            </Grid>
                          </React.Fragment>
                        ))}
                    {!options && (
                      <Typography
                        sx={{
                          color: 'primary.main',
                          fontWeight: 500,
                          mt: documentType === 'investorSignature' ? 4 : 6,
                        }}>
                        {documentName} {required === 'true' ? '*' : ''}
                        {documentType === 'investorSignature' && (
                          <Typography
                            sx={{
                              color: 'primary.main',
                              fontWeight: 400,
                              fontSize: 14,
                            }}>
                            (This signature copy is used as a verification process for future
                            redemptions and transfer requests)
                          </Typography>
                        )}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    flexDirection="column"
                    mt={options ? { xs: 1, sm: 0 } : 0}>
                    {documentsList
                      .filter((item) => item.isVisible)
                      .map((doc, index) => (
                        <React.Fragment key={doc.uniqueKey}>
                          <FileUpload
                            onFileChange={(value, documentId, removeFile) => {
                              const { file, ...rest } =
                                values.topUpApplicants[ind].documents[doc_index].documentsList[
                                  Number(doc.uniqueKey.split('-')[2])
                                ];
                              setFieldValue(
                                `topUpApplicants.${ind}.documents.${doc_index}.documentsList.${
                                  doc.uniqueKey.split('-')[2]
                                }`,
                                {
                                  ...rest,
                                  documentId,
                                  ...(!removeFile && { file }),
                                }
                              );
                            }}
                            name={`topUpApplicants.${ind}.documents.${doc_index}.documentsList.${
                              doc.uniqueKey.split('-')[2]
                            }.documentId`}
                            documentData={doc}
                            disabled={isFieldDisabled || false}
                            onFileUpload={handleUpload}
                            onFileUploaded={handleUploaded}
                          />
                          {index > 0 && doc.isVisible && !doc.documentId && (
                            <DeleteOutlineIcon
                              onClick={() => {
                                const getDocs = values.topUpApplicants[ind].documents[
                                  doc_index
                                ].documentsList
                                  // .filter((_, i) => i !== index)
                                  .map((_item, i) => ({
                                    ..._item,
                                    isVisible:
                                      i !== Number(doc.uniqueKey.split('-')[2])
                                        ? _item.isVisible
                                        : false,
                                  }));
                                setFieldValue(
                                  `topUpApplicants.${ind}.documents.${doc_index}.documentsList`,
                                  getDocs
                                );
                              }}
                              color="error"
                              sx={{
                                alignSelf: 'end',
                                position: 'relative',
                                bottom: '90px',
                                left: '22px',
                                cursor: 'pointer',
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    {multipleFiles === 'true' &&
                      !isFieldDisabled &&
                      documentsList.filter((item) => item.isVisible).length <
                        (documentType === 'additional_document_topup' ? 3 : 2) && (
                        <Typography
                          onClick={() => {
                            const currentDocTypeListLength =
                              values.topUpApplicants[ind].documents[doc_index].documentsList.length;
                            const {
                              required = true,
                              documentType,
                              documentName,
                              // eslint-disable-next-line
                              documentId,
                            } = values.topUpApplicants[ind].documents[doc_index].documentsList[
                              currentDocTypeListLength - 1
                            ];
                            try {
                              documentsList
                                .filter((item) => item.isVisible)
                                .forEach((item, _i) => {
                                  if (!item.documentId) {
                                    throw Error(
                                      `Please upload the ${getDocNumberForMultipleDocs(
                                        _i + 1
                                      )}  document to add more documents`
                                    );
                                  }
                                });
                              setFieldValue(
                                `topUpApplicants.${ind}.documents.${doc_index}.documentsList`,
                                [
                                  ...values.topUpApplicants[ind].documents[doc_index].documentsList,
                                  {
                                    ...document_object,
                                    isVisible: true,
                                    uniqueKey:
                                      ind.toString() +
                                      '-' +
                                      doc_index.toString() +
                                      '-' +
                                      currentDocTypeListLength.toString(),
                                    documentType,
                                    documentName,
                                    required,
                                  },
                                ]
                              );
                            } catch (e) {
                              dispatch(showError((e as Error).message));
                            }
                          }}
                          sx={{
                            color: '#417850',
                            fontSize: '12px',
                            fontWeight: 500,
                            textDecoration: 'underline',
                            alignSelf: 'end',
                            cursor: 'pointer',
                          }}>
                          {'Add another document'}
                        </Typography>
                      )}
                  </Grid>
                </React.Fragment>
              );
            })}
          </>
        );
      })}
      <SubHeading2>Existing Application Documents</SubHeading2>
    </>
  ) : (
    <></>
  );
};

export default function DocumentDetails(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [documentUpload, setDocumentUpload] = useState(false);
  const { countries } = useMdmsCountryData();
  const { riskProfile } = useMdmsRiskProfileData();
  const { BrokerList } = useMdmsBrokerListData();
  const { Document } = useMdmsDocumentData();
  const { TopUpDocument } = useMdmsTopUpDocumentData();

  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };
  const [documentsData, setDocumentsData] = useState<Document>();
  const [topUpDocumentsData, setTopUpDocumentsData] = useState<Document>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async function () {
      try {
        setDocumentsData(Document as Document);
        setTopUpDocumentsData(TopUpDocument as Document);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [Document, TopUpDocument]);

  const { banks = [], hasPOA = true } = application || {};

  const initialValues: Values = {
    topUpApplicants: individualTopupDocuments(application, topUpDocumentsData),
    applicants: individualDocuments(application, documentsData),
    nominees: nomineesDocuments(application, documentsData),
    saveType: 'save and proceed',
    banks: banks,
    hasPOA: hasPOA,
    isTopup: !!application?.isTopup,
  };
  const updatedApplicants = (
    exisitingApplicants: Partial<Applicant>[],
    applicants: documentProps[],
    topUpApplicants: documentProps[],
    isTopup: boolean
  ): Partial<Applicant>[] => {
    return exisitingApplicants.map((applicant, index) => {
      const { documents = [] } = applicants[index] || {};
      const { documents: topUpDocuments = [] } = topUpApplicants[index] || {};
      const updatedDoc = documents.map((doc) => {
        return {
          ...doc,
          documentsList: doc.documentsList.map((_doc) => {
            return { ..._doc, ...(isTopup ? { isTopup: false } : {}) };
          }),
        };
      });
      const finalDocs = (
        isTopup
          ? updatedDoc.concat(
              topUpDocuments.map((doc) => {
                return {
                  ...doc,
                  documentsList: doc.documentsList.map((_doc) => {
                    return { ..._doc, isTopup: true };
                  }),
                };
              })
            )
          : updatedDoc
      ).map((doc) => {
        return doc.documentsList.filter((d) => d.documentId);
      });
      return {
        ...applicant,
        documents: finalDocs.flat().map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, isVisible, ...rest } = doc;
          return rest;
        }),
      };
    }) as Partial<Applicant>[];
  };

  const updatedNominees = (
    existingNominees: NomineeType[],
    nominees: nomineeDocumentProps[]
  ): NomineeType[] => {
    return existingNominees.map((nominee, index) => {
      const { nomineedocuments = [] } = nominees[index] || {};
      const finalDocs = nomineedocuments.map((doc) => {
        return doc.documentsList.filter((d) => d.documentId);
      });
      return {
        ...nominee,
        nomineedocuments: finalDocs.flat().map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, isVisible, ...rest } = doc;
          return { ...rest, ...(application?.isTopup ? { isTopup: false } : {}) };
        }),
      };
    }) as NomineeType[];
  };

  const validateDocument = (applicants: documentProps[], nominees: nomineeDocumentProps[]) => {
    applicants.map((_applicant, index) => {
      _applicant.documents.map((document) => {
        document.documentsList.map((doc) => {
          if (document.required === 'true' && !doc.documentId) {
            if (
              doc.documentType === 'bankAccountProof' &&
              getApplicantMappedBank(banks, document.applicantId as string)?.pennydropVerified
            ) {
              return;
            }
            throw `Please Upload ${doc.documentName} for ${getApplicantName(
              index + 1
            )} contributor${index !== 0 ? ' ' + index : ''}`;
          }
        });
      });
    });

    nominees.map((nomineeDoc, index) => {
      nomineeDoc.nomineedocuments.map((nomineeDocument) => {
        nomineeDocument.documentsList.map((doc) => {
          if (nomineeDocument.required === 'true' && !doc.documentId) {
            throw `Please Upload ${doc.documentName} for ${
              ['First', 'Second', 'Third'][index]
            } Nominee`;
          }
        });
      });
    });
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      if (shouldValidateUponSaveLater(values?.saveType)) {
        let risk;
        if (RiskProfileEnabled) {
          risk = riskProfile;
        }
        await isFormValidForSubmission(
          application,
          risk,
          false,
          true,
          countries,
          BrokerList.broker_list as Broker[],
          documentsData,
          topUpDocumentsData,
          role
        );
      }
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId,
        currentStep,
        status,
        applicationNumber,
        nominees: existingNominees = [],
        isTopup = false,
      } = application || {};
      const { applicants, saveType, hasPOA, nominees, topUpApplicants } = values;
      validateDocument(applicants, nominees);
      // const updatedApplicants = exisitingApplicants.map((applicant, index) => {
      //   const applicantsWithNoDocsAttached = Object.keys(applicants[index].documents).filter(
      //     (doc) => applicants[index].documents[doc].documentId
      //   );
      //   return {
      //     ...applicant,
      //     countryCode: '+91', //For now hard coding country code. Need to remove after UI is done
      //     documents: applicantsWithNoDocsAttached.map((documentType) => ({
      //       ...getExistingDocuments(exisitingApplicants[index].documents || [], documentType),
      //       ...applicants[index].documents[documentType],
      //     })),
      //   };
      // });

      const updatedApplicantsWithDoc = updatedApplicants(
        exisitingApplicants,
        applicants,
        topUpApplicants,
        !!isTopup
      );
      updatedApplicantsWithDoc.map((applicant) => {
        if (applicant.documents?.length) {
          applicant.documents.map((document) => {
            if (document.options && !document.options.includes(document.documentName)) {
              throw `Please Select ${
                getFieldTitle[document.documentType] || document.documentName
              }`;
            }
          });
        }
      });

      const updatedNomineesWithDoc = updatedNominees(existingNominees, nominees);
      updatedNomineesWithDoc.map((_nominee) => {
        if (_nominee.nomineedocuments?.length) {
          _nominee.nomineedocuments.map((document) => {
            if (document.options && !document.options.includes(document.documentName)) {
              throw `Please Select ${
                getFieldTitle[document.documentType] || document.documentName
              }`;
            }
          });
        }
      });
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                documents: applicant.documents
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    const {
                      // eslint-disable-next-line
                      documentData,
                      // id,
                      // applicantId,
                      // eslint-disable-next-line
                      filename,
                      // eslint-disable-next-line
                      updatedAt,
                      // eslint-disable-next-line
                      createdAt,
                      // eslint-disable-next-line
                      updatedBy,
                      // eslint-disable-next-line
                      createdBy,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          nominees: application?.nominees
            ?.map((nominee) => {
              return {
                ...nominee,
                nomineedocuments: nominee.nomineedocuments
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    const {
                      // eslint-disable-next-line
                      documentData,
                      // id,
                      // nomineeId,
                      // eslint-disable-next-line
                      filename,
                      // eslint-disable-next-line
                      updatedAt,
                      // eslint-disable-next-line
                      createdAt,
                      // eslint-disable-next-line
                      updatedBy,
                      // eslint-disable-next-line
                      createdBy,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)),
        },
        {
          ...application,
          applicants: updatedApplicantsWithDoc
            .map((applicant) => {
              return {
                ...applicant,
                documents: applicant.documents
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    // eslint-disable-next-line
                    const { documentData, options, ...rest } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          nominees: updatedNomineesWithDoc
            .map((nominee) => {
              return {
                ...nominee,
                nomineedocuments: nominee.nomineedocuments
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    // eslint-disable-next-line
                    const { documentData, options, ...rest } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)),
          currentStep:
            !!currentStep && currentStep > (isTopup ? 2 : RiskProfileEnabled ? 9 : 8)
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicantsWithDoc,
              nominees: updatedNomineesWithDoc,
              // : (status === 'draft' || status === 'sent_to_amc_approver') &&
              status:
                !application?.hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                applyRoleBasedStatus(role) &&
                status !== 'draft'
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: isTopup
                ? 3
                : RiskProfileEnabled
                ? getStep(10, isSaveLater)
                : getStep(9, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),

              // currentStep: !!currentStep && currentStep > 8 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
        history.push(
          role === USER_ROLES.INVESTOR
            ? `/${
                application?.topupInitiated ? 'topup' : 'investment-details'
              }/${applicant1ReferenceId}/application-details`
            : role === USER_ROLES.POAAPPROVER || USER_ROLES.AMCAPPROVER === role
            ? `/application-details/${id}`
            : isSaveLater
            ? `/applications`
            : `/application-preview/${id}`,
          { topUpApplication: application?.topupInitiated }
        );
      } else if (isSaveLater) {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        history.push(
          role === USER_ROLES.INVESTOR
            ? `/investment-details/${applicant1ReferenceId}/application-details`
            : role === USER_ROLES.POAAPPROVER || USER_ROLES.AMCAPPROVER === role
            ? `/application-details/${id}`
            : `/applications`
        );
      } else {
        history.push(
          role === USER_ROLES.INVESTOR
            ? `/${
                application?.topupInitiated ? 'topup' : 'investment-details'
              }/${applicant1ReferenceId}/application-details`
            : role === USER_ROLES.POAAPPROVER || USER_ROLES.AMCAPPROVER === role
            ? `/application-details/${id}`
            : `/application-preview/${id}`,
          { topUpApplication: application?.topupInitiated }
        );
      }
    } catch (e) {
      console.log(e, 'error');
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const isFieldDisabled = [USER_ROLES.INVESTOR].includes(role);
  const getLabel = (
    documentName: string,
    documentType: string,
    required: string,
    values: Values,
    ind: number,
    applicantId?: string,
    wishToContribute?: boolean
  ): string => {
    return `${documentName}  ${
      ['bankAccountProof'].includes(documentType)
        ? !getApplicantMappedBank(values.banks, applicantId as string)?.pennydropVerified &&
          wishToContribute
          ? '*'
          : ''
        : ['bankAccountProofTopUp'].includes(documentType)
        ? !getApplicantMappedBank(values.banks, applicantId as string)?.pennydropVerified &&
          wishToContribute
          ? '*'
          : ''
        : required === 'true'
        ? '*'
        : ''
    } `;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      //validationSchema={documentDetailsSchema}
      validate={(values: Values) => {
        try {
          // validateYupSchema(values, documentDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={10}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid sx={{ width: '100%' }}>
            <Notes
              displayContent={'Please upload latest documents not more than 2 months old'}
              displayContent1={"Please don't upload any password protected files"}
              displayContent2={mandatorytext}
            />
          </Grid>
          <ApplicantTopUpDocuments
            values={values}
            setFieldValue={setFieldValue}
            getLabel={getLabel}
            handleUpload={handleUpload}
            handleUploaded={handleUploaded}
            isFieldDisabled={isFieldDisabled}
          />
          {values.applicants.map((applicant, ind) => {
            const { documents } = applicant;
            return (
              <>
                <SubHeading>
                  {getApplicantName(ind + 1)} Contributor
                  {ind !== 0 && ' ' + ind} Document Details
                </SubHeading>
                {ENABLE_KRA_DOCUMENT_FETCH && USER_ROLES.INVESTOR !== role && (
                  <KraDocument KraDocument={applicant.kraDocument} />
                )}
                {documents.map((document, doc_index) => {
                  const {
                    documentType,
                    documentName,
                    documentsList = [],
                    // eslint-disable-next-line
                    multipleFiles,
                    options,
                    required,
                    applicantId,
                  } = document;
                  return (
                    <React.Fragment key={ind}>
                      <Grid item xs={12} sm={6}>
                        {options &&
                          documentsList
                            .filter(
                              (document, index) =>
                                documentsList.findIndex(
                                  (obj) => document.documentType === obj.documentType
                                ) === index
                            )
                            .map((doc, index) => (
                              <React.Fragment key={index}>
                                <Grid xs={12} sm={12}>
                                  <MFSelectField
                                    name={`applicants.${ind}.documents.${doc_index}.documentsList.${index}.documentName`}
                                    label={getLabel(
                                      documentName,
                                      documentType,
                                      required,
                                      values,
                                      ind,
                                      applicantId,
                                      application?.applicants[ind].wishToContribute
                                    )}
                                    items={options.map((item: string) => ({
                                      key: item,
                                      value: item,
                                    }))}
                                    disabled={
                                      isFieldDisabled || application?.topupInitiated || false
                                    }
                                    applyLabelStyles={true}
                                    onChange={({
                                      target: { value },
                                    }: SelectChangeEvent<unknown>) => {
                                      const updatedDocumentList = values.applicants[ind].documents[
                                        doc_index
                                      ].documentsList.map((docList) => {
                                        if (docList.documentType === doc.documentType) {
                                          return {
                                            ...docList,
                                            documentName: value,
                                          };
                                        }
                                        return docList;
                                      });
                                      setFieldValue(
                                        `applicants.${ind}.documents.${doc_index}.documentsList`,
                                        updatedDocumentList
                                      );
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ))}
                        {!options && (
                          <Typography
                            sx={{
                              color: 'primary.main',
                              fontWeight: 500,
                              mt: documentType === 'investorSignature' ? 4 : 6,
                            }}>
                            {documentName} {required === 'true' ? '*' : ''}
                            {documentType === 'investorSignature' && (
                              <Typography
                                sx={{
                                  color: 'primary.main',
                                  fontWeight: 400,
                                  fontSize: 14,
                                }}>
                                (This signature copy is used as a verification process for future
                                redemptions and transfer requests)
                              </Typography>
                            )}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        display="flex"
                        flexDirection="column"
                        mt={options ? { xs: 1, sm: 0 } : 0}>
                        {documentsList
                          .filter((item) => item.isVisible)
                          .map((doc, index) => (
                            <React.Fragment key={doc.uniqueKey}>
                              <FileUpload
                                onFileChange={(value, documentId, removeFile) => {
                                  const { file, ...rest } =
                                    values.applicants[ind].documents[doc_index].documentsList[
                                      Number(doc.uniqueKey.split('-')[2])
                                    ];
                                  setFieldValue(
                                    `applicants.${ind}.documents.${doc_index}.documentsList.${
                                      doc.uniqueKey.split('-')[2]
                                    }`,
                                    {
                                      ...rest,
                                      documentId,
                                      ...(!removeFile && { file }),
                                    }
                                  );
                                }}
                                name={`applicants.${ind}.documents.${doc_index}.documentsList.${
                                  doc.uniqueKey.split('-')[2]
                                }.documentId`}
                                documentData={doc}
                                disabled={isFieldDisabled || application?.topupInitiated || false}
                                onFileUpload={handleUpload}
                                onFileUploaded={handleUploaded}
                              />
                              {index > 0 && doc.isVisible && !doc.documentId && (
                                <DeleteOutlineIcon
                                  onClick={() => {
                                    const getDocs = values.applicants[ind].documents[
                                      doc_index
                                    ].documentsList
                                      // .filter((_, i) => i !== index)
                                      .map((_item, i) => ({
                                        ..._item,
                                        isVisible:
                                          i !== Number(doc.uniqueKey.split('-')[2])
                                            ? _item.isVisible
                                            : false,
                                      }));
                                    setFieldValue(
                                      `applicants.${ind}.documents.${doc_index}.documentsList`,
                                      getDocs
                                    );
                                  }}
                                  color="error"
                                  sx={{
                                    alignSelf: 'end',
                                    position: 'relative',
                                    bottom: '90px',
                                    left: '22px',
                                    cursor: 'pointer',
                                  }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                        {multipleFiles === 'true' &&
                          !isFieldDisabled &&
                          !application?.topupInitiated &&
                          documentsList.filter((item) => item.isVisible).length <
                            (documentType === 'additional' ? 3 : 2) && (
                            <Typography
                              onClick={() => {
                                const currentDocTypeListLength =
                                  values.applicants[ind].documents[doc_index].documentsList.length;
                                const {
                                  required = true,
                                  documentType,
                                  documentName,
                                  // eslint-disable-next-line
                                  documentId,
                                } = values.applicants[ind].documents[doc_index].documentsList[
                                  currentDocTypeListLength - 1
                                ];
                                try {
                                  documentsList
                                    .filter((item) => item.isVisible)
                                    .forEach((item, _i) => {
                                      if (!item.documentId) {
                                        throw Error(
                                          `Please upload the ${getDocNumberForMultipleDocs(
                                            _i + 1
                                          )}  document to add more documents`
                                        );
                                      }
                                    });
                                  setFieldValue(
                                    `applicants.${ind}.documents.${doc_index}.documentsList`,
                                    [
                                      ...values.applicants[ind].documents[doc_index].documentsList,
                                      {
                                        ...document_object,
                                        isVisible: true,
                                        uniqueKey:
                                          ind.toString() +
                                          '-' +
                                          doc_index.toString() +
                                          '-' +
                                          currentDocTypeListLength.toString(),
                                        documentType,
                                        documentName,
                                        required,
                                      },
                                    ]
                                  );
                                } catch (e) {
                                  dispatch(showError((e as Error).message));
                                }
                              }}
                              sx={{
                                color: '#417850',
                                fontSize: '12px',
                                fontWeight: 500,
                                textDecoration: 'underline',
                                alignSelf: 'end',
                                cursor: 'pointer',
                              }}>
                              {'Add another document'}
                            </Typography>
                          )}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </>
            );
          })}
          {values.nominees.map((nominee, ind) => {
            const { nomineedocuments } = nominee;
            return nomineedocuments?.length > 0 ? (
              <>
                {' '}
                <SubHeading>
                  {['First', 'Second', 'Third'][ind]} Nominee Document Details
                </SubHeading>
                {nomineedocuments.map((document, doc_index) => {
                  const {
                    documentType,
                    documentName,
                    documentsList = [],
                    // eslint-disable-next-line
                    multipleFiles,
                    options,
                    required,
                  } = document;
                  return (
                    <React.Fragment key={ind}>
                      <Grid item xs={12} sm={6}>
                        {options &&
                          documentsList
                            .filter(
                              (document, index) =>
                                documentsList.findIndex(
                                  (obj) => document.documentType === obj.documentType
                                ) === index
                            )
                            .filter((item) => item.isVisible)
                            .map((doc, index) => (
                              <React.Fragment key={index}>
                                <Grid xs={12} sm={12}>
                                  <MFSelectField
                                    name={`nominees.${ind}.nomineedocuments.${doc_index}.documentsList.${index}.documentName`}
                                    label={getLabel(
                                      documentName,
                                      documentType,
                                      required,
                                      values,
                                      ind
                                    )}
                                    items={options.map((item: string) => ({
                                      key: item,
                                      value: item,
                                    }))}
                                    disabled={
                                      isFieldDisabled || application?.topupInitiated || false
                                    }
                                    applyLabelStyles={true}
                                    onChange={({
                                      target: { value },
                                    }: SelectChangeEvent<unknown>) => {
                                      const updatedDocumentList = values.nominees[
                                        ind
                                      ].nomineedocuments[doc_index].documentsList.map((docList) => {
                                        if (docList.documentType === doc.documentType) {
                                          return {
                                            ...docList,
                                            documentName: value,
                                          };
                                        }
                                        return docList;
                                      });
                                      setFieldValue(
                                        `nominees.${ind}.nomineedocuments.${doc_index}.documentsList`,
                                        updatedDocumentList
                                      );
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ))}
                        {!options && (
                          <Typography
                            sx={{
                              color: 'primary.main',
                              fontWeight: 500,
                              mt: documentType === 'investorSignature' ? 4 : 6,
                            }}>
                            {documentName} {required === 'true' ? '*' : ''}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        display="flex"
                        flexDirection="column"
                        mt={options ? { xs: 1, sm: 0 } : 0}>
                        {documentsList.map((doc, index) => (
                          <React.Fragment key={doc.uniqueKey}>
                            <FileUpload
                              onFileChange={(value, documentId, removeFile) => {
                                const { file, ...rest } =
                                  values.nominees[ind].nomineedocuments[doc_index].documentsList[
                                    index
                                  ];
                                setFieldValue(
                                  `nominees.${ind}.nomineedocuments.${doc_index}.documentsList.${index}`,
                                  {
                                    ...rest,
                                    documentId,
                                    ...(!removeFile && { file }),
                                  }
                                );
                              }}
                              name={`nominees.${ind}.nomineedocuments.${doc_index}.documentsList.${index}.documentId`}
                              documentData={doc}
                              disabled={isFieldDisabled || application?.topupInitiated || false}
                              onFileUpload={handleUpload}
                              onFileUploaded={handleUploaded}
                            />
                            {index > 0 && !doc.documentId && (
                              <DeleteOutlineIcon
                                onClick={() => {
                                  const getDocs = values.nominees[ind].nomineedocuments[
                                    doc_index
                                  ].documentsList.filter((_, i) => i !== index);
                                  setFieldValue(
                                    `nominees.${ind}.nomineedocuments.${doc_index}.documentsList`,
                                    getDocs
                                  );
                                }}
                                color="error"
                                sx={{
                                  alignSelf: 'end',
                                  position: 'relative',
                                  bottom: '90px',
                                  left: '22px',
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                        {multipleFiles === 'true' &&
                          !isFieldDisabled &&
                          !application?.topupInitiated &&
                          documentsList.filter((item) => item.isVisible).length < 2 && (
                            <Typography
                              onClick={() => {
                                const currentDocTypeListLength =
                                  values.nominees[ind].nomineedocuments[doc_index].documentsList
                                    .length;
                                const {
                                  required = true,
                                  documentType,
                                  documentName,
                                  documentId,
                                } = values.nominees[ind].nomineedocuments[doc_index].documentsList[
                                  currentDocTypeListLength - 1
                                ];
                                try {
                                  if (!documentId) {
                                    throw Error(
                                      'Please upload the first document to add more documents'
                                    );
                                  }
                                  setFieldValue(
                                    `nominees.${ind}.nomineedocuments.${doc_index}.documentsList`,
                                    [
                                      ...values.nominees[ind].nomineedocuments[doc_index]
                                        .documentsList,
                                      {
                                        ...document_object,
                                        uniqueKey:
                                          ind.toString() +
                                          '-' +
                                          doc_index.toString() +
                                          '-' +
                                          currentDocTypeListLength.toString(),
                                        documentType,
                                        documentName,
                                        required,
                                      },
                                    ]
                                  );
                                } catch (e) {
                                  dispatch(showError((e as Error).message));
                                }
                              }}
                              sx={{
                                color: '#417850',
                                fontSize: '12px',
                                fontWeight: 500,
                                textDecoration: 'underline',
                                alignSelf: 'end',
                                cursor: 'pointer',
                              }}>
                              {'Add another document'}
                            </Typography>
                          )}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <></>
            );
          })}
          <FieldValidationNote finalPage={true} />

          <ProceedSaveLater
            saveButtonText={
              isFieldDisabled || USER_ROLES.AMCAPPROVER === role ? 'Save Application' : 'Preview'
            }
            showEndIcon={false}
            saveLater={() => {
              setFieldValue('saveType', 'save for later');
            }}
            loader={loading}
            clickedButton={values.saveType}
            showSaveForLater={
              isFieldDisabled ? false : USER_ROLES.AMCAPPROVER === role ? false : true
            }
            disabled={documentUpload}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
          />
        </Grid>
      )}
    </Formik>
  );
}
