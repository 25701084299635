import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Applicant, Bank } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { SubHeading } from '../investors/components';
import { getBankAddress } from '../../utils/utilityFunctions';

export default function BankDetails({
  banks = [],
  applicants = [],
}: {
  banks: Partial<Bank>[];
  applicants: Partial<Applicant>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Contributor&apos;s Bank Details
            </Typography>
            {banks.length === 0 ? (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No Bank Details
              </Grid>
            ) : (
              banks
                .sort((bank1, bank2) => {
                  return Number(bank1.applicantId) - Number(bank2.applicantId);
                })
                .map((bank, index: number) => {
                  const title = [
                    'Primary Contributor Details',
                    'Joint Contributor 1 Details',
                    'Joint Contributor 2 Details',
                  ][index];
                  const bankAddress = getBankAddress(bank.address1, bank.address2, bank.address3);
                  const mappedApplicantName = applicants?.find(
                    (applicant) => Number(applicant.id) === Number(bank.applicantId)
                  )?.name;
                  const displayBankDetails = applicants?.find(
                    (applicant) => Number(applicant.id) === Number(bank.applicantId)
                  )?.wishToContribute;
                  return (
                    <React.Fragment key={bank.id}>
                      <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                      <Gridstyles>
                        <Grid
                          item
                          container
                          pt={2}
                          pb={5}
                          rowSpacing={6}
                          columnSpacing={13}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start">
                          <KeyValue title={'Contributor Name'} description={mappedApplicantName} />
                          {!displayBankDetails && (
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              {/* <Checkbox defaultChecked disabled={true} /> */}
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  letterSpacing: '0.5px',
                                }}>
                                {`I do not wish to contribute`}
                              </Typography>
                            </Grid>
                          )}
                          {displayBankDetails && (
                            <>
                              <KeyValue title={'IFSC Code'} description={bank.ifscCode} />
                              <KeyValue
                                title={'Bank Account Number'}
                                description={bank.bankAccountNumber}
                              />
                              <KeyValue title={'MICR Code'} description={bank.micrCode} />
                              <KeyValue title={'Bank Type'} description={bank.bankAccountType} />
                              <KeyValue title={'Bank Name'} description={bank.bankName} />
                              <KeyValue title={'Branch Name'} description={bank.bankBranch} />
                              <KeyValue
                                title={`Bank Address`}
                                description={`${bankAddress}`}
                                sx={{
                                  textTransform: 'capitalize',
                                }}
                              />
                              <KeyValue
                                title={`Default Bank Account`}
                                description={bank.defaultBankAccount ? 'Yes' : '-'}
                              />
                              <KeyValue
                                title={`Penny Drop Verified`}
                                description={
                                  bank.pennydropCheck
                                    ? bank.pennydropVerified
                                      ? 'Yes'
                                      : 'No'
                                    : '-'
                                }
                              />
                            </>
                          )}
                        </Grid>
                      </Gridstyles>
                    </React.Fragment>
                  );
                })
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
