import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Gridstyles } from '../Investments/investor-application';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { styles } from '../../Styles/styles';
import { POAAuthorizedSignatry } from '../NonIndividualApplicationDetails/AuthorisedSignatories';
import {
  checkIfApplicationIsIndividualPOA,
  checkValidationBasedOnDate,
  getOlderDate,
} from '../../utils/utilityFunctions';
import {
  POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE,
  poaValidationDate,
} from '../../utils/constant';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';

export default function DistributorDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { auth } = useSelector((store: RootStateType) => store);
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Direct/Distributor Details
              </Typography>
            </Grid>

            <Gridstyles>
              <Grid
                item
                container
                pt={1}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={'Distributor ARN Code'}
                  description={application?.distributor?.arnCode || 'ARN-DIRECT'}
                />
                <KeyValue
                  title={'Distributor Name'}
                  description={application?.distributor?.name || 'DIRECT'}
                />
                <KeyValue
                  title={'Distributor RM Email Id'}
                  description={
                    application?.subDistributor?.user?.email ||
                    application?.distributor?.user?.email
                  }
                />
                <KeyValue title={'3PIM Email Id'} description={'services@3pim.in'} />
              </Grid>
            </Gridstyles>
            {application?.rmDetails?.length > 0 && (
              <>
                <SubHeading
                  sx={{
                    color: 'common.black',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                  }}>
                  Additional RM Details
                </SubHeading>
                <Box sx={{ width: '100%' }}>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pt={1}
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {application?.rmDetails?.map((item, i) => (
                        <React.Fragment key={i}>
                          <KeyValue
                            title={`Additional RM Name ${item.sno}`}
                            description={item?.name}
                          />
                          <KeyValue
                            title={`Additional RM Email ${item.sno}`}
                            description={item?.email}
                          />
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Gridstyles>
                </Box>
              </>
            )}
          </Grid>
          {application?.poaauthorisedsignatories?.length >= 0 &&
            checkValidationBasedOnDate(
              getOlderDate(application?.createdAt || '', application?.applicationCreatedAt || ''),
              poaValidationDate
            ) &&
            checkIfApplicationIsIndividualPOA(application) &&
            POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE && (
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                  Authorised Signatories
                </Typography>
                {application?.poaauthorisedsignatories?.length === 0 ? (
                  <Grid
                    xs={12}
                    sm={12}
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'common.black',
                      textAlign: 'center',
                      my: 3,
                    }}>
                    No Authorised Signatories
                  </Grid>
                ) : (
                  <POAAuthorizedSignatry />
                )}
              </Grid>
            )}
        </Box>
      </Box>
    </>
  );
}
