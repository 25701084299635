import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import React from 'react';
import { Gridstyles } from './index';
import { NomineeType } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { SubHeading } from '../investors/components';
import { isMinor } from '../../utils/utilityFunctions';
import { styles } from '../../Styles/styles';

export default function NomineeDetails({
  nominees = [],
}: {
  nominees: Partial<NomineeType>[];
}): JSX.Element {
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Nominee Details
            </Typography>
            {nominees.length === 0 ? (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                }}>
                <Grid item xs={12} display="flex" alignItems={'flex-start'} mt={3}>
                  <Checkbox defaultChecked disabled={true} />
                  <Typography
                    sx={{
                      fontSize: 15,
                      fontWeight: 700,
                      color: 'rgba(0, 0, 0, 0.6)',
                      letterSpacing: '0.5px',
                      mt: 1,
                    }}>
                    {`I do not wish to nominate.`}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              nominees.map((nominee, index: number) => {
                const title = [
                  'First Nominee Details',
                  'Second Nominee Details',
                  'Third Nominee Details',
                ][index];
                return (
                  <React.Fragment key={nominee.id}>
                    <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                      {title}
                    </SubHeading>
                    <Gridstyles>
                      <Grid
                        item
                        container
                        pt={2}
                        pb={5}
                        rowSpacing={6}
                        columnSpacing={13}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start">
                        <KeyValue
                          title={`Nominee Name ${index + 1}`}
                          description={nominee.nomineeName}
                        />
                        <KeyValue
                          title={'Nominee RelationShip'}
                          description={nominee.nomineeRelationship}
                        />
                        <KeyValue
                          title={'Date Of Birth'}
                          description={formatShortDate(nominee.dateOfBirth)}
                        />
                        <KeyValue
                          title={'Nominee Percentage'}
                          description={nominee.nomineePercentage}
                        />

                        <KeyValue title={'Nominee PAN'} description={nominee.nomineePan} />

                        {nominee.dateOfBirth && isMinor(nominee.dateOfBirth) && (
                          <>
                            <KeyValue title={'Guardian Name'} description={nominee.guardianName} />
                            <KeyValue
                              title={'Guardian Pan'}
                              description={nominee.nomineeGuardianPan}
                            />
                          </>
                        )}
                      </Grid>
                    </Gridstyles>
                  </React.Fragment>
                );
              })
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
