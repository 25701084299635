import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import { addPlan, fundAccess, getPlanDetail, updatePlan } from '../../redux-store/actions/funds';
import {
  accessType,
  addFundPlanType,
  addPlanType,
  ClassPlanProps,
  topUpPlanType,
} from '../../redux-store/types/funds';
import MFSelectField from '../../lib/formik/SelectField';
import { Location } from 'history';
import { getFundManagersList, getTrustessList } from '../../redux-store/actions/userManagement';
import { FundManager, Trustee } from '../../redux-store/types/api-types';
import { addPlanValidation } from '../../utils/schema';
import { TextDatePicker } from '../../lib/formik/DatePickerWithTextFeild';
import { showError } from '../../redux-store/actions/auth';
import {
  allowOnlyNumbers,
  currencyConversion,
  getArrayLength,
  UpdatedTopUpValues,
} from '../../utils/utilityFunctions';
import { styles } from '../../Styles/styles';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import MFCheckbox from '../../lib/formik/Checkbox';

export const topUpPlanInitialValues: topUpPlanType = {
  isTopupApplicable: false,
  topupSetupFee: 0,
  topupMaxCommitmentAmount: 0,
  topupMinCommitmentAmount: 0,
  topupMinPrimaryCommitmentAmount: 0,
  topupMinJointHolder1CommitmentAmount: 0,
  topupMinJointHolder2CommitmentAmount: 0,
  topupMinContributionPercentage: 0,
  topupManagementFee: 0,
  topupPerformanceFees: '',
  topupHurdleRate: '',
};
const initialValues: addPlanType = {
  // id: '',
  schemeId: 0,
  planCode: '',
  planDescription: '',
  schemeNature: '',
  registrationNumber: '',
  schemeCategory: '',
  country: '',
  currency: '',
  schemeStartDate: `${new Date()}`,
  lockInPeriod: '',
  lockInExtension: '',
  faceValue: 0,
  fundAddress1: '',
  fundmanagerIds: null,
  fundmanagerDetails: { fundmanagerIds: [], fundmanagers: [] },
  trusteeDetails: { trusteeIds: [], trustees: [] },
  trusteeIds: null,
  requiredTrustees: null,
  requiredFundManagers: null,
  fundAddress2: '',
  fundAddress3: '',
  inActive: true,
  isActive: true,
  custodian: '',
  setupFee: 0,
  minCommitmentAmount: null,
  maxCommitmentAmount: 0,
  minContributionPercentage: 0,
  managementFee: 0,
  tcLink: '',
  minPrimaryCommitmentAmount: 0,
  minJointHolder1CommitmentAmount: 0,
  minJointHolder2CommitmentAmount: 0,
  performanceFees: '',
  hurdleRate: '',
  ...topUpPlanInitialValues,
};

export const Plan = ({
  values,
  access,
  fundManager,
  trustee,
}: {
  values: addPlanType | addFundPlanType;
  access: boolean;
  fundManager: FundManager[];
  trustee: Trustee[];
}): JSX.Element => {
  return (
    <Grid container rowSpacing={1} columnSpacing={4} px={2}>
      <SubHeading
        sx={{
          color: 'common.blue',
          letterSpacing: 0.5 + 'px',
          padding: { xs: '10px 10px', sm: '10px 30px' },
          ml: { xs: 3.5 },
        }}>
        {'Plan Details'}
      </SubHeading>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="planCode" label="Plan Code *" placeholder="Enter Plan Code" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="planDescription"
          label="Plan Description *"
          placeholder="Enter Plan Description"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="schemeNature"
          label="Scheme Nature *"
          placeholder="Enter Scheme Nature"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="schemeCategory"
          label="Scheme Category *"
          placeholder="Enter Scheme Category"
        />
      </Grid>
      {/* <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="registrationNumber"
                      label="Registration Number *"
                      placeholder="Enter Registration Number"
                    />
                  </Grid> */}
      <Grid item xs={12} sm={6} md={4}>
        <MultipleSelect
          name="fundmanagerIds"
          label="Investment Manager(s) *"
          items={fundManager.map((item) => ({
            key: String(item.id),
            value: item.name,
          }))}
          renderText="Selected Investment Manager(s)"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFSelectField
          name="requiredFundManagers"
          label="No. of Investment Manager(s) to esign *"
          items={getArrayLength(values?.fundmanagerIds?.length || 1)?.map((thresholdValue) => ({
            key: thresholdValue,
            value: thresholdValue,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MultipleSelect
          name="trusteeIds"
          label="Trustee Name(s) *"
          items={trustee.map((item) => ({
            key: String(item.id),
            value: item.name,
          }))}
          renderText="Selected Trustee(s)"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFSelectField
          name="requiredTrustees"
          label="No. of Trustee(s) to esign *"
          items={getArrayLength(values?.trusteeIds?.length || 1)?.map((thresholdValue) => ({
            key: thresholdValue,
            value: thresholdValue,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="country" label="Country" placeholder="Enter Country" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="currency" label="Currency" placeholder="Enter Currency" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextDatePicker
          label={'Scheme Start Date'}
          inputLabelStyles={{
            transform: 'unset',

            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Scheme Start Date'}
          // maxDate={minDateForContributor()}
          name={`schemeStartDate`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="lockInPeriod"
          label="Lock In Period(In Year)"
          placeholder="Enter Lock In Period(In Year)"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="lockInExtension"
          label="Lock In Extension(In Year)"
          disabled={!access}
          placeholder="Enter Lock In Extension(In Year)"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="faceValue"
          label="Face value"
          placeholder="Enter Face value"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="fundAddress1"
          label="Fund Address 1"
          placeholder="Enter Fund Address 1"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="fundAddress2"
          label="Fund Address 2"
          placeholder="Enter Fund Address 2"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="fundAddress3"
          label="Fund Address 3"
          placeholder="Enter Fund Address 3"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="custodian" label="Custodian" placeholder="Enter Custodian" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="setupFee"
          label="Setup Fee"
          placeholder="Enter Setup Fee"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="minCommitmentAmount"
          label="Total Minimum Commitment Amount *"
          placeholder="Enter Total Minimum Commitment Amount"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
        {values.minCommitmentAmount != 0 && (
          <Typography sx={{ fontSize: 13 }}>
            {currencyConversion(values.minCommitmentAmount)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="minPrimaryCommitmentAmount"
          label="Primary Applicant Minimum Commitment Amount *"
          placeholder="Enter Primary Applicant Minimum Commitment Amount"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
        {values.minPrimaryCommitmentAmount != 0 && (
          <Typography sx={{ fontSize: 13 }}>
            {currencyConversion(values.minPrimaryCommitmentAmount)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="minJointHolder1CommitmentAmount"
          label="Joint Holder1 Minimum Commitment Amount *"
          placeholder="Enter Joint Holder1 Minimum Commitment Amount"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
        {values.minJointHolder1CommitmentAmount != 0 && (
          <Typography sx={{ fontSize: 13 }}>
            {currencyConversion(values.minJointHolder1CommitmentAmount)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="minJointHolder2CommitmentAmount"
          label="Joint Holder2 Minimum Commitment Amount *"
          placeholder="Enter Joint Holder2 Minimum Commitment Amount"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
        {values.minJointHolder2CommitmentAmount != 0 && (
          <Typography sx={{ fontSize: 13 }}>
            {currencyConversion(values.minJointHolder2CommitmentAmount)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="maxCommitmentAmount"
          label="Maximum Commitment Amount *"
          placeholder="Enter Maximum Commitment Amount"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
        {values.maxCommitmentAmount != 0 && (
          <Typography sx={{ fontSize: 13 }}>
            {currencyConversion(values.maxCommitmentAmount)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="minContributionPercentage"
          label="Minimum Contribution Percentage"
          placeholder="Enter Minimum Contribution Percentage"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="managementFee"
          label="Management Fee"
          placeholder="Enter Management Fee"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="tcLink" label={`PPM Link *`} placeholder={`Enter PPM Link`} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="performanceFees"
          label="Performance Fees"
          placeholder="Enter Performance Fees"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="hurdleRate" label="Hurdle Rate" placeholder="Enter Hurdle Rate" />
      </Grid>
    </Grid>
  );
};

export const TopUpPlan = ({ values }: { values: addPlanType | addFundPlanType }): JSX.Element => {
  return (
    <Grid container rowSpacing={1} columnSpacing={4} px={2}>
      <SubHeading>Top Up Section</SubHeading>
      <Grid item xs={12} sm={6} md={4}>
        <MFCheckbox name={`isTopupApplicable`} label="Top Up Applicable" />
      </Grid>
      {values.isTopupApplicable && (
        <>
          {/* <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupSetupFee"
              label="Top UpSetup Fee"
              placeholder="Enter Top Up Setup Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupMinCommitmentAmount"
              label="Top Up Minimum Commitment Amount *"
              placeholder="Enter Top Up Minimum Commitment Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
            {values.topupMinCommitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.topupMinCommitmentAmount)}
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupMinPrimaryCommitmentAmount"
              label="Primary Applicant Top Up Minimum Commitment Amount *"
              placeholder="Enter Primary Applicant Top Up Minimum Commitment Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
            {values.topupMinPrimaryCommitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.topupMinPrimaryCommitmentAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupMinJointHolder1CommitmentAmount"
              label="Joint Holder1 Top UpMinimum Commitment Amount *"
              placeholder="Enter Joint Holder1 Top Up Minimum Commitment Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
            {values.topupMinJointHolder1CommitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.topupMinJointHolder1CommitmentAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupMinJointHolder2CommitmentAmount"
              label="Joint Holder2 Top Up Minimum Commitment Amount *"
              placeholder="Enter Joint Holder2 Top Up Minimum Commitment Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
            {values.topupMinJointHolder2CommitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.topupMinJointHolder2CommitmentAmount)}
              </Typography>
            )}
          </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupMaxCommitmentAmount"
              label="Top Up Maximum Commitment Amount *"
              placeholder="Enter Top Up Maximum Commitment Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
            {values.topupMaxCommitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.topupMaxCommitmentAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupMinContributionPercentage"
              label="Top Up Minimum Contribution Percentage"
              placeholder="Enter Top Up Minimum Contribution Percentage"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupManagementFee"
              label="Top Up Management Fee"
              placeholder="Enter Top Up Management Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupPerformanceFees"
              label="Top Up Performance Fees"
              placeholder="Enter Top Up Performance Fees"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topupHurdleRate"
              label="Top Up Hurdle Rate"
              placeholder="Enter Top Up Hurdle Rate"
            />
          </Grid> */}
        </>
      )}
    </Grid>
  );
};

export default function AddPlan({
  location,
}: {
  location: Location<{ fundId: string; PlanId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fundManager, setFundManager] = useState<FundManager[]>([]);
  const [loading, setLoading] = useState(false);
  const [planInitial, setPlanInitial] = useState(initialValues);
  const [access, setAccess] = useState(false);
  const { fundId, PlanId } = location.state || {};
  const [trustee, setTrustee] = useState<Trustee[]>([]);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const trusteeList = (await dispatch(getTrustessList())) as unknown as Trustee[];
        const fundManagerList = (await dispatch(getFundManagersList())) as unknown as FundManager[];
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        setAccess(fundSetupAccess);
        setFundManager(fundManagerList?.filter((fundManager) => fundManager?.name));
        setTrustee(trusteeList.filter((trustee) => trustee?.name));
        if (PlanId) {
          const GetFundDetail = (await dispatch(
            getPlanDetail(PlanId)
          )) as unknown as ClassPlanProps;
          const {
            planCode,
            schemeId,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage,
            managementFee,
            fundmanagerDetails,
            trusteeDetails,
            requiredTrustees,
            requiredFundManagers,
            tcLink,
            minPrimaryCommitmentAmount,
            minJointHolder1CommitmentAmount,
            minJointHolder2CommitmentAmount,
            performanceFees,
            hurdleRate,
          } = GetFundDetail || {};

          const topupValues = {
            isTopupApplicable: GetFundDetail?.isTopupApplicable || false,
            topupSetupFee: GetFundDetail?.topupSetupFee || 0,
            topupMinCommitmentAmount: GetFundDetail?.topupMinCommitmentAmount || 0,
            topupMinPrimaryCommitmentAmount: GetFundDetail?.topupMinPrimaryCommitmentAmount || 0,
            topupMinJointHolder1CommitmentAmount:
              GetFundDetail?.topupMinJointHolder1CommitmentAmount || 0,
            topupMinJointHolder2CommitmentAmount:
              GetFundDetail?.topupMinJointHolder2CommitmentAmount || 0,
            topupMaxCommitmentAmount: GetFundDetail?.topupMaxCommitmentAmount || 0,
            topupMinContributionPercentage: GetFundDetail?.topupMinContributionPercentage || 0,
            topupManagementFee: GetFundDetail?.topupManagementFee || 0,
            topupPerformanceFees: GetFundDetail?.topupPerformanceFees || '',
            topupHurdleRate: GetFundDetail?.topupHurdleRate || '',
          };

          setPlanInitial({
            ...planInitial,
            planCode,
            schemeId,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage,
            managementFee,
            fundmanagerIds: fundmanagerDetails.fundmanagerIds || planInitial.fundmanagerIds,
            trusteeIds: trusteeDetails.trusteeIds || planInitial.trusteeIds,
            requiredTrustees: requiredTrustees || planInitial.requiredTrustees,
            requiredFundManagers: requiredFundManagers || planInitial.requiredFundManagers,
            tcLink,
            minPrimaryCommitmentAmount,
            minJointHolder1CommitmentAmount,
            minJointHolder2CommitmentAmount,
            performanceFees,
            hurdleRate,
            ...topupValues,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: addPlanType) => {
    try {
      const {
        planCode,
        schemeId,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee,
        minCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage,
        managementFee,
        fundmanagerIds,
        trusteeIds,
        requiredTrustees,
        requiredFundManagers,
        tcLink,
        minPrimaryCommitmentAmount,
        minJointHolder1CommitmentAmount,
        minJointHolder2CommitmentAmount,
        performanceFees,
        hurdleRate,
      } = values;

      const updatedTopUpValues = UpdatedTopUpValues(values);
      const commitmentAmountMinCheck =
        Number(minPrimaryCommitmentAmount) +
        Number(minJointHolder1CommitmentAmount) +
        Number(minJointHolder2CommitmentAmount);
      if (Number(commitmentAmountMinCheck) > Number(maxCommitmentAmount)) {
        throw 'Combination of Primary Applicant,Joint Holder1 and Joint Holder2 Minimum Commitment Amount should be less than or equal to Maximum Commitment Amount';
      }

      const updatedFundmanagerIds =
        fundmanagerIds?.sort((id1, id2) => Number(id1) - Number(id2))?.map((f) => Number(f)) || [];
      const updatedTrusteeIds =
        trusteeIds?.sort((id1, id2) => Number(id1) - Number(id2))?.map((f) => Number(f)) || [];
      const updatedValues = {
        schemeId: !PlanId ? fundId : schemeId,
        planCode,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        fundmanagerIds: updatedFundmanagerIds,
        trusteeIds: updatedTrusteeIds,
        fundmanagerDetails: {
          fundmanagerIds: updatedFundmanagerIds,
          fundmanagers: fundManager
            ?.sort((fund1, fund2) => Number(fund1?.id) - Number(fund2?.id))
            ?.filter((f) => updatedFundmanagerIds.includes(Number(f.id))),
        },
        trusteeDetails: {
          trusteeIds: updatedTrusteeIds,
          trustees: trustee
            ?.sort((trustee1, trustee2) => Number(trustee1?.id) - Number(trustee2?.id))
            ?.filter((f) => updatedTrusteeIds.includes(Number(f.id))),
        },
        requiredTrustees: Number(requiredTrustees),
        requiredFundManagers: Number(requiredFundManagers),
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee: setupFee ? setupFee : 0,
        minCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
        managementFee: managementFee ? managementFee : 0,
        tcLink,
        minPrimaryCommitmentAmount,
        minJointHolder1CommitmentAmount,
        minJointHolder2CommitmentAmount,
        performanceFees,
        hurdleRate,
        ...updatedTopUpValues,
      };
      if (!PlanId) {
        await dispatch(addPlan(updatedValues));
      } else {
        await dispatch(updatePlan(Number(PlanId), updatedValues));
      }
      history.push(PlanId ? 'plan-details' : 'view-plans', {
        fundId: fundId,
        PlanId: PlanId,
      });
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={planInitial}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addPlanValidation}>
        {({ handleSubmit, values, setValues }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {PlanId ? 'Edit Plan' : 'Add Plan'}
              </Typography>
            </Box>

            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Plan values={values} access={access} fundManager={fundManager} trustee={trustee} />
                <TopUpPlan values={values} />
                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
