import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { bulkApplicationType, bulkFundType } from '../../utils/constant';
import { CommonLayout } from '../commonComponents';
import { Notes } from '../investors/components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fundTemplate, physicalApplicationTemplate, planTemplate } from '../../redux-store/actions';
import { reportDownload } from '../Reports/report-utils';
import BulkUploadFilePond from '../userManagement/bulkuploadFilePond';

export default function createBlukApplications(): JSX.Element {
  const [selectedUser, setSelectedUser] = useState('download');
  const dispatch = useDispatch();
  return (
    <CommonLayout>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 18,
            fontWeight: 500,
            mb: { xs: 2, md: 4 },
            p: { xs: '5px 15px', md: '' },
            bgcolor: '#e5f1f8',
            borderRadius: '10px',
          }}>
          Create Bulk Physical Applications
        </Typography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={10}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '25%' } },
            px: '30px',
            py: '5%',
            bgcolor: 'common.white',
            mt: 1.5,
          }}>
          <Grid sx={{ width: '100%', mb: 4 }}>
            <Notes
              displayContent={'Please upload the template for Physical Application creation'}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Select
              style={{
                width: '100%',
                height: '44px',
                padding: '10px 12px',
                borderRadius: '6px',
                fontSize: '15px',
              }}
              value={selectedUser}
              onChange={async ({ target: { value } }) => {
                try {
                  setSelectedUser(value);
                  switch (value) {
                    case 'physicalApplication': {
                      const res = await dispatch(physicalApplicationTemplate());
                      reportDownload(res as unknown as Blob, 'physical_application.xlsx');
                      return;
                    }
                  }
                } catch (e) {
                  console.error((e as Error).message);
                }
              }}>
              {Object.keys(bulkApplicationType).map((_user) => (
                <MenuItem value={_user} key={_user}>
                  {bulkApplicationType[_user]}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} mt={4}>
            <Typography
              sx={{
                display: 'block',
                padding: '0 0 5px',
                alignSelf: { xs: 'center' },
              }}>
              Bulk Upload
            </Typography>
            <Box>
              <BulkUploadFilePond bulkTemplateType={'physicalApplication'} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </CommonLayout>
  );
}
