import { Grid, Typography } from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { Notes, ProceedSaveLater, SubHeading } from '../investors/components';
import { MFTextField } from '../../lib/formik';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { Applicant, Broker, BrokerList } from '../../redux-store/types/api-types';
import {
  dateForDematDropdown,
  folioTypeMaster,
  grossAnnualMasters,
  investorTypeMasters,
  mandatorytext,
  PEPsMasters,
  yesAndNoMasters1,
  SAVE_LATER_VALIDATION_CHECK,
} from '../../utils/constant';
import { DatePicker } from '../../lib/formik/DatePicker';
import React, { useEffect, useState } from 'react';
import {
  applicationComparison,
  checkValidationBasedOnDate,
  clearclIdField,
  cleardpIdField,
  currencyConversion,
  getStep,
  removeSingleQuote,
  saveForLater,
  nameOfBrokerExists,
  otherNameOfBroker,
  getOlderDate,
} from '../../utils/utilityFunctions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { getBrokers, updateApplication } from '../../redux-store/actions/application';
import { NonIndividualContributorValidationSchema } from '../../utils/schema';
import { useSnackbar } from 'notistack';
import { TextDatePicker } from '../../lib/formik/DatePickerWithTextFeild';
import { DematDetails } from '../investors/additionalKYCDetails';
import UseRadioGroup from '../../lib/formik/Radio';
import { useMdmsBrokerListData } from '../../utils/useDataMdms';
import FieldValidationNote from '../investors/FieldValidationNote';

const contributorObject: Partial<Applicant & { otherNameOfBroker: string }> = {
  name: '',
  cityOfIncorporation: '',
  ckycNo: '',
  investorType: '',
  dateOfBirth: '',
  panNo: '',
  grossAnnualIncome: '',
  netWorth: '',
  netWorthDate: '',
  politicallyExposedPersonStatus: '',
  leiNumber: '',
  leiExpiry: null,
  physicalCommunication: 'no',
  dlclId: '',
  dpId: '',
  clId: '',
  nameOfBroker: '',
  otherNameOfBroker: '',
};

export type Values = {
  saveType: string;
  modeOfHolding: string;
  // folioType: string;
  applicants: Partial<Applicant & { otherNameOfBroker: string }>[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  // folioType: 'normalFolio',
  applicants: [contributorObject],
  saveType: 'save and proceed',
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [brokerList, setBrokerList] = useState<BrokerList>();
  const { BrokerList } = useMdmsBrokerListData();

  useEffect(() => {
    (async function () {
      try {
        setBrokerList(BrokerList as BrokerList);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [BrokerList]);

  useEffect(() => {
    (async function () {
      try {
        const { applicants = [], createdAt = '', applicationCreatedAt = '' } = application || {};
        setContributorValues({
          ...contributorValues,
          applicants: applicants.length
            ? applicants.map((applicant) => {
                return {
                  ...applicant,
                  dlclId: applicant.dlclId
                    ? applicant.dlclId
                    : checkValidationBasedOnDate(
                        getOlderDate(createdAt, applicationCreatedAt),
                        dateForDematDropdown
                      )
                    ? ''
                    : 'none',
                  nameOfBroker: nameOfBrokerExists(
                    brokerList?.broker_list as Broker[],
                    applicant.nameOfBroker || ''
                  )
                    ? applicant.nameOfBroker
                    : applicant.nameOfBroker
                    ? 'others'
                    : '',
                  otherNameOfBroker: nameOfBrokerExists(
                    brokerList?.broker_list as Broker[],
                    applicant.nameOfBroker || ''
                  )
                    ? ''
                    : applicant.nameOfBroker,
                  investorType: applicant.investorType || '',
                };
              })
            : [contributorObject],
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application, BrokerList]);

  const handleApplicant = async (values: Values) => {
    const { saveType, applicants = [] } = values;
    const {
      id,
      currentStep,
      banks = [],
      applicant1ReferenceId = '',
      applicationNumber,
      topupApplicationNumber,
    } = application || {};
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
          }))
          ?.sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        // folioType,
        banks:
          applicants[0].name?.split("'").join('').trim() ===
          application?.applicants[0].name?.split("'").join('').trim()
            ? banks
            : banks.map((bank) => ({
                ...bank,
                pennydropCheck: false,
                pennydropVerified: false,
              })),
        applicants: applicants
          .map((applicant) => {
            const { otherNameOfBroker: othersNameOfBroker, ...rest } = applicant;
            return {
              ...application?.applicants[0],
              ...rest,
              name: removeSingleQuote(applicant.name),
              cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
              // eslint-disable-next-line
              amlCheck: (applicant.amlCheck as any) === 'Yes' ? true : false,
              dpId: cleardpIdField(applicant.dlclId || '') ? '' : applicant?.dpId?.toUpperCase(),
              nameOfBroker: cleardpIdField(applicant.dlclId || '')
                ? ''
                : otherNameOfBroker(applicant.nameOfBroker || '')
                ? othersNameOfBroker?.toString()
                : applicant.nameOfBroker,
            };
          })
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        modeOfHolding: 'single',
        currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
      }
    );

    const isSaveLater = saveType !== 'save and proceed';
    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            ...application,
            banks:
              applicants[0].name?.split("'").join('').trim() ===
              application?.applicants[0].name?.split("'").join('').trim()
                ? banks
                : banks.map((bank) => ({
                    ...bank,
                    pennydropCheck: false,
                    pennydropVerified: false,
                  })),
            // folioType,
            applicants: applicants.map((applicant) => {
              const { otherNameOfBroker: othersNameOfBroker, ...rest } = applicant;
              return {
                ...application?.applicants[0],
                ...rest,
                name: removeSingleQuote(applicant.name),
                cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
                netWorthDate: applicant.netWorthDate || '',
                clId: clearclIdField(applicant.dlclId || '') ? '' : applicant.clId,
                dpId: cleardpIdField(applicant.dlclId || '') ? '' : applicant?.dpId?.toUpperCase(),
                nameOfBroker: cleardpIdField(applicant.dlclId || '')
                  ? ''
                  : otherNameOfBroker(applicant.nameOfBroker || '')
                  ? othersNameOfBroker?.toString()
                  : applicant.nameOfBroker,
              };
            }),
            modeOfHolding: 'single',
            currentStep: getStep(2, isSaveLater),
            ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
      saveType === 'save and proceed'
        ? history.push('contact-details', {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    } else if (checkApplication) {
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(
          `Application ${
            application?.topupInitiated ? topupApplicationNumber : applicationNumber
          } - ` + ' Saved successfully',
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      }
      saveType === 'save and proceed'
        ? history.push('contact-details', {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      await handleApplicant(values);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={contributorValues}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            NonIndividualContributorValidationSchema(
              brokerList?.broker_list as Broker[],
              getOlderDate(application?.createdAt || '', application?.applicationCreatedAt || '')
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Notes displayContent={mandatorytext} />
            {/* <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
              <UseRadioGroup
                formLabel="Folio Type *"
                name="folioType"
                defaultValue="normalFolio"
                items={Object.keys(folioTypeMaster).map((source) => ({
                  label: folioTypeMaster[source],
                  value: source,
                }))}
              />
            </Grid> */}
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.name`}
                    label="Name of Entity *"
                    placeholder="Enter Name of Entity"
                    disabled={
                      applicant.dataFetchedFromKRA || false || !!application?.topupInitiated
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextDatePicker
                    label={'Date of Registration/Incorporation *'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'DD/MM/YYYY'}
                    name={`applicants.${index}.dateOfBirth`}
                    disabled={
                      applicant.dataFetchedFromKRA || false || !!application?.topupInitiated
                    }
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.cityOfIncorporation`}
                    label="Place of Registration/Incorporation *"
                    placeholder="Enter Place of Registration/Incorporation"
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.ckycNo`}
                    label="CKYC No./CKYC Acknowledge No./KIN No "
                    placeholder="Enter CKYC No./CKYC Acknowledge No./KIN No"
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.investorType`}
                    label="Investor Type *"
                    items={Object.keys(investorTypeMasters).map((investor) => ({
                      key: investorTypeMasters[investor],
                      value: investor,
                    }))}
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.panNo`}
                    label="PAN *"
                    placeholder="Enter PAN"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.leiNumber`}
                    label="LEI Number"
                    placeholder={'Enter LEI number'}
                  />
                  <Notes displayContent="Note: Mandatory for Non-Individuals transacting above the threshold level." />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextDatePicker
                    label={'LEI Expiry Date'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'Enter LEI Expiry Date'}
                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 120))}
                    minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                    name={`applicants.${index}.leiExpiry`}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  />
                </Grid>
                <Grid item xs={12} sm={6} mt={2}>
                  <UseRadioGroup
                    formLabel="Physical Communication *"
                    name={`applicants.${index}.physicalCommunication`}
                    items={Object.keys(yesAndNoMasters1).map((item) => ({
                      label: yesAndNoMasters1[item],
                      value: item,
                    }))}
                  />
                </Grid>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Other Details
                </SubHeading>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.grossAnnualIncome`}
                    label="Gross Annual Income *"
                    items={grossAnnualMasters.map((grossIncome) => ({
                      key: grossIncome,
                      value: grossIncome,
                    }))}
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.netWorth`}
                    label="Net Worth *"
                    placeholder="Enter Net Worth"
                    disabled={!!application?.topupInitiated}
                  />
                  {values.applicants[index].netWorth &&
                    !isNaN(Number(values.applicants[index].netWorth)) && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.applicants[index].netWorth)}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextDatePicker
                    label={'Net worth as on *'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'Enter Net worth as on'}
                    name={`applicants.${index}.netWorthDate`}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.politicallyExposedPersonStatus`}
                    label="Politically Exposed Person (PEP) Status"
                    items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
                    disabled={!!application?.topupInitiated}
                  />
                </Grid> */}
                <DematDetails
                  values={values}
                  index={index}
                  brokers={brokerList?.broker_list as Broker[]}
                  disabled={false}
                />
              </React.Fragment>
            ))}
            <FieldValidationNote />
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
        </>
      )}
    </Formik>
  );
}
