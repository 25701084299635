import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import { getFundManagersList, getTrustessList } from '../../redux-store/actions/userManagement';
import { FundManager, Trustee } from '../../redux-store/types/api-types';
import {
  addFund,
  addPlan,
  fundAccess,
  getPlanList,
  updateFund,
} from '../../redux-store/actions/funds';
import { Location } from 'history';
import {
  accessType,
  addFundPlanType,
  ClassPlanProps,
  FundProps,
} from '../../redux-store/types/funds';
import { addFundValidation, editFundValidation } from '../../utils/schema';
import { allowOnlyNumbers, UpdatedTopUpValues } from '../../utils/utilityFunctions';
import { showError } from '../../redux-store/actions/auth';
import {
  // eslint-disable-next-line
  IS_STAMP_PAPER_SERIES_MASTER,
} from '../../utils/constant';
// eslint-disable-next-line
import UseRadioGroup from '../../lib/formik/Radio';
import { styles } from '../../Styles/styles';
import { getDistributorsListForFilters } from '../../redux-store/actions';
import { Plan, topUpPlanInitialValues, TopUpPlan } from './addPlan';

const initialValues: addFundPlanType = {
  schemeCode: '',
  schemeName: '',
  amcCode: '',
  bankName: '',
  bankBranch: '',
  ifscCode: '',
  bankAccountNumber: '',
  id: '',
  schemeId: null,
  planCode: '',
  planDescription: '',
  schemeNature: '',
  registrationNumber: '',
  schemeCategory: '',
  country: '',
  currency: '',
  schemeStartDate: `${new Date()}`,
  lockInPeriod: '',
  lockInExtension: '',
  inActive: true,
  faceValue: 0,
  fundAddress1: '',
  fundAddress2: '',
  fundAddress3: '',
  isActive: true,
  custodian: '',
  setupFee: 0,
  fundmanagerIds: null,
  trusteeIds: null,
  requiredTrustees: null,
  requiredFundManagers: null,
  minCommitmentAmount: null,
  maxCommitmentAmount: 0,
  minContributionPercentage: 0,
  managementFee: 0,
  tcLink: '',
  minPrimaryCommitmentAmount: 0,
  minJointHolder1CommitmentAmount: 0,
  minJointHolder2CommitmentAmount: 0,
  performanceFees: '',
  hurdleRate: '',
  LEEGALITY_STAMP_SERIES: '',
  LEEGALITY_STAMP_SERIES_GROUP: '',
  LEEGALITY_STAMP_VALUE: null,
  LEEGALITY_USE_STAMP_SERIES: null,
  fundmanagerDetails: { fundmanagerIds: [], fundmanagers: [] },
  trusteeDetails: { trusteeIds: [], trustees: [] },
  ...topUpPlanInitialValues,
};

export default function AddFund({
  location,
}: {
  location: Location<{ fundId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fundManager, setFundManager] = useState<FundManager[]>([]);
  const [editField, setEditField] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { fundId } = location.state || {};
  const [access, setAccess] = useState(false);
  const [trustee, setTrustee] = useState<Trustee[]>([]);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const trusteeList = (await dispatch(
          getTrustessList({ isActive: true })
        )) as unknown as Trustee[];
        const fundManagerList = (await dispatch(
          getFundManagersList({ isActive: true })
        )) as unknown as FundManager[];
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        setAccess(fundSetupAccess);
        setFundManager(fundManagerList);
        setTrustee(trusteeList);

        if (fundId) {
          const GetFundDetail = (await dispatch(
            getPlanList(fundId.toString())
          )) as unknown as FundProps;
          const {
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            LEEGALITY_STAMP_SERIES = '',
            LEEGALITY_STAMP_SERIES_GROUP = '',
            LEEGALITY_STAMP_VALUE = null,
            // LEEGALITY_USE_STAMP_SERIES = null,
          } = GetFundDetail || {};

          setEditField({
            ...editField,
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            LEEGALITY_STAMP_SERIES,
            LEEGALITY_STAMP_SERIES_GROUP,
            LEEGALITY_STAMP_VALUE,
            // LEEGALITY_USE_STAMP_SERIES: LEEGALITY_USE_STAMP_SERIES || false,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: addFundPlanType) => {
    try {
      const {
        schemeCode,
        schemeName,
        amcCode,
        bankName,
        bankBranch,
        ifscCode,
        bankAccountNumber,
        LEEGALITY_STAMP_SERIES = '',
        LEEGALITY_STAMP_SERIES_GROUP = '',
        LEEGALITY_STAMP_VALUE = null,
        // LEEGALITY_USE_STAMP_SERIES = null,
      } = values;

      const {
        planCode,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee,
        minCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage,
        managementFee,
        fundmanagerIds,
        trusteeIds,
        requiredTrustees,
        requiredFundManagers,
        tcLink,
        minPrimaryCommitmentAmount,
        minJointHolder1CommitmentAmount,
        minJointHolder2CommitmentAmount,
        performanceFees,
        hurdleRate,
      } = values;
      const updatedTopUpValues = UpdatedTopUpValues(values);
      const updatedFundmanagerIds =
        fundmanagerIds?.sort((id1, id2) => Number(id1) - Number(id2))?.map((f) => Number(f)) || [];
      const updatedTrusteeIds =
        trusteeIds?.sort((id1, id2) => Number(id1) - Number(id2))?.map((f) => Number(f)) || [];
      if (fundId) {
        await dispatch(
          updateFund(fundId, {
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            LEEGALITY_STAMP_SERIES,
            LEEGALITY_STAMP_SERIES_GROUP,
            LEEGALITY_STAMP_VALUE,
            LEEGALITY_USE_STAMP_SERIES: false,
            // LEEGALITY_USE_STAMP_SERIES?.toString() === 'true' ? true : false,
          })
        );
      } else {
        const commitmentAmountMinCheck =
          Number(minPrimaryCommitmentAmount) +
          Number(minJointHolder1CommitmentAmount) +
          Number(minJointHolder2CommitmentAmount);
        if (Number(commitmentAmountMinCheck) > Number(maxCommitmentAmount)) {
          throw 'Combination of Primary Applicant,Joint Holder1 and Joint Holder2 Minimum Commitment Amount should be less than or equal to Maximum Commitment Amount';
        }
        const fundRes = (await dispatch(
          addFund({
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            LEEGALITY_STAMP_SERIES,
            LEEGALITY_STAMP_SERIES_GROUP,
            LEEGALITY_STAMP_VALUE,
            LEEGALITY_USE_STAMP_SERIES: false,
            // LEEGALITY_USE_STAMP_SERIES?.toString() === 'true' ? true : false,
          })
        )) as unknown as FundProps;

        await dispatch(
          addPlan({
            schemeId: fundRes.id,
            planCode,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            fundmanagerIds,
            trusteeIds,
            fundmanagerDetails: {
              fundmanagerIds: updatedFundmanagerIds,
              fundmanagers: fundManager
                ?.sort((fund1, fund2) => Number(fund1?.id) - Number(fund2?.id))
                ?.filter((f) => updatedFundmanagerIds.includes(Number(f.id))),
            },
            trusteeDetails: {
              trusteeIds: updatedTrusteeIds,
              trustees: trustee
                ?.sort((trustee1, trustee2) => Number(trustee1?.id) - Number(trustee2?.id))
                ?.filter((f) => updatedTrusteeIds.includes(Number(f.id))),
            },
            requiredFundManagers: Number(requiredFundManagers),
            requiredTrustees: Number(requiredTrustees),
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee: setupFee ? setupFee : 0,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
            managementFee: managementFee ? managementFee : 0,
            tcLink,
            minPrimaryCommitmentAmount,
            minJointHolder1CommitmentAmount,
            minJointHolder2CommitmentAmount,
            performanceFees,
            hurdleRate,
            ...updatedTopUpValues,
          }) as unknown as ClassPlanProps
        );
      }
      history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId });
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={editField}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={fundId ? editFundValidation : addFundValidation}>
        {({ handleSubmit, values, setValues }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {fundId ? 'Edit Fund' : 'Add Fund'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                      // backgroundColor: 'red',
                    }}>
                    {'Fund Details(Scheme)'}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeCode"
                      label="Scheme Code *"
                      placeholder="Enter Scheme Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeName"
                      label="Scheme Name *"
                      placeholder="Enter Scheme Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="amcCode" label="AMC Code" placeholder="Enter AMC Code" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="bankName" label="Bank Name" placeholder="Enter Bank Name" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="bankBranch"
                      label="Bank Branch"
                      placeholder="Enter Bank Branch"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="ifscCode" label="IFSC Code" placeholder="Enter IFSC Code" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="bankAccountNumber"
                      label="Bank Account Number"
                      placeholder="Enter Bank Account Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="LEEGALITY_STAMP_SERIES"
                      label="Stamp Paper Series *"
                      placeholder="Enter Stamp Paper Series"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="LEEGALITY_STAMP_SERIES_GROUP"
                      label="Stamp Paper Group *"
                      placeholder="Enter Stamp Paper Group"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="LEEGALITY_STAMP_VALUE"
                      label="Stamp Paper Value *"
                      placeholder="Enter Stamp Paper Value"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <UseRadioGroup
                      name={`LEEGALITY_USE_STAMP_SERIES`}
                      formLabel="Is Stamp Paper Series *"
                      items={Object.keys(IS_STAMP_PAPER_SERIES_MASTER).map((item) => ({
                        label: IS_STAMP_PAPER_SERIES_MASTER[item],
                        value: item,
                      }))}
                      value={values.LEEGALITY_USE_STAMP_SERIES?.toString()}
                    />
                  </Grid> */}
                </Grid>
                {!fundId && (
                  <>
                    <Plan
                      values={values}
                      access={access}
                      fundManager={fundManager}
                      trustee={trustee}
                    />
                    <TopUpPlan values={values} />
                  </>
                )}
                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
