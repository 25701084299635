import { Grid } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { Applicant } from '../../redux-store/types/api-types';
import {
  addressTypesMasters,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  mandatorytext,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
  yesAndNoMasters,
} from '../../utils/constant';
import {
  applicationComparison,
  applyRoleBasedStatus,
  getApplicantName,
  getStep,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
import { FATCAValidationSchema } from '../../utils/schema';
import { Notes } from './components';
import { useSnackbar } from 'notistack';
import { mdmsCountriesList } from '../../redux-store/types/mdms';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import MFCheckbox from '../../lib/formik/Checkbox';
import { useMdmsCountryData } from '../../utils/useDataMdms';
import FieldValidationNote from './FieldValidationNote';

export type Values = {
  wishToUploadFatcaDocument: boolean;
  applicants: Partial<Applicant>[];
  saveType: string;
  countryDropdown: mdmsCountriesList[];
};

const initialValues: Values = {
  wishToUploadFatcaDocument: false,
  applicants: [
    {
      typeOfAddressProvidedAtKRA: '',
      taxResidentOfAnyCountryOtherThanIndia: false,
      placeOfBirth: '',
      countryOfBirth: 'INDIA',
      countryOfNationality: 'INDIA',
      taxCountryName: '',
      taxID: '',
      idType: '',
      nameOfEntity: '',
      dateOfIncorporation: '',
      cityOfIncorporation: '',
      countryOfIncorporation: '',
      entityExcemptionCode: '',
      residentOfAnyCountryOtherThanIndia: false,
      poaMandateHolderOutsideIndia: false,
      telephoneOutsideIndia: false,
      standingInstructionsTransferFunds: false,
      usPlaceOfBirth: false,
    },
  ],
  saveType: 'save and proceed',
  countryDropdown: [],
};

const FatcaDetails = ({
  applicantName,
  index,
  values,
  nationalitiesMdmsResponse,
  ifTopUp,
}: {
  applicantName: string;
  index: number;
  values: Partial<Applicant>;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  ifTopUp: boolean;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) || ifTopUp;
  return (
    <>
      <SubHeading>
        FATCA Details of {applicantName} Contributor{index !== 0 && ' ' + index}
      </SubHeading>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.typeOfAddressProvidedAtKRA`}
          label="Type of Address Provided At KRA *"
          items={addressTypesMasters.map((address) => ({ key: address, value: address }))}
          disabled={ifTopUp}
        />
      </Grid>
      <Grid item xs={12} sm={6} mt={2}>
        <UseRadioGroup
          formLabel="Tax Resident of any country other than India *"
          name={`applicants.${index}.taxResidentOfAnyCountryOtherThanIndia`}
          items={Object.keys(yesAndNoMasters).map((item) => ({
            label: item,
            value: yesAndNoMasters[item],
          }))}
          value={values.taxResidentOfAnyCountryOtherThanIndia?.toString()}
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
        <UseRadioGroup
          formLabel="Residence/mailing address in a country other than India *"
          name={`applicants.${index}.residentOfAnyCountryOtherThanIndia`}
          items={Object.keys(yesAndNoMasters).map((item) => ({
            label: item,
            value: yesAndNoMasters[item],
          }))}
          value={values.residentOfAnyCountryOtherThanIndia?.toString()}
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
        <UseRadioGroup
          formLabel="POA or a mandate holder who has an address outside India *"
          name={`applicants.${index}.poaMandateHolderOutsideIndia`}
          items={Object.keys(yesAndNoMasters).map((item) => ({
            label: item,
            value: yesAndNoMasters[item],
          }))}
          value={values.poaMandateHolderOutsideIndia?.toString()}
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
        <UseRadioGroup
          formLabel="Telephone number in a country other than India *"
          name={`applicants.${index}.telephoneOutsideIndia`}
          items={Object.keys(yesAndNoMasters).map((item) => ({
            label: item,
            value: yesAndNoMasters[item],
          }))}
          value={values.telephoneOutsideIndia?.toString()}
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
        <UseRadioGroup
          formLabel="Standing instructions to transfer funds to an account maintained in a country other than India (other than depository accounts) *"
          name={`applicants.${index}.standingInstructionsTransferFunds`}
          items={Object.keys(yesAndNoMasters).map((item) => ({
            label: item,
            value: yesAndNoMasters[item],
          }))}
          value={values.standingInstructionsTransferFunds?.toString()}
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
        <UseRadioGroup
          formLabel="US Place of Birth *"
          name={`applicants.${index}.usPlaceOfBirth`}
          items={Object.keys(yesAndNoMasters).map((item) => ({
            label: item,
            value: yesAndNoMasters[item],
          }))}
          value={values.usPlaceOfBirth?.toString()}
          disabled={isFieldDisabled}
        />
      </Grid>
      {/* {applicant?.taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' && ( */}
      <>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.placeOfBirth`}
            label="Place of Birth *"
            placeholder="Enter Place of Birth"
            disabled={ifTopUp}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <MFTextField
            name={`applicants.${index}.countryOfBirth`}
            label="Country of Birth *"
            placeholder="Enter Country of Birth"
            disabled={isFieldDisabled}
          /> */}
          <SearchableSelect
            name={`applicants.${index}.countryOfBirth`}
            label="Country of Birth *"
            items={nationalitiesMdmsResponse.map((nationality) => ({
              key: nationality.name,
              value: nationality.name,
            }))}
            disabled={isFieldDisabled}
            searchFieldPlaceholder={'Search Country'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <MFTextField
            name={`applicants.${index}.countryOfNationality`}
            label="Country of Nationality *"
            placeholder="Enter Country of Nationality"
            disabled={isFieldDisabled}
          /> */}
          <SearchableSelect
            name={`applicants.${index}.countryOfNationality`}
            label="Country of Nationality *"
            items={nationalitiesMdmsResponse.map((nationality) => ({
              key: nationality.name,
              value: nationality.name,
            }))}
            disabled={isFieldDisabled}
            searchFieldPlaceholder={'Search Country'}
          />
        </Grid>
        {values?.taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' && (
          <>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.taxCountryName`}
                label="Tax country Name *"
                placeholder="Enter Tax country Name"
                disabled={isFieldDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.taxID`}
                label="Tax ID *"
                placeholder="Enter Tax ID"
                disabled={isFieldDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.idType`}
                label="ID Type *"
                placeholder="Enter ID Type"
                disabled={isFieldDisabled}
              />
            </Grid>
          </>
        )}
        {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.nameOfEntity`}
              label="Name of Entity"
              placeholder="Enter Name of Entity"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Date of Incorporation"
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              maxDate={minDateForContributor()}
              name={`applicants.${index}.dateOfIncorporation`}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={applicant.dateOfBirth || minDateForContributor().toString()}
              onChange={(newValue) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant, ind) => {
                    if (index === ind) {
                      return {
                        ...applicant,
                        dateOfIncorporation: newValue?.toString(),
                      };
                    }
                    return applicant;
                  }),
                });
              }}
              textFieldStyles={{
                'label + &': {
                  mt: 4,
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #DDEAF3',
                },
                '& .MuiOutlinedInput-root': {
                  position: 'relative',
                  backgroundColor: 'common.white',
                  border: '1px solid #DDEAF3',
                  fontSize: 16,
                  '&:hover': {
                    borderColor: 'primary.main',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  '.MuiInputBase-input': {
                    p: '10px 12px',
                  },
                  '&:focus-visible': {
                    outline: 'none',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.cityOfIncorporation`}
              label="City of Incorporation"
              placeholder="Enter City of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.countryOfIncorporation`}
              label="Country of Incorporation"
              placeholder="Enter Country of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.entityExcemptionCode`}
              label="Entity Exemption Code"
              placeholder="Enter Entity Exemption Code"
            />
          </Grid> */}
      </>
      {/* )} */}
    </>
  );
};

export default function Fatca(): JSX.Element {
  const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { countries } = useMdmsCountryData();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  useEffect(() => {
    (async function () {
      try {
        setMdmsCountriesList(countries.countries);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [countries]);

  useEffect(() => {
    const { applicants = [], wishToUploadFatcaDocument } = application || {};
    (async function () {
      try {
        setFatcaDetails({
          ...fatcaDetails,
          wishToUploadFatcaDocument: wishToUploadFatcaDocument || false,
          applicants: applicants?.map((applicant) => ({
            typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
            taxResidentOfAnyCountryOtherThanIndia:
              applicant.taxResidentOfAnyCountryOtherThanIndia || false,
            residentOfAnyCountryOtherThanIndia:
              applicant.residentOfAnyCountryOtherThanIndia || false,
            poaMandateHolderOutsideIndia: applicant.poaMandateHolderOutsideIndia || false,
            telephoneOutsideIndia: applicant.telephoneOutsideIndia || false,
            standingInstructionsTransferFunds: applicant.standingInstructionsTransferFunds || false,
            usPlaceOfBirth: applicant.usPlaceOfBirth || false,
            placeOfBirth: applicant.placeOfBirth || '',
            countryOfBirth: applicant.countryOfBirth
              ? applicant.countryOfBirth.toUpperCase()
              : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            countryOfNationality: applicant.countryOfNationality
              ? applicant.countryOfNationality.toUpperCase()
              : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            taxCountryName: applicant.taxCountryName || '',
            taxID: applicant.taxID || '',
            idType: applicant.idType || '',
            nameOfEntity: applicant.nameOfEntity || '',
            dateOfIncorporation: applicant.dateOfIncorporation || '',
            cityOfIncorporation: applicant.cityOfIncorporation || '',
            countryOfIncorporation: applicant.countryOfIncorporation || '',
            entityExcemptionCode: applicant.entityExcemptionCode || '',
          })),
          countryDropdown: countries.countries,
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application, countries]);

  const stringToBoolean = (value: any) => {
    return value.toString() === 'false' ? false : true;
  };
  const handleSubmit = async (values: Values) => {
    try {
      const {
        applicants: existingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
        topupApplicationNumber,
      } = application || {};
      const { applicants, saveType, wishToUploadFatcaDocument } = values;
      const updatedPayload = existingApplicants.map((applicant, index: number) => {
        const {
          taxResidentOfAnyCountryOtherThanIndia = false,
          residentOfAnyCountryOtherThanIndia = false,
          poaMandateHolderOutsideIndia = false,
          telephoneOutsideIndia = false,
          standingInstructionsTransferFunds = false,
          usPlaceOfBirth = false,
          // dateOfBirth = '',
          dateOfIncorporation = '',
          ...rest
        } = applicants[index];
        return {
          ...applicant,
          taxResidentOfAnyCountryOtherThanIndia: stringToBoolean(
            taxResidentOfAnyCountryOtherThanIndia
          ),
          residentOfAnyCountryOtherThanIndia: stringToBoolean(residentOfAnyCountryOtherThanIndia),
          poaMandateHolderOutsideIndia: stringToBoolean(poaMandateHolderOutsideIndia),
          telephoneOutsideIndia: stringToBoolean(telephoneOutsideIndia),
          standingInstructionsTransferFunds: stringToBoolean(standingInstructionsTransferFunds),
          usPlaceOfBirth: stringToBoolean(usPlaceOfBirth),
          // dateOfBirth: dateOfBirth ? dateOfBirth : null,
          dateOfIncorporation: dateOfIncorporation ? dateOfIncorporation : null,
          ...rest,
          placeOfBirth: removeSingleQuote(rest.placeOfBirth),
          taxCountryName:
            taxResidentOfAnyCountryOtherThanIndia.toString() === 'false'
              ? ''
              : removeSingleQuote(rest.taxCountryName),
          taxID:
            taxResidentOfAnyCountryOtherThanIndia.toString() === 'false'
              ? ''
              : removeSingleQuote(rest.taxID),
          idType:
            taxResidentOfAnyCountryOtherThanIndia.toString() === 'false'
              ? ''
              : removeSingleQuote(rest.idType),
        };
      });
      const checkApplication = applicationComparison(application, {
        ...application,
        wishToUploadFatcaDocument: wishToUploadFatcaDocument,
        applicants: updatedPayload,
        currentStep: !!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              wishToUploadFatcaDocument: wishToUploadFatcaDocument,
              applicants: updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: getStep(5, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
              //!!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('nominee-details', {
              id,
              applicant1ReferenceId,
              topUpApplication: application?.topupInitiated,
            })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(
            `Application ${
              application?.topupInitiated ? topupApplicationNumber : applicationNumber
            } - ` + ' Saved successfully',
            {
              variant: 'success',
              autoHideDuration: 3000,
            }
          );
        }
        !isSaveLater
          ? history.push('nominee-details', {
              id,
              applicant1ReferenceId,
              topUpApplication: application?.topupInitiated,
            })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={fatcaDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, FATCAValidationSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes
            displayContent={'FATCA Information is mandatory to disclose any Foreign Citizenship'}
            displayContent1={mandatorytext}
          />
          {application?.hasPOA && (
            <Grid item xs={12}>
              <MFCheckbox
                name="wishToUploadFatcaDocument"
                label="Wish to upload FATCA-CRS Declaration"
                sx={{ letterSpacing: '1px' }}
                onChange={({ target: { checked } }) => {
                  setValues({ ...values, wishToUploadFatcaDocument: checked });
                }}
                checked={values.wishToUploadFatcaDocument}
              />
            </Grid>
          )}

          {values.applicants.map((applicant, index) => (
            <FatcaDetails
              applicantName={getApplicantName(index + 1)}
              index={index}
              key={index}
              values={applicant}
              nationalitiesMdmsResponse={mdmsCountriesList}
              ifTopUp={application?.topupInitiated || false}
            />
          ))}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
