import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Gridstyles } from './index';
import { Applicant } from '../../redux-store/types/api-types';
import { styles } from '../../Styles/styles';

export default function FatcaDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  const booleanToYesAndNo = (value: any) => {
    return value === null ? null : value === true ? 'Yes' : 'No';
  };
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              FATCA
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'Primary/Solo Contributor Details',
                'Joint Contributor 1 Details',
                'Joint Contributor 2 Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>

                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Type Of Address Provided At KRA'}
                        description={applicant.typeOfAddressProvidedAtKRA}
                      />
                      <KeyValue
                        title={'Tax Resident Of Any Country Other Than India'}
                        description={booleanToYesAndNo(
                          applicant.taxResidentOfAnyCountryOtherThanIndia
                        )}
                      />
                      <KeyValue
                        title={'Residence/mailing address in a country other than India'}
                        description={booleanToYesAndNo(
                          applicant.residentOfAnyCountryOtherThanIndia
                        )}
                      />
                      <KeyValue
                        title={'POA or a mandate holder who has an address outside India'}
                        description={booleanToYesAndNo(applicant.poaMandateHolderOutsideIndia)}
                      />
                      <KeyValue
                        title={'Telephone number in a country other than India'}
                        description={booleanToYesAndNo(applicant.telephoneOutsideIndia)}
                      />
                      <KeyValue
                        title={
                          'Standing instructions to transfer funds to an account maintained in a country other than India (other than depository accounts)'
                        }
                        description={booleanToYesAndNo(applicant.standingInstructionsTransferFunds)}
                      />
                      <KeyValue
                        title={'US Place of Birth'}
                        description={booleanToYesAndNo(applicant.usPlaceOfBirth)}
                      />
                      <KeyValue title={'Place Of Birth'} description={applicant.placeOfBirth} />
                      <KeyValue title={'Country Of Birth'} description={applicant.countryOfBirth} />
                      <KeyValue
                        title={'Country Of Nationality'}
                        description={applicant.countryOfNationality}
                      />
                      {applicant.taxResidentOfAnyCountryOtherThanIndia === true && (
                        <>
                          <KeyValue
                            title={'Tax Country Name'}
                            description={applicant.taxCountryName}
                          />
                          <KeyValue title={'Tax ID'} description={applicant.taxID} />
                          <KeyValue title={'Id Type'} description={applicant.idType} />
                        </>
                      )}
                      {/* <KeyValue title={'Name Of Entity'} description={applicant.nameOfEntity} />
                      <KeyValue
                        title={'Date Of Incorporation'}
                        description={applicant.dateOfIncorporation?.split(' ')[0]}
                      />
                      <KeyValue
                        title={'City Of Incorporation'}
                        description={applicant.cityOfIncorporation}
                      />
                      <KeyValue
                        title={'Entity Excemption Code'}
                        description={applicant.entityExcemptionCode}
                      /> */}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
