import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { Notes, NotesWithText, ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import { ApplicationProps, JointholderRelationMaster } from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { getFunds } from '../../redux-store/actions/funds';
import { ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { topUpInvestmentPaymentDetailsSchema } from '../../utils/schema';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  mandatorytext,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  currencyConversion,
  DoNotWishToValidateCommitmentAmountRoles,
  getFolioTypeBasedOnPlan,
  getStep,
  saveForLater,
  setUpFeeCalculations,
  sumOfCommitmentAmount,
  updatedMinimumCommitmentAmount,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { jointHolderRelationsList } from '../../redux-store/actions';
import { showError } from '../../redux-store/actions/auth';
import FieldValidationNote from './FieldValidationNote';
import MFCheckbox from '../../lib/formik/Checkbox';

type Values = Partial<ApplicationProps> & {
  saveType: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  aggregateAmount: 0,
  topupAmount: 0,
  setupFeePercentage: 0,
  managementFeesRate: 0,
  topupContributionAmount: 0,
  setupFeeAmount: null,
  setupFeeGST: null,
  totalSetupFee: null,
  setupFeeTDS: null,
  netSetupFeeLessTDS: null,
  totalAmountPayable: null,
};

const initialValues: Values = {
  ...fundInitialValues,
  modeOfPayment: 'cheque',
  paymentRefNo: '',
  chequeDate: '',
  paymentBankAccountNumber: '',
  paymentBankame: '',
  paymentBankAccontType: '',
  saveType: 'save and proceed',
};

const planValidationCheck = (getApplicants: any, planCode?: string) => {
  return getApplicants && planCode && getApplicants.length > 1 && planCode === 'E';
};

export default function InvestmentPaymentDetails(): JSX.Element {
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const getApplicants =
    application?.applicants.map((applicant) => applicant.applicant_type?.toString()) || [];

  const {
    topupMinCommitmentAmount = 0,
    topupMaxCommitmentAmount = 0,
    topupMinContributionPercentage = 0,
    // topupMinPrimaryCommitmentAmount = 10000000,
    // topupMinJointHolder1CommitmentAmount = 10000000,
    // topupMinJointHolder2CommitmentAmount = 10000000,
  } = selectedPlan || {};

  useEffect(() => {
    const { plan } = application || ({} as ApplicationProps);
    const {
      topupMinCommitmentAmount = 1,
      topupMaxCommitmentAmount = 0,
      topupMinContributionPercentage = 1,
      topupMinPrimaryCommitmentAmount = 1,
      topupMinJointHolder1CommitmentAmount = 1,
      topupMinJointHolder2CommitmentAmount = 1,
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            topupMinCommitmentAmount: Number(topupMinCommitmentAmount),
            topupMaxCommitmentAmount: Number(topupMaxCommitmentAmount),
            topupMinContributionPercentage: Number(topupMinContributionPercentage),
            topupMinPrimaryCommitmentAmount: Number(topupMinPrimaryCommitmentAmount),
            topupMinJointHolder1CommitmentAmount: Number(topupMinJointHolder1CommitmentAmount),
            topupMinJointHolder2CommitmentAmount: Number(topupMinJointHolder2CommitmentAmount),
          }
        : null
    );

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount: application?.commitmentAmount || 0,
      setupFeePercentage: application?.setupFeePercentage || 0,
      managementFeesRate: application?.managementFeesRate || 0,
      topupContributionAmount: Number(application?.topupContributionAmount) || 0,
      topupAmount: Number(application?.topupAmount) || 0,
      aggregateAmount:
        application?.aggregateAmount ||
        Number(application?.commitmentAmount) + Number(application?.topupAmount) ||
        0,
      setupFeeAmount: application?.setupFeeAmount,
      setupFeeGST: application?.setupFeeGST,
      totalSetupFee: application?.totalSetupFee,
      setupFeeTDS: application?.setupFeeTDS,
      netSetupFeeLessTDS: application?.netSetupFeeLessTDS,
      totalAmountPayable: application?.totalAmountPayable,
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        applicationNumber,
        topupApplicationNumber,
        status,
        hasPOA,
        folioType,
      } = application || {};
      const { saveType, ...rest } = values;
      const checkApplication = applicationComparison(
        { ...application, setupFeePercentage: Number(application?.setupFeePercentage) },
        {
          ...application,
          ...rest,
          chequeDate: null,
          folioType: getFolioTypeBasedOnPlan(selectedPlan?.planCode),
          topupContributionAmount: Number(rest.topupContributionAmount) || 0,
          setupFeePercentage: Number(rest.setupFeePercentage),
          currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              ...rest,
              chequeDate: null,
              folioType: getFolioTypeBasedOnPlan(selectedPlan?.planCode),
              topupContributionAmount: Number(rest.topupContributionAmount) || 0,
              setupFeePercentage: Number(rest.setupFeePercentage),
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role) &&
                applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: getStep(2, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('authorised-signatory', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
            : history.push('document-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(
            `Application ${
              application?.topupInitiated ? topupApplicationNumber : applicationNumber
            } - ` + ' Saved successfully',
            {
              variant: 'success',
              autoHideDuration: 3000,
            }
          );
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('authorised-signatory', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
            : history.push('document-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = (await dispatch(
          getFunds({ isActive: true, isTopupApplicable: true })
        )) as unknown as FundProps[];
        if (!isComponentAlive) {
          return;
        }
        const { scheme } = application || {};
        const updatedFundPlan =
          application?.schemeId && !application.scheme.isActive
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund?.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund?.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund?.id) === Number(scheme?.id)) || null;
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const getClassPlanOptionsOrDetails = (detail?: string) => {
    if (detail) {
      return selectedFund?.[detail] || '';
    }
    return (
      selectedFund?.plans?.map((plan) => ({ key: plan.planDescription, value: Number(plan.id) })) ||
      []
    );
  };

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={paymentDetails}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            topUpInvestmentPaymentDetailsSchema(
              Number(topupMinCommitmentAmount),
              topupMaxCommitmentAmount || 0,
              topupMinContributionPercentage || 0
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Notes displayContent={mandatorytext} />
          <Grid item xs={12}>
            <SearchableSelect
              name={'schemeId'}
              label="Scheme Name *"
              items={fundsList.map((fund) => ({ value: Number(fund.id), key: fund.schemeName }))}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                setValues({
                  ...values,
                  ...fundInitialValues,
                  schemeId: value as string,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                });
                setSelectedFund(fundsList.find((f) => Number(f.id) === value) || null);
                setSelectedPlan(null);
              }}
              disabled={true}
              searchFieldPlaceholder={'Search Scheme Name'}
            />
          </Grid>

          <Grid item xs={12}>
            <MFTextField
              name={'commitmentAmount'}
              label={`Existing Commitment Amount *`}
              type="number"
              placeholder="Enter Existing Commitment Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.commitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.commitmentAmount)}
              </Typography>
            )}
          </Grid>
          <SubHeading>Top Up Section</SubHeading>

          <Grid item xs={12} sm={6}>
            <SearchableSelect
              name={'planId'}
              label="Class/Subclass *"
              items={getClassPlanOptionsOrDetails() as [{ key: string; value: string | number }]}
              disabled={!values.schemeId || isFieldDisabled}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                try {
                  const selectedPlan = selectedFund?.plans
                    ?.filter((plan) => plan.isActive)
                    ?.find((p) => Number(p.id) === value);
                  setSelectedPlan(selectedPlan || null);
                  setValues({
                    ...values,
                    planId: value as string,
                    topupContributionAmount: 0,
                    setupFeePercentage: 0,
                    managementFeesRate: selectedPlan?.topupManagementFee,
                    setupFeeAmount: 0,
                    setupFeeGST: 0,
                    totalSetupFee: 0,
                    setupFeeTDS: 0,
                    netSetupFeeLessTDS: 0,
                    totalAmountPayable: 0,
                    commitmentAmountJointHolder1: 0,
                    commitmentAmountJointHolder2: 0,
                    commitmentAmountPrimaryApplicant: 0,
                  });
                  if (planValidationCheck(getApplicants, selectedPlan?.planCode)) {
                    throw `${selectedPlan?.planDescription} not applicable for Joint Contributor(s)`;
                  }
                } catch (e) {
                  typeof e === 'string' && dispatch(showError(e));
                }
              }}
              searchFieldPlaceholder={'Search Class (Plan)'}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'topupAmount'}
              label={'Top Up Commitment Amount *'}
              type="number"
              placeholder="Enter Commitment Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  Number(value) || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  commitmentAmount: values.commitmentAmount,
                  aggregateAmount: Number(value) + Number(values.commitmentAmount),
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: values.topupContributionAmount || Number(value),
                  topupAmount: Number(value),
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.topupAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.topupAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={'managementFeesRate'}
              label="Management Fees Rate"
              placeholder="Enter Management Fee Percentage"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'topupContributionAmount'}
              label="Top Up Contribution Amount *"
              placeholder="Enter Contribution Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  topupContributionAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: Number(value) || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={
                isFieldDisabled ||
                !values.planId ||
                planValidationCheck(getApplicants, selectedPlan?.planCode)
              }
            />
            {values.topupContributionAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.topupContributionAmount)}
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'setupFeePercentage'}
              label="Setup Fee Percentage *"
              type="number"
              placeholder="Enter Setup Fee Percentage"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  Number(value)
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  setupFeePercentage: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: values.topupContributionAmount || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeAmount'}
              label="Setup Fee Amount"
              type="number"
              placeholder="Enter Setup Fee Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.setupFeeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeGST'}
              label="GST (GST On Setup Fee Amount)"
              type="number"
              placeholder="Enter GST"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.setupFeeGST != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeGST)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalSetupFee'}
              label="Total Setup Fee"
              type="number"
              placeholder="Enter Total Setup Fee"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalSetupFee != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalSetupFee)}
              </Typography>
            )}
          </Grid> */}
          {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeTDS'}
              label="TDS"
              type="number"
              placeholder="Enter TDS"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const setupFeeLessTDS = (values.totalSetupFee || 0) - Number(value);
                setValues({
                  ...values,
                  setupFeeTDS: value ? Number(value) : null,
                  netSetupFeeLessTDS:
                    values.totalSetupFee && value ? setupFeeLessTDS : values.totalSetupFee,
                  totalAmountPayable: values.topupContributionAmount || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.setupFeeTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeTDS)}
              </Typography>
            )}
          </Grid> */}
          {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'netSetupFeeLessTDS'}
              label="Setup Fee Payable"
              type="number"
              placeholder="Enter Setup Fee Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.netSetupFeeLessTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.netSetupFeeLessTDS)}
              </Typography>
            )}
          </Grid> */}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalAmountPayable'}
              label="Total Amount Payable"
              type="number"
              placeholder="Enter Total Amount Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalAmountPayable != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalAmountPayable)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'aggregateAmount'}
              label={`Aggregate Commitment Amount`}
              type="number"
              placeholder="Enter Aggregate Commitment Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.aggregateAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.aggregateAmount)}
              </Typography>
            )}
          </Grid>
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
            disabled={planValidationCheck(getApplicants, selectedPlan?.planCode)}
          />
        </Grid>
      )}
    </Formik>
  );
}
