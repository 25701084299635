import { Box, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { SubDistributorListType } from '../../redux-store/types/api-types';
import { getDistributorRMbyId } from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { BackButtonAndHeader } from './userBackButton';
import { AddressFieldReadonly } from './AddressFields';
import { styles } from '../../Styles/styles';

export default function DistributorRmDetails({
  location,
}: {
  location: Location<{ subDistributorId: number }>;
}): JSX.Element {
  const { subDistributorId: id } = location.state || { subDistributorId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [distributorRmDetails, setDistributorRmDetails] = useState<SubDistributorListType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getDistributorRmDetails = (await dispatch(
            getDistributorRMbyId(id)
          )) as unknown as SubDistributorListType;
          setDistributorRmDetails(getDistributorRmDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.UsersFormikBox}>
        <BackButtonAndHeader
          User="Distributor's RM"
          accessForEditAdd={true}
          onClickBack={() =>
            history.push('distributors-rm', {
              distributorId: distributorRmDetails?.distributorId,
            })
          }
          onClickEdit={() =>
            history.push('edit-distributors-rm', {
              subDistributorId: distributorRmDetails?.id,
              distributorId: distributorRmDetails?.distributorId,
            })
          }
        />
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          distributorRmDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue
                      title={"Distributor's RM name"}
                      description={distributorRmDetails.user.firstName}
                    />
                    <KeyValue title={'Email Id'} description={distributorRmDetails.user.email} />
                    <KeyValue
                      title={'Mobile Number'}
                      description={distributorRmDetails.user.phone}
                    />
                    <KeyValue title={'Pan'} description={distributorRmDetails.panNumber} />
                    <KeyValue
                      title={"Distributor's RM Code"}
                      description={distributorRmDetails.distributors_RM_Code}
                    />
                    <KeyValue title={"AMC's RM Name"} description={distributorRmDetails.rm.name} />
                    <AddressFieldReadonly AddressField={distributorRmDetails} />
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
