import { CardMedia } from '@mui/material';

const LogoName = '3PIM-logo.png';

const logoWidth = '100px';

export const Logo1 = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt="Logo"
      sx={{ width: logoWidth, m: 3, mt: 1 }}
    />
  );
};
export const Logo2 = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt="Logo"
      sx={{ width: logoWidth, m: 1, ml: 3 }}
    />
  );
};

export const LogoEditApplication = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt="Logo"
      sx={{ width: logoWidth, ml: 3, m: 1 }}
    />
  );
};

export const LogoSideBar = (): JSX.Element => {
  return (
    <CardMedia component="img" src={`/images/${LogoName}`} alt="Logo" sx={{ width: logoWidth }} />
  );
};
