/* eslint-disable @typescript-eslint/no-explicit-any*/
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ProceedSaveLater, SubHeading } from './components';
import { showError } from '../../redux-store/actions/auth';
import MFCheckbox from '../../lib/formik/Checkbox';
import { UseRadioGroupTransparent } from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { useHistory } from 'react-router';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Applicant,
  profileType,
  RiskProfile,
  RiskProfileMasterType,
} from '../../redux-store/types/api-types';
import { RootStateType } from '../../redux-store/reducers';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  currencyConversion,
  getApplicantName,
  getStep,
  saveForLater,
} from '../../utils/utilityFunctions';
import { updateApplication } from '../../redux-store/actions/application';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  SAVE_LATER_VALIDATION_CHECK,
  WishToUploadRiskProfileEnabled,
  salutationsMasters,
} from '../../utils/constant';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line
import { riskProfileDeclaration } from '../../utils/declaration';
import { ConfirmationDialog } from '../commonComponents';
import { PieChart } from 'react-minimal-pie-chart';
import MFSelectField from '../../lib/formik/SelectField';
import { DatePicker } from '../../lib/formik/DatePicker';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { DataTable } from '../DataTable';
import { stringRegex } from '../../utils/regex';
import { useMdmsRiskProfileData } from '../../utils/useDataMdms';
import FieldValidationNote from './FieldValidationNote';

export type updatedRiskProfile = RiskProfile &
  RiskProfileMasterType & {
    otherValue?: string;
    InvestmentGoal?: string[];
    scoreCal?: number;
  };
export type updatedSectionType = updatedRiskProfile & {
  objective?: string;
  investmentAmount?: string;
  values?: updatedRiskProfile['values'] & {
    isChecked?: boolean;
  };
};
export type updateApplicant = {
  riskprofiles: updatedRiskProfile[];
  riskQuetionnaire: updatedSectionType[];
} & Partial<Applicant>;

export type Values = {
  applicants: updateApplicant[];
  applicationType: string;
  saveType: string;
};
export type InvestmentHorizonAndGoalType = {
  isChecked: boolean;
  investmentHorizon?: string;
  objective?: string;
  investmentAmount?: string;
};

const newInvestmentHorizonAndGoal: InvestmentHorizonAndGoalType = {
  isChecked: false,
  investmentHorizon: '',
  objective: '',
  investmentAmount: '',
};
export const RiskProfileObj: updatedRiskProfile = {
  question: '',
  answer: '',
  scoreText: '',
  otherValue: '',
  key: '',
  displayText: '',
  InvestmentGoal: [],
  scoreCal: 0,
};

export const riskQuetionnaireObject = {
  questionType: '',
  question: '',
  answer: '',
  scoreText: '',
  otherValue: '',
  key: '',
  displayText: '',
};

const initialValues: Values = {
  applicants: [
    {
      riskDocumentCheck: null,
      namePrefix: '',
      wishToAddRiskProfile: false,
      riskProfileDeclaration: true,
      totalRiskProfileScore: 0,
      riskprofiles: [],
      investmentHorizonAndGoal: [newInvestmentHorizonAndGoal],
      riskQuetionnaire: [riskQuetionnaireObject],
    },
  ],
  applicationType: '',
  saveType: 'save and proceed',
};

function RadioOrCheckbox({
  dataValues,
  index,
  ind,
  applicant,
  riskType,
  _handleSelection,
  setTotalScore,
  totalScore,
}: {
  dataValues: any;
  index: number;
  ind: number;
  applicant: updateApplicant;
  _handleSelection?: any;
  setTotalScore?: any;
  totalScore?: number;
  riskType: 'riskprofiles' | 'riskQuetionnaire';
}): JSX.Element {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      {dataValues.isMultiselect === 'false' && (
        <UseRadioGroupTransparent
          name={`applicants.${index}.${riskType}.${ind}.answer`}
          items={dataValues.values.map((profile: any) => ({
            label: profile.label,
            value: profile.key,
          }))}
          onChange={(e) => {
            let score;
            setFieldValue(
              `applicants.${index}.${riskType}`,
              applicant[riskType].map((risk, index) => {
                if (index === ind) {
                  score = risk.values?.filter((risk) => {
                    return risk.key === e.target.value;
                  })?.[0]?.score;
                  score && setTotalScore(totalScore ? totalScore : 0 + Number(score));
                  return {
                    ...risk,
                    answer: e.target.value,
                    scoreText: score?.toString() || '',
                  };
                }
                return risk;
              })
            );
          }}
          sx={{
            '& .MuiFormGroup-root': { flexDirection: 'column' },
            '& .MuiFormControlLabel-label': { fontWeight: 400 },
          }}
        />
      )}
      {dataValues.isMultiselect === 'true' &&
        dataValues.values.map((risk: any) => {
          return (
            <>
              <FormControlLabel
                key={dataValues.key}
                value={dataValues.key}
                sx={{
                  width: '100%',
                  position: 'relative',
                  pb: 0,
                  ml: 0.2,
                  '.MuiFormLabel-root ': {
                    color: 'primary.main',
                    fontWeight: 600,
                    mb: 0.5,
                  },
                  '.MuiFormControlLabel-root': {
                    width: '100%',
                    alignItems: 'flex-start',
                    '.MuiTypography-root': {
                      color: 'text.primary',
                      fontWeight: 500,
                      fontSize: 14,
                      overflow: { xs: 'unset', md: 'hidden' },
                      whiteSpace: { xs: 'unset', md: 'nowrap' },
                      textOverflow: { xs: 'unset', md: 'ellipsis' },
                      wordBreak: { xs: 'break-word', md: 'unset' },
                    },
                    '.MuiRadio-root,.MuiCheckbox-root ,.Mui-checked': {
                      color: 'text.primary',
                    },
                    '.MuiRadio-root,.MuiCheckbox-root ': { p: 0, mr: 0.5 },
                  },
                  '.MuiAccordionDetails-root': {
                    paddingTop: 0,
                  },
                  '.MuiAccordionSummary-content.Mui-expanded': {
                    marginBottom: '10px',
                  },
                }}
                onChange={() => {
                  _handleSelection &&
                    _handleSelection(
                      index,
                      ind,
                      risk,
                      dataValues?.InvestmentGoal || [],
                      dataValues?.scoreCal || 0
                    );
                }}
                control={
                  <Checkbox
                    size="small"
                    checked={dataValues.InvestmentGoal?.map((goal: any) =>
                      goal.toString()
                    ).includes(`${risk.key}_${risk.score}`)}
                  />
                }
                label={risk.label}
                title={risk.label}
              />
            </>
          );
        })}
      {(applicant[riskType][ind]?.answer === 'others' ||
        (applicant[riskType][ind]?.isMultiselect === 'true' &&
          applicant[riskType][ind]?.answer.includes('others'))) && (
        <Grid xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.${riskType}.${ind}.otherValue`}
            placeholder={`Please specify`}
            sx={{
              mb: 0,
              '&.MuiInputBase-root': {
                position: 'relative',
                border: 0,
                borderBottom: '1px solid #DDEAF3',
                fontSize: 16,
              },
            }}
            onChange={(e) => {
              const removeAstricOrUnderscore = e.target.value?.replace('*', '')?.replace('_', '');
              setFieldValue(
                `applicants.${index}.${riskType}`,
                applicant[riskType].map((risk, index) => {
                  if (index === ind) {
                    return {
                      ...risk,
                      otherValue: removeAstricOrUnderscore,
                    };
                  }
                  return risk;
                })
              );
            }}
          />
        </Grid>
      )}
    </>
  );
}

function RiskDeclaration({
  data,
  values,
  // eslint-disable-next-line
  setValues,
  setTotalScore,
  totalScore,
  applicant,
  disabled,
  index,
}: {
  data: updatedRiskProfile[];
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  setTotalScore: any;
  totalScore: any;
  applicant: updateApplicant;
  disabled: boolean;
  index: number;
}): JSX.Element {
  const [expanded, setExpanded] = React.useState<string | false>('');
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    setExpanded(disabled ? '' : expanded);
  }, [disabled]);

  const _handleSelection = async (
    _index: number,
    ind: number,
    selectedOptionObj: any,
    InvestmentGoal: string[],
    scoreCal: string | number
  ) => {
    const index = InvestmentGoal?.indexOf(
      `${selectedOptionObj.key}_${selectedOptionObj.score}` as string
    );
    const investmentGoalSelect =
      index === -1
        ? [...InvestmentGoal, `${selectedOptionObj.key}_${selectedOptionObj.score}`]
        : InvestmentGoal?.filter(
            (goal: any) => goal != (`${selectedOptionObj.key}_${selectedOptionObj.score}` as string)
          );

    const total = investmentGoalSelect?.includes(
      `${selectedOptionObj.key}_${selectedOptionObj.score}`
    )
      ? Number(scoreCal) + Number(selectedOptionObj.score)
      : Number(scoreCal) - Number(selectedOptionObj.score);
    setFieldValue(
      `applicants.${_index}.riskprofiles`,
      applicant.riskprofiles.map((risk, index) => {
        if (index === ind)
          return {
            ...risk,
            answer: investmentGoalSelect?.toString().replace(/,/g, '*'),
            scoreText: total.toFixed(1)?.toString() || '',
            InvestmentGoal: investmentGoalSelect,
            scoreCal: total,
          };
        return risk;
      })
    );
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box sx={{ width: '100%' }}>
      {data.map((dataValues: any, ind: number) => {
        return (
          <>
            <Accordion
              sx={{ boxShadow: 'none', mb: 3, '&::before': { display: 'none' } }}
              key={ind}
              expanded={expanded === `panel${ind}`}
              onChange={handleChange(`panel${ind}`)}
              disabled={disabled}>
              <AccordionSummary
                sx={{
                  bgcolor: 'rgba(221, 234, 243, 0.2)',
                  borderRadius: '5px',
                  '&.Mui-expanded': { minHeight: 'unset' },
                  '& .MuiAccordionSummary-content': { '&.Mui-expanded': { my: 2 } },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${ind}d-content`}
                id={`panel${ind}d-header`}>
                <Typography
                  sx={{
                    color:
                      values.applicants[index].riskprofiles[ind]?.answer !== ''
                        ? '#57B6BA'
                        : '#131836',
                    fontSize: 14,
                    fontWeight: 600,
                  }}>
                  {`${ind + 1}. ${dataValues.displayText} ${
                    dataValues.required === 'true' ? '*' : ''
                  }`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pb: 0 }}>
                <RadioOrCheckbox
                  dataValues={dataValues}
                  index={index}
                  ind={ind}
                  applicant={applicant}
                  riskType={'riskprofiles'}
                  setTotalScore={setTotalScore}
                  _handleSelection={_handleSelection}
                  totalScore={totalScore}
                />
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </Box>
  );
}

export function InvestmentHorizonAndGoalTable({
  values,
  _index,
  sectionIndex,
  mdmsData,
  applicant,
}: {
  applicant: updateApplicant;
  values: Values;
  mdmsData: any;
  _index: number;
  sectionIndex: number;
}): JSX.Element {
  const { setValues } = useFormikContext();
  const headers = [
    {
      header: 'Investment Horizon And Goal',
      label: 'investmentHorizonAndGoal',
      renderEditDeleteCell: (params: any, index: number): JSX.Element => {
        return (
          <>
            <MFCheckbox
              name={`applicants.${_index}.riskQuetionnaire.${sectionIndex}.values.${index}.isChecked`}
              label={params.label}
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              // eslint-disable-next-line
              onChange={({ target: { checked } }) => {
                return setValues({
                  ...values,
                  applicants: values.applicants.map((_applicant, _ind) => {
                    const applicantCheck = _ind === _index;
                    const updatedRiskQuetionnaire = applicantCheck
                      ? _applicant.riskQuetionnaire.map((risk, riskIndex) => {
                          const riskChecked =
                            riskIndex === sectionIndex &&
                            risk.key === 'investmentHorizonAndGoalArray';
                          return {
                            ...risk,
                            values: riskChecked
                              ? risk?.values?.map((value, i) => {
                                  const valueChecked = i === index;
                                  return {
                                    ...value,
                                    isChecked: valueChecked,
                                    investmentAmount: valueChecked ? value?.investmentAmount : '',
                                    objective: valueChecked ? value?.objective : '',
                                  };
                                })
                              : risk?.values,
                            answer: riskChecked ? mdmsData[riskIndex].key : risk.answer,
                            objective: riskChecked ? mdmsData[riskIndex].objective : risk.objective,
                            investmentAmount: riskChecked
                              ? mdmsData[riskIndex].investmentAmount
                              : risk.investmentAmount,
                          };
                        })
                      : _applicant.riskQuetionnaire;

                    return {
                      ..._applicant,
                      riskQuetionnaire: updatedRiskQuetionnaire,
                    };
                  }),
                });
              }}
            />
          </>
        );
      },
    },
    {
      header: 'Objective',
      label: 'objective',
      renderEditDeleteCell: (params: any, index: number): JSX.Element => {
        return (
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'inline-flex',
              //   width: 240,
              maxWidth: '100%',
              py: 1,
              '& .MuiFormControl-root': {
                minWidth: 140,
                m: 0,
              },
            }}>
            <MFTextField
              name={`applicants.${_index}.riskQuetionnaire.${sectionIndex}.values.${index}.objective`}
              placeholder="Enter Objective"
              trimOnBlur={false}
              disabled={!mdmsData[index]?.isChecked}
              sx={{
                '&.MuiInputBase-root': {
                  borderColor: params.isChecked && '#9BA2A6',
                  '& ::placeholder': {
                    color: params.isChecked && '#000000',
                  },
                },
              }}
            />
          </Stack>
        );
      },
    },
    {
      header: 'Amount',
      label: 'investmentAmount',
      renderEditDeleteCell: (params: any, index: number): JSX.Element => {
        return (
          <Box
            sx={{
              // width: 250,
              maxWidth: '100%',
              py: 1,
              '& .MuiFormControl-root': { m: 0, minWidth: 140 },
            }}>
            <MFTextField
              name={`applicants.${_index}.riskQuetionnaire.${sectionIndex}.values.${index}.investmentAmount`}
              placeholder="Enter Amount"
              // onChange={updateState(index, 'investmentAmount')}
              sx={{
                '&.MuiInputBase-root': {
                  borderColor: params.isChecked && '#9BA2A6',
                  '& ::placeholder': {
                    color: params.isChecked && '#000000',
                  },
                },
                // '&.Mui-disabled': { '&,& .Mui-disabled': { cursor: 'no-drop' } },
              }}
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={!mdmsData[index]?.isChecked}
            />
            {Number(mdmsData[index]?.investmentAmount) > 0 && mdmsData[index]?.isChecked && (
              <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
                {currencyConversion(mdmsData[index]?.investmentAmount)}
              </Typography>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <DataTable
      tableData={applicant.riskQuetionnaire[sectionIndex].values}
      tableHeader={headers}
      renderAdditionalRow={false}
      tableHeaderCustomStyles={{
        '.MuiTableCell-head': {
          fontFamily: 'Poppins, sans-serif',
          fontSize: 14,
          fontWeight: 500,
          color: 'primary.main',
          textAlign: 'left',
          pl: 2,
          '&:nth-of-type(1)': {
            pl: 6,
          },
        },
      }}
      rowCustomStyles={{
        '.MuiTableCell-root': {
          py: 0,
          overflowWrap: 'anywhere',
          textAlign: 'left',
          verticalAlign: 'top',
        },
      }}
      boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
    />
  );
}

function SectionQuetionnaires({
  data,
  values,
  applicant,
  index,
}: {
  data: updatedSectionType[];
  values: Values;
  applicant: updateApplicant;
  index: number;
}): JSX.Element {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography>Section 1: Demographics</Typography>
      <Grid
        container
        rowSpacing={1}
        sx={{
          width: '100%',
          '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
        }}>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.name`}
            label="Name *"
            placeholder="Enter Name"
            startAdornment={
              values.applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL ? (
                <InputAdornment
                  position="start"
                  sx={{
                    pr: '0 !important',
                    mx: '0 !important',
                    borderRight: '0 !important',
                  }}>
                  <MFSelectField
                    name={`applicants.${index}.namePrefix`}
                    items={salutationsMasters.map((salutation) => ({
                      key: salutation,
                      value: salutation,
                    }))}
                    disabled={true}
                    sx={{
                      color: 'primary.main',
                      '.MuiSvgIcon-root ': { color: 'primary.main' },
                      '&.MuiInputBase-root': {
                        '.MuiSelect-select': {
                          p: 0,
                          pr: 2.5,
                          border: 0,
                        },
                      },
                      '&,&:hover,&.Mui-focused': {
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      },
                    }}
                  />
                </InputAdornment>
              ) : (
                <></>
              )
            }
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label={'Date of birth *'}
            inputLabelStyles={{
              transform: 'unset',
              fontSize: 14,
              fontWeight: 500,
              color: 'rgba(0,0,0,0.7)',
            }}
            placeholder={'Enter Date of birth'}
            name={`applicants.${index}.dateOfBirth`}
            disabled={true}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          />
        </Grid>
      </Grid>
      {data.map((section, ind) => (
        <>
          <>
            <Typography>
              Section {ind + 2}:
              {` ${section.displayText} ${section.required === 'true' ? '*' : ''}`}
            </Typography>
            {section?.questionType === 'radio' && (
              <RadioOrCheckbox
                dataValues={section}
                index={index}
                ind={ind}
                applicant={applicant}
                riskType={'riskQuetionnaire'}
              />
            )}
            {section?.questionType === 'radioTable' && (
              <InvestmentHorizonAndGoalTable
                sectionIndex={ind}
                _index={index}
                applicant={applicant}
                values={values}
                mdmsData={section.values}
              />
            )}
          </>
        </>
      ))}
    </Box>
  );
}

export const profiletext = (total: number, profileData: profileType[]): any => {
  let text;
  profileData?.map((profile) => {
    const min = Math.min(profile.minScore, profile.maxScore),
      max = Math.max(profile.minScore, profile.maxScore);
    const chartAngle = (profile.maxDebt + profile.minDebt) / 2;
    const minchartpercentage = 22;
    const DebtChart = chartAngle > minchartpercentage ? chartAngle : minchartpercentage;
    const labelDebt = `${profile.minDebt}-${profile.maxDebt}%`;
    const labelEquity = `${100 - profile.maxDebt}-${100 - profile.minDebt}%`;

    if (total >= min && total <= max) {
      text = (
        <>
          <Typography sx={{ fontSize: 16 }} display={{ sx: 'block', md: 'flex' }}>
            <Typography>
              <Typography>
                Risk Profile is{' '}
                <Typography
                  component="strong"
                  sx={{ fontSize: 16, fontWeight: 600, color: 'black' }}>
                  {'  '}
                  {profile.profile}.{' '}
                </Typography>
              </Typography>
              <Typography>
                <Typography
                  component="strong"
                  sx={{ fontSize: 16, fontWeight: 600, color: 'black' }}>
                  {'  '}
                  {profile.objective}.{' '}
                </Typography>
              </Typography>
              <Typography>
                <Typography>
                  {'  '}
                  {profile.description}.{' '}
                </Typography>
              </Typography>
            </Typography>
            <Typography>
              {'  '}
              <PieChart
                data={
                  DebtChart !== 100
                    ? [
                        { title: 'Debt', value: DebtChart, color: 'gray', label: labelDebt },
                        {
                          title: 'Equity',
                          value:
                            100 - DebtChart > minchartpercentage
                              ? 100 - DebtChart
                              : minchartpercentage,
                          color: 'rgb(255, 205, 86)',
                          label: labelEquity,
                        },
                      ]
                    : [{ title: 'Debt 100%', value: DebtChart, color: 'gray' }]
                }
                label={({ x, y, dx, dy, dataEntry }) => (
                  <>
                    <Typography
                      component="text"
                      x={x}
                      y={y}
                      dx={dx}
                      dy={dy - 5}
                      dominantBaseline="central"
                      textAnchor="middle"
                      style={{
                        fontSize: '10px',
                        fontFamily: 'sans-serif',
                      }}>
                      {dataEntry.title}
                    </Typography>
                    <Typography
                      component="text"
                      x={x}
                      y={y}
                      dx={dx}
                      dy={dy + 5}
                      dominantBaseline="central"
                      textAnchor="middle"
                      style={{
                        fontSize: '10px',
                        fontFamily: 'sans-serif',
                      }}>
                      {dataEntry.label}
                    </Typography>
                  </>
                )}
                labelStyle={{
                  fontSize: 8,
                  fontWeight: 500,
                  overflow: 'auto',
                  wordBreak: 'break-word',
                }}
                startAngle={270}
                radius={50}
                style={{ height: 130, width: 130, wordBreak: 'break-word' }}
              />
            </Typography>
          </Typography>
        </>
      );
    }
  });
  return text;
};

export const handleIntialSelect = (
  riskprofiles: RiskProfile[],
  riskProfileQuestion: string
): string[] => {
  let selectedValue: string[] = [];
  const longTermArr = riskprofiles?.find((a) => a.question === riskProfileQuestion);
  selectedValue = longTermArr?.answer.split('*') as unknown as string[];
  return selectedValue;
};

export default function riskProfile(): JSX.Element {
  const [riskProfileData, setRiskProfileData] = useState<Values>(initialValues);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [riskProfileDataMaster, setRiskProfileDataMaster] = useState<RiskProfileMasterType[]>([]);
  const [profileData, setProfileData] = useState<profileType[]>([]);
  // eslint-disable-next-line
  const [riskQuetionnaire, setRiskQuetionnaire] = useState<RiskProfileMasterType[]>([
    riskQuetionnaireObject,
  ]);
  const { riskProfile } = useMdmsRiskProfileData();
  const [totalScore, setTotalScore] = useState(0);
  const [openPopup, setOpenPopup] = useState<{
    riskProfileCheck: boolean;
    applicant: updateApplicant;
  } | null>(null);

  const manageTotal = (values: updateApplicant) => {
    const total = values.riskprofiles
      .map((risk) => {
        return Number(risk.scoreText);
      })
      .reduce(function (acc, val) {
        return acc + val;
      }, 0);

    return total;
  };

  const handleApplicant = async (values: Values) => {
    const { applicants = [], saveType } = values;
    const {
      id,
      currentStep,
      applicants: existingApplicant = [],
      applicant1ReferenceId,
      applicationNumber,
      topupApplicationNumber,
      applicationType,
    } = application || {};
    applicants.map((applicant) => {
      if (!applicant.riskDocumentCheck) {
        const checkAllProfileQuestionsAnswered = applicant.riskprofiles
            ?.map((profile) => profile.answer !== '' && profile.required === 'true')
            ?.every((_profile) => _profile),
          checkAllRiskQuestionsAnswered = applicant.riskQuetionnaire.some(
            (section) => section.answer !== '' && section.required === 'true'
          );
        if (
          (!applicant.wishToAddRiskProfile && !checkAllProfileQuestionsAnswered) ||
          !checkAllRiskQuestionsAnswered
        ) {
          throw 'Please fill all the required(*) fields';
        }
        if (!applicant.wishToAddRiskProfile && !applicant.riskProfileDeclaration) {
          throw 'Declaration is required';
        }
        applicant.riskprofiles?.map((risk) => {
          if (
            !applicant.wishToAddRiskProfile &&
            (risk.answer === 'others' ||
              (risk.isMultiselect === 'true' && risk.answer.includes('others'))) &&
            !risk.otherValue &&
            risk.required === 'true'
          ) {
            const questionForDisplay = riskProfileDataMaster
              ?.map((riskProfile) => {
                if (riskProfile.key === risk.question) {
                  return riskProfile.displayText;
                }
                return;
              })
              ?.filter((ele) => ele)
              ?.toString();
            throw 'Please specify others in ' + questionForDisplay;
          }
          if (
            !applicant.wishToAddRiskProfile &&
            (risk.answer === 'others' ||
              (risk.isMultiselect === 'true' && risk.answer.includes('others'))) &&
            risk.otherValue
          ) {
            if (risk.otherValidation === 'stringRegex' && !stringRegex.test(risk.otherValue))
              throw 'Special characters and numbers not allowed for others in ' + risk.displayText;
          }
          if (
            risk.answer &&
            risk.validation === 'oneOf' &&
            risk.isMultiselect === 'false' &&
            !risk.values?.map((val) => val.key).includes(risk.answer)
          ) {
            throw 'Invalid ' + risk.displayText;
          }
          if (risk.answer && risk.isMultiselect === 'true' && risk.validation === 'oneOf') {
            if (
              !risk.answer
                .split('*')
                .filter((ans) => risk?.values?.map((val) => val.key).includes(ans.split('_')[0]))
                .length
            )
              throw 'Invalid ' + risk.displayText;
          }
        });
        applicant.riskQuetionnaire.map((section) => {
          if (section.answer === 'others' && !section.otherValue && section.required === 'true') {
            throw 'Please specify others in ' + section.displayText;
          }
          if (!section.answer && section.required === 'true') {
            throw 'Please specify ' + section.displayText;
          }
          if (section.answer === 'others' && section.otherValue) {
            if (section.otherValidation === 'stringRegex' && !stringRegex.test(section.otherValue))
              throw (
                'Special characters and numbers not allowed for others in ' + section.displayText
              );
          }
          if (
            section.answer &&
            section.validation === 'oneOf' &&
            section.isMultiselect === 'false' &&
            !section.values?.map((val) => val.key).includes(section.answer)
          ) {
            throw 'Invalid ' + section.displayText;
          }
          if (
            section.answer &&
            section.isMultiselect === 'true' &&
            section.validation === 'oneOf'
          ) {
            if (
              !section.answer
                .split('*')
                .filter((ans) => section?.values?.map((val) => val.key).includes(ans.split('_')[0]))
                .length
            )
              throw 'Invalid ' + section.displayText;
          }
          if (section.answer && section.key === 'investmentHorizonAndGoalArray') {
            if (!section.values?.some((val) => val.objective))
              throw 'Please specify objective in ' + section.displayText;
            if (!section.values?.some((val) => val.investmentAmount))
              throw 'Please specify investment amount in ' + section.displayText;
            if (!section.values?.find((val) => val.objective)?.objective?.match(stringRegex))
              throw (
                'Special characters and numbers not allowed for objective in ' + section.displayText
              );
          }
        });
      }
    });
    const updatedApplicants = values.applicants.map((applicant, index) => {
      const {
        riskDocumentCheck,
        wishToAddRiskProfile,
        riskProfileDeclaration,
        // eslint-disable-next-line
        totalRiskProfileScore,
        riskprofiles,
        // eslint-disable-next-line
        investmentHorizonAndGoal,
        riskQuetionnaire,
        ...rest
      } = applicant;

      const updatedRiskProfile = riskprofiles?.map((_riskProfile) => {
        const {
          otherValue,
          // eslint-disable-next-line
          required,
          // eslint-disable-next-line
          values,
          // eslint-disable-next-line
          displayText,
          // eslint-disable-next-line
          key,
          // eslint-disable-next-line
          isMultiselect,
          // eslint-disable-next-line
          totalPercentage,
          // eslint-disable-next-line
          InvestmentGoal,
          // eslint-disable-next-line
          scoreCal,
          // eslint-disable-next-line
          validation,
          ...restrisk
        } = _riskProfile;
        return {
          ...restrisk,
          answer:
            otherValue && restrisk.answer === 'others'
              ? otherValue
              : isMultiselect === 'true' && otherValue && restrisk.answer?.includes('others')
              ? restrisk.answer?.replace('others', otherValue)
              : restrisk.answer,
        };
      });
      const newInvestment = riskQuetionnaire.find(
        (section) => section.key === 'investmentHorizonAndGoalArray'
      );
      const InvestmentValues = newInvestment?.values?.find((value: any) => value.isChecked);
      const updateInvestmentSectionValues: any = riskQuetionnaire.find(
        (section) => section.key === 'investmentObjective'
      );
      return {
        ...existingApplicant[index],
        ...rest,
        riskDocumentCheck,
        wishToAddRiskProfile: !riskDocumentCheck ? wishToAddRiskProfile : false,
        riskProfileDeclaration: !riskDocumentCheck ? riskProfileDeclaration : false,
        totalRiskProfileScore: !riskDocumentCheck ? manageTotal(applicant) : 0,
        riskprofiles:
          wishToAddRiskProfile || riskDocumentCheck
            ? updatedRiskProfile && updatedRiskProfile?.length > 0
              ? (updatedRiskProfile
                  ?.map((_profile) => {
                    if (_profile.id) {
                      return {
                        ..._profile,
                        isActive: false,
                      };
                    }
                    return;
                  })
                  .filter((ele) => ele) as updatedRiskProfile[])
              : updatedRiskProfile?.filter((profile) => profile.answer)
            : updatedRiskProfile?.filter((profile) => profile.answer),
        investmentAmount: !riskDocumentCheck ? InvestmentValues?.investmentAmount || null : null,
        investmentHorizon: !riskDocumentCheck ? InvestmentValues?.key : '',
        objective: !riskDocumentCheck ? InvestmentValues?.objective || '' : '',
        investmentObjective: riskDocumentCheck
          ? ''
          : (updateInvestmentSectionValues?.otherValue &&
            updateInvestmentSectionValues?.answer === 'others'
              ? updateInvestmentSectionValues?.otherValue
              : updateInvestmentSectionValues?.answer) || '',
      };
    });
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants
          ?.map((applicant) => {
            return {
              ...applicant,
              riskprofiles: applicant.riskprofiles?.sort(
                (riskprofile1, riskprofile2) => Number(riskprofile1.id) - Number(riskprofile2.id)
              ),
            };
          })
          ?.sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        applicants: updatedApplicants
          ?.map((applicant) => {
            return {
              ...applicant,
              riskprofiles: applicant.riskprofiles?.sort(
                (riskprofile1, riskprofile2) => Number(riskprofile1.id) - Number(riskprofile2.id)
              ),
            };
          })
          ?.sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        currentStep:
          applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? !!currentStep && currentStep > 3
              ? currentStep
              : Number(currentStep) + 1
            : !!currentStep && currentStep > 7
            ? currentStep
            : Number(currentStep) + 1,
      }
    );
    const isSaveLater = saveType !== 'save and proceed';
    if (id && !checkApplication) {
      setLoading(true);
      await dispatch(
        updateApplication({
          body: {
            ...application,
            applicants: updatedApplicants,
            status:
              !application?.hasPOA &&
              AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
              applyRoleBasedStatus(role) &&
              application?.status !== 'draft'
                ? 'sent_to_amc_approver'
                : application?.status,
            currentStep:
              applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                ? getStep(4, isSaveLater)
                : getStep(8, isSaveLater),
            ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
          },
          applicationId: id,
          ...(isSaveLater && { toastMessage: '' }),
        })
      );
      !isSaveLater
        ? history.push('investment-payment-details', {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    } else if (checkApplication) {
      if (isSaveLater) {
        enqueueSnackbar(
          `Application ${
            application?.topupInitiated ? topupApplicationNumber : applicationNumber
          } - ` + ' Saved successfully',
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      }
      !isSaveLater
        ? history.push('investment-payment-details', {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      await handleApplicant(values);
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };
  useEffect(() => {
    const { applicants: exisitingApplicants = [], applicationType = '' } = application || {};
    (async function () {
      try {
        setRiskProfileDataMaster(riskProfile.riskProfile);
        setProfileData(riskProfile?.profile);
        setRiskQuetionnaire(riskProfile?.section);
        const applicants = exisitingApplicants.map((applicant) => {
          const {
            riskDocumentCheck = true,
            namePrefix,
            name,
            dateOfBirth,
            riskProfileDeclaration = true,
            // eslint-disable-next-line
            wishToAddRiskProfile = false,
            totalRiskProfileScore = 0,
            investmentHorizon,
            objective,
            investmentAmount,
            investmentObjective,
          } = applicant;
          const defaultPayload = {
            riskDocumentCheck,
            namePrefix,
            name,
            dateOfBirth,
            riskProfileDeclaration,
            wishToAddRiskProfile: false,
            riskprofiles: riskProfile.riskProfile
              ?.map((mdmsProfile) => {
                const existingRiskProfiles = applicant?.riskprofiles?.filter(
                  (risk_profiles: any) => risk_profiles.question === mdmsProfile.key
                );
                const newrisk = existingRiskProfiles?.length
                  ? existingRiskProfiles?.map((existingProfiles: any) => {
                      if (
                        existingProfiles.question === mdmsProfile.key &&
                        mdmsProfile.isMultiselect === 'true'
                      ) {
                        const getInvestment = handleIntialSelect(
                          applicant?.riskprofiles as RiskProfile[],
                          mdmsProfile.key
                        );
                        const checkAnswerArray = mdmsProfile.values?.map((_value) => _value.key);
                        let getOtherValue = '';
                        const updateOptions = getInvestment.map((ans) => {
                          if (!checkAnswerArray?.includes(ans.split('_')[0])) {
                            getOtherValue = ans.split('_')[0];
                            return `others_${ans.split('_')[1]}`;
                          }
                          return ans;
                        });
                        return {
                          ...existingProfiles,
                          ...mdmsProfile,
                          values: mdmsProfile.values?.map((value) => {
                            const scoreUpdate = updateOptions
                              .find((investment) => investment?.split('_')[0] === value.key)
                              ?.split('_')[1];

                            return {
                              ...value,
                              score: scoreUpdate ? Number(scoreUpdate) : value.score,
                            };
                          }),
                          InvestmentGoal: updateOptions || [],
                          scoreCal: Number(existingProfiles.scoreText) || 0,
                          otherValue: getOtherValue,
                          answer: getOtherValue
                            ? updateOptions?.toString()?.replace(/,/g, '*')
                            : existingProfiles.answer,
                        };
                      } else {
                        const checkAnswer =
                          existingProfiles.question === mdmsProfile.key &&
                          mdmsProfile.isMultiselect === 'false' &&
                          mdmsProfile.values
                            ?.map((value) => value.key)
                            .includes(existingProfiles.answer);

                        return {
                          ...existingProfiles,
                          ...mdmsProfile,
                          values: mdmsProfile.values?.map((value) => {
                            if (existingProfiles.answer === value.key) {
                              return {
                                ...value,
                                score: Number(existingProfiles.scoreText),
                              };
                            }
                            return value;
                          }),
                          otherValue: checkAnswer ? '' : existingProfiles.answer,
                          answer: checkAnswer ? existingProfiles.answer : 'others',
                        };
                      }
                    })
                  : [
                      {
                        ...RiskProfileObj,
                        question: mdmsProfile.key,
                        answer: '',
                        scoreText: '',
                        ...mdmsProfile,
                      },
                    ];
                return newrisk;
              })
              .flat(),
            totalRiskProfileScore,
            riskQuetionnaire: riskProfile?.section?.map((sec) => {
              const setAnswer = sec.values
                ?.map((value) => value.key)
                .includes(investmentObjective || '');
              return {
                ...riskQuetionnaireObject,
                ...sec,
                answer:
                  (sec.key === 'investmentObjective'
                    ? setAnswer
                      ? investmentObjective
                      : investmentObjective
                      ? 'others'
                      : ''
                    : sec.key === 'investmentHorizonAndGoalArray'
                    ? investmentHorizon
                    : '') || '',
                otherValue:
                  sec.key === 'investmentObjective' ? (setAnswer ? '' : investmentObjective) : '',
                values:
                  sec.key === 'investmentHorizonAndGoalArray'
                    ? sec?.values?.map((value) => {
                        const check = value.key === investmentHorizon;
                        return {
                          ...value,
                          isChecked: check,
                          objective:
                            check && sec.key === 'investmentHorizonAndGoalArray' ? objective : '',
                          investmentAmount:
                            check && sec.key === 'investmentHorizonAndGoalArray'
                              ? investmentAmount
                              : '',
                        };
                      })
                    : sec?.values,
              };
            }),
            investmentHorizonAndGoal: newInvestmentHorizonAndGoal,
          };
          return defaultPayload;
        });
        setRiskProfileData({
          ...riskProfileData,
          applicants: applicants,
          applicationType,
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application, riskProfile]);

  return (
    <Formik initialValues={riskProfileData} onSubmit={handleSubmit} enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((applicant, index) => {
            return (
              <>
                {WishToUploadRiskProfileEnabled && application?.hasPOA && index === 0 && (
                  <Grid item xs={12}>
                    <MFCheckbox
                      name={`applicants.${index}.riskDocumentCheck`}
                      label={'Wish to upload Risk Form'}
                      sx={{ my: 2 }}
                      onChange={({ target: { checked } }) => {
                        setValues({
                          ...values,
                          applicants: values.applicants.map((_applicant) => {
                            return { ..._applicant, riskDocumentCheck: checked };
                          }),
                        });
                      }}
                    />
                  </Grid>
                )}
                {!applicant.riskDocumentCheck && (
                  <>
                    <SubHeading
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      Risk Questionnaire -{getApplicantName(index + 1, true)} Holder
                    </SubHeading>

                    <SectionQuetionnaires
                      data={applicant?.riskQuetionnaire}
                      values={values}
                      applicant={applicant}
                      index={index}
                    />
                    <SubHeading
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      Risk Profile of {getApplicantName(index + 1, true)} Applicant
                    </SubHeading>
                    {/* <Grid xs={12} sx={{ mb: 2 }}>
                  <MFCheckbox
                    name={`applicants.${index}.wishToAddRiskProfile`}
                    label={riskProfileDonotWish}
                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                    onChange={({ target: { checked } }) => {
                      const updatedRiskProfile = riskProfileDataMaster.map((mdmsProfile) => {
                        const riskObj = applicant.riskprofiles?.find(
                          (_profile) => _profile.key === mdmsProfile.key
                        );
                        return {
                          ...RiskProfileObj,
                          question: mdmsProfile.key,
                          ...riskObj,
                          answer: '',
                          scoreText: '',
                          ...mdmsProfile,
                        };
                      });
                      !checked &&
                        setValues({
                          ...values,
                          applicants: values.applicants.map((_applicant) => {
                            if (_applicant === applicant)
                              return {
                                ...applicant,
                                wishToAddRiskProfile: checked,
                                riskProfileDeclaration: applicant.riskProfileDeclaration || true,
                                riskprofiles: [...updatedRiskProfile],
                              };
                            return _applicant;
                          }),
                        });
                      checked && setOpenPopup({ riskProfileCheck: checked, applicant });
                    }}
                    checked={applicant.wishToAddRiskProfile}
                  />
                </Grid> */}

                    <RiskDeclaration
                      data={applicant?.riskprofiles}
                      values={values}
                      setValues={setValues}
                      setTotalScore={setTotalScore}
                      totalScore={totalScore}
                      applicant={applicant}
                      disabled={!!applicant.wishToAddRiskProfile}
                      index={index}
                    />
                    <Typography
                      sx={{
                        color: 'black',
                        fontWeight: 600,
                        fontSize: 14,
                        mt: 2,
                      }}>{`Your total Score is ${manageTotal(applicant)}
        `}</Typography>
                    <Grid xs={12}>
                      <Typography sx={{ mt: 1 }}>
                        {profiletext(Number(manageTotal(applicant)), profileData)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <MFCheckbox
                        name={`applicants.${index}.riskProfileDeclaration`}
                        label={riskProfileDeclaration}
                        sx={{ display: 'flex', alignItems: 'flex-start' }}
                        disabled={applicant.wishToAddRiskProfile}
                      />
                    </Grid>
                  </>
                )}
              </>
            );
          })}

          <FieldValidationNote />

          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={`Are you sure you do not wish to undergo the RISK Profiling ?`}
            open={openPopup !== null}
            setOpen={() => {
              setOpenPopup(null);
            }}
            onSave={() => {
              setValues({
                ...values,
                applicants: values.applicants.map((_applicant) => {
                  if (_applicant === openPopup?.applicant)
                    return {
                      ..._applicant,
                      wishToAddRiskProfile: openPopup?.riskProfileCheck as boolean,
                      riskProfileDeclaration: false,
                      riskprofiles: _applicant.riskprofiles?.map((_profile) => {
                        return {
                          ..._profile,
                          answer: '',
                          scoreText: '',
                          InvestmentGoal: [],
                          scoreCal: 0,
                        };
                      }),
                    };
                  return _applicant;
                }),
              });
              setOpenPopup(null);
            }}
            onCancel={() => {
              setOpenPopup(null);
            }}
          />
        </Grid>
      )}
    </Formik>
  );
}
