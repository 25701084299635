import { useState, useEffect } from 'react';
import { Box, Grid, LinearProgress, SelectChangeEvent } from '@mui/material';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import {
  AddDistributorRequestBody,
  DistributorListType,
  newRmType,
  RmsType,
} from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addDistributor,
  editDistributor,
  getDistributorById,
  getRMsList,
} from '../../redux-store/actions/userManagement';
import { DistributorSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import { Location } from 'history';
import { DistributorTypesForUserManageMent } from '../../utils/constant';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { AddressFieldEditable } from './AddressFields';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';
import { nonDigitRegex } from '../../utils/regex';

export default function AddDistributor({
  location,
}: {
  location: Location<{ typeOfDistributor: string; distributorId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { typeOfDistributor, distributorId } = location.state || {};
  const [rmDropDownMaster, setRmDropDownMaster] = useState<RmsType[]>([]);

  const initialValues: AddDistributorRequestBody = {
    name: '',
    email: '',
    countryNameAndCode: 'India: +91',
    countryCode: '+91',
    phone: '',
    panNumber: '',
    arnCode: '',
    pincode: '',
    buildingNo: '',
    streetName: '',
    city: '',
    state: '',
    type: typeOfDistributor || '',
    country: '',
    isActive: true,
    rmId: null,
  };

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       if (!formikValues) return;
  //       if (distributor) {
  //         await dispatch(editDistributor(id, formikValues));
  //       } else {
  //         await dispatch(addDistributor(formikValues));
  //       }

  //       if (!isComponentActive) return;
  //       history.push('distributors', { distributorType: formikValues.type });
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);

  const [distributorDetails, setDistributorDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const res = (await dispatch(getRMsList({ isActive: true }))) as unknown as newRmType;
        setLoading(true);
        if (distributorId) {
          const getDistributorDetails = (await dispatch(
            getDistributorById(distributorId)
          )) as unknown as DistributorListType;
          const {
            name,
            panNumber,
            arnCode,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            id,
            type,
            rmId,
            isActive,
          } = getDistributorDetails;
          const { countryCode, countryNameAndCode, email, phone } = getDistributorDetails.user || {
            countryCode: '+91',
            countryNameAndCode: 'India: +91',
            email: '',
            phone: '',
          };
          setDistributorDetails({
            ...distributorDetails,
            name,
            panNumber,
            arnCode,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            email,
            phone,
            id,
            type,
            rmId,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
          });
        }
        if (!isComponentAlive) return;
        const { rms } = res || {};
        setRmDropDownMaster(rms);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);
  const onSubmit = async (values: AddDistributorRequestBody) => {
    try {
      if (distributorId) {
        await dispatch(
          editDistributor(distributorId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('distributor-details', { distributorType: values.type, distributorId });
      } else {
        await dispatch(
          addDistributor({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('distributors', { distributorType: values.type });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={distributorDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={DistributorSchema}>
        {({ handleSubmit, values, setFieldValue, setValues }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <BackButtonAndHeader
              User={
                typeOfDistributor
                  ? 'Onboarding RM/Distributor Details'
                  : `${values.type} RM/Distributor Detail`
              }
              onClickBack={() =>
                distributorId
                  ? history.push(`distributor-details`, {
                      distributorType: values.type,
                      distributorId,
                    })
                  : history.push('distributors', { distributorType: values.type })
              }
            />
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                {typeOfDistributor && (
                  <>
                    <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFSelectField
                          name="type"
                          items={Object.keys(DistributorTypesForUserManageMent).map((_ele) => ({
                            key: _ele,
                            value: DistributorTypesForUserManageMent[_ele],
                          }))}
                          label="Distributor Type *"
                          onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                            setValues({
                              ...values,
                              ...initialValues,
                              type: value as unknown as string,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Distributor Name *"
                      placeholder="Enter Distributor Name"
                    />
                  </Grid>
                  {values.type === 'individual' && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="phone"
                          label="Mobile Number *"
                          placeholder="Enter Mobile Number"
                          // startAdornment={
                          //   <CountryCodesDropDown
                          //     name={`countryNameAndCode`}
                          //     value={values.countryNameAndCode}
                          //   />
                          // }
                          regexForFilterValue={nonDigitRegex}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="PAN"
                      placeholder="Enter Pan"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="arnCode"
                      label="ARN Code *"
                      placeholder="Enter ARN Code"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('arnCode', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  {values.type === 'individual' && (
                    <Grid item xs={12} sm={6} md={4}>
                      {/* <MFTextField name="rmName" label="RM Name" placeholder="Enter RM Name" /> */}
                      <SearchableSelect
                        name="rmId"
                        label="RM Name *"
                        items={rmDropDownMaster
                          .sort((rm1, rm2) =>
                            (rm1['name'] || '')
                              .toString()
                              .localeCompare((rm2['name'] || '').toString())
                          )
                          .map((item) => ({
                            key: item.name,
                            value: item.id,
                          }))}
                        searchFieldPlaceholder={'Search RM'}
                      />
                    </Grid>
                  )}
                  <AddressFieldEditable />
                </Grid>
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
