import { DistributorListType, FundManager, Trustee } from './api-types';

export const GET_FUNDS_LIST = 'GET_FUNDS_LIST';
export const GET_FUNDS_LIST_SUCCESS = 'GET_FUNDS_LIST_SUCCESS';

export const ADD_FUND = 'ADD_FUND';
export const ADD_FUND_SUCCESS = 'ADD_FUND_SUCCESS';

export const UPLOAD_BULK_FUND_SUCCESS = 'UPLOAD_BULK_FUND_SUCCESS';
export const UPLOAD_BULK_FUND = 'UPLOAD_BULK_FUND';

export const ADD_PLAN = 'ADD_PLAN';
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS';

export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';

export const GET_FUNDS_ACCESS = 'GET_FUNDS_ACCESS';
export const GET_FUNDS_ACCESS_SUCCESS = 'GET_FUNDS_ACCESS_SUCCESS';

export const UPDATE_FUND = 'UPDATE_FUND';
export const UPDATE_FUND_SUCCESS = 'UPDATE_FUND_SUCCESS';

export const GET_PLAN = 'GET_PLAN';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';

export const GET_PLAN_DETAILS = 'GET_PLAN_DETAILS';
export const GET_PLAN_DETAILS_SUCCESS = 'GET_PLAN_DETAILS_SUCCESS';

export type accessType = {
  fundSetupAccess: boolean;
};

export type addFundType = {
  bankAccountNumber: string;
  schemeCode: string;
  schemeName: string;
  amcCode: string;
  bankName: string;
  bankBranch: string;
  ifscCode: string;
  LEEGALITY_STAMP_SERIES: string;
  LEEGALITY_STAMP_SERIES_GROUP: string;
  LEEGALITY_STAMP_VALUE: number | null;
  LEEGALITY_USE_STAMP_SERIES: boolean | null;
};

export type FundManagerDetails = {
  fundmanagerIds: string[] | number[] | null;
  fundmanagers: FundManager[] | null;
};

export type TrusteeDetails = {
  trusteeIds: string[] | number[] | null;
  trustees: Trustee[] | null;
};

export type topUpPlanType = {
  isTopupApplicable: boolean;
  topupSetupFee: number | null;
  topupMaxCommitmentAmount: number | null;
  topupMinCommitmentAmount: number | null;
  topupMinPrimaryCommitmentAmount: number | null;
  topupMinJointHolder1CommitmentAmount: number | null;
  topupMinJointHolder2CommitmentAmount: number | null;
  topupMinContributionPercentage: number | null;
  topupManagementFee: number | null;
  topupPerformanceFees: string;
  topupHurdleRate: string;
};

export type addPlanType = topUpPlanType & {
  schemeId: number | string | null;
  planCode: string;
  planDescription: string;
  schemeNature: string;
  registrationNumber: string;
  schemeCategory: string;
  country: string;
  currency: string;
  schemeStartDate: string;
  lockInPeriod: string;
  lockInExtension: string;
  inActive: boolean;
  faceValue: number | null;
  fundAddress1: string;
  fundAddress2: string;
  fundAddress3: string;
  isActive: boolean;
  custodian: string;
  setupFee: number | null;
  fundManagerId?: number | null;
  trusteeId?: number | null;
  fundmanagerIds: string[] | number[] | null;
  trusteeIds: string[] | number[] | null;
  requiredFundManagers?: number | null;
  requiredTrustees?: number | null;
  fundmanagerDetails: FundManagerDetails;
  trusteeDetails: TrusteeDetails;
  minCommitmentAmount: number | null;
  maxCommitmentAmount: number | null;
  minContributionPercentage: number | null;
  managementFee: number | null;
  tcLink: string;
  minPrimaryCommitmentAmount: number | null;
  minJointHolder1CommitmentAmount: number | null;
  minJointHolder2CommitmentAmount: number | null;
  performanceFees: string;
  hurdleRate: string;
};

export type addFundPlanType = addFundType &
  addPlanType & {
    id: string;
  };

export type ClassPlanProps = addPlanType & {
  id: string;
  fundManager: FundManager;
  trustee: Trustee;
  createdBy: number | null;
  createdAt: string;
  updatedAt: string;
};

export type FundProps = addFundType & {
  amcId: number;
  isActive: boolean;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  plans: ClassPlanProps[];
  id: string;
  [key: string]: string | number | boolean | ClassPlanProps[] | undefined | null;
};

export type GetFundsListResponseBody = {
  funds: FundProps[];
};

export const GET_FUNDS_SUCCESS = 'GET_FUNDS_SUCCESS';
export const GET_FUNDS = 'GET_FUNDS';
