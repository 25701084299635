import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React, { useEffect, useState } from 'react';
import { Gridstyles } from './index';
import { ApplicationProps, Broker, BrokerList } from '../../redux-store/types/api-types';
import { DLCLMasters, JointHolderDematValidationDate } from '../../utils/constant';
import {
  checkFirstApplicant,
  checkValidationBasedOnDate,
  getclIdField,
  getdpIdField,
  getOlderDate,
  isCDSL,
  nameOfBrokerExists,
} from '../../utils/utilityFunctions';
import { styles } from '../../Styles/styles';
import { useMdmsBrokerListData } from '../../utils/useDataMdms';

export default function KycDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const [brokerList, setBrokerList] = useState<BrokerList>();
  const { BrokerList } = useMdmsBrokerListData();
  useEffect(() => {
    (async function () {
      try {
        setBrokerList(BrokerList as BrokerList);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [BrokerList]);

  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Additional KYC Details
            </Typography>
            {application.applicants?.map((applicant, index: number) => {
              const title = [
                'Primary Contributor Details',
                'Joint Contributor 1 Details',
                'Joint Contributor 2 Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={15}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Occupation Details'}
                        description={applicant.occupationDetails}
                      />
                      <KeyValue
                        title={'Gross Annual Income'}
                        description={applicant.grossAnnualIncome}
                      />
                      {/* <KeyValue
                        title={'Net-Worth (Mandatory For Non-Individuals)'}
                        description={applicant.netWorth}
                      />
                      <KeyValue
                        title={'Net Worth Date'}
                        description={formatShortDate(applicant.netWorthDate)}
                      /> */}
                      <KeyValue
                        title={'Politically Exposed Person (PEP) Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      />
                      <KeyValue title={'CKYC Number'} description={applicant.ckycNo} />
                      {((!checkFirstApplicant(applicant?.applicant_type) &&
                        checkValidationBasedOnDate(
                          getOlderDate(
                            application?.createdAt || '',
                            application?.applicationCreatedAt || ''
                          ),
                          JointHolderDematValidationDate
                        )) ||
                        !checkValidationBasedOnDate(
                          getOlderDate(
                            application?.createdAt || '',
                            application?.applicationCreatedAt || ''
                          ),
                          JointHolderDematValidationDate
                        )) && (
                        <>
                          <KeyValue
                            title={'Demat Account Details (for allotment of units)'}
                            description={DLCLMasters[applicant.dlclId || '']}
                          />
                          {getdpIdField(applicant.dlclId || '') && (
                            <KeyValue
                              title={isCDSL(applicant.dlclId || '') ? 'DPCL ID' : 'DP ID'}
                              description={applicant.dpId}
                            />
                          )}
                          {getclIdField(applicant.dlclId || '') && (
                            <KeyValue title={'CL ID'} description={applicant.clId} />
                          )}
                          {getdpIdField(applicant.dlclId || '') && (
                            <KeyValue
                              title={'Name of Broker/Depository Participant (DP)'}
                              description={
                                nameOfBrokerExists(
                                  brokerList?.broker_list as Broker[],
                                  applicant.nameOfBroker || ''
                                )
                                  ? brokerList?.broker_list.find(
                                      (broker) => broker.key === applicant.nameOfBroker
                                    )?.value
                                  : applicant.nameOfBroker
                              }
                            />
                          )}
                        </>
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
