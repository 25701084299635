import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import {
  Formik,
  setNestedObjectValues,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { Applicant, Bank } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  folioTypeMaster,
  APPLICATION_LISTING_STATUS,
  genderMasters,
  mandatorytext,
  nationalitiesMasters,
  salutationsMasters,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
  yesAndNoMasters1,
} from '../../utils/constant';
import {
  getApplicantName,
  getApplicantType,
  getFirstHolderName,
  minDateForContributor,
  saveForLater,
  getRelation,
  applyRoleBasedStatus,
  applicationComparison,
  removeSingleQuote,
  getStep,
  sendApplication_Nri,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { DatePicker } from '../../lib/formik/DatePicker';
import { ConfirmationDialog } from '../commonComponents';
import { contributorDetailsSchema } from '../../utils/schema';
import MFCheckbox from '../../lib/formik/Checkbox';
import { Relations } from '../../utils/constant';
import { mdmsCountriesList } from '../../redux-store/types/mdms';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { TextDatePicker } from '../../lib/formik/DatePickerWithTextFeild';
import { useMdmsCountryData } from '../../utils/useDataMdms';
import FieldValidationNote from './FieldValidationNote';

const contributorObject: Partial<Applicant> = {
  name: '',
  namePrefix: '',
  motherName: '',
  weddingAnniversaryDate: '',
  fatherOrSpouseName: '',
  dateOfBirth: minDateForContributor().toString(),
  status: '',
  passportNumber: '',
  passportExpiryDate: '',
  nationality: 'INDIAN',
  panNo: '',
  relationShipWithFirstApplicant: '',
  mobile: '',
  gender: '',
  sno: 0,
  leiNumber: '',
  leiExpiry: null,
  physicalCommunication: 'no',
};
type updateApplicant = Applicant & {
  jointApplicantRelation: string | null;
};
export type Values = {
  modeOfHolding: string;
  applicants: Partial<updateApplicant>[];
  banks: Bank[];
  // poaHolderName: string;
  // folioType: string;
  saveType: string;
  nationalityDropdown: string[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  // folioType: 'normalFolio',
  applicants: [contributorObject],
  // poaHolderName: '',
  saveType: 'save and proceed',
  banks: [],
  nationalityDropdown: [],
};

const HolderDetails = ({
  index,
  values,
  nationalitiesMdmsResponse,
  applicationStatus,
  dataFetchedFromKRA,
  ifTopUpApplication,
}: {
  index: number;
  values: Values;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  applicationStatus?: string;
  dataFetchedFromKRA: boolean | null;
  ifTopUpApplication: boolean;
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled =
    [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) || ifTopUpApplication;
  const [open, setOpen] = useState<{
    index: number;
    status: string;
  } | null>(null);

  const handleOpen = (index: number, status: string) => {
    setOpen({ index, status });
  };
  const handleClose = () => {
    setOpen(null);
  };
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.name`}
          label="Name *"
          placeholder="Enter Name"
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
              <MFSelectField
                name={`applicants.${index}.namePrefix`}
                items={salutationsMasters.map((salutation) => ({
                  key: salutation,
                  value: salutation,
                }))}
                disabled={isFieldDisabled}
                sx={{
                  color: 'primary.main',
                  '.MuiSvgIcon-root ': { color: 'primary.main' },
                  '&.MuiInputBase-root': {
                    '.MuiSelect-select': {
                      p: 0,
                      pr: 2.5,
                      border: 0,
                    },
                  },
                  '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
                }}
              />
            </InputAdornment>
          }
          // onChange={({ target: { value } }) => {
          //   setFieldValue(`applicants.${index}.name`, value);
          //   index === 0 &&
          //     setFieldValue(
          //       'banks',
          //       values.banks.length
          //         ? values.banks.map((bank) => ({
          //             ...bank,
          //             pennydropCheck: false,
          //             pennydropVerified: false,
          //           }))
          //         : []
          //     );
          // }}
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.gender`}
          label="Gender *"
          items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
          disabled={ifTopUpApplication}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.motherName`}
          label="Mother Name"
          placeholder="Enter Mother Name"
          disabled={ifTopUpApplication}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Wedding Anniversary'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Wedding Anniversary'}
          name={`applicants.${index}.weddingAnniversaryDate`}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.fatherOrSpouseName`}
          label="Father/Spouse Name *"
          placeholder="Enter Father/Spouse Name"
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Date of birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of birth'}
          maxDate={minDateForContributor()}
          name={`applicants.${index}.dateOfBirth`}
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.status`}
          label="Status *"
          items={Object.keys(applicantStatusMasters)
            // .filter((_appliStatus) => _appliStatus !== 'NRI')
            .map((status) => ({
              key: applicantStatusMasters[status],
              value: status,
            }))}
          disabled={
            isFieldDisabled ||
            APPLICATION_LISTING_STATUS[applicationStatus || ''] !== APPLICATION_LISTING_STATUS.draft
          }
          onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
            if ([applicantStatusMasters.NRI].includes(applicantStatusMasters[value as string])) {
              if (
                APPLICATION_LISTING_STATUS[applicationStatus || ''] ===
                APPLICATION_LISTING_STATUS.sent_to_applicant1
              ) {
                handleOpen(index, value as string);
              } else {
                setFieldValue(`applicants.${index}.status`, value);
                setFieldValue(`applicants.${index}.nationality`, 'INDIAN');
              }
            } else {
              if (
                !values.applicants
                  .map((applicant, ind) => {
                    if (applicant.sno === index) {
                      return value;
                    }
                    return applicant.status;
                  })
                  .includes('NRI') &&
                APPLICATION_LISTING_STATUS[applicationStatus || ''] ===
                  APPLICATION_LISTING_STATUS.sent_to_amc_approver
              ) {
                return handleOpen(index, value as string);
              }

              setFieldValue(`applicants.${index}.status`, value);
            }
          }}
        />
      </Grid>
      {values.applicants[index].status && values.applicants[index].status === 'NRI' && (
        <>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.passportNumber`}
              label={`Passport Number`}
              placeholder="Enter Passport Number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextDatePicker
              label={'Passport Expiry Date'}
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 120))}
              placeholder={'Enter Passport Expiry Date'}
              name={`applicants.${index}.passportExpiryDate`}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <SearchableSelect
          name={`applicants.${index}.nationality`}
          label="Nationality of Contributor *"
          items={nationalitiesMdmsResponse.map((nationality) => ({
            key: nationality.nationality,
            value: nationality.nationality,
          }))}
          disabled={
            isFieldDisabled
            //  ||
            // applicantStatusMasters[values?.applicants[index]?.status as string] ===
            //   applicantStatusMasters.NRI
          }
          searchFieldPlaceholder={'Search Nationality'}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.panNo`}
          label="PAN"
          placeholder="Enter PAN"
          disabled={true}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.leiNumber`}
          label="LEI Number"
          placeholder={'Enter LEI number'}
          disabled={isFieldDisabled}
        />
        <Notes displayContent="Note: Mandatory for Non-Individuals transacting/proposing to transact for an amount of Rs.50 crores or more" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'LEI Expiry Date'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'DD/MM/YYYY'}
          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 120))}
          minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
          name={`applicants.${index}.leiExpiry`}
          disabled={isFieldDisabled}
        />
      </Grid> */}
      <Grid item xs={12} sm={6} mt={2}>
        <UseRadioGroup
          formLabel="Physical Communication *"
          name={`applicants.${index}.physicalCommunication`}
          items={Object.keys(yesAndNoMasters1).map((item) => ({
            label: yesAndNoMasters1[item],
            value: item,
          }))}
          disabled={isFieldDisabled}
        />
      </Grid>
      {index !== 0 && (
        <Grid item xs={12} sm={6}>
          <MFSelectField
            name={`applicants.${index}.jointApplicantRelation`}
            label="Relationship With Primary Contributor *"
            items={Relations.filter((relation) => {
              const { applicants } = values;
              const selectedRelations = applicants
                .filter(
                  (_applicant, ind) =>
                    _applicant.sno !== index &&
                    _applicant.jointApplicantRelation &&
                    ['Mother', 'Father', 'Spouse'].includes(_applicant.jointApplicantRelation)
                )
                .map((applicant) => applicant.jointApplicantRelation);
              return !selectedRelations.includes(relation);
            }).map((relation) => ({
              key: relation,
              value: relation,
            }))}
            disabled={ifTopUpApplication}
          />
          {values.applicants.map((applicant, idx: number) => {
            if (applicant.jointApplicantRelation === 'Others' && applicant.sno === index) {
              return (
                <MFTextField
                  name={`applicants.${index}.relationShipWithFirstApplicant`}
                  label="Please Specify Relationship *"
                  placeholder={`Enter Relationship`}
                  disabled={ifTopUpApplication}
                />
              );
            }
          })}
        </Grid>
      )}
      <ConfirmationDialog
        message={`Any change in the investor status will affect the application's status and workflow. Are you sure you want to proceed?`}
        open={open !== null}
        setOpen={handleClose}
        onSave={() => {
          setFieldValue(`applicants.${open?.index}.status`, open?.status);
          setFieldValue(`applicants.${open?.index}.nationality`, 'INDIAN');
          handleClose();
        }}
        onCancel={handleClose}
      />
      {/* <InfoDialog onClose={handleClose} open={open} content={'status changed to draft.'} /> */}
    </>
  );
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const [applicantToDelete, setApplicantToDelete] = useState<{
    index: number;
  } | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingAddContributor, setLoadingAddContributor] = useState(false);

  const { countries } = useMdmsCountryData();

  useEffect(() => {
    (async function () {
      try {
        setMdmsCountriesList(countries.countries);
        const {
          applicants = [],
          modeOfHolding = '',
          banks = [],
          // folioType = 'normalFolio',
        } = application || {};
        const sortedApplicants = applicants
          .map((applicant) => ({
            ...applicant,
            applicant_type: getApplicantType(applicant.applicant_type),
          }))
          .sort((applicant1, applicant2) => {
            return Number(applicant1.applicant_type) - Number(applicant2.applicant_type);
          });
        setContributorValues({
          ...contributorValues,
          // folioType: folioType || 'normalFolio',
          banks,
          modeOfHolding:
            modeOfHolding === 'joint'
              ? 'joint'
              : sortedApplicants.length > 1
              ? 'either or survivor'
              : modeOfHolding,
          applicants: applicants.length
            ? sortedApplicants.map((applicant, index) => ({
                ...applicant,
                nationality: applicant.nationality
                  ? applicant.nationality.toUpperCase()
                  : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
                  ? ''
                  : 'INDIAN',
                namePrefix:
                  applicant.namePrefix && salutationsMasters.includes(applicant.namePrefix || '')
                    ? applicant.namePrefix
                    : 'Mr.',
                jointApplicantRelation: getRelation(applicant.relationShipWithFirstApplicant)
                  ? applicant.relationShipWithFirstApplicant
                  : 'Others',
                relationShipWithFirstApplicant: getRelation(
                  applicant.relationShipWithFirstApplicant
                )
                  ? ''
                  : applicant.relationShipWithFirstApplicant,
                sno: index,
                physicalCommunication: applicant.physicalCommunication || 'no',
              }))
            : [contributorObject],
          nationalityDropdown: countries.countries.map((list) => list.nationality),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application, countries]);

  const handleApplicant = async (values: Values, addJointHolder = false) => {
    const { applicants = [], modeOfHolding = '', saveType, banks = [] } = values;
    const {
      id,
      currentStep,
      hasPOA,
      status,
      applicant1ReferenceId = '',
      applicationNumber,
      topupApplicationNumber,
    } = application || {};
    // const isAllBanksPennyChecked = banks.length
    //   ? banks.every((bank) => bank.pennydropCheck)
    //   : false;
    const updatedBanks =
      applicants[0].name?.split("'").join('').trim() ===
      application?.applicants[0].name?.split("'").join('').trim()
        ? banks
        : banks.map((bank) => ({
            ...bank,
            pennydropCheck: false,
            pennydropVerified: false,
          }));
    const updatedApplicants = values.applicants.map((applicant) => {
      const { jointApplicantRelation, sno, ...rest } = applicant;
      return {
        ...rest,
        name: removeSingleQuote(applicant.name),
        fatherOrSpouseName: removeSingleQuote(applicant.fatherOrSpouseName),
        motherName: removeSingleQuote(applicant.motherName),
        relationShipWithFirstApplicant: getRelation(jointApplicantRelation)
          ? jointApplicantRelation
          : removeSingleQuote(applicant.relationShipWithFirstApplicant),
        passportNumber: applicant.status === 'NRI' ? applicant.passportNumber : null,
        passportExpiryDate: applicant.status === 'NRI' ? applicant.passportExpiryDate : null,
      };
    });
    const deletingApplicant = updatedApplicants
      ?.filter((_applicant) => !_applicant.isActive)
      ?.map((applicant) => applicant.applicant_type);
    const updatedModeOfHolding =
      applicants.filter((applicant) => applicant.isActive).length === 1 ? 'single' : modeOfHolding;
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        banks: updatedBanks,
        // folioType,
        applicants: updatedApplicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
        modeOfHolding: updatedModeOfHolding,
        currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
      }
    );
    const routerPath = addJointHolder ? 'contributor-details' : 'contact-details';
    const isSaveLater = saveType !== 'save and proceed';
    if (id && !checkApplication) {
      const checkFORValueUpdate = application && deletingApplicant && deletingApplicant.length;
      const holder1CommitmentAmount =
        updatedApplicants?.filter((_applicant) => _applicant.isActive)?.length === 1
          ? null
          : checkFORValueUpdate && application?.planId
          ? deletingApplicant.includes('2')
            ? Number(application.commitmentAmountJointHolder2)
            : Number(application.commitmentAmountJointHolder1)
          : Number(application?.commitmentAmountJointHolder1);
      const holder2CommitmentAmount =
        updatedApplicants?.filter((_applicant) => _applicant.isActive)?.length === 1
          ? null
          : checkFORValueUpdate
          ? null
          : Number(application?.commitmentAmountJointHolder2);
      const totalCommitmentAmount =
        checkFORValueUpdate && application?.planId
          ? Number(holder1CommitmentAmount) +
            Number(holder2CommitmentAmount) +
            Number(application?.commitmentAmountPrimaryApplicant)
          : application?.commitmentAmount;
      const deleteBank = updatedBanks.map((bank) => {
        if (bank.id && bank.applicantId && checkFORValueUpdate) {
          const findApplicant = updatedApplicants.find(
            (_applicant) => Number(_applicant.id) === Number(bank.applicantId)
          );
          return {
            ...bank,
            isActive: findApplicant ? (findApplicant.isActive as boolean) : bank.isActive,
          };
        }
        return bank;
      });

      await dispatch(
        updateApplication({
          body: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...application!,
            banks: deleteBank,
            applicants: updatedApplicants,
            modeOfHolding: updatedModeOfHolding,
            commitmentAmountJointHolder1: application?.planId
              ? holder1CommitmentAmount
              : application?.commitmentAmountJointHolder1,
            commitmentAmountJointHolder2: application?.planId
              ? holder2CommitmentAmount
              : application?.commitmentAmountJointHolder2,
            commitmentAmount: application?.planId
              ? Number(totalCommitmentAmount)
              : application?.commitmentAmount,
            // folioType,
            ...(saveType === 'save and proceed' && {
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : (sendApplication_Nri({ ...application!, applicants: updatedApplicants }) &&
                      APPLICATION_LISTING_STATUS[status as string] ===
                        APPLICATION_LISTING_STATUS.sent_to_applicant1) ||
                    (!sendApplication_Nri({ ...application!, applicants: updatedApplicants }) &&
                      APPLICATION_LISTING_STATUS[status as string] ===
                        APPLICATION_LISTING_STATUS.sent_to_amc_approver)
                  ? 'draft'
                  : status,
            }),
            currentStep: getStep(2, isSaveLater),
            ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
      saveType === 'save and proceed'
        ? history.push(routerPath, {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    } else if (checkApplication) {
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(
          `Application ${
            application?.topupInitiated ? topupApplicationNumber : applicationNumber
          } - ` + ' Saved successfully',
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      }
      saveType === 'save and proceed'
        ? history.push(routerPath, {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      await handleApplicant(values);
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={contributorValues}
      onSubmit={handleSubmit}
      //validationSchema={contributorDetailsSchema}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, contributorDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({
        handleSubmit,
        values,
        setValues,
        validateForm,
        setErrors,
        setTouched,
        setFieldValue,
      }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Notes displayContent={mandatorytext} />
            {/* <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
              <UseRadioGroup
                formLabel="Folio Type *"
                name="folioType"
                defaultValue="normalFolio"
                items={Object.keys(folioTypeMaster).map((source) => ({
                  label: folioTypeMaster[source],
                  value: source,
                }))}
                disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
              />
            </Grid> */}
            {values.applicants
              ?.filter((item) => item.isActive)
              ?.map((applicant, index) => (
                <React.Fragment key={index}>
                  <SubHeading
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    Contributor details of {getApplicantName(index + 1)} Contributor
                    {index !== 0 && ' ' + index}
                    {index !== 0 && !application?.isTopup && (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => setApplicantToDelete({ index: applicant.sno as number })}>
                        <Typography
                          sx={{
                            color: 'rgba(196, 42, 51, 0.8)',
                            fontSize: 12,
                            ml: 'auto',
                            letterSpacing: '-0.2px',
                            cursor: 'pointer',
                          }}>
                          Remove Joint Contributor{' '}
                        </Typography>
                        <IconButton sx={{ p: '2px' }}>
                          <CardMedia
                            component="img"
                            src="/images/delete-red.svg"
                            alt="Delete Icon"
                            sx={{ width: 'unset' }}
                          />
                        </IconButton>
                      </Box>
                    )}
                  </SubHeading>
                  <HolderDetails
                    index={applicant.sno as number}
                    values={values}
                    nationalitiesMdmsResponse={mdmsCountriesList}
                    applicationStatus={application?.status}
                    dataFetchedFromKRA={applicant.dataFetchedFromKRA || null}
                    ifTopUpApplication={!!application?.topupInitiated}
                  />
                </React.Fragment>
              ))}
            {!!values.applicants?.filter((item) => item.isActive).length &&
              values.applicants?.filter((item) => item.isActive).length < 3 &&
              ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) &&
              !application?.isTopup && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 2,
                  }}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{
                      color: 'primary.main',
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                    onClick={async () => {
                      try {
                        const formErrors = await validateForm();
                        if (!Object.keys(formErrors).length) {
                          setLoadingAddContributor(true);
                          await handleApplicant(values, true);
                          history.push('/onboarding/enter-pan-number', {
                            redirectTo:
                              USER_ROLES.AMCAPPROVER === role
                                ? '/investment-details/edit-application/contributor-details'
                                : '/application/contributor-details',
                            application: {
                              ...application,
                              applicants: values.applicants.filter(
                                (activeApplicant) => activeApplicant.isActive
                              ),
                            },
                            firstHolderName: getFirstHolderName(values.applicants),
                          });
                          return;
                        }
                        setTouched(setNestedObjectValues(formErrors, true));
                        setErrors(formErrors);
                      } catch (e) {
                        setLoadingAddContributor(false);
                        console.error((e as Error).message);
                      }
                    }}>
                    Add Joint Contributor
                  </Button>
                  {loadingAddContributor && (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress size={30} />
                    </Box>
                  )}
                </Grid>
              )}
            {values.applicants?.filter((item) => item.isActive).length > 1 && (
              <>
                <SubHeading>Mode of Holding </SubHeading>
                <Grid item xs={12}>
                  <MFCheckbox
                    checked={values.modeOfHolding === 'either or survivor'}
                    name="holding"
                    label="Anyone or Survivor"
                    onChange={({ target: { checked } }) => {
                      setFieldValue('modeOfHolding', checked ? 'either or survivor' : 'joint');
                      setOpen(checked ? false : true);
                    }}
                    disabled={
                      [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) ||
                      application?.topupInitiated ||
                      false
                    }
                  />
                </Grid>
                {/* {values.modeOfHolding !== 'joint' && (
                  <>
                    <SubHeading>POA</SubHeading>
                    <Grid item xs={12}>
                      <UseRadioGroup
                        formLabel=""
                        name="poaHolderName"
                        items={values.applicants.map((applicant, index) => ({
                          label: applicant.name || `Applicant ${index + 1}`,
                          value: applicant.name || `Applicant ${index + 1}`,
                        }))}
                      />
                    </Grid>
                  </>
                )} */}
              </>
            )}
            <FieldValidationNote />
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                p: 4,
                width: { xs: '70%', sm: '70%' },
              },
            }}>
            <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <Box sx={{ mb: 2 }}>
              <CardMedia
                component="img"
                src="/images/warning-icon-red.svg"
                sx={{ width: 'unset', mx: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto',
                '.MuiTypography-root': {
                  fontSize: 20,
                  fontWeight: 500,
                  textAlign: 'center',
                },
              }}>
              <Typography sx={{ color: '#FD3A69', lineHeight: '35px' }}>
                Disabling “Anyone or Survivor” will cause issues later.
              </Typography>
              <Typography sx={{ color: 'primary.main', lineHeight: '35px' }}>
                Are you willing to disable?
              </Typography>
            </Box>
            <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
              <Button
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'joint',
                  });
                  handleClose();
                }}
                variant="outlined"
                sx={{
                  fontSize: 14,
                  color: 'primary.main',
                  minWidth: 200,
                }}>
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'either or survivor',
                  });
                  handleClose();
                }}
                sx={{
                  fontSize: 14,
                  minWidth: 200,
                }}>
                No
              </Button>
            </Grid>
          </Dialog>
          <ConfirmationDialog
            message={`Are you sure you want to delete contributor ?`}
            open={applicantToDelete !== null}
            setOpen={() => setApplicantToDelete(null)}
            onSave={async () => {
              const remainedApplicants = values.applicants.map((applicant, i) => {
                const noOfApplicants = values.applicants.length;
                if (applicant.sno === applicantToDelete?.index) {
                  return {
                    ...applicant,
                    isActive: false,
                  };
                }
                if (noOfApplicants === 3 && applicant.sno === 2) {
                  return {
                    ...applicant,
                    applicant_type: '2',
                  };
                }
                return applicant;
              });
              const defaultPayload = {
                applicants: remainedApplicants,
                modeOfHolding:
                  remainedApplicants.filter((applicant) => applicant.isActive).length === 1
                    ? 'single'
                    : values.modeOfHolding,
              };
              setContributorValues({ ...values, ...defaultPayload });
              setApplicantToDelete(null);
              // if (application?.id) {
              //   setApplicantToDelete(null);
              //   await dispatch(
              //     updateApplication({
              //       body: {
              //         ...application,
              //         ...defaultPayload,
              //         commitmentAmountJointHolder1:
              //           Number(deletingApplicant?.applicant_type) === 2
              //             ? application.commitmentAmountJointHolder2
              //             : application.commitmentAmountJointHolder1,
              //         commitmentAmountJointHolder2: null,
              //         status:
              //           !application?.hasPOA &&
              //           AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
              //           application?.status !== 'draft' &&
              //           applyRoleBasedStatus(role)
              //             ? 'sent_to_amc_approver'
              //             : application?.status,
              //       },
              //       applicationId: application.id,
              //     })
              //   );
              // }
            }}
            onCancel={() => setApplicantToDelete(null)}
          />
        </>
      )}
    </Formik>
  );
}
