import { Box, Button, Dialog, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import { Location } from 'history';
import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import { getFundsList, updatePlan } from '../../redux-store/actions/funds';
import { ClassPlanProps, FundProps, GetFundsListResponseBody } from '../../redux-store/types/funds';
import { useDispatch } from 'react-redux';
import { ColumnType, DataTable } from '../DataTable';
import { Formik } from 'formik';
import {
  getFundManagersById,
  getFundManagersList,
  getIMAccess,
  gettrusteeAccess,
  getTrustesById,
  getTrustessList,
  IMAccess,
  trusteeAccess,
} from '../../redux-store/actions/userManagement';
import { FundManager, Trustee } from '../../redux-store/types/api-types';
import { useSnackbar } from 'notistack';
import { ConfirmationDialog, LoadingDialog } from '../commonComponents';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MFTextField } from '../../lib/formik';
import { showError } from '../../redux-store/actions/auth';
import { getArrayLength } from '../../utils/utilityFunctions';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import { updateImAndTrusteeFundValidation } from '../../utils/schema';
import { styles } from '../../Styles/styles';
import MFSelectField from '../../lib/formik/SelectField';

type UpdatePopupValues = {
  trusteeId?: number | null;
  fundManagerId?: number | null;
  fundmanagerIds: string[] | number[] | null;
  trusteeIds: string[] | number[] | null;
  requiredFundManagers?: number | null;
  requiredTrustees?: number | null;
  comment: string;
};
const planHeader: ColumnType[] = [
  {
    header: 'Plan Code',
    label: 'planCode',
    valueGetter: (row: ClassPlanProps) => row.planCode || 'N/A',
  },
  {
    header: 'Plan Description',
    label: 'planDescription',
    valueGetter: (row: ClassPlanProps) => row.planDescription || 'N/A',
  },
  {
    header: 'Setup Fees Percentage',
    label: 'setupFee',
    valueGetter: (row: ClassPlanProps) => row.setupFee || 'N/A',
  },
  {
    header: 'Minimum Commitment Amount',
    label: 'minCommitmentAmount',
    valueGetter: (row: ClassPlanProps) => row.minCommitmentAmount || 'N/A',
  },
];
//IMId-->investment manager or fundManager id
function MappedPlansForIMAndTrustee({
  location,
}: {
  location: Location<{ trusteeId: number; IMId: number }>;
}): JSX.Element {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { trusteeId: idForTrustee, IMId } = location.state || { trusteeId: null, IMId: null };
  const [plansByTrusteeIdOrIMId, setPlansByTrusteeIdOrIMId] = useState<FundProps[]>();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<{
    planData: ClassPlanProps;
  } | null>(null);
  const [trusteeOrImListForDropDown, setTrusteeOrImListForDropDown] = useState<
    Trustee[] | FundManager[]
  >([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingPopup, setloadingPopup] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<{
    planRowDataForConfirmationDialog: ClassPlanProps;
    updatedTrusteeOrImIds: number[];
    updatedRequiredImOrTrustee: number;
    comment: string;
  } | null>(null);
  const [plansUpdateAccess, setPlansUpdateAccess] = useState(false);
  const handleClose = () => {
    setOpen(null);
  };

  const handleLoadingPopupClose = () => {
    setloadingPopup(false);
  };

  const getImTrusteeFundList = async (show: boolean) => {
    if (idForTrustee) {
      const getDetails = (await dispatch(
        getFundsList({ trusteeId: idForTrustee })
      )) as unknown as GetFundsListResponseBody;
      setPlansByTrusteeIdOrIMId(getDetails.funds);
      if (show) {
        const { trusteeAccess } = (await dispatch(gettrusteeAccess())) as unknown as trusteeAccess;
        setPlansUpdateAccess(trusteeAccess);
      }
    } else if (IMId) {
      const getDetails = (await dispatch(
        getFundsList({ fundManagerId: IMId })
      )) as unknown as GetFundsListResponseBody;
      setPlansByTrusteeIdOrIMId(getDetails.funds);
      if (show) {
        const { fundManagerAccess } = (await dispatch(getIMAccess())) as unknown as IMAccess;
        setPlansByTrusteeIdOrIMId(getDetails.funds);
        setPlansUpdateAccess(fundManagerAccess);
      }
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        await getImTrusteeFundList(true);
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const trusteeUpdateHeader = [
    {
      header: '',
      renderCell: (row: ClassPlanProps): JSX.Element => {
        return (
          <Typography
            variant="h5"
            //   component={RouterLink}
            //   to={{ pathname: 'srms', state: { rmId: row.id } }}
            sx={{
              color: plansUpdateAccess ? '#61D1D6' : '#ccc',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              cursor: plansUpdateAccess ? 'pointer' : 'not-allowed',
              pointerEvents: plansUpdateAccess ? 'all' : 'none',
            }}
            onClick={async () => {
              try {
                setloadingPopup(true);
                if (idForTrustee) {
                  const trusteeRes = (await dispatch(
                    getTrustessList({ isActive: true })
                  )) as unknown as Trustee[];
                  const checkTrusteeId = trusteeRes.find(
                    (_list) => Number(_list.id) === Number(idForTrustee)
                  );
                  if (checkTrusteeId) {
                    setTrusteeOrImListForDropDown(trusteeRes);
                  } else {
                    const getDetails = (await dispatch(
                      getTrustesById(idForTrustee)
                    )) as unknown as Trustee;
                    setTrusteeOrImListForDropDown([...trusteeRes, getDetails]);
                  }
                } else if (IMId) {
                  const res = (await dispatch(
                    getFundManagersList({ isActive: true })
                  )) as unknown as FundManager[];
                  const checkIMId = res.find((_list) => Number(_list.id) === Number(IMId));
                  if (checkIMId) {
                    setTrusteeOrImListForDropDown(res);
                  } else {
                    const getDetails = (await dispatch(
                      getFundManagersById(IMId)
                    )) as unknown as FundManager;
                    setTrusteeOrImListForDropDown([...res, getDetails]);
                  }
                }
                setOpen({ planData: row });
                handleLoadingPopupClose();
              } catch (e) {
                handleLoadingPopupClose();
                console.error((e as Error).message);
              }
            }}>
            {idForTrustee ? 'Update Trustee' : 'Update Investment Manager'}
          </Typography>
        );
      },
    },
  ];

  const initialValues: UpdatePopupValues = {
    trusteeId: open?.planData.trusteeId as number,
    fundManagerId: open?.planData.fundManagerId as number,
    trusteeIds: open?.planData?.trusteeDetails?.trusteeIds as number[],
    fundmanagerIds: open?.planData?.fundmanagerDetails?.fundmanagerIds as number[],
    requiredFundManagers: open?.planData?.requiredFundManagers as number,
    requiredTrustees: open?.planData?.requiredTrustees as number,
    comment: '',
  };

  const onSubmit = (values: UpdatePopupValues) => {
    setOpenConfirmationDialog({
      planRowDataForConfirmationDialog: open?.planData as ClassPlanProps,
      updatedTrusteeOrImIds: (idForTrustee ? values.trusteeIds : values.fundmanagerIds) as number[],
      updatedRequiredImOrTrustee: (idForTrustee
        ? values.requiredTrustees
        : values.requiredFundManagers) as number,
      comment: values.comment,
    });
  };

  return (
    <Box sx={{ bgcolor: 'common.white', px: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          pt: { xs: 2, sm: 7 },
          pb: { xs: 2, sm: 4 },
          flexDirection: { xs: 'column', sm: 'row' },
        }}>
        <IconButton
          sx={{
            fontSize: 20,
            ':hover': {
              textDecoration: 'underline',
              backgroundColor: '#fff',
            },
          }}
          onClick={() => history.push(idForTrustee ? 'trustee' : 'invest-managers')}>
          {/* <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} /> */}
          {idForTrustee ? 'Trustee' : 'Investment Manager'}
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NavigateNextIcon fontSize="medium" />
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              color: '#1C2D47',
            }}>
            {'List of Mapped Plans'}
          </Typography>
        </Box>
      </Box>
      {/* <Box sx={{ pb: { xs: 2, sm: 4 } }}>
        <Notes displayContent={'If you want to update trustee then plans should be active'} />
      </Box> */}
      {loading ? (
        <LinearProgress />
      ) : plansByTrusteeIdOrIMId && plansByTrusteeIdOrIMId.length > 0 ? (
        plansByTrusteeIdOrIMId.map((fundAndPlans, index) => {
          return (
            <React.Fragment key={index}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'primary.main',
                  pl: { xs: 2, sm: 2 },
                  textTransform: 'capitalize',
                  pt: 2,
                  pb: 0.5,
                }}>
                {fundAndPlans.schemeName}
              </Typography>
              <Grid item xs={12} sm={12} lg={12} sx={{ pb: 2 }}>
                <DataTable
                  tableData={fundAndPlans.plans}
                  tableHeader={[...planHeader, ...trusteeUpdateHeader]}
                  isLoading={loading}
                  renderAdditionalRow={true}
                  tableBodyCustomStyles={styles.UsersTableBodyCustomStyles}
                  tableHeaderCustomStyles={styles.UsersTableHeaderCustomStyles}
                  boxStyles={{
                    maxHeight: 'calc(100vh - 240px)',
                    minHeight: '150px',
                  }}
                />
              </Grid>
            </React.Fragment>
          );
        })
      ) : (
        <Typography
          sx={{
            textAlign: 'center',
            height: 100,
          }}>
          No data found
        </Typography>
      )}

      <Dialog
        onClose={handleClose}
        open={open !== null}
        sx={{
          '.MuiPaper-root ': {
            py: 4,
            px: 2,
            width: { xs: '90%', sm: '70%' },
          },
        }}>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 18,
              fontWeight: 500,
            },
          }}>
          <Typography sx={{ color: 'primary.main', pb: 3, textAlign: 'center' }}>
            {idForTrustee ? 'Update Trustee' : 'Update Investment Manager'}
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={updateImAndTrusteeFundValidation(IMId, idForTrustee)}>
            {({ handleSubmit, values }) => (
              <Box component="form" noValidate onSubmit={handleSubmit}>
                <MultipleSelect
                  name={idForTrustee ? 'trusteeIds' : 'fundmanagerIds'}
                  label={idForTrustee ? 'Trustee(s)' : 'Investment Manager(s)'}
                  items={trusteeOrImListForDropDown.map((item) => ({
                    key: String(item.id),
                    value: item.name,
                  }))}
                  renderText={
                    idForTrustee ? 'Selected Trustee(s)' : 'Selected Investment Manager(s)'
                  }
                />
                <MFSelectField
                  name={idForTrustee ? 'requiredTrustees' : 'requiredFundManagers'}
                  label={
                    idForTrustee
                      ? 'No. of Trustee(s) to esign'
                      : 'No. of Investment Manager(s) to esign'
                  }
                  items={getArrayLength(
                    (idForTrustee ? values.trusteeIds : values.fundmanagerIds)?.length || 1
                  ).map((thresholdValue) => ({
                    key: thresholdValue,
                    value: thresholdValue,
                  }))}
                />

                <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    variant="outlined"
                    sx={{
                      fontSize: 15,
                      color: 'primary.main',
                      minWidth: 180,
                      letterSpacing: 2,
                      fontWeight: 500,
                    }}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: 15,
                      minWidth: 180,
                      letterSpacing: 2,
                      fontWeight: 400,
                    }}
                    type="submit">
                    Update
                  </Button>
                </Grid>
              </Box>
            )}
          </Formik>
        </Box>
      </Dialog>
      <ConfirmationDialog
        message={`Are you sure you want to update the ${
          idForTrustee ? 'Trustee' : 'Investment Manager'
        } ?`}
        open={openConfirmationDialog !== null}
        setOpen={() => setOpenConfirmationDialog(null)}
        onSave={async () => {
          try {
            handleClose();
            setOpenConfirmationDialog(null);
            setloadingPopup(true);
            const updatedTrusteeorIMIds =
              openConfirmationDialog?.updatedTrusteeOrImIds
                ?.sort((id1, id2) => Number(id1) - Number(id2))
                ?.map((f) => Number(f)) || [];
            if (idForTrustee) {
              await dispatch(
                updatePlan(Number(openConfirmationDialog?.planRowDataForConfirmationDialog.id), {
                  ...openConfirmationDialog?.planRowDataForConfirmationDialog,
                  trusteeIds: updatedTrusteeorIMIds,
                  trusteeDetails: {
                    trusteeIds: updatedTrusteeorIMIds,
                    trustees: trusteeOrImListForDropDown
                      ?.sort((fund1, fund2) => Number(fund1?.id) - Number(fund2?.id))
                      ?.filter((f) => updatedTrusteeorIMIds.includes(Number(f.id))),
                  },
                  requiredTrustees: openConfirmationDialog?.updatedRequiredImOrTrustee,
                })
              );
            } else if (IMId) {
              await dispatch(
                updatePlan(Number(openConfirmationDialog?.planRowDataForConfirmationDialog.id), {
                  ...openConfirmationDialog?.planRowDataForConfirmationDialog,
                  fundmanagerIds: updatedTrusteeorIMIds,
                  fundmanagerDetails: {
                    fundmanagerIds: updatedTrusteeorIMIds,
                    fundmanagers: trusteeOrImListForDropDown
                      ?.sort((fund1, fund2) => Number(fund1?.id) - Number(fund2?.id))
                      ?.filter((f) => updatedTrusteeorIMIds.includes(Number(f.id))),
                  },
                  requiredFundManagers: openConfirmationDialog?.updatedRequiredImOrTrustee,
                })
              );
            }
            if (IMId || idForTrustee) {
              await getImTrusteeFundList(false);
              handleLoadingPopupClose();
              enqueueSnackbar(`${IMId ? 'Investment Manager' : 'Trustee'} Updated Successfully`, {
                variant: 'success',
                autoHideDuration: 3000,
              });
            }
          } catch (e) {
            handleLoadingPopupClose();
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setOpenConfirmationDialog(null)}
      />
      <LoadingDialog loadingPopup={loadingPopup} onLoadingPopupClose={handleLoadingPopupClose} />
    </Box>
  );
}
export default MappedPlansForIMAndTrustee;
