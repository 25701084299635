import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { Notes, NotesWithText, ProceedSaveLater } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { ApplicationProps, JointholderRelationMaster } from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { DatePicker } from '../../lib/formik/DatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFunds } from '../../redux-store/actions/funds';
import { ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { investmentPaymentDetailsSchema } from '../../utils/schema';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  mandatorytext,
  USER_ROLES,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  currencyConversion,
  DoNotWishToValidateCommitmentAmountRoles,
  getFolioTypeBasedOnPlan,
  saveForLater,
  setUpFeeCalculations,
  sumOfCommitmentAmount,
  updatedCommitmentAmount,
  updatedMinimumCommitmentAmount,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { jointHolderRelationsList } from '../../redux-store/actions';
import { showError } from '../../redux-store/actions/auth';
import MFCheckbox from '../../lib/formik/Checkbox';

type Values = Partial<ApplicationProps> & {
  saveType: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  setupFeePercentage: 0,
  managementFeesRate: 0,
  taxRate: 0,
  drawDownNumber: 0,
  contributionChequeAmount: 0,
  setupFeeAmount: null,
  setupFeeGST: null,
  totalSetupFee: null,
  setupFeeTDS: null,
  netSetupFeeLessTDS: null,
  totalAmountPayable: null,
  commitmentAmountJointHolder1: null,
  commitmentAmountJointHolder2: null,
  commitmentAmountPrimaryApplicant: 0,
};

const initialValues: Values = {
  ...fundInitialValues,
  modeOfPayment: 'cheque',
  paymentRefNo: '',
  chequeDate: '',
  paymentBankAccountNumber: '',
  paymentBankame: '',
  paymentBankAccontType: '',
  saveType: 'save and proceed',
  doNotWishToValidateCommitmentAmount: false,
};

const planValidationCheck = (getApplicants: any, planCode?: string) => {
  return getApplicants && planCode && getApplicants.length > 1 && planCode === 'E';
};

export default function InvestmentPaymentDetails(): JSX.Element {
  // const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [jointHoldersData, setJointHoldersData] = useState<JointholderRelationMaster>();
  const commitmentAmountLimitPerApplicantWhenCheckLimitEnabled = 10000000;
  const getApplicants =
    application?.applicants.map((applicant) => applicant.applicant_type?.toString()) || [];
  const secondApplicantContributeStatus = application?.applicants.find(
    (applicant) => Number(applicant.applicant_type) === 2
  )?.wishToContribute;

  const {
    minCommitmentAmount = 10000000,
    maxCommitmentAmount = 20000000,
    minContributionPercentage = 10,
    minPrimaryCommitmentAmount = 10000000,
    minJointHolder1CommitmentAmount = 10000000,
    minJointHolder2CommitmentAmount = 10000000,
  } = selectedPlan || {};

  useEffect(() => {
    const { plan } = application || ({} as ApplicationProps);
    const {
      minCommitmentAmount = 1,
      maxCommitmentAmount = 0,
      minContributionPercentage = 1,
      minPrimaryCommitmentAmount = 1,
      minJointHolder1CommitmentAmount = 1,
      minJointHolder2CommitmentAmount = 1,
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            minCommitmentAmount: Number(minCommitmentAmount),
            maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            minPrimaryCommitmentAmount: Number(minPrimaryCommitmentAmount),
            minJointHolder1CommitmentAmount: Number(minJointHolder1CommitmentAmount),
            minJointHolder2CommitmentAmount: Number(minJointHolder2CommitmentAmount),
          }
        : null
    );
    const updateCommitmentAmount = application?.commitmentAmount
      ? !secondApplicantContributeStatus
        ? Number(application?.commitmentAmount) - Number(application?.commitmentAmountJointHolder1)
        : application?.commitmentAmount
      : application?.commitmentAmount || null;
    const updateContributionAmount = Number(application?.contributionChequeAmount)
      ? !secondApplicantContributeStatus
        ? (Number(updateCommitmentAmount) * Number(application?.plan?.minContributionPercentage)) /
          100
        : Number(application?.contributionChequeAmount)
      : Number(application?.contributionChequeAmount) || 0;

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount: updateCommitmentAmount,
      setupFeePercentage: application?.setupFeePercentage || 0,
      managementFeesRate: application?.managementFeesRate || 0,
      // taxRate: application?.taxRate || null,
      // drawDownNumber: application?.drawDownNumber || null,
      contributionChequeAmount: Number(application?.contributionChequeAmount) || 0,
      modeOfPayment: application?.modeOfPayment || '',
      paymentRefNo: application?.paymentRefNo || '',
      chequeDate: application?.chequeDate || null,
      paymentBankAccountNumber: application?.paymentBankAccountNumber || '',
      paymentBankame: application?.paymentBankame || '',
      paymentBankAccontType: application?.paymentBankAccontType || '',
      setupFeeAmount: application?.setupFeeAmount,
      setupFeeGST: application?.setupFeeGST,
      totalSetupFee: application?.totalSetupFee,
      setupFeeTDS: application?.setupFeeTDS,
      netSetupFeeLessTDS: application?.netSetupFeeLessTDS,
      totalAmountPayable: application?.totalAmountPayable,
      commitmentAmountJointHolder1:
        application?.commitmentAmountJointHolder1 === 0
          ? application?.commitmentAmountJointHolder1
          : !secondApplicantContributeStatus
          ? 0
          : application?.commitmentAmountJointHolder1 || null,
      commitmentAmountJointHolder2:
        application?.commitmentAmountJointHolder2 === 0
          ? application?.commitmentAmountJointHolder2
          : application?.commitmentAmountJointHolder2 || null,
      commitmentAmountPrimaryApplicant: application?.commitmentAmountPrimaryApplicant || 0,
      doNotWishToValidateCommitmentAmount:
        application?.doNotWishToValidateCommitmentAmount || false,
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        status,
        hasPOA,
        applicationNumber,
        folioType,
      } = application || {};
      const { saveType, ...rest } = values;
      const checkApplication = applicationComparison(
        { ...application, setupFeePercentage: Number(application?.setupFeePercentage) },
        {
          ...application,
          ...rest,
          folioType: getFolioTypeBasedOnPlan(selectedPlan?.planCode),
          contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
          setupFeePercentage: Number(rest.setupFeePercentage),
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > 3
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 7
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              ...rest,
              folioType: getFolioTypeBasedOnPlan(selectedPlan?.planCode),
              contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
              setupFeePercentage: Number(rest.setupFeePercentage),
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role) &&
                applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? 4 : 8,
              // applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              //   ? !!currentStep && currentStep > 3
              //     ? currentStep
              //     : Number(currentStep) + 1
              //   : !!currentStep && currentStep > 7
              //   ? currentStep
              //   : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = (await dispatch(getFunds({ isActive: true }))) as unknown as FundProps[];
        if (!isComponentAlive) {
          return;
        }
        const { scheme } = application || {};
        const updatedFundPlan =
          application?.schemeId && !application.scheme.isActive
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)) || null;
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const getClassPlanOptionsOrDetails = (detail?: string) => {
    if (detail) {
      return selectedFund?.[detail] || '';
    }
    return (
      selectedFund?.plans?.map((plan) => ({ key: plan.planDescription, value: Number(plan.id) })) ||
      []
    );
  };

  useEffect(() => {
    (async function () {
      try {
        const jointHodersMdmsMasters = (await dispatch(
          jointHolderRelationsList()
        )) as unknown as JointholderRelationMaster;
        setJointHoldersData(jointHodersMdmsMasters);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={paymentDetails}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        const minCommitmentAmountBasedOnJointHolders =
          application && application.applicants?.length > 1
            ? updatedMinimumCommitmentAmount(
                values.doNotWishToValidateCommitmentAmount
                  ? getApplicants?.length === 2 && !secondApplicantContributeStatus
                    ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                    : commitmentAmountLimitPerApplicantWhenCheckLimitEnabled * getApplicants.length
                  : Number(minCommitmentAmount),
                values.doNotWishToValidateCommitmentAmount
                  ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                  : Number(minPrimaryCommitmentAmount),
                values.doNotWishToValidateCommitmentAmount
                  ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                  : Number(minJointHolder1CommitmentAmount),
                values.doNotWishToValidateCommitmentAmount
                  ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                  : Number(minJointHolder2CommitmentAmount),
                application.applicants,
                secondApplicantContributeStatus
              )
            : {
                primaryApplicantMinCommitmentAmount: values.doNotWishToValidateCommitmentAmount
                  ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                  : Number(minCommitmentAmount),
                jointHolder1MinCommitmentAmount: 0,
                jointHolder2MinCommitmentAmount: 0,
              };
        // updatedCommitmentAmount(
        //   Number(minCommitmentAmount),
        //   application.applicants,
        //   jointHoldersData
        // );
        try {
          validateYupSchema(
            values,
            investmentPaymentDetailsSchema(
              values.doNotWishToValidateCommitmentAmount
                ? getApplicants?.length === 2 && !secondApplicantContributeStatus
                  ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                  : commitmentAmountLimitPerApplicantWhenCheckLimitEnabled * getApplicants.length
                : Number(minCommitmentAmount),
              maxCommitmentAmount || 0,
              minContributionPercentage || 0,
              minCommitmentAmountBasedOnJointHolders?.primaryApplicantMinCommitmentAmount,
              minCommitmentAmountBasedOnJointHolders?.jointHolder1MinCommitmentAmount,
              minCommitmentAmountBasedOnJointHolders?.jointHolder2MinCommitmentAmount,
              getApplicants?.length || 1
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Notes displayContent={mandatorytext} />
          {DoNotWishToValidateCommitmentAmountRoles(role) && (
            <Grid item xs={12}>
              <MFCheckbox
                label={'Wish To Lower the Commitment Amount'}
                name={'doNotWishToValidateCommitmentAmount'}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SearchableSelect
              name={'schemeId'}
              label="Scheme Name *"
              items={fundsList.map((fund) => ({ value: Number(fund.id), key: fund.schemeName }))}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                setValues({
                  ...values,
                  ...fundInitialValues,
                  schemeId: value as string,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                });
                setSelectedFund(fundsList.find((f) => Number(f.id) === value) || null);
                setSelectedPlan(null);
              }}
              disabled={isFieldDisabled}
              searchFieldPlaceholder={'Search Scheme Name'}
            />
          </Grid>
          {/* {!!values.schemeId && (
            <Grid item xs={12}>
              <Box
                sx={{
                  boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                  p: { xs: '20px', sm: '30px 40px' },
                  my: 2,
                }}>
                <Box
                  onClick={() => setOpen(!open)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 600,
                      fontSize: 16,
                      color: 'primary.main',
                      letterSpacing: '0.05em',
                    }}>
                    Bank Details related to this fund (Scheme)
                  </Typography>
                  <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} />
                </Box>
                {open && (
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      mt: 3,
                      '.MuiTypography-root': {
                        fontSize: 16,
                        letterSpacing: '0.05em',
                        '&.MuiTypography-body2 ': { fontWeight: 600 },
                      },
                    }}>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Name</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Account Number</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankAccountNumber')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>IFSC Code</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('ifscCode')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Branch</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankBranch')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          )} */}
          <Grid item xs={12} sm={6}>
            <SearchableSelect
              name={'planId'}
              label="Class/Subclass *"
              items={getClassPlanOptionsOrDetails() as [{ key: string; value: string | number }]}
              disabled={!values.schemeId || isFieldDisabled}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                try {
                  const selectedPlan = selectedFund?.plans
                    ?.filter((plan) => plan.isActive)
                    ?.find((p) => Number(p.id) === value);
                  setSelectedPlan(selectedPlan || null);
                  setValues({
                    ...values,
                    planId: value as string,
                    commitmentAmount: 0,
                    contributionChequeAmount: 0,
                    setupFeePercentage: 0,
                    managementFeesRate: selectedPlan?.managementFee,
                    setupFeeAmount: 0,
                    setupFeeGST: 0,
                    totalSetupFee: 0,
                    setupFeeTDS: 0,
                    netSetupFeeLessTDS: 0,
                    totalAmountPayable: 0,
                    commitmentAmountJointHolder1: 0,
                    commitmentAmountJointHolder2: 0,
                    commitmentAmountPrimaryApplicant: 0,
                  });
                  if (planValidationCheck(getApplicants, selectedPlan?.planCode)) {
                    throw `${selectedPlan?.planDescription} not applicable for Joint Contributor(s)`;
                  }
                } catch (e) {
                  typeof e === 'string' && dispatch(showError(e));
                }
              }}
              searchFieldPlaceholder={'Search Class (Plan)'}
            />
          </Grid>
          {getApplicants && getApplicants.length > 0 ? (
            <>
              {getApplicants.includes('1') && (
                <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                  <MFTextField
                    name={'commitmentAmountPrimaryApplicant'}
                    label={`Primary/Solo Contributor Commitment Amount *`}
                    type="number"
                    placeholder={`Enter Primary/Solo Contributor Commitment Amount *`}
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                      const getCommitmentAmount = sumOfCommitmentAmount(
                        Number(value),
                        Number(values.commitmentAmountJointHolder1),
                        Number(values.commitmentAmountJointHolder2)
                      );
                      const feeAmountObj = setUpFeeCalculations(
                        getCommitmentAmount || 0,
                        values.setupFeePercentage || 0
                      );
                      const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                      setValues({
                        ...values,
                        commitmentAmount: getCommitmentAmount,
                        commitmentAmountPrimaryApplicant: value ? Number(value) : null,
                        setupFeeAmount: feeAmountObj.feeAmount,
                        setupFeeGST: feeAmountObj.feeGst,
                        totalSetupFee: feeAmountObj.totalSetupFee,
                        netSetupFeeLessTDS: netSetupFee,
                        totalAmountPayable: values.contributionChequeAmount || getCommitmentAmount,
                      });
                    }}
                    trimOnBlur={false}
                    disabled={
                      isFieldDisabled ||
                      !values.planId ||
                      planValidationCheck(getApplicants, selectedPlan?.planCode)
                    }
                  />
                  {values.commitmentAmountPrimaryApplicant != 0 && (
                    <Typography sx={{ fontSize: 13 }}>
                      {currencyConversion(values.commitmentAmountPrimaryApplicant)}
                    </Typography>
                  )}
                </Grid>
              )}
              {getApplicants.includes('2') && application && (
                <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                  <MFTextField
                    name={'commitmentAmountJointHolder1'}
                    label={`Joint Contributor 1 Commitment Amount ${
                      updatedMinimumCommitmentAmount(
                        values.doNotWishToValidateCommitmentAmount
                          ? getApplicants?.length === 2 && !secondApplicantContributeStatus
                            ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                            : commitmentAmountLimitPerApplicantWhenCheckLimitEnabled *
                              getApplicants.length
                          : Number(minCommitmentAmount),
                        values.doNotWishToValidateCommitmentAmount
                          ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                          : Number(minPrimaryCommitmentAmount),
                        values.doNotWishToValidateCommitmentAmount
                          ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                          : Number(minJointHolder1CommitmentAmount),
                        values.doNotWishToValidateCommitmentAmount
                          ? commitmentAmountLimitPerApplicantWhenCheckLimitEnabled
                          : Number(minJointHolder2CommitmentAmount),
                        application.applicants,
                        secondApplicantContributeStatus
                      ).jointHolder1MinCommitmentAmount
                        ? '*'
                        : ''
                    }`}
                    type="number"
                    placeholder="Enter Joint Contributor 1 Commitment Amount"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                      const getCommitmentAmount = sumOfCommitmentAmount(
                        Number(values.commitmentAmountPrimaryApplicant),
                        Number(value),
                        Number(values.commitmentAmountJointHolder2)
                      );
                      const feeAmountObj = setUpFeeCalculations(
                        getCommitmentAmount || 0,
                        values.setupFeePercentage || 0
                      );
                      const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                      setValues({
                        ...values,
                        commitmentAmount: getCommitmentAmount,
                        commitmentAmountJointHolder1: value ? Number(value) : null,
                        setupFeeAmount: feeAmountObj.feeAmount,
                        setupFeeGST: feeAmountObj.feeGst,
                        totalSetupFee: feeAmountObj.totalSetupFee,
                        netSetupFeeLessTDS: netSetupFee,
                        totalAmountPayable: values.contributionChequeAmount || getCommitmentAmount,
                      });
                    }}
                    trimOnBlur={false}
                    disabled={
                      isFieldDisabled ||
                      !values.planId ||
                      planValidationCheck(getApplicants, selectedPlan?.planCode) ||
                      !secondApplicantContributeStatus
                    }
                  />
                  {values.commitmentAmountJointHolder1 != 0 && (
                    <Typography sx={{ fontSize: 13 }}>
                      {currencyConversion(values.commitmentAmountJointHolder1)}
                    </Typography>
                  )}
                </Grid>
              )}
              {getApplicants.includes('3') && (
                <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                  <MFTextField
                    name={'commitmentAmountJointHolder2'}
                    label="Joint Contributor 2 Commitment Amount *"
                    type="number"
                    placeholder="Enter Joint Contributor 2 Commitment Amount"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                      const getCommitmentAmount = sumOfCommitmentAmount(
                        Number(values.commitmentAmountPrimaryApplicant),
                        Number(values.commitmentAmountJointHolder1),
                        Number(value)
                      );
                      const feeAmountObj = setUpFeeCalculations(
                        getCommitmentAmount || 0,
                        values.setupFeePercentage || 0
                      );
                      const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                      setValues({
                        ...values,
                        commitmentAmount: getCommitmentAmount,
                        commitmentAmountJointHolder2: value ? Number(value) : null,
                        setupFeeAmount: feeAmountObj.feeAmount,
                        setupFeeGST: feeAmountObj.feeGst,
                        totalSetupFee: feeAmountObj.totalSetupFee,
                        netSetupFeeLessTDS: netSetupFee,
                        totalAmountPayable: values.contributionChequeAmount || getCommitmentAmount,
                      });
                    }}
                    trimOnBlur={false}
                    disabled={
                      isFieldDisabled ||
                      !values.planId ||
                      planValidationCheck(getApplicants, selectedPlan?.planCode)
                    }
                  />
                  {values.commitmentAmountJointHolder2 != 0 && (
                    <Typography sx={{ fontSize: 13 }}>
                      {currencyConversion(values.commitmentAmountJointHolder2)}
                    </Typography>
                  )}
                </Grid>
              )}
            </>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={'managementFeesRate'}
              label="Management Fees Rate"
              placeholder="Enter Management Fee Percentage"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'taxRate'}
              label="Tax Rate"
              placeholder="Enter Tax Rate"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={'drawDownNumber'}
              label="Drawdown Number"
              items={DrawDownMasters.map((master) => ({ key: master, value: master }))}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'contributionChequeAmount'}
              label="Contribution Amount *"
              placeholder="Enter Contribution Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  contributionChequeAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: Number(value) || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={
                isFieldDisabled ||
                !values.planId ||
                planValidationCheck(getApplicants, selectedPlan?.planCode)
              }
            />
            {values.contributionChequeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.contributionChequeAmount)}
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'setupFeePercentage'}
              label="Setup Fee Percentage *"
              type="number"
              placeholder="Enter Setup Fee Percentage"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  Number(value)
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  setupFeePercentage: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: values.contributionChequeAmount || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeAmount'}
              label="Setup Fee Amount"
              type="number"
              placeholder="Enter Setup Fee Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.setupFeeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeGST'}
              label="GST (GST On Setup Fee Amount)"
              type="number"
              placeholder="Enter GST"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.setupFeeGST != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeGST)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalSetupFee'}
              label="Total Setup Fee"
              type="number"
              placeholder="Enter Total Setup Fee"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalSetupFee != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalSetupFee)}
              </Typography>
            )}
          </Grid> */}
          {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeTDS'}
              label="TDS"
              type="number"
              placeholder="Enter TDS"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const setupFeeLessTDS = (values.totalSetupFee || 0) - Number(value);
                setValues({
                  ...values,
                  setupFeeTDS: value ? Number(value) : null,
                  netSetupFeeLessTDS:
                    values.totalSetupFee && value ? setupFeeLessTDS : values.totalSetupFee,
                  totalAmountPayable: values.contributionChequeAmount || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.setupFeeTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeTDS)}
              </Typography>
            )}
          </Grid> */}
          {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'netSetupFeeLessTDS'}
              label="Setup Fee Payable"
              type="number"
              placeholder="Enter Setup Fee Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.netSetupFeeLessTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.netSetupFeeLessTDS)}
              </Typography>
            )}
          </Grid> */}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalAmountPayable'}
              label="Total Amount Payable"
              type="number"
              placeholder="Enter Total Amount Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalAmountPayable != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalAmountPayable)}
              </Typography>
            )}
          </Grid>
          {/* <Grid
            item
            xs={12}
            onClick={() => setPaymentOpen(!paymentOpen)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              my: 2,
            }}>
            <Typography
              component="h3"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                color: 'secondary.main',
              }}>
              Payment Details
            </Typography>
            <ExpandMoreIcon sx={{ fontSize: 30, transform: paymentOpen ? 'rotate(180deg)' : '' }} />
          </Grid> */}
          {/* {paymentOpen && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankame'}
                  label="Payment Bank Name"
                  placeholder="Enter Payment Bank Name"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankAccountNumber'}
                  label="Payment Bank Account Number"
                  placeholder="Enter Payment Bank Account Number"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                <UseRadioGroup
                  name={'paymentBankAccontType'}
                  formLabel="Payment Bank Account Type"
                  defaultValue="current"
                  items={[
                    { label: 'Current', value: 'current' },
                    { label: 'Savings', value: 'savings' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <UseRadioGroup
                  name={'modeOfPayment'}
                  formLabel="Mode of Payment"
                  defaultValue="cheque"
                  items={[
                    { label: 'Cheque', value: 'cheque' },
                    { label: 'RTGS', value: 'rtgs' },
                    { label: 'Online Transfer', value: 'online-transfer' },
                    { label: 'Others', value: 'others' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              {values.modeOfPayment === 'cheque' && (
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Payment/Cheque Date'}
                    placeholder={'Enter Cheque Date'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    name="chequeDate"
                    disabled={isFieldDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentRefNo'}
                  label="Payment Ref No"
                  placeholder="Enter Payment Ref No"
                  disabled={isFieldDisabled}
                />
              </Grid>
            </>
          )} */}
          {/* <Grid item xs={12}>
            <NotesWithText
              noteTitle="Disclaimer"
              displayContent="Set Up Expenses will be calculated at actuals; not exceeding 0.25% p.a. Units shall be issued to the NFO Contributor for an amount which shall be equal to the Capital Contribution less the Set-Up Expenses including GST.  The set-up expenses shall not be borne by Class E Unitholders."
              background="rgba(229, 67, 92, 0.15)"
              textColor="#E5435C"
            />
          </Grid> */}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
            disabled={planValidationCheck(getApplicants, selectedPlan?.planCode)}
          />
        </Grid>
      )}
    </Formik>
  );
}
