import { ExitToApp } from '@mui/icons-material';
import { AppBar, Divider, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Location } from 'history';
import { Redirect, Route, Switch, useRouteMatch, useHistory, useLocation } from 'react-router';
import { logout } from '../../redux-store/actions/auth';
import LoginOtp from '../auth/loginOtp';
import { Footer } from '../commonComponents';
import { Routes } from '../investors/routes';
import InvestmentDetails from './investment-details';
import InvestorApplication from './investor-application';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ROLES } from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import LoginRequireRoute from '../../routes/LoginRequireRoute';
import ProtectedRoute from '../../routes/ProtectedRoute';
import { LogoEditApplication } from '../Logo';

export const EditApplication = ({
  location,
}: {
  location: Location<{
    id: string;
    applicant1ReferenceId?: string;
    topUpApplication?: boolean;
  }>;
}): JSX.Element => {
  const { id, applicant1ReferenceId, topUpApplication } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();

  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: { xs: 0, sm: '48px' },
            paddingLeft: { xs: 0, sm: '48px' },
          }}>
          <LogoEditApplication />
          {role !== USER_ROLES.INVESTOR && (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                onClick={() => {
                  dispatch(logout());
                  history.push('/login');
                }}>
                <ExitToApp fontSize="large" sx={{ color: 'primary.main' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: 14 }}>
        <Routes
          id={id}
          applicant1ReferenceId={applicant1ReferenceId}
          topUpApplication={topUpApplication}
        />
      </Box>
      <Footer />
    </Box>
  );
};

const InvestorRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const result = useRouteMatch<{ slug: string }>({
    path: `${path}/:slug/`,
  });
  const location = useLocation();
  const investorPath = location.pathname.includes('/topup/') ? '/topup' : '/investment-details';
  const referenceId = result?.params.slug;
  return (
    <Switch>
      <Route
        exact
        path={path}
        // eslint-disable-next-line
        render={(props: any) => (
          <Redirect to={`${path}/:referenceId/details`} from={props.location.state} />
        )}
      />
      <Route path={`${path}/:referenceId/details`} component={InvestmentDetails} />
      <Route path={`${path}/:referenceId/verify-pan`} component={LoginOtp} />
      <ProtectedRoute
        path={`${path}/:referenceId/application-details`}
        component={InvestorApplication}
        isProtectedRouteForRefLogin={true}
        // redirectedRoute={`/investment-details/${referenceId}/details`}
        redirectedRoute={`${investorPath}/${referenceId}/details`}
      />
      <ProtectedRoute
        path={`${path}/:referenceId/investor-edit-application`}
        component={EditApplication}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`${investorPath}/${referenceId}/details`}
      />
      <ProtectedRoute
        path={`${path}/edit-application`}
        component={EditApplication}
        isProtectedRouteForRefLogin={false}
        redirectedRoute={`/login`}
        acceptedRoles={[USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER]}
      />
      <LoginRequireRoute
        path="*"
        routePath={
          referenceId !== 'edit-application' ? `${investorPath}/${referenceId}/details` : '/login'
        }
      />

      {/* 
      <Route path={`${path}/:referenceId/application-details`} component={InvestorApplication} />
      <Route path={`${path}/edit-application`} component={EditApplication} /> 
      <LoginRequireRoute path="*" /> 
      <Route
        path="*"
        render={() => {
          return 'Page you are requesting is not found.....';
        }}
      /> */}
    </Switch>
  );
};

export default InvestorRoutes;
