import {
  Box,
  Typography,
  Button,
  AppBar,
  CardMedia,
  Divider,
  IconButton,
  Grid,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Applicant,
  ApplicationProps,
  Broker,
  esignApplicationResponse,
  getSalutationResponse,
  individuals_Poa_nonIndividuals_Documents,
} from '../../redux-store/types/api-types';
import {
  esignApplication,
  getApplicationDetailsWithRefId,
  getSalutation,
  UpdateAppliSalutation,
} from '../../redux-store/actions/onBoarding';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { Footer } from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  APPLICATION_TYPE,
  ESIGN_STATUS,
  RiskProfileEnabled,
  USER_ROLES,
} from '../../utils/constant';
import {
  getDocuments,
  isFormValidForSubmission,
  nonIndividualFormValidForSubmission,
  updateApplication,
  getBrokers,
  updateEsignDeclarations,
} from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import { ApplicationDetailsCommonLayout } from '../ApplicationDetails/index';
import { getNationalityList } from '../../redux-store/actions';
import { nationaliyType } from '../../redux-store/types/mdms';
import MFCheckbox from '../../lib/formik/Checkbox';
import { Formik } from 'formik';
import {
  applicationComparison,
  checkIfApplicationIsNonIndividual,
  checkSalutation,
  checkSalutationAccess,
  getApplicantNames,
} from '../../utils/utilityFunctions';
import { NotesWithText } from '../investors/components';
import MFSelectField from '../../lib/formik/SelectField';
import { MFTextField } from '../../lib/formik';
import { NonIndividualApplicationLayout } from '../NonIndividualApplicationDetails';
import {
  useMdmsBrokerListData,
  useMdmsCountryData,
  useMdmsDocumentData,
  useMdmsRiskProfileData,
  useMdmsTopUpDocumentData,
} from '../../utils/useDataMdms';
import { Location } from 'history';
import { Logo2 } from '../Logo';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));
export type Values = {
  fundMemorandumCheck: boolean | null;
  kycInformationConsent: boolean | null;
  applicants: Partial<Applicant>[];
};

const initialValues: Values = {
  fundMemorandumCheck: true,
  kycInformationConsent: true,
  applicants: [
    {
      salutation: '',
      otherSalutation: '',
    },
  ],
};

export default function InvestorApplication({
  location,
}: {
  location: Location<{
    topUpApplication: boolean;
  }>;
}): JSX.Element {
  const { referenceId } = useParams<{ referenceId: string }>();
  const { topUpApplication } = location.state;
  const dispatch = useDispatch();
  const history = useHistory();
  const { investor, auth } = useSelector((store: RootStateType) => store);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<ApplicationProps>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const [salutationValue, setSalutationValue] = useState<string[]>();
  const { countries } = useMdmsCountryData();
  const { BrokerList } = useMdmsBrokerListData();
  const { riskProfile } = useMdmsRiskProfileData();
  const { Document } = useMdmsDocumentData();
  const { TopUpDocument } = useMdmsTopUpDocumentData();

  useEffect(() => {
    (async function () {
      try {
        let dropdownValues = [] as string[];
        if (checkSalutationAccess(auth.role)) {
          const responsesalutation = (await dispatch(
            getSalutation()
          )) as unknown as getSalutationResponse;
          dropdownValues = responsesalutation.map((val) => {
            return val.salutation;
          });
          setSalutationValue([...dropdownValues, 'Others']);
        }
        const _application = (await dispatch(
          getApplicationDetailsWithRefId(referenceId, topUpApplication)
        )) as unknown as ApplicationProps;
        const { applicants: exisitingApplicants = [] } = _application || {};
        setApplication(_application);
        setFatcaDetails({
          ...fatcaDetails,
          fundMemorandumCheck: _application.fundMemorandumCheck || true,
          kycInformationConsent: _application.kycInformationConsent || true,
          applicants: exisitingApplicants.map((applicant) => ({
            salutation: [...dropdownValues, ''].includes(applicant.salutation || '')
              ? applicant.salutation
              : 'Others',
            otherSalutation: dropdownValues.includes(applicant.salutation || '')
              ? ''
              : applicant.salutation,
          })),
        });
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  useEffect(() => {
    const { token } = investor;
    if (!token) {
      history.push(`/${topUpApplication ? 'topup' : 'investment-details'}/${referenceId}/details`);
    }
  }, []);

  const handleEsign = async (values: Values) => {
    try {
      setIsSubmitting(true);
      if (values.fundMemorandumCheck === false || values.kycInformationConsent === false) {
        throw 'Declaration is required';
      }
      const { applicants } = values;

      if (checkSalutationAccess(auth.role)) {
        if (checkSalutation(applicants)) {
          throw 'Salutation is Required';
        }
        const updateSaluation = applicants.map((_applicant) => {
          const { otherSalutation, ...rest } = _applicant || {};
          return {
            ...rest,
            salutation: [...(salutationValue as string[]), '']
              .filter((val) => {
                return val !== 'Others';
              })
              .includes(_applicant.salutation || '')
              ? _applicant.salutation
              : _applicant.otherSalutation,
          };
        });

        const { applicants: exisitingApplicants = [], id } = application || {};
        setIsSubmitting(true);
        if (id) {
          const updatedApplicants = exisitingApplicants.map((applicant, index) => ({
            ...applicant,
            ...updateSaluation[index],
          }));
          (await dispatch(
            UpdateAppliSalutation(id, {
              ...application!,
              applicants: updatedApplicants,
            } as Partial<ApplicationProps>)
          )) as unknown as ApplicationProps;
        }
      }

      let risk;
      if (RiskProfileEnabled) {
        risk = riskProfile;
      }
      if (ESIGN_STATUS.PENDING_SIGNATURE !== application?.signDetails.status) {
        !checkIfApplicationIsNonIndividual(application as ApplicationProps)
          ? ESIGN_STATUS.NOT_GENERATED === application?.signDetails.status
            ? await isFormValidForSubmission(
                application as ApplicationProps,
                risk,
                true,
                true,
                countries,
                BrokerList.broker_list as Broker[],
                Document,
                TopUpDocument,
                auth.role
              )
            : await isFormValidForSubmission(
                application as ApplicationProps,
                risk,
                true,
                false,
                countries,
                BrokerList.broker_list as Broker[],
                Document,
                TopUpDocument,
                auth.role
              )
          : ESIGN_STATUS.NOT_GENERATED === application?.signDetails.status
          ? await nonIndividualFormValidForSubmission(
              application as ApplicationProps,
              risk,
              Document[
                application?.applicants[0]?.investorType?.toString() || ''
              ] as individuals_Poa_nonIndividuals_Documents[],
              TopUpDocument[
                application?.applicants[0]?.investorType?.toString() || ''
              ] as individuals_Poa_nonIndividuals_Documents[],
              countries.countries,
              BrokerList.broker_list as Broker[],
              true,
              auth.role
            )
          : await nonIndividualFormValidForSubmission(
              application as ApplicationProps,
              risk,
              Document[
                application?.applicants[0]?.investorType?.toString() || ''
              ] as individuals_Poa_nonIndividuals_Documents[],
              TopUpDocument[
                application?.applicants[0]?.investorType?.toString() || ''
              ] as individuals_Poa_nonIndividuals_Documents[],
              countries.countries,
              BrokerList.broker_list as Broker[],
              false,
              auth.role
            );
      }
      setIsSubmitting(true);
      const { signDetails, id } = application || {};
      if (signDetails?.url) {
        window.open(signDetails?.url, '_blank');
        return;
      }

      if (id) {
        const applicationResponse = (await dispatch(
          updateEsignDeclarations({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              fundMemorandumCheck: values.fundMemorandumCheck,
              kycInformationConsent: values.kycInformationConsent,
            },
            applicationId: id,
          })
        )) as unknown as ApplicationProps;
        setApplication({ ...application, ...applicationResponse });
        if (applicationResponse.fundMemorandumCheck && applicationResponse.kycInformationConsent) {
          const response = (await dispatch(
            esignApplication(referenceId, topUpApplication)
          )) as unknown as esignApplicationResponse;
          setApplication({
            ...application,
            ...applicationResponse,
            signDetails: response,
          } as unknown as ApplicationProps);
          window.open(response?.url, '_blank');
          return;
        }
        return;
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e as string));
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={fatcaDetails} onSubmit={handleEsign} enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Box
          sx={{
            bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
          }}
          component="form"
          onSubmit={handleSubmit}>
          <IconButton
            sx={{
              position: 'fixed',
              right: 0,
              bottom: 75,
              borderRadius: '5px  0 0 5px',
              '&,:hover': {
                bgcolor: 'primary.main',
              },
            }}
            onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
            <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
          </IconButton>
          <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Logo2 />
            </Box>
          </AppBar>
          <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }} ref={applicationDetailRef}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                justifyContent: 'space-between',
                mt: 14,
              }}>
              <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main', mb: 4 }}>
                Application Details
              </Typography>
              {application?.editable && (
                <IconButton>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() =>
                      history.push(
                        `/${
                          topUpApplication ? 'topup' : 'investment-details'
                        }/${referenceId}/investor-edit-application/distributor-details`,
                        {
                          id: application?.id,
                          applicant1ReferenceId: application?.applicant1ReferenceId,
                          topUpApplication: topUpApplication,
                        }
                      )
                    }
                  />
                </IconButton>
              )}
            </Box>
            {application && (
              <>
                {application.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? (
                  <NonIndividualApplicationLayout loading={loading} application={application} />
                ) : (
                  <ApplicationDetailsCommonLayout loading={loading} application={application} />
                )}
                {checkSalutationAccess(auth.role) && (
                  <Box
                    sx={{
                      bgcolor: 'white',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                      borderRadius: '10px',
                    }}
                    mt={5}>
                    <Grid container sx={{ mt: 1, pl: { xs: 2 }, pr: { xs: 2 } }}>
                      <Box sx={{ p: 2 }}>
                        <Grid
                          container
                          sx={{ mt: 1, pl: { xs: 2, sm: 2, lg: 6 }, pr: { xs: 2, sm: 10 } }}>
                          <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                            Salutation Details
                          </Typography>
                        </Grid>
                      </Box>

                      <Grid
                        container
                        rowSpacing={1}
                        // columnSpacing={2}
                        sx={{
                          width: '100%',
                          ml: 0,
                          pt: 0,
                          p: 2,
                          pb: 2,
                          '.MuiGrid-item': { px: { xs: 0, lg: '30px' } },
                        }}>
                        {values.applicants.map((applicant, Ind) => {
                          return (
                            <>
                              <Grid item xs={12} md={12} sm={12} lg={6}>
                                <MFSelectField
                                  name={`applicants.${Ind}.salutation`}
                                  label={`Salutation for  ${getApplicantNames(
                                    application.applicants,
                                    Ind
                                  )} *`}
                                  items={(salutationValue || []).map((address) => ({
                                    key: address,
                                    value: address,
                                  }))}
                                  onChange={(e) => {
                                    setValues({
                                      ...values,
                                      applicants: values.applicants.map((appli, index) => {
                                        if (Ind === index) {
                                          return {
                                            ...appli,
                                            salutation: e.target.value as string,
                                            otherSalutation: '',
                                          };
                                        }
                                        return appli;
                                      }),
                                    });
                                  }}
                                />
                              </Grid>
                              {applicant.salutation === 'Others' && (
                                <Grid item xs={12} md={12} sm={12} lg={6}>
                                  <MFTextField
                                    name={`applicants.${Ind}.otherSalutation`}
                                    label={`Please Specify Salutation for ${getApplicantNames(
                                      application.applicants,
                                      Ind
                                    )} *`}
                                    placeholder={`Please Specify Salutation ${getApplicantNames(
                                      application.applicants,
                                      Ind
                                    )}`}
                                  />
                                </Grid>
                              )}
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {![USER_ROLES.FUND_MANAGER, USER_ROLES.TRUSTEE].includes(auth.role) && (
                  <Grid item xs={12} sx={{ mt: 4 }}>
                    <MFCheckbox
                      name={`fundMemorandumCheck`}
                      disabled={application?.fundMemorandumCheck || false}
                      label={
                        <Typography>
                          I hereby confirm that I have read, and understood the{' '}
                          <Button
                            sx={{
                              fontSize: 'inherit',
                              fontWeight: 'inherit',
                              fontFamily: 'inherit',
                              color: 'inherit',
                              textUnderlinePosition: 'under',
                              textDecoration: 'underline',
                              p: 0,
                              ':hover': {
                                bgcolor: '#F4FCFC',
                                color: 'primary.main',
                                textDecoration: 'underline',
                              },
                            }}
                            onClick={() => window.open(application.plan.tcLink)}>
                            PPM Acknowledgement
                          </Button>
                        </Typography>
                      }
                      sx={{ justifyContent: 'flex-start' }}
                      onChange={({ target: { checked } }) => {
                        setValues({
                          ...values,
                          fundMemorandumCheck: checked,
                        });
                      }}
                    />
                    <MFCheckbox
                      name={`kycInformationConsent`}
                      disabled={application?.kycInformationConsent || false}
                      label={
                        <Typography>
                          I have granted my consent to 3PIM or its representatives to fetch my KYC
                          information, data or documents, as may be required.
                        </Typography>
                      }
                      onChange={({ target: { checked } }) => {
                        setValues({
                          ...values,
                          kycInformationConsent: checked,
                        });
                      }}
                    />
                  </Grid>
                )}
                {![USER_ROLES.FUND_MANAGER, USER_ROLES.TRUSTEE].includes(auth.role) && (
                  <Grid item xs={12}>
                    <NotesWithText
                      noteTitle="Disclaimer"
                      displayContent="(a)  The Investment Manager reserves the right to inform the existing Distributor / Referral Agent of any request received from the Contributor which can directly or indirectly impact the Distributors / Referral Agent."
                      displayContent1="(b) Any such request shall be processed only after the completion of cooling off period  subsequent to the receipt of request from the Contributor/s."
                      // background="rgba(229, 67, 92, 0.15)"
                      textColor="#000000"
                    />
                  </Grid>
                )}
                {![ESIGN_STATUS.SIGNED, ESIGN_STATUS.EXPIRED].includes(
                  application.signDetails.status
                ) && (
                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        color: 'common.white',
                        minWidth: '200px',
                        mt: 4,
                        fontWeight: 600,
                        lineHeight: 1.5,
                      }}
                      // onClick={handleEsign}
                      disabled={isSubmitting}>
                      {`e-Sign ${isSubmitting ? '...' : ''}`}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Footer />
        </Box>
      )}
    </Formik>
  );
}
